import React from 'react';
import i18n from 'i18next';
import { Element } from 'react-scroll';

import {
  AgilityRadioGroup,
  AgilityTypography,
  AgilityGrid,
} from 'Src/AgilityComponents';

import { preferContact } from 'App/utils/data';

const YourDetailShowPreferContact = ({
  fields,
  errors,
  isActive,
  handleChange,
}) => {
  const hasError = React.useMemo(
    () =>
      errors &&
      errors.hasOwnProperty('contactPreference') &&
      errors.contactPreference !== '',
    [errors]
  );

  return (
    <AgilityGrid container data-test-id="contact-preference-option">
      <AgilityGrid item xs={12} sm={12}>
        <div className="question-wrapper">
          <AgilityTypography
            variant="body2"
            component="p"
            tooltipText={i18n.t('yourDetail.toolTip.preferContactInfo')}
            showInfo
          >
            {i18n.t('yourDetail.header.preferContact')}
          </AgilityTypography>
          <Element name="contactPreference">
            <AgilityRadioGroup
              options={preferContact}
              onChange={event => {
                handleChange(event.target.value, 'contactPreference');
              }}
              data-test-id="preferContact"
              value={fields.contactPreference || ''}
              disabled={!isActive}
              row
              isError={hasError}
              helperText={hasError ? errors.contactPreference : ''}
            />
          </Element>
        </div>
      </AgilityGrid>
    </AgilityGrid>
  );
};

export default YourDetailShowPreferContact;
