import React from 'react';
import TextField from '@material-ui/core/TextField';

const AgilityMaskInput = props => {
  const handleChange = e => {
    props.onChange(e.target.value);
  };

  return (
    <div
      className={`text-field-wrapper ${props.className}`}
      style={props.style}
      data-test-id="masked-input"
    >
      <TextField
        {...props}
        onChange={event => handleChange(event)}
        label={props.placeholder}
        id={props.id}
        type={props.type}
        rowsMax={props.rowsMax}
        disabled={props.disabled}
        variant={props.variant}
        name={props.name}
        helperText={props.helperText}
        fullWidth={props.fullWidth}
        size={props.size}
        value={props.value}
      />
    </div>
  );
};
export default AgilityMaskInput;
