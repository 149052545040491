import i18n from 'i18next';
import { AGENT_LOGIN_VALIDATION_API } from 'App/utils/constants';
import { showSaveProgressLoading } from 'App/modules/onBoarding/action';
import getListApiUrl from 'App/utils/helper';

export const AGENT_LOGIN_ACTION = 'AGENT_LOGIN_ACTION';

export const validateAgentLogin = data => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(showSaveProgressLoading(true));
    const url = getListApiUrl(AGENT_LOGIN_VALIDATION_API, data);
    window.Api.get(url)
      .then(result => {
        dispatch({
          type: AGENT_LOGIN_ACTION,
          payload: result,
        });
        dispatch(showSaveProgressLoading(false));
        return resolve(result);
      })
      .catch(error => {
        dispatch({
          type: AGENT_LOGIN_ACTION,
          payload: error,
        });
        dispatch(showSaveProgressLoading(false));
        return reject({ message: i18n.t('500.error.message') });
      });
  });
};
