import { connect } from 'react-redux';
import SearchLocation from './SearchLocation';
import { searchAddress, getAddressDetails } from './action';

const mapStateToProps = (state, props) => {
  return {
    ...props,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    searchAddress: data => dispatch(searchAddress(data)),
    getAddressDetails: data => dispatch(getAddressDetails(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchLocation);
