import React from 'react';
import { string, object, func } from 'prop-types';
import i18n from 'i18next';
import { Link } from '@material-ui/core';
import { AlertTitle } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AgilityTypography, AgilityInfo } from 'Src/AgilityComponents';
import dompurify from 'isomorphic-dompurify';
const sanitizer = dompurify.sanitize;

const NoOfferMessage = props => {
  const { productDetails, enteredSearchLocation } = props;
  return (
    <React.Fragment>
      <AgilityInfo
        severity="warning"
        className="text-left"
        icon={
          <FontAwesomeIcon
            icon={['fas', `${productDetails.icon}`]}
            size="sm"
            className={`${productDetails.icon}`}
          />
        }
      >
        <AlertTitle>
          <AgilityTypography variant="h4" component="h6">
            {i18n.t('offers.notfound.header')}
          </AgilityTypography>
        </AlertTitle>
        <AgilityTypography variant="caption" component="p">
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                i18n.t('offers.notfound.message', {
                  phone: process.env.REACT_APP_COMPANY_PHONE,
                })
              ),
            }}
          ></span>
        </AgilityTypography>
      </AgilityInfo>
    </React.Fragment>
  );
};

NoOfferMessage.propTypes = {
  productDetails: object,
  enteredSearchLocation: string,
  openSearchIdentifierDialog: func,
};

NoOfferMessage.defaultProps = {
  productDetails: {},
  enteredSearchLocation: '',
};

export default NoOfferMessage;
