import React from 'react';
import AgilityInfoDialog from './AgilityInfoDialog';
import AgilityTypography from '../Typography/AgilityTypography';
import dompurify from 'isomorphic-dompurify';
import { func, string, bool, array } from 'prop-types';

const sanitizer = dompurify.sanitize;
const AgilityCommonDialog = ({
  open,
  title,
  okText,
  maxWidth,
  contents,
  className,
  titleAlign,
  okClick,
  onDismiss,
  ...other
}) => {
  return (
    <AgilityInfoDialog
      {...other}
      className={className}
      fullWidth
      maxWidth={maxWidth}
      open={open}
      title={title}
      okText={okText}
      titleAlign={titleAlign}
      okClick={okClick}
      closeClick={onDismiss}
    >
      <React.Fragment>
        {contents.map((item, index) => (
          <AgilityTypography
            key={index}
            data-test-id={item.id}
            variant={item.variant}
            component={item.element}
            className={item.class}
            dangerouslySetInnerHTML={{
              __html: sanitizer(item.body),
            }}
          />
        ))}
      </React.Fragment>
    </AgilityInfoDialog>
  );
};

AgilityCommonDialog.propTypes = {
  okClick: func,
  onDismiss: func,
  open: bool,
  title: string,
  okText: string,
  maxWidth: string,
  contents: array,
  titleAlign: string,
  className: string,
};
AgilityCommonDialog.defaultProps = {
  open: false,
  title: '',
  okText: '',
  maxWidth: 'md',
  contents: [],
  titleAlign: '',
  className: '',
};

export default AgilityCommonDialog;
