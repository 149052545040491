import {
  FETCH_IDENTIFICATION_TYPE_LIST,
  GET_CARD_LIST,
  GET_CREDITCARD_FRAME_RESPONSE,
  GET_INDUSTRY_LIST,
  GET_PAYMENT_FREQUENCY_LIST,
  GET_PREFER_HEARING_LIST,
  GET_SALUTATION_LIST,
  RESET_USER_DATA_ACTION,
  SAVE_PROGRESS_IS_SUCCESS_ACTION,
  SAVE_PROGRESS_LOADING_ACTION,
  SAVE_PROGRESS_TRIGGER_ACTION,
  SET_ABN_NUMBER_ACTION,
  SET_ACN_NUMBER_ACTION,
  SET_BUSINESS_NAME_ACTION,
  SET_GST_REGISTERED_AT_ACTION,
  SET_MOVE_IN_DATES_ACTION,
  SET_ORGANISATION_IS_TRUST_ACTION,
  SET_ORGANISATION_TYPE_DESCRIPTION_ACTION,
  SET_ORGANISATION_TYPE_ID_ACTION,
  SET_PRIMARY_SITE_IDENTIFIER,
  SET_TEMPORAL_DATA,
  SET_TRADING_NAME_ACTION,
  TRIGGER_EMAIL_SETTER,
  YOUR_PROPERTY_COORDINATORS_LIST_ACTION,
  YOUR_PROPERTY_SOLAR_TYPE_LIST_ACTION,
  YOUR_PROPERTY_STATE_LIST_ACTION,
} from './action';

import { getCurrentTimestamp } from 'App/utils/helper';

const initialState = {
  stateList: [],
  solarTypeList: [],
  coordinatorsList: [],
  identificationTypeList: [],
  industryList: [],
  organisationTypeId: null,
  organisationTypeDescription: null,
  organisationIsTrust: false,
  businessName: '',
  abnNumber: '',
  abnNumberError: '',
  acnNumber: '',
  gstRegisteredAt: null,
  abnIsLoading: false,
  businessNameIsLoading: false,
  businessNameSelected: false,
  salutationList: [],
  cardList: [],
  preferHearingList: [],
  moveInDatesList: [],
  saveProgressTrigger: getCurrentTimestamp(),
  saveProgressLoading: false,
  saveProgressIsSuccess: false,
  triggerTempProgressSave: {
    trigger: false,
    type: 'mobile',
  },
  paymentFrequencyList: [],
  progressData: {
    value: '',
    name: '',
  },
  primarySiteIdentifier: {},
  bankCodeList: [],
  creditCardIframe: {},
};

const onBoarding = (state = initialState, action) => {
  switch (action.type) {
    case YOUR_PROPERTY_SOLAR_TYPE_LIST_ACTION:
      return { ...state, solarTypeList: action.payload };
    case YOUR_PROPERTY_STATE_LIST_ACTION:
      return { ...state, stateList: action.payload };
    case YOUR_PROPERTY_COORDINATORS_LIST_ACTION:
      return { ...state, coordinatorsList: action.payload };
    case FETCH_IDENTIFICATION_TYPE_LIST:
      return { ...state, identificationTypeList: action.payload };
    case GET_INDUSTRY_LIST:
      return { ...state, industryList: action.payload };
    case SET_ORGANISATION_TYPE_ID_ACTION:
      return { ...state, organisationTypeId: action.payload };
    case SET_ORGANISATION_TYPE_DESCRIPTION_ACTION:
      return { ...state, organisationTypeDescription: action.payload };
    case SET_ORGANISATION_IS_TRUST_ACTION:
      return { ...state, organisationIsTrust: action.payload };
    case SET_BUSINESS_NAME_ACTION:
      return { ...state, businessName: action.payload };
    case SET_ABN_NUMBER_ACTION:
      return { ...state, abnNumber: action.payload };
    case SET_ACN_NUMBER_ACTION:
      return { ...state, acnNumber: action.payload };
    case SET_GST_REGISTERED_AT_ACTION:
      return { ...state, gstRegisteredAt: action.payload };
    case SET_TRADING_NAME_ACTION:
      return { ...state, tradingName: action.payload };
    case GET_SALUTATION_LIST:
      return { ...state, salutationList: action.payload };
    case GET_CARD_LIST:
      return { ...state, cardList: action.payload };
    case GET_PREFER_HEARING_LIST:
      return { ...state, preferHearingList: action.payload };
    case SET_MOVE_IN_DATES_ACTION:
      return { ...state, moveInDatesList: action.payload };
    case SAVE_PROGRESS_TRIGGER_ACTION:
      return {
        ...state,
        saveProgressTrigger: action.payload,
        progressData: action.progressData,
      };
    case SAVE_PROGRESS_LOADING_ACTION:
      return { ...state, saveProgressLoading: action.payload };
    case SAVE_PROGRESS_IS_SUCCESS_ACTION:
      return { ...state, saveProgressIsSuccess: action.payload };
    case SET_PRIMARY_SITE_IDENTIFIER:
      return { ...state, primarySiteIdentifier: action.payload };
    case RESET_USER_DATA_ACTION:
      return {
        ...state,
        saveProgressTrigger: getCurrentTimestamp(),
        saveProgressLoading: false,
        saveProgressIsSuccess: false,
      };
    case GET_PAYMENT_FREQUENCY_LIST:
      return { ...state, paymentFrequencyList: action.payload };
    case GET_CREDITCARD_FRAME_RESPONSE:
      return { ...state, creditCardIframe: action.payload };
    case TRIGGER_EMAIL_SETTER:
      return {
        ...state,
        triggerTempProgressSave: {
          trigger: !state.triggerTempProgressSave,
          type: action.payload.targetType,
        },
      };
    case SET_TEMPORAL_DATA:
      return {
        ...state,
        progressData: action.progressData,
      };
    default:
      return state;
  }
};

export default onBoarding;
