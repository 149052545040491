import React from 'react';
import { AgilityButton, AgilityTypography } from 'Src/AgilityComponents';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';

const SignUpFailure = props => {
  const { customerName } = props;
  return (
    <div className="success-wrapper">
      <div className="failure-card">
        <AgilityTypography
          variant="h3"
          component="h3"
          align="center"
          containerClass="heading__container"
        >
          {i18n.t('signUpCompletion.failure.title')}
        </AgilityTypography>
        <AgilityTypography
          variant="body1"
          component="p"
          align="left"
          containerClass="mb-1"
        >
          {i18n.t('signUpCompletion.failure.description', {
            CUSTOMER_NAME: customerName,
          })}
        </AgilityTypography>
        <AgilityButton
          variant="contained"
          color="primary"
          size="large"
          className="mt-3"
          data-test-id="contact-support"
          onClick={() => {
            window.location.href = process.env.REACT_APP_SUPPORT_URL;
          }}
          label={i18n.t('signUpCompletion.contactSupport.text')}
        />
        <Link to="/">
          <AgilityButton
            color="primary"
            size="large"
            className="mt-1"
            label={i18n.t('signUpCompletion.backToHome.text')}
          />
        </Link>
      </div>
    </div>
  );
};

SignUpFailure.propTypes = {
  customerName: string,
};
SignUpFailure.defaultProps = {
  customerName: '',
};

export default SignUpFailure;
