import React, { useState, useEffect } from 'react';
import './signup-page.scss';
import i18n from 'i18next';
import { format } from 'date-fns';
import { string, array, bool } from 'prop-types';
import { Box } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import {
  AgilityButton,
  AgilityCard,
  AgilityTableCell,
  AgilityTypography,
} from 'Src/AgilityComponents';
import { referAFriend, showDirectDebitStep } from 'App/customConfig';
import dompurify from 'dompurify';
import { useTheme } from '@material-ui/core/styles';
import ReferDialog from 'App/modules/onBoarding/signUpCompletion/ReferDialog';
import { css } from '@emotion/react';
const sanitizer = dompurify.sanitize;

const SignUpSuccess = props => {
  const {
    address,
    referenceNo,
    connectionDate,
    service,
    paymentOption,
    lifeSupport,
    promoCode,
    refer,
    showReferAFriend,
  } = props;

  const [isReferOpen, setIsReferOpen] = useState(false);
  const [firstReferOpened, setFistReferOpened] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!firstReferOpened && showReferAFriend) {
        setIsReferOpen(true);
        setFistReferOpened(true);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const toggleReferPopup = val => {
    setIsReferOpen(val);
  };

  return (
    <div className="success-wrapper">
      <div className="success-card mt-2">
        <img src={theme.images.success} alt="Success" width="205" />
        <AgilityTypography
          variant="h2"
          component="h2"
          align="center"
          containerClass="heading__container"
          dangerouslySetInnerHTML={{
            __html: sanitizer(i18n.t('signUpCompletion.success.title')),
          }}
        ></AgilityTypography>
        <Box my={{ xs: 2, sm: 2, md: 4 }}>
          <AgilityTypography
            variant="body1"
            component="p"
            align="left"
            containerClass="mb-3"
          >
            {i18n.t('signUpCompletion.success.primaryText', {
              COMPANY: process.env.REACT_APP_RETAILER_NAME,
            })}
          </AgilityTypography>
          <AgilityTypography
            variant="h4"
            component="h4"
            align="left"
            containerClass="mb-1"
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                i18n.t('signUpCompletion.success.whatsNext.header')
              ),
            }}
          />
          <AgilityTypography
            variant="body1"
            component="p"
            align="left"
            containerClass="mb-1"
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                i18n.t('signUpCompletion.success.whatsNext.body1')
              ),
            }}
          />
          <AgilityTypography
            variant="h4"
            component="h4"
            align="left"
            containerClass="mb-1"
          >
            {i18n.t('signUpCompletion.success.whatsNext.header2')}
          </AgilityTypography>
          <AgilityTypography
            variant="body1"
            component="p"
            align="left"
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                i18n.t('signUpCompletion.success.whatsNext.body2', {
                  EMAIL: process.env.REACT_APP_COMPANY_EMAIL,
                  PHONE: process.env.REACT_APP_COMPANY_PHONE,
                  COMPANY: process.env.REACT_APP_RETAILER_NAME,
                  RETAILER_SITE_URL: process.env.REACT_APP_RETAILER_SITE_URL,
                  RETAILER_SITE_NAME:
                    process.env.REACT_APP_RETAILER_SITE_URL.replace(
                      /^(http|https):\/\//g,
                      ''
                    ),
                  RETAILER_EMAIL: process.env.REACT_APP_RETAILER_EMAIL,
                  RETAILER_PHONE: process.env.REACT_APP_RETAILER_PHONE,
                  RETAILER_PHONE_WITHOUT_SPACES:
                    process.env.REACT_APP_RETAILER_PHONE.replace(/\s/g, ''),
                })
              ),
            }}
          />
        </Box>

        {referAFriend && showReferAFriend && (
          <React.Fragment>
            <AgilityButton
              onClick={() => toggleReferPopup(true)}
              label={i18n.t('signupCompletion.refer.referButton')}
              variant="contained"
              color="primary"
              fullWidth={false}
              size="large"
              type="primary"
              buttonCss={css`
                margin-bottom: 10px !important;
              `}
            />
            <ReferDialog
              referenceNo={referenceNo}
              isOpen={isReferOpen}
              togglePopup={toggleReferPopup}
            />
          </React.Fragment>
        )}

        <AgilityCard className="mb-3 mt-3">
          <AgilityTypography variant="h4" component="h4" align="left">
            {i18n.t('signUpCompletion.planSummary.text')}
          </AgilityTypography>

          <AgilityTypography variant="caption" component="p" align="left">
            {address}
          </AgilityTypography>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <AgilityTableCell>
                    {i18n.t('signUpCompletion.referenceNo.placeholder')}
                  </AgilityTableCell>
                  <AgilityTableCell align="right" data-test-id="referenceNo">
                    {referenceNo}
                  </AgilityTableCell>
                </TableRow>
                {connectionDate ? (
                  <TableRow>
                    <AgilityTableCell>
                      {i18n.t('signUpCompletion.connectDate.placeholder')}
                    </AgilityTableCell>
                    <AgilityTableCell align="right" data-test-id="connectDate">
                      {format(new Date(connectionDate), window.dateFormat)}
                    </AgilityTableCell>
                  </TableRow>
                ) : null}
                <TableRow>
                  <AgilityTableCell>
                    {i18n.t('signUpCompletion.service.placeholder')}
                  </AgilityTableCell>
                  <AgilityTableCell align="right" data-test-id="service">
                    {service.map((item, index) => {
                      return <div key={index}>{item.value || ''}</div>;
                    })}
                  </AgilityTableCell>
                </TableRow>
                {promoCode ? (
                  <TableRow>
                    <AgilityTableCell>
                      {i18n.t('signUpCompletion.promoCode.placeholder')}
                    </AgilityTableCell>
                    <AgilityTableCell align="right" data-test-id="promo-code">
                      {promoCode}
                    </AgilityTableCell>
                  </TableRow>
                ) : null}
                {refer !== null ? (
                  <TableRow>
                    <AgilityTableCell>
                      {i18n.t('signUpCompletion.referFriend.placeholder')}
                    </AgilityTableCell>
                    <AgilityTableCell
                      align="right"
                      data-test-id="refer-a-friend"
                    >
                      {refer} applied
                    </AgilityTableCell>
                  </TableRow>
                ) : null}
                {showDirectDebitStep ? (
                  <TableRow>
                    <AgilityTableCell>
                      {i18n.t('signUpCompletion.paymentOption.placeholder')}
                    </AgilityTableCell>
                    <AgilityTableCell
                      align="right"
                      data-test-id="payment-option"
                    >
                      {paymentOption}
                    </AgilityTableCell>
                  </TableRow>
                ) : null}
                {lifeSupport ? (
                  <TableRow>
                    <AgilityTableCell>
                      {i18n.t('signUpCompletion.lifeSupport.placeholder')}
                    </AgilityTableCell>
                    <AgilityTableCell align="right" data-test-id="life-support">
                      {lifeSupport}
                    </AgilityTableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </AgilityCard>

        {i18n.exists('signUpCompletion.success.whatsNext.footer.heading1') && (
          <AgilityTypography
            variant="h3"
            component="h3"
            align="left"
            containerClass="mb-1"
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                i18n.t('signUpCompletion.success.whatsNext.footer.heading1')
              ),
            }}
          />
        )}
        {i18n.exists('signUpCompletion.success.whatsNext.footer.heading2') && (
          <AgilityTypography
            variant="h4"
            component="h4"
            align="left"
            containerClass="mb-2 underline"
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                i18n.t('signUpCompletion.success.whatsNext.footer.heading2', {
                  RETAILER_SITE_URL: process.env.REACT_APP_RETAILER_SITE_URL,
                })
              ),
            }}
          />
        )}

        {i18n.exists('signUpCompletion.success.whatsNext.footer.body') && (
          <AgilityTypography
            variant="body2"
            component="p"
            align="left"
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                i18n.t('signUpCompletion.success.whatsNext.footer.body', {
                  EMAIL: process.env.REACT_APP_COMPANY_EMAIL,
                  PHONE: process.env.REACT_APP_COMPANY_PHONE,
                  COMPANY: process.env.REACT_APP_RETAILER_NAME,
                  RETAILER_SITE_URL: process.env.REACT_APP_RETAILER_SITE_URL,
                  RETAILER_SITE_NAME:
                    process.env.REACT_APP_RETAILER_SITE_URL.replace(
                      /^(http|https):\/\//g,
                      ''
                    ),
                  RETAILER_EMAIL: process.env.REACT_APP_RETAILER_EMAIL,
                  RETAILER_PHONE: process.env.REACT_APP_RETAILER_PHONE,
                  RETAILER_PHONE_WITHOUT_SPACES:
                    process.env.REACT_APP_RETAILER_PHONE.replace(/\s/g, ''),
                })
              ),
            }}
          />
        )}
      </div>
    </div>
  );
};

SignUpSuccess.propTypes = {
  address: string,
  customerName: string,
  referenceNo: string,
  connectionDate: string,
  service: array,
  paymentOption: string,
  lifeSupport: string,
  refer: string,
  showReferAFriend: bool,
};
SignUpSuccess.defaultProps = {
  address: '',
  customerName: '',
  referenceNo: '',
  connectionDate: '',
  service: [],
  paymentOption: '',
  lifeSupport: '',
  refer: null,
  showReferAFriend: false,
};

export default SignUpSuccess;
