export const PROMO_CODE_MAX_LENGTH = 20;

/// Identification.
export const ID_NUMBER_MAX_LENGTH = 16;
export const ID_NUMBER_MIN_LENGTH = 5;

///Your Property
export const STREET_ADDRESS_MAX_LENGTH = 100;
export const SUBURB_MAX_LENGTH = 100;
export const POST_CODE_MAX_LENGTH = 10;
export const METER_ACCESS_OBSTACLES_TELL_US_MORE_MAX_LENGTH = 80;
export const METER_ACCESS_RISKS_TELL_US_MORE_MAX_LENGTH = 160;
export const IDENTIFIER_CODE_MAX_LENGTH = 11;
export const IDENTIFIER_CODE_MIN_LENGTH = 10;
export const POST_CODE = 10;
export const METER_ACCESS_OBSTACLES_TELL_US_MORE = 80;
export const METER_ACCESS_RISKS_TELL_US_MORE = 160;

//Bank Account
export const BANK_ACCOUNT_NAME_MAX_LENGTH = 100;

//CompanyDetail
export const BUSINESS_NAME_MAX_LENGTH = 255;
export const TRADING_NAME_MAX_LENGTH = 255;
export const ABN_NUMBER_MAX_LENGTH = 100;
export const ABN_VALID_LENGTH = 11;
export const ACN_VALID_LENGTH = 9;

//YourDetail
export const FIRST_NAME_MAX_LENGTH = 100;
export const MIDDLE_NAME_MAX_LENGTH = 100;
export const LAST_NAME_MAX_LENGTH = 100;
export const MOBILE_NUMBER_MAX_LENGTH = 10;
export const HOME_NUMBER_MAX_LENGTH = 10;
export const EMAIL_MAX_LENGTH = 100;

export const CARDHOLDER_NAME_MAX_LENGTH = 200;
export const CARD_NUMBER_MAX_LENGTH = 16;

//REGISTER
export const REGISTER_EMAIL_MAX_LENGTH = 100;
export const REGISTER_PASSWORD_MAX_LENGTH = 50;
export const REGISTER_ACCOUNT_NUMBER_MAX_LENGTH = 16;
