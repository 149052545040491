import React from 'react';
import { func, object, array } from 'prop-types';
import i18n from 'i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Scrollbars } from 'react-custom-scrollbars';
import { SIGNUP_STEPS as signupSteps } from 'App/utils/constants';
import { maskSiteIdentifier } from 'App/utils/helper';
import { isNullOrEmpty } from 'App/utils/validationHelper';
const SiteIdentifier = props => {
  const { productDetails, siteIdentifierList, selectedSiteIdentifier } = props;

  const handleRowClick = row => {
    if (selectedSiteIdentifier.siteIdentifier === row.siteIdentifier) {
      return;
    } else {
      props.onSiteIdentifierSelected(row);
    }
  };

  const getFullAddress = arr => {
    if (!arr) return '';
    // no unit
    if (arr.length === 3) {
      return arr.filter(str => !isNullOrEmpty(str.trim())).join(', ');
    }
    // with unit /
    return `${arr[0]} / ${arr[1]}, ${arr[2]}, ${arr[3]}`;
  };

  return (
    <div className="table-responsive">
      <TableContainer className="table-wrapper">
        <Table
          stickyHeader
          aria-label="sticky table"
          data-test-id={`site_identifier-${productDetails.code}`}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                {i18n.t('siteidentifier.table.fulladdress')}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <div className="table-fixed-header-wrapper">
          <Scrollbars autoHeight autoHeightMin={10} autoHeightMax="100%">
            <Table>
              <TableBody>
                {siteIdentifierList.map((row, index) => (
                  <TableRow
                    key={row.siteIdentifier}
                    data-test-id={`table-row-${index}`}
                    className={
                      selectedSiteIdentifier.siteIdentifier ===
                      row.siteIdentifier
                        ? signupSteps.selectedPlan
                        : ''
                    }
                    onClick={() => handleRowClick(row)}
                  >
                    {/* <TableCell
                      data-label={`${
                        productDetails.displayAlternative ||
                        i18n.t('displayAlternative.default.text')
                      }: `}
                      data-test-id={`cell-${row.siteIdentifier}`}
                      className="identifier"
                    >
                      &nbsp;{maskSiteIdentifier(row.siteIdentifier)}
                    </TableCell> */}
                    <TableCell>
                      {getFullAddress(row.multilineAddress)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Scrollbars>
        </div>
      </TableContainer>
    </div>
  );
};
SiteIdentifier.propTypes = {
  onSiteIdentifierSelected: func,
  siteIdentifierList: array,
  productDetails: object,
  selectedSiteIdentifier: object,
};
SiteIdentifier.defaultProps = {
  siteIdentifierList: [],
  productDetails: {},
  selectedSiteIdentifier: {},
};
export default SiteIdentifier;
