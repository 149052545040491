import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import AgilityFormControlLabel from '../FormControlLabel';
import AgilityRadio from '../Radio/AgilityRadio';
import AgilityRadioItemLabel from '../RadioItemLabel/AgilityRadioItemLabel';
import AgilityFormGroup from '../FormGroup';
import isEmpty from 'lodash/isEmpty';
import { array, bool, func } from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './radio-group.scss';

const AgilityRadioGroup = props => {
  const { row, options, isError, helperText, defaultIcon, ...otherProps } =
    props;
  return (
    <AgilityFormGroup row={row} classes={{ root: 'radio-inline' }}>
      <RadioGroup {...otherProps}>
        {!isEmpty(options) &&
          options.map(option => {
            const radioProps = {
              key: option.value,
              value: option.value,
              disabled: props.disabled || option.disabled,
            };
            let iconVisible = false;

            if (defaultIcon) {
              iconVisible = true;
              radioProps.icon = <FontAwesomeIcon icon={['fas', 'square']} />;
              radioProps.checkedIcon = (
                <FontAwesomeIcon icon={['fas', 'check-square']} />
              );
            }

            if (option.icon) {
              iconVisible = true;
              radioProps.icon = (
                <FontAwesomeIcon icon={['fas', `${option.icon}`]} />
              );
              radioProps.checkedIcon = (
                <FontAwesomeIcon icon={['fas', `${option.icon}`]} />
              );
            }
            return (
              <AgilityFormControlLabel
                data-test-id={option.value}
                key={option.value}
                value={option.value}
                classes={{
                  root: `radio-group ${
                    iconVisible === true ? 'icon' : 'icon-hidden'
                  }`,
                }}
                control={<AgilityRadio color="primary" {...radioProps} />}
                label={<AgilityRadioItemLabel focusRipple {...option} />}
              />
            );
          })}
      </RadioGroup>
      {isError && (
        <FormHelperText className="MuiFormHelperText-contained Mui-error">
          {helperText}
        </FormHelperText>
      )}
    </AgilityFormGroup>
  );
};

AgilityRadioGroup.propTypes = {
  options: array,
  row: bool,
  onChange: func,
  isError: bool,
  disabled: bool,
  defaultIcon: bool,
};
AgilityRadioGroup.defaultProps = {
  row: false,
  options: [],
  onChange: func,
  isError: false,
  disabled: false,
  defaultIcon: false,
};

export default AgilityRadioGroup;
