import React from 'react';
import { array, bool, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import pull from 'lodash/pull';
import includes from 'lodash/includes';
import AgilityFormGroup from '../FormGroup';
import AgilityFormControlLabel from '../FormControlLabel';
import AgilityCheckbox from '../Checkbox/AgilityCheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './checkbox-group.scss';
import { useTheme } from '@material-ui/core/styles';

const AgilityCheckboxGroup = props => {
  const { selectedOptions, isRow, options } = props;
  const theme = useTheme();
  const handleChange = name => event => {
    const selectedItem = [...selectedOptions];
    if (event.target.checked) {
      selectedItem.push(name);
    } else {
      pull(selectedItem, name);
    }
    props.onChange(selectedItem);
  };

  return (
    <AgilityFormGroup row={isRow}>
      {!isEmpty(options) &&
        options.map(option => {
          const checkboxProps = {
            key: option.value,
            checked: includes(selectedOptions, option.value),
            onChange: handleChange(option.value),
            value: option.value,
            inputProps: { 'aria-label': option.label },
          };
          let iconVisible = false;
          if (option.icon) {
            iconVisible = true;
            checkboxProps.icon = (
              <FontAwesomeIcon
                icon={[
                  `${theme.typography.icons.checkboxStyle}`,
                  `${option.icon}`,
                ]}
              />
            );
            checkboxProps.checkedIcon = (
              <FontAwesomeIcon
                icon={[
                  `${theme.typography.icons.checkboxStyle}`,
                  option.checkedIcon ? option.checkedIcon : option.icon,
                ]}
              />
            );
          }
          return (
            <AgilityFormControlLabel
              data-test-id={option.value}
              key={option.value}
              classes={{
                root: `checkbox-group ${
                  iconVisible === true ? '' : 'icon-hidden'
                }`,
              }}
              control={
                <AgilityCheckbox
                  disabled={props.disabled}
                  color="primary"
                  {...checkboxProps}
                />
              }
              label={option.label}
            />
          );
        })}
    </AgilityFormGroup>
  );
};

AgilityCheckboxGroup.propTypes = {
  isRow: bool,
  options: array,
  selectedOptions: array,
  onChange: func,
};
AgilityCheckboxGroup.defaultProps = {
  isRow: false,
  options: [],
  selectedOptions: [],
  onChange: func,
};
export default AgilityCheckboxGroup;
