import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AgilityButton from '../Button';
import { func, string, bool, element, oneOfType } from 'prop-types';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AgilityTypography from '../Typography';
import { Scrollbars } from 'react-custom-scrollbars';

const DialogTitle = props => {
  const { onClose, children } = props;
  return (
    <MuiDialogTitle disableTypography {...props}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className="closeButton"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={['fas', `times`]} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const AgilityInfoDialog = props => {
  const {
    showOkButton,
    showModalTitle,
    okClick,
    open,
    closeOnOutsideClick,
    title,
    children,
    okText,
    fullWidth,
    maxWidth,
    className,
    closeClick,
    showDeclineButton,
    okLoading,
    noClick,
    declineText,
  } = props;
  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      className={className}
      // disableBackdropClick={!closeOnOutsideClick}
      open={open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          if (closeOnOutsideClick) {
            closeClick();
          }

          return;
        }

        closeClick();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        test-data-id={title}
        id="alert-dialog-title"
        onClose={() => closeClick()}
      >
        {showModalTitle && (
          <AgilityTypography
            data-test-id="dialog-header-id"
            variant="h4"
            component="h4"
            align={props.titleAlign}
          >
            {title}
          </AgilityTypography>
        )}
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          overflow: 'auto',
        }}
        align={props.textAlign}
      >
        <Scrollbars
          autoHeight
          autoHeightMin={30}
          autoHeightMax="100%"
          style={{ width: '100%' }}
          className="dialog-scroll"
          renderView={props => <div {...props} className="page-scroll-inner" />}
        >
          <div className="dialog-scroll-content">{children}</div>
        </Scrollbars>
      </DialogContent>
      {showOkButton && (
        <DialogActions id="dialog-action-id">
          <AgilityButton
            data-test-id="dialog-button-ok"
            onClick={() => okClick()}
            variant="contained"
            color="primary"
            label={okText}
            loading={okLoading}
          />
        </DialogActions>
      )}
      {showDeclineButton && (
        <DialogActions id="dialog-action-id">
          <AgilityButton
            data-test-id="dialog-button-ok"
            onClick={() => noClick()}
            variant="outlined"
            color="primary"
            label={declineText}
          />
        </DialogActions>
      )}
    </Dialog>
  );
};

AgilityInfoDialog.propTypes = {
  open: bool,
  title: oneOfType([string, element]),
  className: string,
  showOkButton: bool,
  okText: string,
  okClick: func,
  okLoading: bool,
  closeOnOutsideClick: bool,
  closeClick: func,
  showModalTitle: bool,
  showDeclineButton: bool,
  declineText: string,
  noClick: func,
};
AgilityInfoDialog.defaultProps = {
  open: false,
  title: '',
  okText: '',
  closeOnOutsideClick: true,
  showOkButton: true,
  showModalTitle: true,
  fullWidth: false,
  maxWidth: 'sm',
  className: '',
  okLoading: false,
  showDeclineButton: false,
  declineText: '',
};

export default AgilityInfoDialog;
