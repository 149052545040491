import { SIGNUP_STEPS as signupSteps } from 'App/utils/constants';
const initialState = {
  currentPage: 0,
  completedStep: 0,
  currentStep: {
    key: 0,
    id: signupSteps.selectedPlan,
    parent: 0,
    visible: true,
  },
  popupState: false,
  popupContent: {},
  triggerHideLeavePopup: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_CURRENT_STEP':
      return { ...state, currentPage: initialState.currentPage };
    case 'SET_COMPLETED_STEP':
      return { ...state, completedStep: action.payload };
    case 'SWITCH_PAGE':
      const returnObj = {
        ...state,
        currentPage: action.payload.page,
        currentStep: action.payload.currentStep,
      };
      if (action.payload.completedStep || action.payload.completedStep === 0) {
        returnObj.completedStep = action.payload.completedStep;
      }
      return returnObj;
    case 'TOGGLE_POPUP':
      return {
        ...state,
        popupState: action.payload.status,
        popupContent: action.payload.content,
      };
    case 'TRIGGER_HIDE_LEAVE_POPUP':
      return { ...state, triggerHideLeavePopup: action.payload };
    default:
      return state;
  }
};
export default reducer;
