import i18n from 'i18next';
import {
  SEARCH_ADDRESS_API,
  GET_ADDRESS_DETAILS_API,
} from 'App/utils/constants';
import getListApiUrl from 'App/utils/helper';

export const searchAddress = data => dispatch => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(SEARCH_ADDRESS_API, data);
    window.Api.get(url)
      .then(result => {
        return resolve(result);
      })
      .catch(error => {
        return reject({ message: i18n.t('500.error.message') });
      });
  });
};

export const getAddressDetails = data => dispatch => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(GET_ADDRESS_DETAILS_API, data);
    window.Api.get(url)
      .then(result => {
        return resolve(result);
      })
      .catch(error => {
        return reject({ message: i18n.t('500.error.message') });
      });
  });
};
