import { connect } from 'react-redux';
import LifeSupportConcession from './LifeSupportConcession';

import { fetchCardList } from 'App/modules/onBoarding/action';

const mapStateToProps = (state, props) => {
  return {
    customConfig: state.app.customConfig,
    cardList: state.onBoarding.cardList,
    signupAPIResponse: state.comparePlan.signupAPIResponse,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchCardList: () => dispatch(fetchCardList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LifeSupportConcession);
