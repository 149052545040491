import React from 'react';
import { AgilityTextField, AgilityAutocomplete } from 'Src/AgilityComponents';
import { Element } from 'react-scroll';
import { useTheme } from '@material-ui/core/styles';

const AbnNumberLookup = props => {
  const theme = useTheme();
  const { abnNumber, abnNumberOptions } = props;

  // val is the string value of the selected name
  // Note - this is the Autocomplete's change event, not the text field
  const onAbnNumberChange = (event, val, reason) => {
    props.onSelectedChange(val);
  };

  return (
    <React.Fragment>
      <Element name="abnNumber">
        <AgilityAutocomplete
          options={abnNumberOptions}
          filterOptions={x => x}
          autoComplete
          includeInputInList
          freeSolo
          onChange={onAbnNumberChange}
          getOptionLabel={option =>
            option && option.Abn ? option.Abn : abnNumber
          }
          renderOption={option => option}
          value={{ Name: abnNumber }}
          renderInput={params => (
            <AgilityTextField
              {...params}
              className={
                !props.error && props.touched && !props.loading ? 'success' : ''
              }
              css={theme.custom.CompanyDetails.SuccessField}
              id="abnNumber"
              data-test-id="abnNumber"
              type="text"
              placeholder={props.placeholder}
              variant="outlined"
              required={props.required}
              onChange={val => {
                props.onChange(val);
              }}
              value={abnNumber}
              error={props.error || props.error ? true : false}
              helperText={props.helperText ? props.helperText : ''}
              InputProps={{
                ...params.InputProps,
                endAdornment: props.endIcon,
              }}
            />
          )}
        />
      </Element>
    </React.Fragment>
  );
};

export default AbnNumberLookup;
