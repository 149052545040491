import React from 'react';
import Typography from '@material-ui/core/Typography';
import AgilityTooltip from '../Tooltip/AgilityTooltip';
import { bool, string, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AgilityTypography = ({ ...props }) => {
  const {
    showInfo,
    tooltipText,
    isTooltip,
    onInfoDialogClick,
    containerClass,
    ...other
  } = props;
  return (
    <div className={`typography__container ${containerClass}`}>
      {showInfo ? (
        <div className="info-header">
          <Typography {...other} />
          {isTooltip ? (
            <AgilityTooltip {...other} tooltipText={tooltipText} />
          ) : (
            <div className="icon">
              <FontAwesomeIcon
                data-test-id="tooltip-icon-id"
                onClick={onInfoDialogClick}
                icon={['fas', `info-circle`]}
              />
            </div>
          )}
        </div>
      ) : (
        <Typography {...other} />
      )}
    </div>
  );
};

AgilityTypography.propTypes = {
  showInfo: bool,
  tooltipText: string,
  isTooltip: bool,
  onInfoDialogClick: func,
  marginClass: string,
  paddingClass: string,
};
AgilityTypography.defaultProps = {
  showInfo: false,
  tooltipText: '',
  isTooltip: true,
  containerClass: '',
};

export default AgilityTypography;
