/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { func } from 'prop-types';
import {
  AgilityCard,
  AgilityTypography,
  AgilityGrid,
  AgilityButton,
  AgilityTextField,
} from 'Src/AgilityComponents';
import { agentLoginHasPassword } from 'App/customConfig';
import { withSnackbar } from 'notistack';
import cookie from 'js-cookie';
import './agent-login.scss';
import { useTheme } from '@material-ui/core/styles';
import { logSaleAction } from 'App/utils/geolocation';
import dompurify from 'isomorphic-dompurify';
import { merge } from 'lodash';

const sanitizer = dompurify.sanitize;

const AgentLogin = props => {
  const [fields, setFields] = useState({
    api_code: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    api_code: '',
    password: '',
  });
  const [touched, setTouched] = useState({
    api_code: false,
    password: false,
  });

  const theme = useTheme();

  /**
   * this method used for submit the form
   * @param {*} e
   */
  const submitForm = e => {
    e.preventDefault();
    for (const key in touched) {
      touched[key] = true;
    }
    setTouched(touched);
    const isFormValid = validateForm();
    if (isFormValid) {
      let data = fields;
      if (!agentLoginHasPassword) {
        data.password = undefined;
      }
      props
        .validateAgentLogin(data)
        .then(() => {
          logSaleAction(data, 'AGENT_LOG_IN_SUCCESSFUL');
        })
        .catch(error => {
          props.enqueueSnackbar(i18n.t('agentlogin.failure.message'), {
            variant: 'error',
          });
        });
    }
  };

  /**
   * This method updates the state of every field on value change
   * @param {*} e is the events i.e fire on value change
   */
  const handleChange = (val, name) => {
    fields[name] = val;
    touched[name] = true;
    setFields(fields);
    setTouched(touched);
    validateForm();
  };

  // this method validate all the form fields
  const validateForm = () => {
    const errorMessages = {};
    let formIsValid = true;

    // if password field is touched than check for error
    if (touched['password']) {
      if (!fields['password']) {
        formIsValid = false;
        errorMessages['password'] = i18n.t(
          'agentlogin.errorMessage.requiredPassword'
        );
      } else if (typeof fields['password'] === 'undefined') {
        formIsValid = false;
        errorMessages['password'] = i18n.t(
          'agentlogin.errorMessage.validPassword'
        );
      }
    }
    if (!agentLoginHasPassword) {
      formIsValid = true;
    }

    // if agentId field is touched than check for error
    if (touched['api_code']) {
      if (!fields['api_code']) {
        formIsValid = false;
        errorMessages['api_code'] = i18n.t(
          'agentlogin.errorMessage.requiredAgentId'
        );
      } else if (typeof fields['api_code'] === 'undefined') {
        formIsValid = false;
        errorMessages['api_code'] = i18n.t(
          'agentlogin.errorMessage.validAgentId'
        );
      }
    }

    setErrors(errorMessages);
    return formIsValid;
  };

  const homeUrl =
    process.env.REACT_APP_PARTNER_BRAND_CODE === 'DIRECT' ? `/direct/` : `/`;

  const { isAgentLoggedIn } = props;
  const api_code = cookie.get('agentEnteredCode');

  useEffect(() => {
    if (api_code && !isAgentLoggedIn) {
      props.validateAgentLogin({ api_code }).then(() => {
        logSaleAction({ api_code }, 'AGENT_LOG_IN_SUCCESSFUL');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAgentLoggedIn, api_code]);

  return (
    <React.Fragment>
      <div
        css={merge(theme.custom.CenteredPageCss, {
          backgroundImage: 'url(' + theme.images.agentLoginMobile + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '@media screen and (min-width: 600px)': {
            backgroundImage: 'url(' + theme.images.agentLoginDesktop + ')',
          },
        })}
      >
        <Container maxWidth={'sm'}>
          <AgilityCard className="agent-login mt-2 mb-2">
            <Container>
              {!isAgentLoggedIn && (
                <AgilityGrid
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <AgilityGrid item lg={12} md={12} sm={12} xs={12}>
                    <AgilityTypography
                      variant="h3"
                      align="center"
                      className="mb-2"
                    >
                      {i18n.t('agentlogin.header.message')}
                    </AgilityTypography>
                  </AgilityGrid>

                  <AgilityGrid item lg={12} md={12} sm={12} xs={12}>
                    <form
                      onSubmit={submitForm}
                      autoComplete="off"
                      noValidate
                      data-test-id="loginForm"
                    >
                      <AgilityTextField
                        id="api_code"
                        data-test-id="api_code"
                        type="text"
                        fullWidth
                        placeholder={i18n.t('agentlogin.placeholder.agentId')}
                        value={fields.api_code || ''}
                        onChange={val => handleChange(val, 'api_code')}
                        variant="outlined"
                        required={true}
                        error={errors && errors.api_code ? true : false}
                        helperText={
                          errors && errors.api_code
                            ? errors.api_code
                            : i18n.t('agentlogin.errorMessage.helperAgentId')
                        }
                      />
                      {agentLoginHasPassword && (
                        <AgilityTextField
                          id="password"
                          data-test-id="password"
                          type="password"
                          fullWidth
                          placeholder={i18n.t(
                            'agentlogin.placeholder.password'
                          )}
                          value={
                            fields && fields.password ? fields.password : ''
                          }
                          onChange={val => handleChange(val, 'password')}
                          variant="outlined"
                          required={true}
                          error={errors && errors.password ? true : false}
                          helperText={
                            errors && errors.password ? errors.password : ''
                          }
                        />
                      )}
                      <AgilityButton
                        variant="contained"
                        color="primary"
                        type="primary"
                        className="mb-2"
                        fullWidth
                        label={i18n.t('agentlogin.button.agentlogin')}
                        data-test-id="submitButton"
                        onClick={e => submitForm(e)}
                      />
                    </form>
                  </AgilityGrid>
                </AgilityGrid>
              )}
              {isAgentLoggedIn && (
                <AgilityGrid
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <AgilityGrid item lg={12} md={12} sm={12} xs={12}>
                    <AgilityTypography variant="h3" align="center">
                      {i18n.t('agentlogin.success.header', {
                        name: props.agent.firstName,
                      })}
                    </AgilityTypography>
                  </AgilityGrid>

                  <AgilityGrid item lg={12} md={12} sm={12} xs={12}>
                    <AgilityTypography
                      variant="body1"
                      className="mt-2"
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(i18n.t('agentlogin.success.message')),
                      }}
                    />
                    <Link href={homeUrl}>
                      <AgilityButton
                        className="mt-2"
                        variant="contained"
                        color="primary"
                        type="primary"
                        fullWidth
                        label={i18n.t('agentlogin.success.cta')}
                        data-test-id="sellButton"
                      />
                    </Link>
                  </AgilityGrid>
                </AgilityGrid>
              )}
            </Container>
          </AgilityCard>
        </Container>
      </div>
    </React.Fragment>
  );
};

AgentLogin.propTypes = {
  validateAgentLogin: func,
};
AgentLogin.defaultProps = {};

export default withSnackbar(AgentLogin);
