/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import './footer.scss';
import AgilityLogo from '../Logo';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { AgilityTypography } from 'Src/AgilityComponents';
import AgilitySocialFollow from './modules/SocialFollow';
import AgilityMenu from '../Menu';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { useTheme } from '@material-ui/core/styles';

const FooterMenu = [
  ...(process.env.REACT_APP_PRIVACY_POLICY && [
    {
      route: process.env.REACT_APP_PRIVACY_POLICY,
      name: 'Privacy',
      isExternal: true,
    },
  ]),
  ...(process.env.REACT_APP_TERMS_USE && [
    {
      route: process.env.REACT_APP_TERMS_USE,
      name: 'Terms of Use',
      isExternal: true,
    },
  ]),
  ...(process.env.REACT_APP_TERMS_CONDITION && [
    {
      route: process.env.REACT_APP_TERMS_CONDITION,
      name: 'Terms and Conditions',
      isExternal: true,
    },
  ]),
  ...(process.env.REACT_APP_SUPPORT_URL && [
    {
      route: process.env.REACT_APP_SUPPORT_URL,
      name: 'Help and Support',
      isExternal: true,
    },
  ]),
];

const AgilityFooter = props => {
  const theme = useTheme();
  const getCopyRight = () => {
    return `© ${new Date().getFullYear()} ${
      process.env.REACT_APP_COPYRIGHT_COMPANY || ''
    }`;
  };
  return (
    <footer
      className="footer"
      id="footer"
      data-test-id="footer-id"
      css={css`
        background: ${theme.palette.common.white};
        box-shadow: inset 0 1px 0px ${theme.palette.grey['100']};
      `}
    >
      <Container>
        <Grid container className="items-center">
          <Grid item xs={12} sm={12} md={6}>
            <AgilityLogo
              logoImage={theme.custom.Footer.logoImage}
              logoHeight={theme.custom.Footer.logoHeight}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="container-margin">
              <AgilitySocialFollow data-test-id="social-link-id" />
            </div>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Divider light />
        </Hidden>
        <Grid container>
          <Grid item xs={12} sm={12} md={8}>
            <div className="container-margin">
              <AgilityMenu
                menuList={FooterMenu}
                className="footer-link"
                data-test-id="footer-link-id"
                css={css`
                  a {
                    color: ${theme.palette.grey['500']};
                    &:hover {
                      color: ${theme.palette.primary.main};
                    }
                  }
                `}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <AgilityTypography
              component="p"
              className="copyright"
              css={css`
                color: ${theme.palette.grey['500']};
              `}
            >
              {getCopyRight()}
            </AgilityTypography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default AgilityFooter;
