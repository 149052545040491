import i18n from 'i18next';

export const getShortLinkRedirectUrl = data => dispatch => {
  return new Promise((resolve, reject) => {
    window.Api.get(`v2${data.pathname}`)
      .then(result => {
        if (result?.url === null) {
          window.location.href = '/'; // back home
        }
        const url = new URL(result.url);
        window.location.href = url.pathname + url.search;

        return resolve(result);
      })
      .catch(error => {
        window.location.href = '/'; // back home

        return reject({
          message: i18n.t('500.error.message'),
          status: error.status,
        });
      });
  });
};
