import { PRODUCT, PROPERTY } from 'App/utils/constants';

export const productTypes = [
  {
    code: PRODUCT.BOTH,
    color: null,
    display: 'Electricity & Gas',
    displayAlternative: '',
    isDefault: process.env.REACT_APP_GAS_SUPPLY,
    icon: 'bolt',
    value: 0,
  },
  {
    code: PRODUCT.ELECTRICITY,
    color: null,
    display: 'Electricity',
    displayAlternative: 'NMI',
    isDefault: !process.env.REACT_APP_GAS_SUPPLY,
    icon: 'bolt',
    value: 1,
  },
  {
    code: PRODUCT.GAS,
    color: null,
    display: 'Gas',
    displayAlternative: 'MIRN', // @TODO make theme-dynamic
    isDefault: false,
    icon: 'fire',
    value: 2,
  },
];

export const propertyTypes = [
  {
    code: PROPERTY.RESIDENT,
    color: null,
    display: 'Residential',
    isDefault: true,
    value: 1,
  },
  {
    code: PROPERTY.COMPANY,
    color: null,
    display: 'Business',
    isDefault: false,
    value: 2,
  },
];
