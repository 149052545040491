import React from 'react';
import './error-page.scss';
import { AgilityButton, AgilityTypography } from 'Src/AgilityComponents';
import { Helmet } from 'react-helmet';

const ErrorPage = props => {
  return (
    <div className="page-container error-page">
      <Helmet>
        <title>{`Error | ${process.env.REACT_APP_RETAILER_NAME}`}</title>
      </Helmet>
      <AgilityTypography variant="h2" component="h2" align="center">
        Oops! Something went wrong
      </AgilityTypography>

      <AgilityTypography variant="h6" component="h6" align="center">
        This issue has been reported. Please call us on (
        {process.env.REACT_APP_COMPANY_PHONE})
      </AgilityTypography>

      <a href="/" className="mt-3">
        <AgilityButton
          variant="outlined"
          color="primary"
          size="large"
          label=" Back to Home"
        />
      </a>
    </div>
  );
};

export default ErrorPage;
