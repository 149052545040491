import { connect } from 'react-redux';
import {
  setPlanFilters,
  fetchProductList,
  fetchPropertyList,
  searchEmbeddedNetwork,
  getSignupDetails,
} from 'App/pages/ComparePlans/action';
import PlanFilter from './PlanFilter';

const mapStateToProps = (state, props) => {
  return {
    isCovau: state.app.brand.isCovau,
    isFirstEnergy: state.app.brand.isFirstEnergy,
    isGEE: state.app.brand.isGEE,
    isSEG: state.app.brand.isSEG,
    isNBE: state.app.brand.isNBE,
    isDemo: state.app.brand.isDemo,
    isBSolar: state.app.brand.partner.isBSolar,
    planFilterData: state.comparePlan.planFilter,
    productList: state.comparePlan.productList,
    propertyList: state.comparePlan.propertyList,
    siteIdentifierList: state.comparePlan.siteIdentifierList,
    signupAPIResponse: state.comparePlan.signupAPIResponse,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setPlanFilters: data => dispatch(setPlanFilters(data)),
    fetchProductList: () => dispatch(fetchProductList()),
    fetchPropertyList: () => dispatch(fetchPropertyList()),
    searchEmbeddedNetwork,
    getSignupDetails: (hash, accntId) =>
      dispatch(getSignupDetails(hash, accntId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanFilter);
