/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { bool, string } from 'prop-types';
import {
  AgilityAutocomplete,
  AgilityGrid,
  AgilityTextField,
  AgilityTypography,
} from 'Src/AgilityComponents';
import { InputAdornment } from '@material-ui/core';
import AgilitySiteIdentifierDialog from '../../Dialog/AgilitySiteIdentifierDialog';
import debounce from 'lodash/debounce';
import i18n from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { identifierDialogContent } from 'App/utils/data';
import { useTheme } from '@material-ui/core/styles';
import '../location.scss';

const SearchLocation = ({ isPreload, searchValue, showTitle, ...props }) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = React.useState(searchValue);
  const [options, setOptions] = React.useState([]);
  const [showSearchInfo, setShowSearchInfo] = React.useState(false);

  // On preload true
  React.useEffect(() => {
    if (isPreload) {
      setInputValue(searchValue);
    }
  }, [searchValue, isPreload]);

  const handleChange = value => {
    props.onLocationSelected({}, value);
    setInputValue(value);
  };

  const fetchSuggestions = React.useMemo(
    () =>
      debounce((value, callback) => {
        props.searchAddress(value).then(response => callback(response));
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetchSuggestions({ searchTerm: inputValue }, results => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetchSuggestions]);

  const getPlaceDetails = listItem => {
    props
      .getAddressDetails({
        id: listItem.id,
      })
      .then(response => {
        const addressDetails = response;

        // Strip hyphenated street numbers, to just the first number.
        if (addressDetails.StreetNumber.search(/\-/) !== -1) {
          addressDetails.StreetNumber =
            addressDetails.StreetNumber.match(/\d+/g)[0];
        }

        addressDetails.unstructuredAddress = listItem.address;
        addressDetails.UnitNumber = addressDetails.ApartmentNumber;
        addressDetails.HouseNumber1 = addressDetails.StreetNumber;

        // Remove null values from addressDetails
        Object.keys(addressDetails).forEach(
          key => addressDetails[key] == null && delete addressDetails[key]
        );

        setInputValue(listItem.address);
        props.onLocationSelected(addressDetails, listItem.address);
      });
  };

  const onLocationChange = (event, listItem) => {
    if (listItem?.id) {
      getPlaceDetails(listItem);
    }
  };
  const onClose = (event, value) => {
    if (value === '') {
      props.onLocationSelected({}, '');
    }
  };
  const setInfoDialogStatus = (status = false) => {
    setShowSearchInfo(status);
  };
  return (
    <React.Fragment>
      {showTitle && (
        <AgilityTypography
          className="searchLocation-label-wrapper"
          variant="subtitle2"
          component="label"
          align="left"
          css={theme.custom.PlanFilter.searchField.helperTextCss}
        >
          {i18n.t('compareplan.address.header')}
        </AgilityTypography>
      )}
      <AgilityAutocomplete
        className="large"
        onInputChange={onClose}
        onChange={onLocationChange}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.address
        }
        filterOptions={x => x}
        options={options}
        autoComplete
        includeInputInList
        debug
        freeSolo
        classes={{
          root: 'search-input',
        }}
        value={inputValue}
        renderInput={params => (
          <AgilityTextField
            {...params}
            variant="outlined"
            fullWidth
            onChange={val => handleChange(val.trim())}
            value={inputValue}
            InputProps={{
              ...(params.InputProps || {}),
              placeholder: i18n.t('compareplan.address.placeholder'),
              startAdornment: (
                <InputAdornment position="start" css={{ paddingLeft: 8 }}>
                  <FontAwesomeIcon
                    icon={['fas', 'search']}
                    css={theme.custom.PlanFilter.searchField.fieldIconCss}
                  />
                </InputAdornment>
              ),
            }}
            css={{
              '.MuiInputBase-root':
                theme.custom.PlanFilter.searchField.fieldCss,
            }}
          />
        )}
        renderOption={option => {
          return (
            <AgilityGrid
              container
              alignItems="center"
              className="search-result"
              css={css`
                .MuiTypography-root {
                  &.MuiTypography-body2 {
                    color: ${theme.palette.grey['700']};
                  }
                  &.MuiTypography-subtitle1 {
                    color: ${theme.palette.grey['500']};
                  }
                }
              `}
            >
              <AgilityGrid item xs>
                <AgilityTypography variant="body1" component="p">
                  {option.address}
                </AgilityTypography>
              </AgilityGrid>
            </AgilityGrid>
          );
        }}
      />

      <AgilitySiteIdentifierDialog
        open={showSearchInfo}
        onDismiss={setInfoDialogStatus}
        data={identifierDialogContent}
      />
    </React.Fragment>
  );
};
SearchLocation.propTypes = {
  searchValue: string,
  showTitle: bool,
};
SearchLocation.defaultProps = {
  searchValue: '',
  showTitle: true,
};
export default SearchLocation;
