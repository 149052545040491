import React, { lazy } from 'react';

const SegThemeProvider = lazy(() => import('./Seg/SegThemeProvider'));
const DemoThemeProvider = lazy(() => import('./Demo/DemoThemeProvider'));
const CovauThemeProvider = lazy(() => import('./Covau/CovauThemeProvider'));
const GeeThemeProvider = lazy(() => import('./Gee/GeeThemeProvider'));
const FirstThemeProvider = lazy(() => import('./First/FirstThemeProvider'));
const NbeThemeProvider = lazy(() => import('./Nbe/NbeThemeProvider'));
const AccreditedPowerThemeProvider = lazy(() =>
  import('./TangoAccredited/AccreditedPowerThemeProvider')
);
const MyConnectThemeProvider = lazy(() =>
  import('./TangoMyconnect/MyConnectThemeProvider')
);
const EmeraldThemeProvider = lazy(() =>
  import('./TangoEmerald/EmeraldThemeProvider')
);
const UnitedSolarThemeProvider = lazy(() =>
  import('./TangoUnitedsolar/UnitedSolarThemeProvider')
);
const BsolarThemeProvider = lazy(() =>
  import('./TangoBsolar/BsolarThemeProvider')
);
const TangoThemeProvider = lazy(() =>
  import('./TangoDirect/TangoThemeProvider')
);
const TangoStaffThemeProvider = lazy(() =>
  import('./TangoStaff/TangoStaffThemeProvider')
);
const SolarrunThemeProvider = lazy(() =>
  import('./Solarrun/SolarrunThemeProvider')
);
const TangoGreatSouthWestThemeProvider = lazy(() =>
  import('./TangoGreatsouthwest/TangoGreatSouthWestThemeProvider')
);
const TangoAddGasProvider = lazy(() =>
  import('./TangoAddgas/TangoAddGasProvider')
);
const TangoAddElecProvider = lazy(() =>
  import('./TangoAddelec/TangoAddElecProvider')
);
const TangoSpecialOffersProvider = lazy(() =>
  import('./TangoSpecialoffers/TangoSpecialOffersProvider')
);
const TangoOffersProvider = lazy(() =>
  import('./TangoOffers/TangoOffersProvider')
);
const TangoCustomeroffersProvider = lazy(() =>
  import('./TangoCustomeroffers/TangoCustomeroffersProvider')
);
const TangoBusinessProvider = lazy(() =>
  import('./TangoBusiness/TangoBusinessProvider')
);
const TangoBusinessOffersProvider = lazy(() =>
  import('./TangoBusinessoffers/TangoBusinessoffersProvider')
);
const PacificBlueStaffThemeProvider = lazy(() =>
  import('./PacificblueStaff/PacificBlueStaffThemeProvider')
);
const PacificBlueBusinessThemeProvider = lazy(() =>
  import('./PacificblueBusiness/PacificBlueBusinessThemeProvider')
);

const BrandThemeProvider = props => {
  const brand = process.env.REACT_APP_BRAND_CODE;
  const partner = process.env.REACT_APP_PARTNER_BRAND_CODE;

  const getBrandThemeProvider = (props, brand, partner) => {
    switch (brand) {
      case 'SEG':
        return <SegThemeProvider>{props.children}</SegThemeProvider>;
      case 'NBE':
        return <NbeThemeProvider>{props.children}</NbeThemeProvider>;
      case 'COVAU':
        return <CovauThemeProvider>{props.children}</CovauThemeProvider>;
      case 'FIRST':
        return <FirstThemeProvider>{props.children}</FirstThemeProvider>;
      case 'GEE':
        return <GeeThemeProvider>{props.children}</GeeThemeProvider>;
      case 'SOLARRUN':
        return <SolarrunThemeProvider>{props.children}</SolarrunThemeProvider>;
      case 'TANGO':
        return getTangoPartnerThemeProvider(props, partner);
      case 'PACIFICBLUE':
        return getPacificBluePartnerThemeProvider(props, partner);
      default:
        return <DemoThemeProvider>{props.children}</DemoThemeProvider>;
    }
  };

  // pacblue partner
  const getPacificBluePartnerThemeProvider = (props, partner) => {
    switch (partner) {
      case 'BUSINESS':
        return (
          <PacificBlueBusinessThemeProvider>
            {props.children}
          </PacificBlueBusinessThemeProvider>
        );
      case 'STAFF':
      default:
        return (
          <PacificBlueStaffThemeProvider>
            {props.children}
          </PacificBlueStaffThemeProvider>
        );
    }
  };

  // tango partner
  const getTangoPartnerThemeProvider = (props, partner) => {
    switch (partner) {
      case 'ACCREDITED':
        return (
          <AccreditedPowerThemeProvider>
            {props.children}
          </AccreditedPowerThemeProvider>
        );
      case 'EMERALD':
        return <EmeraldThemeProvider>{props.children}</EmeraldThemeProvider>;
      case 'MYCONNECT':
        return (
          <MyConnectThemeProvider>{props.children}</MyConnectThemeProvider>
        );
      case 'UNITEDSOLAR':
        return (
          <UnitedSolarThemeProvider>{props.children}</UnitedSolarThemeProvider>
        );
      case 'BSOLAR':
        return <BsolarThemeProvider>{props.children}</BsolarThemeProvider>;
      case 'DIRECT':
        return <TangoThemeProvider>{props.children}</TangoThemeProvider>;
      case 'ADDGAS':
        return <TangoAddGasProvider>{props.children}</TangoAddGasProvider>;
      case 'ADDELEC':
        return <TangoAddElecProvider>{props.children}</TangoAddElecProvider>;
      case 'OFFERS':
        return <TangoOffersProvider>{props.children}</TangoOffersProvider>;
      case 'BUSINESS':
        return <TangoBusinessProvider>{props.children}</TangoBusinessProvider>;
      case 'BUSINESSOFFERS':
        return (
          <TangoBusinessOffersProvider>
            {props.children}
          </TangoBusinessOffersProvider>
        );
      case 'SPECIALOFFERS':
        return (
          <TangoSpecialOffersProvider>
            {props.children}
          </TangoSpecialOffersProvider>
        );
      case 'CUSTOMEROFFERS':
        return (
          <TangoCustomeroffersProvider>
            {props.children}
          </TangoCustomeroffersProvider>
        );
      case 'GREATSOUTHWEST':
        return (
          <TangoGreatSouthWestThemeProvider>
            {props.children}
          </TangoGreatSouthWestThemeProvider>
        );
      case 'STAFF':
        return (
          <TangoStaffThemeProvider>{props.children}</TangoStaffThemeProvider>
        );
      default:
        return <TangoThemeProvider>{props.children}</TangoThemeProvider>;
    }
  };

  return getBrandThemeProvider(props, brand, partner);
};

export default BrandThemeProvider;
