/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { bool } from 'prop-types';
import {
  AgilityAutocomplete,
  AgilityGrid,
  AgilityTextField,
  AgilityTypography,
} from 'Src/AgilityComponents';
import { InputAdornment } from '@material-ui/core';
import AgilitySiteIdentifierDialog from '../Dialog/AgilitySiteIdentifierDialog';
import debounce from 'lodash/debounce';
import { GOOGLE_LOCATION_API_URL } from 'App/utils/constants';
import i18n from 'i18next';
import { string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { identifierDialogContent } from 'App/utils/data';
import { useTheme } from '@material-ui/core/styles';
import {
  STREET_TYPE,
  STREET_SUFFIX,
  STREET_TYPE_MAPPINGS,
} from 'App/utils/constants';
import './location.scss';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

const SearchLocation = ({ isPreload, searchValue, showTitle, ...props }) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = React.useState(searchValue);
  const [options, setOptions] = React.useState([]);
  const [showSearchInfo, setShowSearchInfo] = React.useState(false);
  const loaded = React.useRef(false);

  // On preload true
  React.useEffect(() => {
    if (isPreload) {
      setInputValue(searchValue);
    }
  }, [searchValue, isPreload]);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        GOOGLE_LOCATION_API_URL,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const handleChange = value => {
    props.onLocationSelected({}, value);
    setInputValue(value);
  };

  const fetch = React.useMemo(
    () =>
      debounce((value, callback) => {
        var request = {
          input: value.input,
          types: ['geocode'],
          componentRestrictions: {
            country: process.env.REACT_APP_COUNTRY_CODE,
          },
        };
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const getPlaceDetails = value => {
    var request = {
      placeId: value.place_id,
      fields: ['address_component', 'name', 'geometry'],
    };
    const addressDetails = {};
    addressDetails.unstructuredAddress = value.description;
    placesService.current.getDetails(request, function (place, status) {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const addressComponents = place.address_components;
        if (addressComponents !== null) {
          for (let i = 0; i < addressComponents.length; i++) {
            const componentType = addressComponents[i].types[0];
            const componentValue = addressComponents[i].short_name;
            const componentValueLong = addressComponents[i].long_name;
            switch (componentType) {
              case 'subpremise':
                addressDetails.ApartmentNumber = componentValue;
                break;
              case 'street_number':
                const splitAddress = componentValue.split(/[/-]+/);
                const streetNumber =
                  splitAddress.length === 2 ? splitAddress[1] : splitAddress[0];
                if (splitAddress.length === 2) {
                  addressDetails.ApartmentNumber = splitAddress[0];
                }
                if (streetNumber.search(/\D/) !== -1) {
                  addressDetails.StreetNumber = streetNumber.match(/\d+/g)[0];
                } else {
                  addressDetails.StreetNumber = streetNumber;
                }
                break;
              case 'route':
                const _address = componentValue.split(' ');
                if (_address.length > 1) {
                  let lastPart = _address.pop();
                  if (STREET_SUFFIX.includes(lastPart.toUpperCase())) {
                    addressDetails.StreetSuffix = lastPart;

                    lastPart = _address.pop();
                  }

                  if (STREET_TYPE.includes(lastPart.toUpperCase())) {
                    // Sometimes google places has a different abbreviation, so lets check if we have one
                    let addrMappingIndex = STREET_TYPE_MAPPINGS.findIndex(
                      addrMap => {
                        return addrMap.places === lastPart.toUpperCase();
                      }
                    );

                    // If we do; let's use that instead of what came for google places
                    if (addrMappingIndex !== -1) {
                      addressDetails.StreetType =
                        STREET_TYPE_MAPPINGS[addrMappingIndex].msats;
                    } else {
                      addressDetails.StreetType = lastPart;
                    }
                  } else {
                    _address.push(lastPart);
                  }

                  // Check if the street address has a directional prefix
                  // e.g. 499 E Maurice, and use the full version for the
                  // MSATS lookup, as that's what AEMO expects.
                  const directionalPrefixes = ['N', 'E', 'S', 'W'];
                  if (
                    directionalPrefixes.includes(_address[0]) &&
                    _address.length > 1
                  ) {
                    // Long Value has the full North/East/South/West
                    _address[0] = componentValueLong.split(' ')[0];
                  }

                  addressDetails.StreetName = _address.join(' ');
                } else {
                  addressDetails.StreetName = componentValue;
                }
                break;
              case 'locality':
                addressDetails.Suburb = componentValue;
                break;
              case 'administrative_area_level_1':
                addressDetails.State = componentValue;
                break;
              case 'postal_code':
                addressDetails.PostCode = componentValue;
                break;
              default:
                break;
            }
          }
          // It's better to set unit number here in case it didn't come through from street number
          if (typeof addressDetails.ApartmentNumber === 'undefined') {
            const unitIndex = value.description.search(/[/-]/);
            if (unitIndex !== -1) {
              addressDetails.ApartmentNumber =
                value.description.split(/[/-]+/)[0];
            }
          }
          addressDetails.UnitNumber = addressDetails.ApartmentNumber;
          addressDetails.HouseNumber1 = addressDetails.StreetNumber;
          addressDetails.Latitude = place.geometry.location.lat();
          addressDetails.Longitude = place.geometry.location.lng();
        }
        //save to store
        setInputValue(value.description);
        props.onLocationSelected(addressDetails, value.description);
      }
    });
  };

  const onLocationChange = (event, value) => {
    if (value && value.description) {
      getPlaceDetails(value);
    }
  };
  const onClose = (event, value) => {
    if (value === '') {
      props.onLocationSelected({}, '');
    }
  };
  const setInfoDialogStatus = (status = false) => {
    setShowSearchInfo(status);
  };
  return (
    <React.Fragment>
      {showTitle && (
        <AgilityTypography
          className="searchLocation-label-wrapper"
          variant="subtitle2"
          component="label"
          align="left"
          css={theme.custom.PlanFilter.searchField.helperTextCss}
        >
          {i18n.t('compareplan.address.header')}
        </AgilityTypography>
      )}
      <AgilityAutocomplete
        className="large"
        onInputChange={onClose}
        onChange={onLocationChange}
        data-test-id="autocomplete-google"
        id="google-map-demo"
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={x => x}
        options={options}
        autoComplete
        includeInputInList
        freeSolo
        classes={{
          root: 'search-input',
        }}
        value={inputValue}
        renderInput={params => (
          <AgilityTextField
            {...params}
            variant="outlined"
            fullWidth
            // InputLabelProps={{ shrink: true }}
            onChange={val => handleChange(val.trim())}
            value={inputValue}
            InputProps={{
              ...(params.InputProps || {}),
              placeholder: i18n.t('compareplan.address.placeholder'),
              startAdornment: (
                <InputAdornment position="start" css={{ paddingLeft: 8 }}>
                  <FontAwesomeIcon
                    icon={['fas', 'search']}
                    css={theme.custom.PlanFilter.searchField.fieldIconCss}
                  />
                </InputAdornment>
              ),
            }}
            css={{
              '.MuiInputBase-root':
                theme.custom.PlanFilter.searchField.fieldCss,
            }}
          />
        )}
        renderOption={option => {
          const parts = option.description.split(',');

          return (
            <AgilityGrid
              container
              alignItems="center"
              className="search-result"
              css={css`
                .MuiTypography-root {
                  &.MuiTypography-body2 {
                    color: ${theme.palette.grey['700']};
                  }
                  &.MuiTypography-subtitle1 {
                    color: ${theme.palette.grey['500']};
                  }
                }
              `}
            >
              <AgilityGrid item>
                <FontAwesomeIcon
                  icon={['fas', `map-marker-alt`]}
                  size="2x"
                  className="icon-marker"
                  css={css`
                    color: ${theme.palette.grey['400']};
                    margin-right: 1rem;
                    font-size: 1.75rem;
                  `}
                />
              </AgilityGrid>
              <AgilityGrid item xs>
                <AgilityTypography variant="body1" component="p">
                  <span style={{ fontWeight: 700 }}>{parts.shift()}</span>
                </AgilityTypography>
                <AgilityTypography variant="subtitle2" component="p">
                  {parts.join(',')}
                </AgilityTypography>
              </AgilityGrid>
            </AgilityGrid>
          );
        }}
      />

      <AgilitySiteIdentifierDialog
        open={showSearchInfo}
        onDismiss={setInfoDialogStatus}
        data={identifierDialogContent}
      />
    </React.Fragment>
  );
};
SearchLocation.propTypes = {
  searchValue: string,
  showTitle: bool,
};
SearchLocation.defaultProps = {
  searchValue: '',
  showTitle: true,
};
export default SearchLocation;
