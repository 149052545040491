import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AgilityButton, AgilityTypography } from 'Src/AgilityComponents';
import { Helmet } from 'react-helmet';
import LoadingWrapper from 'Src/AgilityComponents/Components/APILoader/LoadingWrapper';
const ShortLinkRedirect = props => {
  useEffect(() => {
    if (props.location) {
      props.getShortLinkRedirectUrl(props.location);
    }
  }, [props, props.location]);

  return (
    <div className="page-container page-not-found">
      <Helmet>
        <title>{`Redirecting | ${process.env.REACT_APP_RETAILER_NAME}`}</title>
      </Helmet>
      <AgilityTypography variant="h2" component="h2" align="center">
        Retrieving your details
      </AgilityTypography>
      <LoadingWrapper
        isLoading={true}
        className="blank-page full-page-loader"
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          zIndex: '999999',
          top: '0',
          left: '0',
          padding: '0px 20px',
          display: 'flex',
          alignItems: 'center',
          justify: 'center',
        }}
      />
      <Link to="/" className="mt-3">
        <AgilityButton
          variant="outlined"
          color="primary"
          size="large"
          label="Back to Home"
        />
      </Link>
    </div>
  );
};

export default ShortLinkRedirect;
