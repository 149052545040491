/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { useTheme } from '@material-ui/core';
import { t } from 'i18next';
import {
  AgilityCheckbox,
  AgilityFormControlLabel,
  AgilityFormGroup,
} from 'Src/AgilityComponents';

const DoorToDoorCheckbox = ({ value: d2dApproval, onChange }) => {
  const theme = useTheme();

  const handleOnChange = React.useCallback(() => {
    onChange(!d2dApproval);
  }, [d2dApproval, onChange]);

  const { d2dCheckboxCss } = theme.custom.PlanFilter.filters;

  return (
    <AgilityFormGroup row className="custom-checkbox-row d2d-checkbox-wrapper">
      <AgilityFormControlLabel
        css={d2dCheckboxCss}
        control={
          <AgilityCheckbox
            onClick={handleOnChange}
            name="d2dDisclaimer"
            data-test-id="d2d-disclaimer"
            color="secondary"
          />
        }
        label={t('compareplan.address.d2d.disclaimer')}
      />
    </AgilityFormGroup>
  );
};

export default DoorToDoorCheckbox;
