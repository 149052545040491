import React from 'react';
import i18n from 'i18next';
import { AgilityTextField, AgilityAutocomplete } from 'Src/AgilityComponents';
import { Element } from 'react-scroll';
import { useTheme } from '@material-ui/core/styles';

const BusinessNameLookup = props => {
  const theme = useTheme();
  const { businessName, businessNamesOptions } = props;

  // value is the API response object for this business name
  // Note - this is the Autocomplete's change event, not the text field
  const onBusinessNameChange = (event, value, reason) => {
    props.onChange(value);
  };

  return (
    <React.Fragment>
      <Element name="accountName">
        <AgilityAutocomplete
          options={businessNamesOptions}
          getOptionLabel={option => (option && option.Name ? option.Name : '')}
          renderOption={option =>
            option && option.Abn ? `ABN: ${option.Abn} - ${option.Name}` : ''
          }
          filterOptions={x => x}
          autoComplete
          includeInputInList
          freeSolo
          onChange={onBusinessNameChange}
          value={{ Name: businessName }}
          renderInput={params => (
            <AgilityTextField
              {...params}
              className={
                !props.error && props.touched && !props.loading ? 'success' : ''
              }
              css={theme.custom.CompanyDetails.SuccessField}
              placeholder={i18n.t('companyDetail.placeholder.businessName')}
              variant="outlined"
              required={props.required}
              fullWidth
              onChange={val => {
                props.onChange({ Name: val });
              }}
              value={businessName}
              error={props.error}
              helperText={props.helperText}
              InputProps={{
                ...params.InputProps,
                endAdornment: props.endIcon,
              }}
            />
          )}
        />
      </Element>
    </React.Fragment>
  );
};

export default BusinessNameLookup;
