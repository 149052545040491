// import { css, jsx } from '@emotion/react';
import React from 'react';
import './menu.scss';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { array } from 'prop-types';
// import { useTheme } from '@material-ui/core/styles';

class AgilityMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.menuList[0],
    };
  }
  handleClick = menuItem => {
    this.setState({ active: menuItem });
  };

  render() {
    return (
      <MenuList
        dense={true}
        className={`navbar ${this.props.className}`}
        data-test-id="menu-links"
      >
        {this.props.menuList.map((menuItem, index) => (
          <MenuItem key={index} gutters={20}>
            {menuItem.isExternal ? (
              <a
                data-test-id="menu-links-link"
                className={this.state.active === menuItem ? 'activeItem' : ''}
                href={menuItem.route}
              >
                {menuItem.name}
              </a>
            ) : (
              <Link
                data-test-id="menu-links-link"
                className={this.state.active === menuItem ? 'activeItem' : ''}
                onClick={() => this.handleClick(menuItem)}
                to={menuItem.route}
                title={menuItem.name}
              >
                {menuItem.name}
              </Link>
            )}
          </MenuItem>
        ))}
      </MenuList>
    );
  }
}

AgilityMenu.propTypes = {
  menuList: array,
};

export default AgilityMenu;
