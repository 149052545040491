/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useState } from 'react';

import {
  AgilityCard,
  AgilityTypography,
  AgilityConfirmationDialog,
} from 'Src/AgilityComponents';

import Link from '@material-ui/core/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './paymentCard.scss';
import i18n from 'i18next';
import { bool } from 'prop-types';
import { paymentIcons } from 'App/utils/helper';

const PaymentCard = props => {
  const [openDialog, setOpenDialog] = useState(false);
  const { paymentCardInfo, className } = props;

  const onConfirmation = () => {
    props.onConfirm();
    setOpenDialog(false);
  };
  const cardIcon = paymentCardInfo.cardIcon || 'credit-card';
  return (
    <React.Fragment>
      <AgilityCard className={'payment-card ' + className} elevation={2}>
        <AgilityTypography component="h4" variant="h4" className="mb-2">
          {paymentCardInfo.cardName}
        </AgilityTypography>

        {props.showRemove && (
          <Link
            data-test-id="removeLink"
            color="primary"
            className="detail-link"
            css={{ cursor: 'pointer' }}
            onClick={() => setOpenDialog(true)}
          >
            {i18n.t('paymentCard.button.remove')}
          </Link>
        )}

        <AgilityTypography
          data-test-id="plan-desc-id"
          variant="body2"
          component="p"
          containerClass="desc payment__details"
          noWrap
        >
          {paymentCardInfo.cardholderName}
        </AgilityTypography>

        {paymentCardInfo.bankBsb && (
          <AgilityTypography
            data-test-id="plan-bsb-id"
            variant="body2"
            component="p"
            containerClass="desc payment__details"
          >
            {paymentCardInfo.bankBsb}
          </AgilityTypography>
        )}

        <AgilityTypography
          data-test-id="plan-desc-id"
          variant="body2"
          component="p"
          containerClass="desc payment__details mb-0"
        >
          {paymentCardInfo.cardNumber}
        </AgilityTypography>

        <div className="card-images">
          <div>
            {paymentCardInfo.bankIcon && (
              <FontAwesomeIcon
                icon={['fas', `${paymentCardInfo.bankIcon}`]}
                className={`icon ${paymentCardInfo.bankIcon}`}
              />
            )}
            {paymentCardInfo.cardIcon && (
              <FontAwesomeIcon
                icon={[paymentIcons[cardIcon], `${cardIcon}`]}
                className={`icon ${cardIcon}`}
              />
            )}
          </div>
        </div>
      </AgilityCard>

      <AgilityConfirmationDialog
        data-test-id="confirmationDialog"
        className="confirmation-dialog"
        closeOnOutsideClick={false}
        open={openDialog}
        textAlign="center"
        fullWidth
        maxWidth="xs"
        titleAlign="center"
        title={i18n.t('confirmation.dialog.heading')}
        description={i18n.t('confirmation.dialog.description')}
        okText={i18n.t('confirmation.button.yes')}
        cancelText={i18n.t('confirmation.button.no')}
        okClick={() => onConfirmation()}
        cancelClick={() => setOpenDialog(false)}
        closeClick={() => setOpenDialog(false)}
      />
    </React.Fragment>
  );
};

PaymentCard.propTypes = {
  showRemove: bool,
};
PaymentCard.defaultProps = {
  showRemove: true,
};

export default PaymentCard;
