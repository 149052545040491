import { connect } from 'react-redux';
import SignUpCompletion from './SignUpCompletion';
import { withRouter } from 'react-router-dom';
import { submitSignupDetails, resetOnBoardingUserData } from '../action';
import {
  getSignupDetails,
  resetComparePlanUserData,
} from 'App/pages/ComparePlans/action';

const mapStateToProps = (state, props) => {
  return {
    signupAPIResponse: state.comparePlan.signupAPIResponse,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getSignupDetails: (id, accntId) => dispatch(getSignupDetails(id, accntId)),
    submitSignupDetails: data => dispatch(submitSignupDetails(data)),
    resetOnBoardingUserData: () => dispatch(resetOnBoardingUserData()),
    resetComparePlanUserData: () => dispatch(resetComparePlanUserData()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignUpCompletion)
);
