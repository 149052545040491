/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ThemedFakeSelect = ({
  handleOpenMenu = () => null,
  open = false,
  selectedLabel = '',
  anchorEl = null,
  icon = ['fas', 'alert'],
}) => {
  const theme = useTheme();
  const { menuButtonCss, textCss, iconCss } = theme.custom.PlanFilter.filters;

  const iconMarginTop = React.useMemo(() => {
    return (
      (menuButtonCss.borderWidth > 0 ? menuButtonCss.borderWidth * 2 : 0) + 1
    );
  }, [menuButtonCss.borderWidth]);

  return (
    <Button
      fullWidth
      css={{
        flexDirection: 'row',
        alignItems: 'center',
        ...menuButtonCss,
      }}
      px={3}
      onClick={handleOpenMenu}
    >
      <Grid
        container
        alignItems="center"
        alignContent={'center'}
        css={{
          height: 44 - menuButtonCss.borderWidth * 2,
          flexDirection: 'row',
        }}
      >
        <Grid item css={{ width: 36, textAlign: 'center', paddingRight: 12 }}>
          <FontAwesomeIcon
            css={{
              marginTop: iconMarginTop,
              ...iconCss,
            }}
            icon={icon}
          />
        </Grid>
        <Grid item css={{ flexGrow: 1 }}>
          <Typography css={textCss}>{selectedLabel}</Typography>
        </Grid>
        <Grid
          item
          css={{ width: 24, textAlign: 'center', alignSelf: 'center' }}
        >
          <FontAwesomeIcon
            icon={anchorEl ? ['fas', 'caret-up'] : ['fas', 'caret-down']}
            css={{
              marginTop: iconMarginTop,
              ...iconCss,
            }}
          />
        </Grid>
      </Grid>
    </Button>
  );
};

export default ThemedFakeSelect;
