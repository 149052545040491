import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './loader.scss';

const LoadingWrapper = ({ isLoading = false, loadingMessage = '', ...prop }) =>
  isLoading ? (
    <div className={`blank-page ${prop.className}`} style={prop.style}>
      <div className="content flex">
        <div>
          <CircularProgress
            color="primary"
            variant="indeterminate"
            className="loader"
            thickness={5}
            size={64}
            value={30}
            data-test-id="loading-progress-id"
            style={{
              marginLeft: '-32px',
              marginRight: '-32px',
              marginTop: '-110px',
            }}
          />
        </div>
        {loadingMessage !== '' && (
          <React.Fragment>{loadingMessage}</React.Fragment>
        )}
      </div>
    </div>
  ) : (
    <React.Fragment>{prop.children}</React.Fragment>
  );

export default LoadingWrapper;
