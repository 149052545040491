export const successResponse = {
  isSuccess: true,
  messages: [
    {
      messageType: 0,
      code: null,
      message: 'Success',
    },
  ],
};
export const failureResponse = {
  isSuccess: false,
  messages: [
    {
      messageType: 0,
      code: null,
      message: 'Failure',
    },
  ],
};

export const stateList = [
  {
    code: 'WA',
    display: 'WESTERN AUSTRALIA',
    value: 'WA',
    id: 1,
  },
  {
    code: 'NT',
    display: 'NORTHERN TERRITORY',
    value: 'NT',
    id: 2,
  },
  {
    code: 'QLD',
    display: 'QUEENSLAND',
    value: 'QLD',
    id: 3,
  },
  {
    code: 'SA',
    display: 'SOUTH AUSTRALIA',
    value: 'SA',
    id: 4,
  },
  {
    code: 'NSW',
    display: 'NEW SOUTH WALES',
    value: 'NSW',
    id: 5,
  },
  {
    code: 'ACT',
    display: 'AUSTRALIAN CAPITAL TERRITORY',
    value: 'ACT',
    id: 6,
  },
  {
    code: 'VIC',
    display: 'VICTORIA',
    value: 'VIC',
    id: 7,
  },
  {
    code: 'TAS',
    display: 'TASMANIA',
    value: 'TAS',
    id: 8,
  },
];

export const coordinatorsList = [
  {
    value: '101',
    code: '101',
    display: 'Jeff Johnston',
    _links: null,
  },
  {
    value: '102',
    code: '102',
    display: 'Lucile Burgess',
    _links: null,
  },
  {
    value: '103',
    code: '103',
    display: 'Susan Ortiz',
    _links: null,
  },
  {
    value: '104',
    code: '104',
    display: 'Bhuvnesh',
    _links: null,
  },
  {
    value: '105',
    code: '105',
    display: 'viren',
    _links: null,
  },
  {
    value: '106',
    code: '106',
    display: 'rajat',
    _links: null,
  },
  {
    value: '107',
    code: '107',
    display: 'rishi',
    _links: null,
  },
  {
    value: '108',
    code: '108',
    display: 'mayur',
    _links: null,
  },
  {
    value: '109',
    code: '109',
    display: 'rohan',
    _links: null,
  },
  {
    value: '110',
    code: '110',
    display: 'My preferred MC is not in this list',
    _links: null,
  },
];

export const identificationTypeList = [
  {
    value: 6,
    code: 'ABN',
    display: 'Australian Business Number',
    displayAlternative: null,
    color: null,
    icon: null,
  },
  {
    value: 7,
    code: 'ACN',
    display: 'Australian Company Number',
    displayAlternative: null,
    color: null,
    icon: null,
  },
  {
    value: 1,
    code: 'BCERT',
    display: 'Birth Certificate',
    displayAlternative: null,
    color: null,
    icon: null,
  },
  {
    value: 2,
    code: 'DRIVE',
    display: 'Drivers Licence',
    displayAlternative: null,
    color: null,
    icon: null,
  },
  {
    value: 3,
    code: 'KEYPASS',
    display: 'Key Pass',
    displayAlternative: null,
    color: null,
    icon: null,
  },
  {
    value: 4,
    code: 'MEDICARE',
    display: 'Medicare card',
    displayAlternative: null,
    color: null,
    icon: null,
  },
  {
    value: 5,
    code: 'PASSPORT',
    display: 'Passport',
    displayAlternative: null,
    color: null,
    icon: null,
  },
  {
    value: 8,
    code: 'PCC',
    display: 'Pension Concession Card',
    displayAlternative: null,
    color: null,
    icon: null,
  },
];

export const industryList = [
  {
    value: 367,
    code: '3291',
    display: 'Abrasive Products',
    displayAlternative: null,
    color: null,
    icon: null,
  },
  {
    value: 802,
    code: '6321',
    display: 'Accident and Health Insurance',
    displayAlternative: null,
    color: null,
    icon: null,
  },
];

export const salutationList = [
  {
    value: 'CR',
    code: 'CR',
    display: 'Cr',
    displayAlternative: null,
    color: null,
    icon: null,
  },
  {
    value: 'DR',
    code: 'DR',
    display: 'Dr',
    displayAlternative: null,
    color: null,
    icon: null,
  },
];

export const cardList = [
  {
    value: 'DVAGC',
    code: 'DVAGC',
    display: 'Dept of Veterans Affairs Gold Card',
    displayAlternative: null,
    color: null,
    icon: null,
  },
  {
    value: 'DVA_DIS',
    code: 'DVA_DIS',
    display: 'DVA Gold Card (Disability Pension)',
    displayAlternative: null,
    color: null,
    icon: null,
  },
];

export const preferHearingList = [
  {
    id: 1,
    description: 'Website',
  },
  {
    value: 2,
    description: 'Google Search',
  },
];

export const moveInDatesList = ['2020-05-16T00:00:00', '2020-05-17T00:00:00'];

export const paymentFrequencyList = [
  {
    value: 1,
    code: 'F',
    display: 'Fortnightly',
    displayAlternative: null,
    color: null,
    icon: null,
  },
  {
    value: 2,
    code: 'M',
    display: 'Monthly',
    displayAlternative: null,
    color: null,
    icon: null,
  },
];

export const paymentVendorList = ['PaymentExpress'];

export const rates = [
  {
    offeringId: 0,
    priceClass: 'Off peak usage',
    description: 'Praesent cursus vestibulum erat interdum gravida.',
    code: 'OFFPEAK',
    startDate: null,
    isFutureRate: false,
    stepped: true,
    step: 2,
    stepUnitsStart: 150,
    rate: 0.4,
    referenceRate: 0.8,
    save: 50.0,
    unitOfMeasure: 'kWh',
    rates: [],
  },
  {
    offeringId: 0,
    priceClass: 'Daily supply charge',
    description:
      'Curabitur vitae mattis tortor. Praesent cursus vestibulum erat interdum gravida.',
    code: 'DAILY',
    startDate: null,
    isFutureRate: false,
    stepped: false,
    step: 1,
    stepUnitsStart: 0,
    rate: 1.639,
    referenceRate: 1.639,
    save: 100,
    unitOfMeasure: 'kWh',
    rates: [],
  },
  {
    offeringId: 0,
    priceClass: 'Peak usage',
    description:
      'In eu sem purus, et iaculis elit. Donec sit amet diam sit amet nulla gravida tincidunt.',
    code: 'PEAK',
    startDate: null,
    isFutureRate: false,
    stepped: false,
    step: 1,
    stepUnitsStart: 0,
    rate: 1.85,
    referenceRate: 2.0,
    save: 92.5,
    unitOfMeasure: 'kWh',
    rates: [],
  },
  {
    offeringId: 0,
    priceClass: 'Peak usage',
    description:
      'In eu sem purus, et iaculis elit. Donec sit amet diam sit amet nulla gravida tincidunt.',
    code: 'PEAK',
    startDate: '2025-01-01T00:00:00',
    isFutureRate: true,
    stepped: false,
    step: 1,
    stepUnitsStart: 0,
    rate: 1.95,
    referenceRate: 2.1,
    save: 92.85714285714285714285714286,
    unitOfMeasure: 'kWh',
    rates: [],
  },
  {
    offeringId: 0,
    priceClass: 'Peak usage',
    description: 'Praesent cursus vestibulum erat interdum gravida.',
    code: 'OFFPEAK',
    startDate: null,
    isFutureRate: false,
    stepped: true,
    step: 2,
    stepUnitsStart: 50,
    rate: 0.5,
    referenceRate: 0.9,
    save: 55.55555555555555555555555556,
    unitOfMeasure: 'kWh',
    rates: [],
  },
  {
    offeringId: 0,
    priceClass: 'Off peak usage',
    description: 'Praesent cursus vestibulum erat interdum gravida.',
    code: 'OFFPEAK',
    startDate: null,
    isFutureRate: false,
    stepped: true,
    step: 1,
    stepUnitsStart: 0,
    rate: 0.6,
    referenceRate: 1.0,
    save: 60.0,
    unitOfMeasure: 'kWh',
    rates: [],
  },
];

export const productTypes = [
  {
    code: 'BOTH',
    color: null,
    display: 'Electricity & Gas',
    displayAlternative: '',
    icon: 'bolt', // @TODO make theme-dynamic
    checkedIcon: 'check', // @TODO make theme-dynamic
    isDefault: process.env.REACT_APP_GAS_SUPPLY,
    value: 0,
  },
  {
    code: 'POWER',
    color: null,
    display: 'Electricity',
    displayAlternative: 'NMI',
    icon: 'bolt', // @TODO make theme-dynamic
    checkedIcon: 'check', // @TODO make theme-dynamic
    isDefault: !process.env.REACT_APP_GAS_SUPPLY,
    value: 1,
  },
  {
    code: 'GAS',
    color: null,
    display: 'Gas',
    displayAlternative: 'MIRN', // @TODO make theme-dynamic
    icon: 'fire', // @TODO make theme-dynamic
    checkedIcon: 'check',
    isDefault: false,
    value: 2,
  },
];
