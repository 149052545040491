import i18n from 'i18next';
class CustomError extends Error {
  constructor(message = i18n.t('500.error.message'), status = 500, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }

    this.message = message;
    this.status = status;
  }
}
export default CustomError;
