import React from 'react';
const AgilityRadioItemLabel = props => {
  return (
    <div className="radio-item">
      <p className="text2">{props.label}</p>
      {props.people && <p className="text3">{props.people}</p>}
      {props.unit && <p className="text4">{props.unit}</p>}
    </div>
  );
};

export default AgilityRadioItemLabel;
