import { connect } from 'react-redux';
import mobileAuthentication from './MobileAuthentication';
import {
  checkMobileCode,
  requestMobileAuthentication,
} from 'App/modules/onBoarding/action';

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    checkMobileCode: data => checkMobileCode(data),
    requestMobileAuthentication: data => requestMobileAuthentication(data),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mobileAuthentication);
