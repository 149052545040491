/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Menu, useTheme, MenuItem } from '@material-ui/core';

const ThemedMenu = ({
  anchorEl = null,
  handleOnChange = () => null,
  handleClose = () => null,
  open = false,
  width = 0,
  options = [],
}) => {
  const theme = useTheme();
  const { menuButtonCss, textCss } = theme.custom.PlanFilter.filters;

  return (
    <Menu
      keepMounted
      anchorEl={anchorEl}
      onClose={handleClose}
      open={open}
      css={{
        '.MuiPaper-root': {
          borderWidth: menuButtonCss.borderWidth,
          borderColor: menuButtonCss.borderColor,
          borderStyle: menuButtonCss.borderStyle,
          borderRadius: menuButtonCss.borderRadius,
        },
        '.MuiList-root': {
          width: width - menuButtonCss.borderWidth * 2,
          backgroundColor: menuButtonCss.backgroundColor,
          color: textCss.color,
          margin: 0,
          padding: 12,
          maxWidth: '100%',
        },
      }}
    >
      {options.map((option, index) => (
        <MenuItem
          key={option.value}
          onClick={handleOnChange}
          data-value={option.value}
          css={{
            height: 48,
            lineHeight: 1,
            width: '100%',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingTop: 12,
            paddingBottom: 12,
            borderRadius: menuButtonCss.borderRadius,
            backgroundColor: menuButtonCss.backgroundColor,
            color: textCss.color,
            textTransform: 'uppercase',
            fontWeight: `${textCss.fontWeight} !important`,
            fontSize: textCss.fontSize,
            fontFamily: textCss.fontFamily,
            '&:hover': {
              backgroundColor: textCss.color,
              color: menuButtonCss.backgroundColor,
            },
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default ThemedMenu;
