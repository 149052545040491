/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Helmet } from 'react-helmet';
import {
  AgilityButton,
  AgilityCard,
  AgilityGrid,
  AgilityTypography,
  LoadingWrapper,
} from 'Src/AgilityComponents';
import Container from '@material-ui/core/Container';
import { useTheme } from '@material-ui/core/styles';
import i18n from 'i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { func } from 'prop-types';
import cookie from 'js-cookie';
import React from 'react';
import dompurify from 'isomorphic-dompurify';

const Refer = props => {
  const theme = useTheme();
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const ref = queryParams.ref;
  const { name, getReferName } = props;
  const sanitizer = dompurify.sanitize;

  const getName = () => {
    if (name === null) {
      getReferName(ref);
      cookie.set(
        'refer',
        JSON.stringify({
          campaign: 'RAF',
          medium: 'Website',
          source: ref,
        }),
        { expires: 28 }
      );
    }

    return name;
  };

  return (
    <div css={theme.custom.CenteredPageCss}>
      <Container maxWidth={'sm'}>
        <Helmet>
          <title>{`Refer A Friend | ${process.env.REACT_APP_RETAILER_NAME}`}</title>
        </Helmet>
        <AgilityCard className="agent-login mt-2 mb-2">
          <LoadingWrapper
            isLoading={getName() === null}
            css={css`
              height: 255px;
            `}
          >
            <Container>
              <AgilityTypography variant="h3" align="center" className="mb-2">
                {i18n.t('refer.header', { name: getName() })}
              </AgilityTypography>
              <AgilityTypography variant="h6" component="h6">
                {i18n.t('refer.message', {
                  retailer:
                    process.env.REACT_APP_BRAND_CODE === 'TANGO'
                      ? 'Tango Energy'
                      : process.env.REACT_APP_RETAILER_NAME,
                  name: getName() !== '' ? getName() : 'your friend',
                })}
              </AgilityTypography>
              <AgilityButton
                onClick={() => props.history.push('/')} // not using href to avoid a:visited styles
                label={i18n.t('refer.buttonText')}
                variant="contained"
                color="primary"
                fullWidth={true}
                size="large"
                type="primary"
                css={css`
                  margin-top: 15px;
                `}
              />
              <AgilityGrid item xs={12}>
                <AgilityTypography
                  variant="p"
                  component="p"
                  align="center"
                  css={theme.custom.ReferDialog.TermsCss}
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(
                      i18n.t('signupCompletion.refer.termsText', {
                        link: i18n.t('signupCompletion.refer.termsLink'),
                        interpolation: { escapeValue: false },
                      })
                    ),
                  }}
                />
              </AgilityGrid>
            </Container>
          </LoadingWrapper>
        </AgilityCard>
      </Container>
    </div>
  );
};

Refer.propTypes = {
  getReferName: func,
};
Refer.defaultProps = {};

export default Refer;
