import getListApiUrl, {
  getListForSelectField,
  getListUsageCategories,
  replaceUrlParams,
} from 'App/utils/helper';
import {
  PRODUCT_LIST_API,
  OFFER_LIST_API,
  PROMO_CODE_VALIDATE_API,
  PROPERTY_LIST_API,
  PRICE_FILTER_API,
  SIGNUP_API,
  POWER_SEARCH_SITE_IDENTIFIER_API,
  GAS_SEARCH_SITE_IDENTIFIER_API,
  COST_FREQUENCY_LIST_API,
  USAGE_CATEGORIES_API,
  GET_PLAN_DETAILS_API,
  REQUEST_PARK_PROGRESS,
  EMBEDDED_SEARCH_API,
  EMBEDDED_GET_SITES_API,
  PRODUCT,
  OFFER_AVAILABLE,
  GET_DISTRIBUTORS_API,
  GET_LOCATION_API,
} from 'App/utils/constants';
import {
  showSaveProgressLoading,
  saveProgressIsSuccess,
} from 'App/modules/onBoarding/action';
import { productTypes, propertyTypes } from 'App/pages/ComparePlans/data';
import {
  offerRequestMap,
  inputSignupMap,
  addressMapping,
  offerMapping,
} from 'App/utils/data_adapter';

import { logSaleAction } from 'App/utils/geolocation';

export const PLAN_FILTER_ACTION = 'PLAN_FILTER_ACTION';
export const PRICE_FILTER_SELECTION_ACTION = 'PRICE_FILTER_SELECTION_ACTION';
export const PRODUCT_LIST_ACTION = 'PRODUCT_LIST_ACTION';
export const OFFERS_LIST_ACTION = 'OFFERS_LIST_ACTION';
export const PLAN_SELECTION_ACTION = 'PLAN_SELECTION_ACTION';
export const SITE_IDENTIFIER_LIST_ACTION = 'SITE_IDENTIFIER_LIST_ACTION';
export const SITE_IDENTIFIER = 'SITE_IDENTIFIER';
export const PROPERTY_LIST_ACTION = 'PROPERTY_LIST_ACTION';
export const PRICE_FILTERS_ACTION = 'PRICE_FILTERS_ACTION';
export const CLEAR_LOWER_SECTION_ACTION = 'CLEAR_LOWER_SECTION_ACTION';
export const SIGNUP_API_ACTION = 'SIGNUP_API_ACTION';
export const SET_PLAN_SELECTION_ACTION = 'SET_PLAN_SELECTION_ACTION';
export const SET_SITE_IDENTIFIER_SELECTION = 'SET_SITE_IDENTIFIER_SELECTION';
export const SIGNUP_GET_API_ACTION = 'SIGNUP_GET_API_ACTION';
export const RESET_USER_DATA_ACTION = 'RESET_USER_DATA_ACTION';
export const SET_COST_FREQUENCY = 'SET_COST_FREQUENCY';
export const SET_USAGE_CATEGORIES = 'SET_USAGE_CATEGORIES';
export const GET_PLAN_DETAILS_ACTION = 'GET_PLAN_DETAILS_ACTION';
export const SET_PROPERTY_TYPE_ACTION = 'SET_PROPERTY_TYPE_ACTION';
export const CLEAR_AFTER_PRELOAD = 'CLEAR_AFTER_PRELOAD';
export const SET_POSTCODE_OFFERS = 'SET_POSTCODE_OFFERS';
export const SET_SOLAR_SELECTED_ACTION = 'SET_SOLAR_SELECTED_ACTION';
export const SET_PROMOCODE_ACTION = 'SET_PROMOCODE_ACTION';

export function setOfferFilterHasSolar(data) {
  return {
    type: SET_SOLAR_SELECTED_ACTION,
    payload: data,
  };
}

export function setOfferFilterPromoCode(data) {
  return {
    type: SET_PROMOCODE_ACTION,
    payload: data,
  };
}

export function setPlanFilters(data) {
  return {
    type: PLAN_FILTER_ACTION,
    payload: data,
  };
}

export function setIdentifier(productType, data) {
  return {
    type: SITE_IDENTIFIER,
    payload: data,
    productType: productType,
  };
}

export function setPriceFilters(data) {
  return {
    type: PRICE_FILTER_SELECTION_ACTION,
    ...data,
  };
}

export function resetComparePlanUserData() {
  return {
    type: RESET_USER_DATA_ACTION,
    payload: {},
  };
}

export const fetchProductList = () => dispatch => {
  return new Promise((resolve, reject) => {
    window.Api.get(PRODUCT_LIST_API, null, productTypes)
      .then(result => {
        // Remove multi option if its embedded network
        const index = result.findIndex(x => x.code === 'MULTI');
        if (index !== -1) {
          result =
            process.env.REACT_APP_IS_EMBEDDED_NETWORK === 'true'
              ? [result[index]]
              : (result.splice(index, 1), result);
        }
        dispatch({
          type: PRODUCT_LIST_ACTION,
          payload: result,
        });
        return resolve();
      })
      .catch(err => {
        return reject(err);
      });
  });
};

export const fetchOfferList =
  (siteIdentifier, params, productType) => dispatch => {
    let url = OFFER_LIST_API;
    // dispatch the action to set progress to true
    return new Promise((resolve, reject) => {
      params = offerRequestMap(siteIdentifier, params);
      url = getListApiUrl(url, params);

      window.Api.get(url, null)
        .then(result => {
          dispatch({
            type: OFFERS_LIST_ACTION,
            payload: result,
            productType: productType,
          });
          return resolve();
        })
        .catch(error => {
          dispatch({
            type: OFFERS_LIST_ACTION,
            payload: {},
            productType: productType,
          });
          return reject(error);
        });
    });
  };

export const fetchSiteIdentifierList = (productType, params) => dispatch => {
  logSaleAction(params, `${productType}_SITE_DISCOVERY`);
  return new Promise((resolve, reject) => {
    let url = getListApiUrl(POWER_SEARCH_SITE_IDENTIFIER_API, params);
    if (process.env.REACT_APP_IS_EMBEDDED_NETWORK === 'true') {
      url = replaceUrlParams(EMBEDDED_GET_SITES_API, params);
    } else if (productType === PRODUCT.GAS) {
      url = getListApiUrl(GAS_SEARCH_SITE_IDENTIFIER_API, params);
    }

    window.Api.get(url, null)
      .then(result => {
        result = addressMapping(result, productType);
        dispatch({
          type: SITE_IDENTIFIER_LIST_ACTION,
          payload: result,
          productType: productType,
        });
        return resolve(result);
      })
      .catch(error => {
        dispatch({
          type: SITE_IDENTIFIER_LIST_ACTION,
          payload: [],
          productType: productType,
        });
        return reject(error);
      });
  });
};

export const fetchSiteIdentifierListById =
  (productType, params) => dispatch => {
    let url =
      productType === PRODUCT.GAS
        ? GAS_SEARCH_SITE_IDENTIFIER_API
        : POWER_SEARCH_SITE_IDENTIFIER_API;

    return new Promise((resolve, reject) => {
      logSaleAction(params, `${productType}_SITE_DISCOVERY`);
      params = offerRequestMap(params.siteIdentifier, {
        ...params,
        productType,
      });
      url = getListApiUrl(url, params);
      window.Api.get(url)
        .then(result => {
          result = addressMapping(result, productType);
          dispatch({
            type: SITE_IDENTIFIER_LIST_ACTION,
            payload: result,
            productType: productType,
          });
          return resolve(result);
        })
        .catch(error => {
          dispatch({
            type: SITE_IDENTIFIER_LIST_ACTION,
            payload: [],
            productType: productType,
          });
          return reject(error);
        });
    });
  };

export const validatePromoCode = async params => {
  const url = getListApiUrl(PROMO_CODE_VALIDATE_API, params);
  return window.Api.get(url).catch(error => {
    throw new Error('Error occurred');
  });
};

export const setPlanSelection = (data, productType) => {
  return {
    type: PLAN_SELECTION_ACTION,
    payload: data,
    productType: productType,
  };
};

export const setDefaultPriceFilters = productType => {
  return {
    type: PRICE_FILTERS_ACTION,
    payload: {},
    productType: productType,
  };
};

export const fetchPropertyList = () => dispatch => {
  return new Promise((resolve, reject) => {
    window.Api.get(PROPERTY_LIST_API, null, propertyTypes)
      .then(result => {
        dispatch({
          type: PROPERTY_LIST_ACTION,
          payload: getListForSelectField(result, true),
        });
        return resolve();
      })
      .catch(error => {
        return reject(error);
      });
  });
};

export const fetchPriceFilterList = productType => dispatch => {
  const url = `${PRICE_FILTER_API}/${productType}`;
  window.Api.get(url)
    .then(result => {
      return dispatch({
        type: PRICE_FILTERS_ACTION,
        payload: result,
        productType: productType,
      });
    })
    .catch(error => {});
};

export function clearLowerSectionComparePlan() {
  return {
    type: CLEAR_LOWER_SECTION_ACTION,
    payload: {},
  };
}

export const postSignupDetails =
  (data, fromSaveProgress = false) =>
  dispatch => {
    return new Promise((resolve, reject) => {
      let url = SIGNUP_API;
      if (fromSaveProgress) {
        dispatch(showSaveProgressLoading(true));
        url = REQUEST_PARK_PROGRESS;
        const mobileNumber = data?.contacts?.[0]?.phones?.[0]?.phoneNumber;
        //Need `context` as json because we also need to store `sms_content` inside `context`.
        logSaleAction(
          { ...data, context: JSON.stringify({ number: mobileNumber }) },
          'SEND_SMS'
        );
      }
      const _data = inputSignupMap(
        {
          ...data,
          brandCode: process.env.REACT_APP_BRAND_CODE || '',
        },
        'POST'
      );
      window.Api.post(url, _data)
        .then(result => {
          dispatch({
            type: SIGNUP_API_ACTION,
            payload: result,
          });
          if (fromSaveProgress) {
            dispatch(saveProgressIsSuccess(true));
            dispatch(showSaveProgressLoading(false));
          } else {
            logSaleAction(
              {
                accountId: result.id,
                ...data,
              },
              'CREATE_ACCOUNT',
              true
            );
          }
          return resolve(result);
        })
        .catch(error => {
          if (fromSaveProgress) {
            dispatch(saveProgressIsSuccess(false));
            dispatch(showSaveProgressLoading(false));
          }
          return reject(error);
        });
    });
  };

export const putSignupDetails =
  ({ id, ...data }, fromSaveProgress = false, email = '') =>
  dispatch => {
    return new Promise((resolve, reject) => {
      logSaleAction({ accountId: id, ...data }, 'UPDATE_ACCOUNT', true);
      let url = `${SIGNUP_API}/${id}`;
      if (fromSaveProgress && email) {
        dispatch(showSaveProgressLoading(true));
        url = REQUEST_PARK_PROGRESS + '?email=' + email;
      }
      const _data = inputSignupMap(
        {
          ...data,
          brandCode: process.env.REACT_APP_BRAND_CODE || '',
        },
        'PUT'
      );

      window.Api.put(url, _data)
        .then(result => {
          dispatch({
            type: SIGNUP_API_ACTION,
            payload: result,
          });
          if (fromSaveProgress) {
            dispatch(saveProgressIsSuccess(true));
            dispatch(showSaveProgressLoading(false));
          }
          return resolve(data);
        })
        .catch(error => {
          if (fromSaveProgress) {
            dispatch(saveProgressIsSuccess(false));
            dispatch(showSaveProgressLoading(false));
          }
          return reject(error);
        });
    });
  };

export const getSignupDetails =
  (id, accntId, dateOfBirth = null) =>
  dispatch => {
    return new Promise((resolve, reject) => {
      const url = `${SIGNUP_API}/${accntId}?hash=${id}`;
      window.Api.get(url)
        .then(result => {
          dispatch({
            type: SIGNUP_GET_API_ACTION,
            payload: result,
          });
          if (dateOfBirth) {
            logSaleAction(
              {
                accountId: accntId,
                ...result,
                context: dateOfBirth.split('-').reverse().join('/'),
              },
              'READ_ACCOUNT',
              true
            );
          } else {
            logSaleAction(
              {
                accountId: accntId,
                ...result,
              },
              'READ_ACCOUNT',
              true
            );
          }
          setPropertyType(result.propertyType);
          return resolve(result);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

export const setPropertyType = propertyType => dispatch => {
  return dispatch({
    type: SET_PROPERTY_TYPE_ACTION,
    payload: propertyType,
  });
};
export const setPlanSelectionAction = data => {
  return {
    type: SET_PLAN_SELECTION_ACTION,
    payload: data,
  };
};

export const setIdentifierSelectionAction = data => {
  return {
    type: SET_SITE_IDENTIFIER_SELECTION,
    payload: data,
  };
};

export const fetchCostFrequency = () => dispatch => {
  return new Promise((resolve, reject) => {
    window.Api.get(COST_FREQUENCY_LIST_API)
      .then(result => {
        dispatch({
          type: SET_COST_FREQUENCY,
          payload: getListForSelectField(result, true),
        });
        return resolve(result);
      })
      .catch(error => {
        return reject(error);
      });
  });
};

export const fetchUsageCategories = (propertyType, productType) => dispatch => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(USAGE_CATEGORIES_API, {
      propertyType,
      productType,
    });
    window.Api.get(url)
      .then(result => {
        dispatch({
          type: SET_USAGE_CATEGORIES,
          payload: getListUsageCategories(result),
        });
        return resolve(result);
      })
      .catch(error => {
        return reject(error);
      });
  });
};

export const getPlanDetailsAPI =
  (hash, accountId, productType, id = undefined) =>
  dispatch => {
    return new Promise((resolve, reject) => {
      const url = getListApiUrl(GET_PLAN_DETAILS_API, {
        hash,
        account_id: accountId,
        id: id,
      });
      window.Api.get(url)
        .then(result => {
          dispatch({
            type: GET_PLAN_DETAILS_ACTION,
            payload: offerMapping(result, productType, true),
            productType: productType,
          });
          return resolve();
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

export const searchEmbeddedNetwork = keySearch => {
  const url = EMBEDDED_SEARCH_API + '?searchText=' + keySearch;
  return window.Api.get(url);
};

// export const saveProgress = (data, body = null) => (dispatch) => {
//   const url = REQUEST_PARK_PROGRESS
//   if(body){
//     body.customers[0].phones[0] = data.value
//   }
// }

export const getOfferAvailability = data => dispatch => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(OFFER_AVAILABLE, data);
    window.Api.get(url)
      .then(result => {
        dispatch({
          type: SET_POSTCODE_OFFERS,
          payload: result,
        });
        return resolve(result);
      })
      .catch(error => {
        return reject(error);
      });
  });
};

export const fetchDistributors = postcode => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(GET_DISTRIBUTORS_API, { postcode });
    window.Api.get(url)
      .then(result => {
        return resolve(result);
      })
      .catch(error => {
        return reject(error);
      });
  });
};

export const getLocation = () => {
  return new Promise((resolve, reject) => {
    window.Api.get(GET_LOCATION_API)
      .then(result => {
        return resolve(result);
      })
      .catch(error => {
        return reject(error);
      });
  });
};
