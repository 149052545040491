import React from 'react';
import i18n from 'i18next';
import {
  AgilityButton,
  AgilityGrid,
  AgilityInfoDialog,
  AgilityTextField,
  AgilityTypography,
} from 'Src/AgilityComponents';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useTheme } from '@material-ui/core/styles';
import { bool, func, string } from 'prop-types';
import dompurify from 'isomorphic-dompurify';
import { referAFriendSocialButtons } from 'App/customConfig';

const sanitizer = dompurify.sanitize;

const ReferDialog = props => {
  const theme = useTheme();
  const { referenceNo, isOpen, togglePopup } = props;

  const getReferUrl = () => {
    return (
      document.location.protocol +
      '//' +
      document.location.host +
      '/refer?ref=' +
      referenceNo
    );
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(getReferUrl());
  };

  return (
    <AgilityInfoDialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      okClick={() => togglePopup(false)}
      closeClick={() => togglePopup(false)}
      className="full-page-popup-sm"
      showOkButton={false}
      showModalTitle={false}
      css={{ textAlign: 'center' }}
    >
      <img
        css={{ maxHeight: '100px', maxWidth: '100%', height: 'auto' }}
        height="100%"
        src={theme.images.refer}
        alt="Finding address"
      />
      <h2
        css={css`
          color: ${theme.palette.primary.light};
        `}
      >
        {i18n.t('signupCompletion.refer.header')}
      </h2>
      <p>
        {i18n.t('signupCompletion.refer.text', {
          retailer:
            process.env.REACT_APP_BRAND_CODE === 'TANGO'
              ? 'Tango Energy'
              : process.env.REACT_APP_RETAILER_NAME,
          ref: referenceNo,
        })}
      </p>
      <AgilityGrid container direction="row">
        <AgilityGrid item lg={9} md={9} sm={8} xs={6}>
          <AgilityTextField
            id="copy-url"
            type="text"
            fullWidth
            value={getReferUrl()}
            disabled={true}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={['fas', 'link']} />
                </InputAdornment>
              ),
            }}
            css={css`
              margin-right: 10px;
              @media (max-width: ${theme.breakpoints.values.md}px) {
                display: none;
              }
            `}
          />
        </AgilityGrid>
        <AgilityGrid item lg={3} md={3} sm={12} xs={12}>
          <AgilityButton
            onClick={() => copyUrl()}
            label={i18n.t('signupCompletion.refer.copyButton')}
            variant="contained"
            color="primary"
            fullWidth={true}
            size="large"
            type="primary"
            endIcon={<FontAwesomeIcon icon={['fas', 'copy']} />}
            buttonCss={css`
              margin-bottom: 10px;
            `}
          />
          <AgilityButton
            href={
              'sms:?&body=' +
              i18n.t('signupCompletion.refer.sms', {
                retailer:
                  process.env.REACT_APP_BRAND_CODE === 'TANGO'
                    ? 'Tango Energy'
                    : process.env.REACT_APP_RETAILER_NAME,
                referLink: getReferUrl(),
              })
            }
            label={i18n.t('signupCompletion.refer.smsButton')}
            variant="contained"
            color="primary"
            fullWidth={true}
            size="large"
            type="primary"
            endIcon={<FontAwesomeIcon icon={['fas', 'sms']} />}
            buttonCss={css`
              margin-bottom: 10px;
              @media (min-width: ${theme.breakpoints.values.md}px) {
                display: none;
              }
            `}
          />
          {referAFriendSocialButtons && (
            <div
              container
              justifyContent="center"
              css={css`
                @media (min-width: ${theme.breakpoints.values.md}px) {
                  display: none;
                }
              `}
            >
              <AgilityButton
                href={`https://wa.me/?text=${i18n.t(
                  'signupCompletion.refer.sms',
                  {
                    retailer:
                      process.env.REACT_APP_BRAND_CODE === 'TANGO'
                        ? 'Tango Energy'
                        : process.env.REACT_APP_RETAILER_NAME,
                    referLink: getReferUrl(),
                  }
                )}`}
                label="Send on WhatsApp"
                variant="contained"
                color="primary"
                fullWidth={true}
                size="large"
                type="primary"
                endIcon={<FontAwesomeIcon icon={['fab', 'whatsapp']} />}
                buttonCss={theme.custom.ReferDialog.Whatsapp}
              />
              <AgilityButton
                href={`viber://forward?text=${i18n.t(
                  'signupCompletion.refer.sms',
                  {
                    retailer:
                      process.env.REACT_APP_BRAND_CODE === 'TANGO'
                        ? 'Tango Energy'
                        : process.env.REACT_APP_RETAILER_NAME,
                    referLink: getReferUrl(),
                  }
                )}`}
                label="Send on Viber"
                variant="contained"
                color="primary"
                fullWidth={true}
                size="large"
                type="primary"
                endIcon={<FontAwesomeIcon icon={['fab', 'viber']} />}
                buttonCss={theme.custom.ReferDialog.Viber}
              />
              <AgilityButton
                href={`fb-messenger://share?link=${getReferUrl()}`}
                label="Send on Messenger"
                variant="contained"
                color="primary"
                fullWidth={true}
                size="large"
                type="primary"
                endIcon={
                  <FontAwesomeIcon icon={['fab', 'facebook-messenger']} />
                }
                buttonCss={theme.custom.ReferDialog.Messenger}
              />

              <AgilityButton
                href={`https://www.facebook.com/dialog/share?app_id=1524020608150932&display=page&href=${getReferUrl()}&redirect_uri=${getReferUrl()}`}
                label="Share on Facebook"
                variant="contained"
                color="primary"
                fullWidth={true}
                size="large"
                type="primary"
                endIcon={<FontAwesomeIcon icon={['fab', 'facebook']} />}
                buttonCss={theme.custom.ReferDialog.Facebook}
              />
            </div>
          )}
        </AgilityGrid>
        {referAFriendSocialButtons && (
          <AgilityGrid
            container
            justifyContent="center"
            css={css`
              margin-bottom: 10px;
              @media (max-width: ${theme.breakpoints.values.md}px) {
                display: none;
              }
            `}
          >
            <AgilityButton
              href={`https://www.facebook.com/dialog/share?app_id=1524020608150932&display=page&href=${getReferUrl()}&redirect_uri=${getReferUrl()}`}
              label="Share on Facebook"
              variant="contained"
              color="primary"
              fullWidth={true}
              size="large"
              type="primary"
              endIcon={<FontAwesomeIcon icon={['fab', 'facebook']} />}
              buttonCss={theme.custom.ReferDialog.Facebook}
            />
          </AgilityGrid>
        )}
        <AgilityGrid item xs={12}>
          <AgilityTypography
            variant="body2"
            component="p"
            align="center"
            css={theme.custom.ReferDialog.TermsCss}
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                i18n.t('signupCompletion.refer.termsText', {
                  link: i18n.t('signupCompletion.refer.termsLink'),
                  interpolation: { escapeValue: false },
                })
              ),
            }}
          />
        </AgilityGrid>
      </AgilityGrid>
    </AgilityInfoDialog>
  );
};

ReferDialog.propTypes = {
  referenceNo: string,
  isOpen: bool,
  togglePopup: func,
};

ReferDialog.defaultProps = {
  referenceNo: '',
  isOpen: false,
};

export default ReferDialog;
