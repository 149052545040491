import React from 'react';
import { useSelector } from 'react-redux';

import { useDimensionsRef } from 'rooks';

import { Box } from '@material-ui/core';

import { propertyTypes } from 'App/pages/ComparePlans/data';
import { PROPERTY } from 'Root/src/app/utils/constants';

import ThemedMenu from '../../partials/ThemedMenu';
import ThemedFakeSelect from '../../partials/ThemedFakeSelect';
import ThemedBox from '../../partials/ThemedBox';

const PropertySelect = ({
  options,
  value: selectedOption,
  onChange,
  disableTouchMove,
  enableTouchMove,
}) => {
  const { sellsResidentialProducts, sellsBusinessProducts } = useSelector(
    state => state.app.customConfig
  );

  const [ref, dimensions] = useDimensionsRef();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = React.useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleOpenMenu = React.useCallback(
    event => {
      setAnchorEl(event.currentTarget);
      disableTouchMove();
    },
    [disableTouchMove]
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
    enableTouchMove();
  }, [enableTouchMove]);

  const selectedLabel = React.useMemo(() => {
    return options.filter(({ value }) => value === selectedOption)[0].label;
  }, [options, selectedOption]);

  const handleOnChange = React.useCallback(
    event => {
      onChange(event.target.dataset.value);
      handleClose();
    },
    [handleClose, onChange]
  );

  if (!sellsResidentialProducts) {
    return (
      <ThemedBox
        icon={['fas', 'home']}
        label={
          propertyTypes.filter(({ code }) => code === PROPERTY.COMPANY)[0]
            .display
        }
      />
    );
  }

  if (!sellsBusinessProducts) {
    return (
      <ThemedBox
        icon={['fas', 'home']}
        label={
          propertyTypes.filter(({ code }) => code === PROPERTY.RESIDENT)[0]
            .display
        }
      />
    );
  }

  return (
    <React.Fragment>
      <Box ref={ref}>
        <ThemedFakeSelect
          anchorEl={anchorEl}
          open={open}
          handleOpenMenu={handleOpenMenu}
          selectedLabel={selectedLabel}
          icon={['fas', 'home']}
        />
      </Box>
      <ThemedMenu
        width={dimensions?.width}
        anchorEl={anchorEl}
        open={open}
        handleOnChange={handleOnChange}
        handleClose={handleClose}
        options={options}
      />
    </React.Fragment>
  );
};

export default PropertySelect;
