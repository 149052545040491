import React from 'react';
import i18n from 'i18next';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  AgilityRadioGroup,
  AgilitySelectField,
  AgilityTextField,
  AgilityTypography,
  AgilityGrid,
} from 'Src/AgilityComponents';

import { yesNoOptionsWithBooleanValue } from 'App/utils/data';

import {
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
  MOBILE_NUMBER_MAX_LENGTH,
  HOME_NUMBER_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  MIDDLE_NAME_MAX_LENGTH,
} from 'App/utils/fieldLengthHelper';
import { convertToDateOnly } from 'App/utils/helper';
import { Element } from 'react-scroll';
import { changeDate, maxDob } from '../utils';

const YourDetailSecondaryContact = ({
  isActive,
  salutationList,
  dobError,
  onDobError,
  handleChange,
  fields,
  errors,
}) => {
  const hasError = React.useCallback(
    field => errors && errors.hasOwnProperty(field) && errors[field] !== '',
    [errors]
  );

  return (
    <React.Fragment>
      <AgilityGrid container data-test-id="secondary-contact-option">
        <AgilityGrid item xs={12} sm={12}>
          <div className="question-wrapper">
            <AgilityTypography variant="body2" component="p">
              {i18n.t('yourDetail.header.secondaryContact')}
            </AgilityTypography>
            <Element name="hasAdditionalContacts">
              <AgilityRadioGroup
                options={yesNoOptionsWithBooleanValue}
                onChange={event => {
                  handleChange(event.target.value, 'hasAdditionalContacts');
                }}
                data-test-id="secondary-contact-option-radio"
                value={fields.hasAdditionalContacts}
                disabled={!isActive}
                row
                isError={hasError('hasAdditionalContacts')}
                helperText={
                  hasError('hasAdditionalContacts')
                    ? errors.hasAdditionalContacts
                    : ''
                }
              />
            </Element>
          </div>
        </AgilityGrid>
      </AgilityGrid>

      {Boolean(fields.hasAdditionalContacts) && (
        <AgilityGrid container data-test-id="secondary-contact-fields">
          <AgilityGrid container spacing={2}>
            <AgilityGrid item xs={6} sm={4}>
              <Element name="secondaryTitle">
                <AgilitySelectField
                  data-test-id="secondaryTitle"
                  options={salutationList || []}
                  variant="outlined"
                  labelId="demo-simple-select-filled-label"
                  value={fields.secondaryTitle || ''}
                  disabled={!isActive}
                  showlabel
                  placeholder={i18n.t('yourDetail.placeholder.title')}
                  onChange={event =>
                    handleChange(event.target.value, 'secondaryTitle')
                  }
                  isError={hasError('secondaryTitle')}
                  helperText={
                    hasError('secondaryTitle') ? errors.secondaryTitle : ''
                  }
                />
              </Element>
            </AgilityGrid>
            <AgilityGrid item xs={6} sm={6}>
              <Element name="secondaryFirstName">
                <AgilityTextField
                  id="secondaryFirstName"
                  data-test-id="secondaryFirstName"
                  type="text"
                  fullWidth
                  placeholder={i18n.t('yourDetail.placeholder.firstName')}
                  value={fields.secondaryFirstName || ''}
                  disabled={!isActive}
                  onChange={val => handleChange(val, 'secondaryFirstName')}
                  variant="outlined"
                  required
                  inputProps={{ maxLength: FIRST_NAME_MAX_LENGTH }}
                  error={hasError('secondaryFirstName')}
                  helperText={
                    hasError('secondaryFirstName')
                      ? errors.secondaryFirstName
                      : ''
                  }
                />
              </Element>
            </AgilityGrid>
          </AgilityGrid>
          <AgilityGrid container spacing={2}>
            <AgilityGrid item xs={6} sm={6}>
              <Element name="secondaryMiddleName">
                <AgilityTextField
                  id="secondaryMiddleName"
                  data-test-id="secondaryMiddleName"
                  type="text"
                  fullWidth
                  placeholder={i18n.t('yourDetail.placeholder.middleName')}
                  value={fields.secondaryMiddleName || ''}
                  disabled={!isActive}
                  onChange={val => handleChange(val, 'secondaryMiddleName')}
                  variant="outlined"
                  inputProps={{ maxLength: MIDDLE_NAME_MAX_LENGTH }}
                  error={hasError('secondaryMiddleName')}
                  helperText={
                    hasError('secondaryMiddleName')
                      ? errors.secondaryMiddleName
                      : ''
                  }
                />
              </Element>
            </AgilityGrid>
            <AgilityGrid item xs={6} sm={6}>
              <Element name="secondaryLastName">
                <AgilityTextField
                  id="secondaryLastName"
                  data-test-id="secondaryLastName"
                  type="text"
                  fullWidth
                  placeholder={i18n.t('yourDetail.placeholder.lastName')}
                  value={fields.secondaryLastName || ''}
                  disabled={!isActive}
                  onChange={val => handleChange(val, 'secondaryLastName')}
                  variant="outlined"
                  required
                  inputProps={{ maxLength: LAST_NAME_MAX_LENGTH }}
                  error={hasError('secondaryLastName')}
                  helperText={
                    hasError('secondaryLastName')
                      ? errors.secondaryLastName
                      : ''
                  }
                />
              </Element>
            </AgilityGrid>
          </AgilityGrid>

          <AgilityGrid container spacing={2}>
            <AgilityGrid item xs={12} sm={6}>
              <Element name="secondaryMobileNumber">
                <AgilityTextField
                  id="secondaryMobileNumber"
                  data-test-id="secondaryMobileNumber"
                  type="text"
                  fullWidth
                  placeholder={i18n.t('yourDetail.placeholder.mobilePhone')}
                  value={fields.secondaryMobileNumber || ''}
                  disabled={!isActive}
                  onChange={val => handleChange(val, 'secondaryMobileNumber')}
                  variant="outlined"
                  required
                  error={hasError('secondaryMobileNumber')}
                  helperText={
                    hasError('secondaryMobileNumber')
                      ? errors.secondaryMobileNumber
                      : ''
                  }
                  inputProps={{
                    maxLength: MOBILE_NUMBER_MAX_LENGTH,
                  }}
                />
              </Element>
            </AgilityGrid>

            <AgilityGrid item xs={12} sm={6}>
              <Element name="secondaryHomeNumber">
                <AgilityTextField
                  id="secondaryHomeNumber"
                  data-test-id="secondaryHomeNumber"
                  type="text"
                  fullWidth
                  placeholder={i18n.t('yourDetail.placeholder.homePhone')}
                  value={fields.secondaryHomeNumber || ''}
                  disabled={!isActive}
                  onChange={val => handleChange(val, 'secondaryHomeNumber')}
                  variant="outlined"
                  error={hasError('secondaryHomeNumber')}
                  helperText={
                    hasError('secondaryHomeNumber')
                      ? errors.secondaryHomeNumber
                      : ''
                  }
                  inputProps={{
                    maxLength: HOME_NUMBER_MAX_LENGTH,
                  }}
                />
              </Element>
            </AgilityGrid>
          </AgilityGrid>
          <AgilityGrid container spacing={2}>
            <AgilityGrid item xs={12} sm={6}>
              <div className="text-field-wrapper">
                <Element name="secondaryDob">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      variant="inline"
                      inputVariant="outlined"
                      format={window.dateFormat}
                      id="secondaryDob"
                      label={i18n.t('yourDetail.placeholder.dob', {
                        dateFormat: window.dateFormat.toUpperCase(),
                      })}
                      value={fields.secondaryDob || null}
                      disabled={!isActive}
                      data-test-id="secondaryDob"
                      onChange={val =>
                        handleChange(convertToDateOnly(val), 'secondaryDob')
                      }
                      KeyboardButtonProps={{
                        'aria-label': changeDate,
                      }}
                      autoOk
                      maxDate={maxDob}
                      maxDateMessage={i18n.t(
                        'yourDetail.errorMessage.maxDateDob'
                      )}
                      onError={onDobError}
                      required
                      helperText={
                        Boolean(hasError('secondaryDob') || dobError)
                          ? errors.secondaryDob || dobError
                          : ''
                      }
                      error={Boolean(hasError('secondaryDob') || dobError)}
                    />
                  </MuiPickersUtilsProvider>
                </Element>
              </div>
            </AgilityGrid>
          </AgilityGrid>
          <AgilityGrid container spacing={2}>
            <AgilityGrid item xs={12} sm={12}>
              <Element name="secondaryEmail">
                <AgilityTextField
                  id="secondaryEmail"
                  data-test-id="secondaryEmail"
                  type="email"
                  fullWidth
                  placeholder={i18n.t('yourDetail.placeholder.email')}
                  value={fields.secondaryEmail || ''}
                  disabled={!isActive}
                  onChange={val => handleChange(val, 'secondaryEmail')}
                  variant="outlined"
                  required
                  error={hasError('secondaryEmail')}
                  helperText={
                    hasError('secondaryEmail') ? errors.secondaryEmail : ''
                  }
                  inputProps={{ maxLength: EMAIL_MAX_LENGTH }}
                />
              </Element>
            </AgilityGrid>
          </AgilityGrid>
        </AgilityGrid>
      )}
    </React.Fragment>
  );
};

export default YourDetailSecondaryContact;
