import cookie from 'js-cookie';
import { LOG_D2D_ACTIVITY } from 'App/utils/constants';
import { isDoorToDoor } from 'App/customConfig';
import { isEmpty } from 'App/utils/helper';

/**
 * Let's geolocation and log this request and action
 *
 * @param {object} request - the request (context) that was being sent
 * @param {string} action  - the action we want to log
 */
export const logSaleAction = (request, action, force = false) => {
  //get the user_id from the request
  let apiCode = request.apiCode || request.api_code;

  if (!apiCode && !isEmpty(request.extra_fields)) {
    const extras = JSON.parse(request.extra_fields);
    apiCode = extras.api_code;
  }

  // or the cookie
  if (!apiCode) {
    apiCode = cookie.get('agentEnteredCode') || '';
  }

  // don't log what when we don't have to
  if ((!apiCode || !isDoorToDoor) && !force) {
    return true;
  }

  const requestData = {
    id: request.accountId || request.id,
    action: action,
    context: request.context || null,
    api_code: apiCode || null,
    is_logged_in: cookie.get('agentEnteredCode') || false,
    siteAddress: request?.googleAddress,
  };

  // this is the customer.. so let's not track their location.
  if (!requestData.is_logged_in) {
    getLocationSuccess([], requestData);
    return true;
  }

  // otherwise, track track track.
  if (!navigator.geolocation) {
    return null;
  } else {
    return navigator.geolocation.getCurrentPosition(
      position => {
        getLocationSuccess(position, requestData);
      },
      error => {
        getLocationError(error, requestData);
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 10000 }
    );
  }
};

/**
 * We've successfully located the agent!
 *
 * @param {object} position - navigator.geolocation position object
 * @param {object} requestData - the request data to send onwards the the API
 * @returns
 */
const getLocationSuccess = (position, requestData) => {
  if (position?.coords?.latitude === undefined) {
    return sendDataToApi(position, [], requestData);
  }
  geocodeLatLng(position.coords.latitude, position.coords.longitude).then(
    (geocode, status) => {
      return sendDataToApi(position, geocode, requestData);
    }
  );
};

/**
 * What to do when we can't geolocate the agent.
 * This happens for a few reason, so let's alert for the moment.
 *
 * @param {number} error - the error code
 * @param {object} request - the data that we were going to send
 */
const getLocationError = (error, request) => {
  if (request.is_logged_in && request.api_code) {
    cookie.remove('agentEnteredCode');
    if (error.code === 1) {
      // PERMISSION_DENIED
      window.alert('Please enable geolocation on your device.');
      window.location = `${process.env.REACT_APP_BASENAME_URL}agent-login`;
    } else if (error.code === 2) {
      //POSITION_UNAVAILABLE
      window.alert(
        'Cannot find your location. Make sure your network connection is active and click the link to try again.'
      );
      window.location = `${process.env.REACT_APP_BASENAME_URL}agent-login`;
    } else if (error.code === 3) {
      //TIMEOUT
      window.alert('Cannot find your location. Click the link to try again.');
      window.location = `${process.env.REACT_APP_BASENAME_URL}agent-login`;
    }
  }
};

/**
 * Send the data to Uconx for logging
 *
 * @param {?object} position - navigator.geolocation position object
 * @param {?array} geocode - the geocoded address
 * @param {object} requestData - the data to be sent
 * @returns {Promise}
 */
const sendDataToApi = (position, geocode, requestData) => {
  return new Promise((resolve, reject) => {
    const data = {
      ...requestData,
      coords: {
        accuracy: position?.coords?.accuracy,
        latitude: position?.coords?.latitude,
        longitude: position?.coords?.longitude,
      },
      address: geocode[0]?.formatted_address || null,
      timestamp: position.timestamp,
    };

    window.Api.post(LOG_D2D_ACTIVITY, data)
      .then(result => {
        return resolve(data);
      })
      .catch(error => {
        return reject();
      });
  });
};

/**
 * Get a human-readable address from a lat/long point
 *
 * @param {float} lat - a latitude
 * @param {float} long - a longitude
 * @returns {Promise<Array>}} - an array of matching addresses
 */
const geocodeLatLng = (lat, long) => {
  const geocoder = new window.google.maps.Geocoder();
  const latLong = new window.google.maps.LatLng(lat, long);
  const OK = window.google.maps.GeocoderStatus.OK;
  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: latLong }, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};
