import React from 'react';
import './logo.scss';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@material-ui/core';
import { linkToMainWebsite } from 'App/customConfig';

const AgilityLogo = ({ logoImage, logoHeight = 40 }) => {
  return (
    <div className="brand-logo">
      {linkToMainWebsite && process.env.REACT_APP_WEBSITE_URL && (
        <MuiLink data-test-id="logo" href={process.env.REACT_APP_WEBSITE_URL}>
          <img
            src={logoImage}
            alt={`${process.env.REACT_APP_RETAILER_NAME} logo`}
            height={logoHeight}
          />
        </MuiLink>
      )}
      {(!linkToMainWebsite || !process.env.REACT_APP_WEBSITE_URL) && (
        <Link data-test-id="logo" to="/">
          <img
            src={logoImage}
            alt={`${process.env.REACT_APP_RETAILER_NAME} logo`}
            height={logoHeight}
          />
        </Link>
      )}
    </div>
  );
};

export default AgilityLogo;
