import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import isEmpty from 'lodash/isEmpty';
import { array, bool } from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const AgilitySelectField = ({ ...props }) => {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const { showlabel, isError, helperText, ...other } = props;
  React.useEffect(() => {
    setLabelWidth(showlabel ? inputLabel.current.offsetWidth : 0);
  }, [showlabel]);

  return (
    <div
      className={`text-field-wrapper ${props.className}`}
      style={props.style}
    >
      <FormControl
        variant={props.variant}
        error={isError}
        fullWidth={props.fullWidth}
      >
        {showlabel && (
          <InputLabel id="demo-simple-select-outlined-label" ref={inputLabel}>
            {props.placeholder}
          </InputLabel>
        )}
        <Select
          {...other}
          label={showlabel ? props.placeholder : ''}
          labelWidth={labelWidth}
        >
          {!isEmpty(props.options) &&
            props.options.map(data => (
              <MenuItem value={data.value} key={data.value}>
                {data.label}
              </MenuItem>
            ))}
        </Select>
        {isError && <FormHelperText>{props.helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
};
AgilitySelectField.propTypes = {
  AgilitySelect: array,
  showlabel: bool,
  isError: bool,
};
AgilitySelectField.defaultProps = {
  options: [],
  showlabel: false,
  isError: false,
};
export default AgilitySelectField;
