import { connect } from 'react-redux';
import ProductPlans from './ProductPlans';
import {
  fetchOfferList,
  setPlanSelection,
  fetchSiteIdentifierList,
  setIdentifier,
  setIdentifierSelectionAction,
  setPlanSelectionAction,
  fetchSiteIdentifierListById,
  fetchCostFrequency,
  fetchUsageCategories,
  setDefaultPriceFilters,
  setPlanFilters,
  setOfferFilterHasSolar,
} from 'App/pages/ComparePlans/action';

const mapStateToProps = (state, props) => {
  const {
    offers: { [props.productType]: offers } = { [props.productType]: [] },
    planFilter = {},
    selectedPlans: { [props.productType]: selectedPlans } = {
      [props.productType]: {},
    },
    selectedSiteIdentifier: { [props.productType]: selectedSiteIdentifier } = {
      [props.productType]: {},
    },
    costFrequency,
    usageCategories,
    priceFilter: { [props.productType]: priceFilter } = {
      [props.productType]: {},
    },
    siteIdentifierList: { [props.productType]: siteIdentifierList } = {
      [props.productType]: [],
    },
  } = state.comparePlan;

  return {
    isCovau: state.app.brand.isCovau,
    offerList: offers,
    planFilterData: planFilter,
    selectedPlan: selectedPlans,
    selectedSiteIdentifier: selectedSiteIdentifier,
    costFrequency: costFrequency,
    usageCategories: usageCategories,
    priceFilter: priceFilter,
    siteIdentifierList: siteIdentifierList,
    enteredSearchLocation: planFilter.enteredSearchLocation,
    selectedSearchLocation: planFilter.selectedSearchLocation,
    allSelectedSiteIdentifier: state.comparePlan.selectedSiteIdentifier,
    allSelectedPlan: state.comparePlan.selectedPlans,
    nmiHasSolarMeter: state.comparePlan.nmiHasSolarMeter,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchOfferList: (siteIdentifier, params, productType) =>
      dispatch(fetchOfferList(siteIdentifier, params, productType)),
    setPlanSelection: (data, productType) =>
      dispatch(setPlanSelection(data, productType)),
    fetchSiteIdentifierList: (productType, data) =>
      dispatch(fetchSiteIdentifierList(productType, data)),
    setIdentifier: (productType, data) =>
      dispatch(setIdentifier(productType, data)),
    setIdentifierSelectionAction: data =>
      dispatch(setIdentifierSelectionAction(data)),
    setPlanSelectionAction: data => dispatch(setPlanSelectionAction(data)),
    fetchSiteIdentifierListById: (productType, data) =>
      dispatch(fetchSiteIdentifierListById(productType, data)),
    fetchCostFrequency: () => dispatch(fetchCostFrequency()),
    fetchUsageCategories: (propertyType, productType) =>
      dispatch(fetchUsageCategories(propertyType, productType)),
    setDefaultPriceFilters: productType =>
      dispatch(setDefaultPriceFilters(productType)),
    setPlanFilters: data => dispatch(setPlanFilters(data)),
    setOfferFilterHasSolar: data => dispatch(setOfferFilterHasSolar(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPlans);
