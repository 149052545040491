/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import {
  AgilityGrid,
  AgilityTextField,
  AgilityTypography,
} from 'Src/AgilityComponents';
import AgilitySiteIdentifierDialog from '../Dialog/AgilitySiteIdentifierDialog';
import { string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import './off-market.scss';
import Fuse from 'fuse.js';
import { identifierDialogContent } from 'App/utils/data';
import { useTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import i18n from 'i18next';

const SearchOffMarket = props => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const { searchKey, heading, placeholder, subDataKey } = props;
  const [inputValue, setInputValue] = React.useState(props.searchValue || '');
  const [searchResult, setSearchResult] = React.useState([]);
  const [showSearchInfo, setShowSearchInfo] = React.useState(false);
  const [showSearchResult, setShowSearchResult] = React.useState(false);
  const [autoCompleted, setAutoCompleted] = React.useState(false);
  const [inputFocus, setInputFocus] = React.useState(false);
  // const [loadingAPI, setLoadingAPI] = React.useState(false);
  const inArea = React.useRef(false);
  const callAPI = React.useRef(null);
  const inputElem = React.useRef(null);
  const fuzzySearch = React.useRef(null);
  const fuseOptions = {
    includeScore: true,
    threshold: 0.1,
    keys: [searchKey, subDataKey],
  };
  React.useEffect(() => {
    setShowSearchResult(searchResult.length > 0);
    setAutoCompleted(false);
  }, [searchResult]);

  const setInfoDialogStatus = (status = false) => {
    setShowSearchInfo(status);
  };

  const handleChange = value => {
    props.onLocationSelected({});
    if (
      value.length > inputValue.length &&
      searchResult.length > 0 &&
      fuzzySearch.current
    ) {
      setSearchResult(fuzzySearch.current.search(value));
    } else {
      // setLoadingAPI(true);
      if (callAPI.current) {
        clearTimeout(callAPI.current);
      }
      if (value.length > 2) {
        callAPI.current = setTimeout(() => {
          props
            .searchAPI(value)
            .then(result => {
              fuzzySearch.current = new Fuse(result, fuseOptions);
              // fuzzySearch.current = new Fuse([{display:"1 Sunnynook road"}, {display:"195 Colin Wild"}, {display:"12 Knights Road"}, {display: "12 Takapuna Road"}, {display:"19 Colin Wild"}, {display:"23 Colin Wild"}, {display:"32 Colin Wild"}, {display:"42 Colin Wild"}, {display:"44 Colin Wild"}], fuseOptions)
              setSearchResult(fuzzySearch.current.search(value));
            })
            .catch(error =>
              enqueueSnackbar(i18n.t('embeddedNetwork.error.text'), {
                variant: 'error',
              })
            );

          // setLoadingAPI(false);
        }, 750);
      } else {
        setSearchResult([]);
      }
    }
    setInputValue(value);
  };

  const getTextWithHighlighted = address => {
    if (inputValue) {
      let keywords = [];
      const searchKeys =
        address.indexOf(inputValue) !== -1
          ? [inputValue]
          : inputValue.split(' ').filter(x => x);
      let reg;
      for (const word of searchKeys) {
        reg = new RegExp(word, 'gi');
        const newTags = address.match(reg);
        if (newTags) {
          keywords = keywords.concat(newTags);
        }
      }
      const stringfySeparator = keywords.join('|');
      reg = new RegExp(`(${stringfySeparator})`, 'g');
      address = address.split(reg).map((part, index) =>
        keywords.indexOf(part) !== -1 ? (
          <span key={index} className="highlighted">
            {part}
          </span>
        ) : (
          part
        )
      );
    }
    return <React.Fragment>{address}</React.Fragment>;
  };

  const autoCompleteAddress = _addr => {
    const { address } = _addr;
    setInputValue(address);
    setAutoCompleted(true);
    props.onLocationSelected(_addr);
  };

  const focusController = (isFocus = false) => {
    // Prevent blur when clicking option
    if (!isFocus && inArea.current) {
      return;
    }
    setInputFocus(isFocus);
  };

  const resetSearch = () => {
    inputElem.current.focus();
    setInputValue('');
    // setLoadingAPI(false);
    setAutoCompleted(false);
    setShowSearchResult(false);
    setSearchResult([]);
  };

  const isInArea = (isIn = false) => {
    inArea.current = isIn;
  };

  return (
    <div
      className="offMarket__base search-input"
      css={css`
        .MuiFormControl-root {
          &:before {
            content: '\f002';
            position: absolute;
            left: 0;
            font-family: ${theme.typography.iconFontFamily};
            font-weight: normal;
            text-rendering: auto;
            color: ${theme.palette.grey['500']};
            font-size: 16px;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .MuiInputLabel-formControl {
            left: 28px;
            &.MuiInputLabel-shrink {
              left: 0;
            }
          }
          .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'],
          .MuiOutlinedInput-input {
            padding-left: 45px;
          }
        }
      `}
    >
      <AgilityTypography
        variant="h4"
        component="h4"
        align="left"
        showInfo
        isTooltip={false}
        onInfoDialogClick={() => setInfoDialogStatus(true)}
      >
        {heading}
      </AgilityTypography>
      <div
        onMouseEnter={() => isInArea(true)}
        onMouseLeave={() => isInArea(false)}
      >
        <div className="offMarket__search-wrapper">
          <AgilityTextField
            className="offMarket__input-field"
            placeholder={placeholder}
            variant="outlined"
            fullWidth
            inputRef={input => {
              inputElem.current = input;
            }}
            onChange={val => handleChange(val)}
            value={inputValue}
            onFocus={() => focusController(true)}
            onBlur={() => focusController(false)}
          />
          {inputValue && (
            <IconButton className="offMarket__reset-btn" onClick={resetSearch}>
              <FontAwesomeIcon icon={['fas', 'times']} />
            </IconButton>
          )}
        </div>

        {showSearchResult && inputFocus && !autoCompleted && (
          <div className="offMarket__search-wrapper">
            <div className="offMarket__search-container">
              {searchResult.map((row, index) => (
                <div key={index} onClick={() => autoCompleteAddress(row.item)}>
                  <AgilityGrid
                    container
                    alignItems="center"
                    className="offMarket__row"
                  >
                    <AgilityGrid item className="offMarket__row-icon">
                      <FontAwesomeIcon
                        icon={['fas', `map-marker-alt`]}
                        size="2x"
                        className="icon-marker"
                        css={css`
                          color: ${theme.palette.grey['400']};
                          margin-right: 1rem;
                          font-size: 1.75rem;
                        `}
                      />
                    </AgilityGrid>
                    <AgilityGrid item xs className="offMarket__address">
                      <AgilityTypography variant="body2" component="p">
                        {getTextWithHighlighted(row.item[searchKey])}
                      </AgilityTypography>
                      {subDataKey && (
                        <AgilityTypography variant="subtitle1" component="p">
                          {getTextWithHighlighted(row.item[subDataKey])}
                        </AgilityTypography>
                      )}
                    </AgilityGrid>
                  </AgilityGrid>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <AgilitySiteIdentifierDialog
        open={showSearchInfo}
        onDismiss={setInfoDialogStatus}
        data={identifierDialogContent}
      />
    </div>
  );
};
SearchOffMarket.propTypes = {
  searchValue: string,
  searchKey: string,
  heading: string,
  placeholder: string,
  subDataKey: string,
};
SearchOffMarket.defaultProps = {
  searchValue: '',
  searchKey: '',
  heading: '',
  placeholder: 'Please enter address',
  subDataKey: '',
};
export default SearchOffMarket;
