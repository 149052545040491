// @Todo: make this more dynamic, and put in theme file
const SocialLinks = {
  seg: [
    {
      route: 'https://www.facebook.com/smartenergyau',
      icon: 'facebook',
      title: 'Like us on Facebook',
    },
    {
      route: 'https://www.instagram.com/smartenergyau/ ',
      icon: 'instagram',
      title: 'Visit us on Instagram',
    },
    {
      route: 'https://www.linkedin.com/company/smart-energy-group-pty-ltd/',
      icon: 'linkedin',
      title: 'Visit us on LinkedIn',
    },
  ],
  nbe: [
    {
      route: 'https://www.facebook.com/nextbusinessenergy',
      icon: 'facebook',
      title: 'Like us on Facebook',
    },
  ],
  demo: [
    {
      route: 'https://www.facebook.com/',
      icon: 'facebook',
      title: 'Like us on Facebook',
    },
    {
      route: 'https://www.instagram.com/',
      icon: 'instagram',
      title: 'Visit us on Instagram',
    },
    {
      route: 'https://www.linkedin.com/',
      icon: 'linkedin',
      title: 'Visit us on LinkedIn',
    },
  ],
  covau: [
    {
      route: 'https://www.facebook.com/covau/',
      icon: 'facebook',
      title: 'Like us on Facebook',
    },
    {
      route: 'https://twitter.com/COVAUenergy ',
      icon: 'twitter',
      title: 'Follow us on Twitter',
    },
    {
      route: 'https://www.linkedin.com/company/covau-pty-limited/',
      icon: 'linkedin',
      title: 'Visit us on LinkedIn',
    },
  ],
  tango: [
    {
      route: 'https://www.facebook.com',
      icon: 'facebook',
      title: 'Like us on Facebook',
    },
    {
      route: 'https://www.instagram.com',
      icon: 'instagram',
      title: 'Visit us on Instagram',
    },
    {
      route: 'https://www.youtube.com',
      icon: 'youtube',
      title: 'Check us out on YouTube',
    },
  ],
  gee: [
    {
      route: 'https://www.facebook.com/GEEEnergyAus/',
      icon: 'facebook',
      title: 'Like us on Facebook',
    },
    {
      route: 'https://www.linkedin.com/company/geeenergy/',
      icon: 'linkedin',
      title: 'Visit us on LinkedIn',
    },
  ],
  first: [
    {
      route: 'https://www.facebook.com/1stenergypower/',
      icon: 'facebook',
      title: 'Like us on Facebook',
    },
    {
      route: 'https://twitter.com/1stenergyau',
      icon: 'twitter',
      title: 'Follow us on Twitter',
    },
    {
      route: 'https://www.linkedin.com/company/1st-energy/',
      icon: 'linkedin',
      title: 'Visit us on LinkedIn',
    },
  ],
  solarrun: [
    {
      route: 'https://www.facebook.com/solarrunaus',
      icon: 'facebook',
      title: 'Like us on Facebook',
    },
    {
      route: 'https://au.linkedin.com/company/solar-run',
      icon: 'linkedin',
      title: 'Visit us on LinkedIn',
    },
    {
      route: 'https://www.youtube.com/channel/UC__jZUeTu8gv9060VjJFEbw',
      icon: 'youtube',
      title: 'Check us out on YouTube',
    },
  ],
};

export default SocialLinks;
