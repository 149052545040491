import React from 'react';
import { func, string, object } from 'prop-types';
import InputMask from 'react-input-mask';

const MaskInput = props => {
  const { inputRef, mask, maskChar, formatChars, ...other } = props;

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    let { value } = newState;
    const selection = newState.selection;

    if (value.endsWith('-')) {
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  return (
    <InputMask
      {...other}
      inputRef={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      maskChar={maskChar || null}
      data-test-id="input-mask"
      formatChars={formatChars}
      beforeMaskedValueChange={beforeMaskedValueChange}
    />
  );
};
MaskInput.propTypes = {
  inputRef: func.isRequired,
  mask: string.isRequired,
  formatChars: object,
};

MaskInput.defaultProps = {
  formatChars: {
    1: '[0-9]',
    '?': '[0-9]',
  },
};

export default MaskInput;
