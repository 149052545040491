/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useEffect, useRef } from 'react';
import './stepper.scss';
import { AgilityTypography } from 'Src/AgilityComponents';
import { Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { SIGNUP_STEPS as signupSteps } from 'App/utils/constants';
import { useTheme } from '@material-ui/core/styles';

const VerticalLinearStepper = props => {
  const theme = useTheme();

  const ref = useRef(null);
  const useScrollCaller = () => {
    useEffect(() => {
      window.addEventListener('scroll', handleStepperScroll);
    }, []);
  };

  const handleStepperScroll = () => {
    if (ref.current) {
      const ele = ref.current.getBoundingClientRect();
      const topPosition = ele.top - 10;
      const floatingDivHeight = ele.height;
      const footerFromTop = document.getElementById('footer').offsetTop;
      const absPosition = footerFromTop - floatingDivHeight - 120;
      const win = window;
      if (window.matchMedia('(min-width: 960px)').matches) {
        if (win.pageYOffset > topPosition && win.pageYOffset < absPosition) {
          ref.current.className = 'stepper sticky';
        } else if (
          win.pageYOffset > topPosition &&
          win.pageYOffset > absPosition
        ) {
          ref.current.className = 'stepper abs';
        } else {
          ref.current.className = 'stepper';
        }
      }
    }
  };

  const bgGrey200 = css`
    background: ${theme.palette.grey['200']};
  `;
  const bgPrimary = css`
    background: ${theme.palette.stepper.bgComplete};
  `;
  const textPrimary = css`
    color: ${theme.palette.primary.main};
  `;
  const textColor = css`
    color: ${theme.palette.grey['900']};
  `;
  const active = css`
    display: block;
  `;

  const displayStep = css`
    display: none;
    @media (min-width: ${theme.breakpoints.values.md}px) {
      display: block;
    }
  `;

  const completed = css`
    &:after {
      ${bgPrimary}
    }
  `;

  const subStepItem = css`
    font-family: ${theme.typography.fontFamily};
    color: ${theme.palette.grey['500']};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;

    .completed & {
      &:before {
        font-family: ${theme.typography.iconFontFamily};
        color: ${theme.palette.success.main};
      }
    }
  `;

  useScrollCaller();
  return (
    <div className="sidebar-right">
      {props.steps.length && (
        <ul
          id="sidebarRight"
          className={'stepper'}
          ref={ref}
          css={css`
            background: ${theme.palette.common.white};
          `}
        >
          {props.steps.map((obj, index) =>
            obj.id !== signupSteps.completeSummary ? (
              <React.Fragment key={`fragment-${index}`}>
                <li
                  data-test-id={obj.title}
                  key={`item-${index}`}
                  className={
                    obj.key === props.currentStep
                      ? 'active'
                      : obj.key < props.activeStep ||
                        obj.key < props.completedStep
                      ? 'completed'
                      : ''
                  }
                  css={[
                    displayStep,
                    obj.key === props.currentStep ? active : '',
                    obj.key < props.activeStep || obj.key < props.completedStep
                      ? completed
                      : '',
                  ]}
                >
                  <span
                    className="count"
                    css={css`
                      position: absolute;
                      width: 32px;
                      height: 32px;
                      background: ${theme.palette.grey['500']};
                      color: ${theme.palette.stepper.textComplete};
                      border-radius: 50%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      left: -45px;
                      font-family: ${theme.typography.headingFontFamily};
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 21px;
                      z-index: 9;
                      @media (min-width: ${theme.breakpoints.values.md}px) {
                        left: -60px;
                      }
                      .active & {
                        background: ${theme.palette.stepper.bgComplete};
                        color: ${theme.palette.stepper.textComplete};
                      }
                      .completed & {
                        background: ${theme.palette.stepper.bgComplete};
                        color: ${theme.palette.stepper.textComplete};
                      }
                    `}
                  >
                    {index + 1}
                  </span>
                  <AgilityTypography
                    variant="h4"
                    component="h4"
                    className="heading4"
                  >
                    {obj.title}
                  </AgilityTypography>
                  {obj.nested && (
                    <ul className="sub-step">
                      {obj.nested.map((child, index) => (
                        <li
                          key={`nested-item-${index}`}
                          className={`${
                            child.key < props.activeStep ||
                            child.key < props.completedStep
                              ? 'completed'
                              : ''
                          } ${child.key === props.activeStep ? 'active' : ''}`}
                        >
                          {/* Key: {child.key}
                          <br />
                          active step: {props.activeStep} <br />
                          completed: {props.completedStep} <br /> */}
                          {child.key === 0 ? (
                            <React.Fragment>
                              {child.component &&
                              props.match.params.step !==
                                signupSteps.selectedPlan ? (
                                <div className="service-card-list">
                                  <Link
                                    to={`/user-signup/${child.id}`}
                                    className="sub-step-item"
                                    css={[
                                      subStepItem,
                                      child.key === props.activeStep
                                        ? textPrimary
                                        : '',
                                      child.key < props.activeStep
                                        ? textColor
                                        : '',
                                    ]}
                                  >
                                    {/* <FontAwesomeIcon icon={['fas', 'check']} /> */}
                                    {child.title}
                                  </Link>
                                  {child.component}
                                </div>
                              ) : (
                                <Link
                                  to={`/user-signup/${child.id}`}
                                  className="sub-step-item"
                                  css={[
                                    subStepItem,
                                    child.key === props.activeStep
                                      ? textPrimary
                                      : '',
                                    child.key < props.activeStep
                                      ? textColor
                                      : '',
                                  ]}
                                >
                                  {/* <FontAwesomeIcon icon={['fas', 'check']} /> */}
                                  {child.title}
                                </Link>
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {child.component && props.activeStep > 0 ? (
                                <div className="service-card-list">
                                  {child.component}
                                </div>
                              ) : child.key <= props.completedStep ? (
                                <Link
                                  to={`/user-signup/${child.id}`}
                                  className="sub-step-item"
                                  css={[
                                    subStepItem,
                                    child.key === props.activeStep
                                      ? textPrimary
                                      : '',
                                    child.key < props.activeStep
                                      ? textColor
                                      : '',
                                  ]}
                                >
                                  {/* <FontAwesomeIcon icon={['fas', 'check']} /> */}
                                  {child.title}
                                </Link>
                              ) : (
                                <span
                                  className="sub-step-item inactive"
                                  css={[
                                    subStepItem,
                                    child.key === props.activeStep
                                      ? textPrimary
                                      : '',
                                    child.key < props.activeStep
                                      ? textColor
                                      : '',
                                  ]}
                                >
                                  {/* <FontAwesomeIcon icon={['fas', 'check']} /> */}
                                  {child.title}
                                </span>
                              )}
                            </React.Fragment>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              </React.Fragment>
            ) : (
              <Hidden mdUp key={index}>
                <li
                  key={`item-${index}`}
                  className={`no-count ${
                    obj.key === props.currentStep
                      ? 'active'
                      : obj.key < props.activeStep ||
                        obj.key < props.completedStep
                      ? 'completed'
                      : ''
                  }`}
                  css={[displayStep]}
                >
                  <AgilityTypography
                    variant="h4"
                    component="h4"
                    className="heading4"
                  >
                    {obj.title}
                  </AgilityTypography>
                </li>
              </Hidden>
            )
          )}
          <Hidden mdUp>
            <ul
              className="bottom-borders"
              css={css`
                background: ${theme.palette.common.white};
              `}
            >
              {props.steps.map((obj, index) => (
                <li
                  key={`mobile-item-${index}`}
                  className={
                    obj.key === props.activeStep ||
                    obj.key === props.currentStep
                      ? 'active'
                      : obj.key < props.activeStep
                      ? 'completed'
                      : ''
                  }
                >
                  {obj.nested && (
                    <ul className="sub-step">
                      {obj.nested.map((child, index) => (
                        <li
                          key={`mobile-nested-item-${index}`}
                          className={
                            child.key < props.activeStep
                              ? 'completed'
                              : child.key === props.activeStep
                              ? 'active'
                              : ''
                          }
                          css={[
                            child.key < props.activeStep ||
                            child.key === props.activeStep
                              ? bgPrimary
                              : bgGrey200,
                          ]}
                        ></li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </Hidden>
        </ul>
      )}
    </div>
  );
};

export default VerticalLinearStepper;
