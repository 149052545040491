import {
  PLAN_FILTER_ACTION,
  PRICE_FILTER_SELECTION_ACTION,
  PRODUCT_LIST_ACTION,
  SITE_IDENTIFIER,
  OFFERS_LIST_ACTION,
  PLAN_SELECTION_ACTION,
  SITE_IDENTIFIER_LIST_ACTION,
  PROPERTY_LIST_ACTION,
  PRICE_FILTERS_ACTION,
  CLEAR_LOWER_SECTION_ACTION,
  SIGNUP_API_ACTION,
  SET_PLAN_SELECTION_ACTION,
  SET_SITE_IDENTIFIER_SELECTION,
  SIGNUP_GET_API_ACTION,
  RESET_USER_DATA_ACTION,
  SET_COST_FREQUENCY,
  SET_USAGE_CATEGORIES,
  GET_PLAN_DETAILS_ACTION,
  SET_PROPERTY_TYPE_ACTION,
  CLEAR_AFTER_PRELOAD,
  SET_POSTCODE_OFFERS,
  SET_SOLAR_SELECTED_ACTION,
  SET_PROMOCODE_ACTION,
} from './action';
import {
  APPLICATION_ID,
  PRICE_FILTER_DEFAULT_USAGE,
  PRICE_FILTER_DEFAULT_FREQUENCY,
  ACCOUNT_ID,
  PRODUCT,
} from 'App/utils/constants';
// import { filterOffering, filterEstimates } from 'App/utils/helper';
import { isEmpty } from 'App/utils/helper';
import { offerMapping, signupMap } from 'App/utils/data_adapter';
import {
  sellsResidentialProducts,
  hasGasSupply,
  sellsBusinessProducts,
  isXSellGas,
} from 'App/customConfig';
import cookie from 'js-cookie';

const initialState = {
  planFilter: {
    selectedProducts: [],
    selectedProperty: sellsResidentialProducts ? 'RESIDENT' : 'COMPANY',
    selectedSearchLocation: {},
    enteredSearchLocation: '',
    promoCode: {},
    hasSolar: false,
    d2dApproval: false,
  },
  priceFilter: {},
  productList: [],
  offers: {},
  offersTemp: {},
  selectedPlans: {},
  siteIdentifierList: {},
  selectedSiteIdentifier: {},
  propertyList: [],
  signupAPIResponse: {},
  costFrequency: [],
  usageCategories: [],
  postcodeOffers: false,
  nmiHasSolarMeter: false,
};

let offers;
let offersTemp;
let productOffers;
let productFilter;

const comparePlan = (state = initialState, action) => {
  switch (action.type) {
    case SET_POSTCODE_OFFERS:
      return { ...state, postcodeOffers: action.payload };
    case SET_SOLAR_SELECTED_ACTION:
      return {
        ...state,
        planFilter: { ...state.planFilter, hasSolar: action.payload },
      };
    case SET_PROMOCODE_ACTION:
      return {
        ...state,
        planFilter: { ...state.planFilter, promoCode: action.payload },
      };
    case PLAN_FILTER_ACTION:
      return { ...state, planFilter: action.payload };
    case SET_PROPERTY_TYPE_ACTION:
      const filterData = { ...state.planFilter };
      filterData.selectedProperty = action.payload;
      return { ...state, planFilter: filterData };
    case PRICE_FILTER_SELECTION_ACTION:
      const productType = action.productType;
      const filterType = action.filterType;
      const filter = { ...state.priceFilter };
      if (isEmpty(filter[productType])) {
        productFilter[productType] = {
          [filterType]: action.value,
        };
        filter[productType] = productFilter[productType];
      } else {
        filter[productType][action.filterType] = action.value;
      }
      offers = { ...state.offers };
      offersTemp = { ...state.offersTemp };
      // productOffers = filterOffering(
      //   offersTemp[productType],
      //   productFilter[productType].usage,
      //   productFilter[productType].frequency
      // );
      // offers[productType] = productOffers;
      return { ...state, priceFilter: filter, offers };
    case PRODUCT_LIST_ACTION:
      // All retailers have supply Electricity by default
      let fuels = [PRODUCT.ELECTRICITY];
      if (hasGasSupply && isXSellGas) {
        fuels.pop(); // remove elec
        fuels.push(PRODUCT.GAS);
        state.planFilter.selectedProducts = [PRODUCT.GAS];
      } else if (hasGasSupply) {
        fuels.push(PRODUCT.GAS);
        fuels.push(PRODUCT.BOTH);
        state.planFilter.selectedProducts = [PRODUCT.ELECTRICITY, PRODUCT.GAS];
      } else {
        state.planFilter.selectedProducts = [PRODUCT.ELECTRICITY];
      }
      return {
        ...state,
        productList: action.payload.filter(product => {
          return fuels.includes(product.code);
        }),
        // productList: action.payload.filter(product =>
        //   fuels.includes(product.code)
        // ),
      };
    case OFFERS_LIST_ACTION:
      const { offers: offerings = [] } = action.payload;
      const _productType = action.productType;
      let _offerings = offerMapping(offerings, _productType);

      offers = { ...state.offers };
      offersTemp = { ...state.offersTemp };
      productFilter = state.priceFilter;
      // productOffers = filterOffering(
      //   action.payload,
      //   productFilter[action.productType].usage,
      //   productFilter[action.productType].frequency
      // );
      productOffers = _offerings;
      offers[_productType] = productOffers;
      offersTemp[_productType] = _offerings;
      return {
        ...state,
        offers,
        offersTemp,
        nmiHasSolarMeter: action.payload.query?.has_solar_meter === true,
      };
    case PLAN_SELECTION_ACTION:
      const selectedPlans = { ...state.selectedPlans };
      // unselect the plan.
      if (isEmpty(action.payload)) {
        delete selectedPlans[action.productType];
      } else {
        selectedPlans[action.productType] = action.payload;
      }
      return { ...state, selectedPlans };
    case SITE_IDENTIFIER:
      const selectedSiteIdentifier = { ...state.selectedSiteIdentifier };
      selectedSiteIdentifier[action.productType] = action.payload;
      return { ...state, selectedSiteIdentifier };
    case SITE_IDENTIFIER_LIST_ACTION:
      const siteIdentifierList = { ...state.siteIdentifierList };
      siteIdentifierList[action.productType] = action.payload;
      return { ...state, siteIdentifierList };
    case PROPERTY_LIST_ACTION:
      let propertyTypes = action.payload;

      if (!sellsResidentialProducts) {
        propertyTypes = propertyTypes.filter(
          productType => productType.value !== 'RESIDENT'
        );
      }
      if (!sellsBusinessProducts) {
        propertyTypes = propertyTypes.filter(
          productType => productType.value !== 'COMPANY'
        );
      }
      return { ...state, propertyList: propertyTypes };
    case PRICE_FILTERS_ACTION:
      const priceFilter = { ...state.priceFilter };
      if (isEmpty(priceFilter[action.productType])) {
        const defaultFilters = {
          usage: PRICE_FILTER_DEFAULT_USAGE,
          frequency: PRICE_FILTER_DEFAULT_FREQUENCY,
        };
        priceFilter[action.productType] = defaultFilters;
      }
      return { ...state, priceFilter };
    case CLEAR_LOWER_SECTION_ACTION:
      return {
        ...state,
        priceFilter: {},
        selectedSiteIdentifier: {},
        siteIdentifierList: {},
        offers: {},
        offersTemp: {},
        selectedPlans: {},
      };
    case SIGNUP_API_ACTION:
      localStorage.setItem(ACCOUNT_ID, action.payload.id);
      localStorage.setItem(APPLICATION_ID, action.payload.hash);
      return { ...state, signupAPIResponse: signupMap(action.payload) };
    case SET_PLAN_SELECTION_ACTION:
      return { ...state, selectedPlans: action.payload };
    case SET_SITE_IDENTIFIER_SELECTION:
      return { ...state, selectedSiteIdentifier: action.payload };

    case SIGNUP_GET_API_ACTION:
      const planFilter = {
        selectedProducts: [],
        selectedProperty: action.payload.property_type,
        selectedSearchLocation: {},
        enteredSearchLocation: '',
        promoCode: {
          isPromoApplied: false,
          code: '',
        },
        hasSolar: false,
      };
      if (action.payload.promo_code) {
        planFilter.promoCode = {
          code: action.payload.promo_code,
          isPromoApplied: true,
        };
      }
      localStorage.setItem(ACCOUNT_ID, action.payload.id);
      localStorage.setItem(APPLICATION_ID, action.payload.hash);
      return {
        ...state,
        planFilter,
        signupAPIResponse: signupMap(action.payload),
      };
    case RESET_USER_DATA_ACTION:
      cookie.remove('promoCode');
      return { ...initialState };
    case SET_COST_FREQUENCY:
      return { ...state, costFrequency: action.payload };
    case SET_USAGE_CATEGORIES:
      return { ...state, usageCategories: action.payload };
    case GET_PLAN_DETAILS_ACTION:
      const sPlans = { ...state.selectedPlans };
      const sPlan = action.payload;
      // @TODO Removed.. but this will be useful when we re-implement the low-med-high usage stuff.
      // const serviceObj = state.signupAPIResponse.services.find(
      //   obj => obj.productType === action.productType
      // );
      // sPlan.productOfferingEstimate = filterEstimates(
      //   sPlan.productOfferingEstimates,
      //   serviceObj.productOffering.usageCategory,
      //   serviceObj.productOffering.paymentFrequency
      // );
      sPlans[action.productType] = sPlan;
      return { ...state, selectedPlans: sPlans };
    case CLEAR_AFTER_PRELOAD:
      // TODO do we need this?
      localStorage.clear();
      return { ...state, signupAPIResponse: {} };
    default:
      return state;
  }
};
export default comparePlan;
