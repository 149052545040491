import { AGENT_LOGIN_ACTION } from './action';
import cookie from 'js-cookie';

const initialState = {
  isAgentLoggedIn: false,
  agent: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AGENT_LOGIN_ACTION:
      if (action.payload?.status === 404 || !action.payload?.api_code) {
        return {
          ...state,
          isAgentLoggedIn: false,
          agent: {
            id: '',
            first_name: '',
            last_name: '',
            email: '',
          },
        };
      }
      cookie.set('agentEnteredCode', action.payload.api_code, {
        expires: 2,
      });

      return {
        ...state,
        isAgentLoggedIn: true,
        agent: {
          id: action.payload.api_code,
          firstName: action.payload.first_name,
          lastName: action.payload.last_name,
          email: action.payload.email,
        },
      };
    default:
      return { ...state };
  }
};

export default reducer;
