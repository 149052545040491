/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import AgilityTypography from '../Typography';
import { useTheme } from '@material-ui/core/styles';
import dompurify from 'isomorphic-dompurify';
import Container from '@material-ui/core/Container';
import i18n from 'i18next';
const sanitizer = dompurify.sanitize;

const AgilityBanner = props => {
  const theme = useTheme();
  return (
    <section className={`hero-image-background`}>
      <div
        className={`hero-image ${props.type}`}
        css={css`
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url('${theme.images.banner}');
          
          position: relative;
          overflow: hidden;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          font-size: 1rem;
          padding: 150px 0px;

          @media (max-width: ${theme.breakpoints.values.md}px) {
            background-image: url('${
              theme.images.bannerMobile || theme.images.banner
            }');
          }

          &.inner-banner {
            padding: 100px 0px;
        `}
      >
        <Container disableGutters={true}>
          <div
            className="hero-text"
            css={css`
              text-align: center;
              position: relative;
              color: ${theme.palette.miscellaneous.bannerContrastText ??
              theme.palette.primary.contrastText};
              padding: 0 30px;
              .MuiButton-root.btn {
                padding-left: 25px;
                padding-right: 25px;
                margin: 0px auto;
              }
            `}
          >
            <AgilityTypography
              variant="h1"
              component="h1"
              className="hero-text__banner-text"
              dangerouslySetInnerHTML={{
                __html: `<span>${sanitizer(
                  i18n.t('compareplan.banner.text')
                )}</span>`,
              }}
            ></AgilityTypography>
            <AgilityTypography
              variant="body2"
              component="p"
              className="hero-text__banner-description"
              css={css`
                margin: 15px 0;
                color: ${theme.palette.grey['700']};
                @media (min-width: ${theme.breakpoints.values.sm}px) {
                  margin: 23px 0;
                }
              `}
              dangerouslySetInnerHTML={{
                __html: `<span>${sanitizer(
                  i18n.t('compareplan.banner.description')
                )}</span>`,
              }}
            ></AgilityTypography>
            {i18n.exists('compareplan.banner.terms') && (
              <AgilityTypography
                variant="subtitle2"
                component="h6"
                className="hero-text__banner-terms"
                css={css`
                  margin: 15px 0;
                  color: ${theme.palette.grey['600']};
                  @media (min-width: ${theme.breakpoints.values.sm}px) {
                    margin: 23px 0;
                  }
                `}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(i18n.t('compareplan.banner.terms')),
                }}
              ></AgilityTypography>
            )}
            {props.children}
          </div>
        </Container>
      </div>
    </section>
  );
};

export default AgilityBanner;
