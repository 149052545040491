import { connect } from 'react-redux';
import ComparePlan from './ComparePlan';
import {
  fetchOfferList,
  setPlanFilters,
  clearLowerSectionComparePlan,
  postSignupDetails,
  setIdentifierSelectionAction,
  setPlanSelectionAction,
  putSignupDetails,
  getSignupDetails,
  getOfferAvailability,
  fetchDistributors,
  getLocation,
} from './action';
import { validateAgentLogin } from 'App/pages/AgentLogin/action';
import { triggerEmailSetter } from 'App/modules/onBoarding/action';
import { triggerHideLeavePopup } from 'App/pages/UserSignup/actions';

const mapStateToProps = (state, props) => {
  const { offers = {} } = state.comparePlan;
  return {
    isCovau: state.app.brand.isCovau,
    isNBE: state.app.brand.isNBE,
    offerList: offers,
    planFilterData: state.comparePlan.planFilter,
    selectedPlans: state.comparePlan.selectedPlans,
    siteIdentifierList: state.comparePlan.siteIdentifierList,
    productList: state.comparePlan.productList,
    selectedSiteIdentifier: state.comparePlan.selectedSiteIdentifier,
    signupAPIResponse: state.comparePlan.signupAPIResponse,
    allPlans: state.comparePlan.offers,
    priceFilter: state.comparePlan.priceFilter,
    isEmbeddedNetwork: state.app.customConfig.isEmbeddedNetwork,
    saveProgressTrigger: state.onBoarding.saveProgressTrigger,
    progressData: state.onBoarding.progressData,
    postcodeOffers: state.comparePlan.postcodeOffers,
    agent: state.pages.login,
    offers: offers,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setPlanFilters: data => dispatch(setPlanFilters(data)),
    clearLowerSectionComparePlan: () =>
      dispatch(clearLowerSectionComparePlan()),
    postSignupDetails: (data, saveProgress) =>
      dispatch(postSignupDetails(data, saveProgress)),
    setPlanSelectionAction: data => dispatch(setPlanSelectionAction(data)),
    setIdentifierSelectionAction: data =>
      dispatch(setIdentifierSelectionAction(data)),
    resetCurrentStep: () => dispatch({ type: 'RESET_CURRENT_STEP' }),
    putSignupDetails: data => dispatch(putSignupDetails(data)),
    getSignupDetails: (id, accntId) => dispatch(getSignupDetails(id, accntId)),
    triggerEmailSetter: () => dispatch(triggerEmailSetter()),
    triggerHideLeavePopup: () => dispatch(triggerHideLeavePopup()),
    getOfferAvailability: data => dispatch(getOfferAvailability(data)),
    validateAgentLogin: data => dispatch(validateAgentLogin(data)),
    fetchDistributors: postcode => fetchDistributors(postcode),
    getLocation: () => getLocation(),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ComparePlan);
