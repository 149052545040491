import {
  isNotEmpty,
  isValidEmail,
  isValidMobilePhoneNumberPrefix,
  isValidMobilePhoneNumberLength,
  isValidHomePhoneNumberPrefix,
  isValidHomePhoneNumberLength,
  isValidNameWithSpaceAndApostrophe,
} from 'App/utils/validationHelper';

export const changeDate = 'change date';

export const maxDob = new Date(
  new Date().getFullYear() - 18,
  new Date().getMonth(),
  new Date().getDate()
);

export const secondaryContactArrayField = [
  'secondaryTitle',
  'secondaryFirstName',
  'secondaryMiddleName',
  'secondaryLastName',
  'secondaryEmail',
  'secondaryDob',
  'secondaryMobileNumber',
  'secondaryHomeNumber',
];

export const defaultFieldsState = {
  primaryId: null, //id holder
  primaryTitle: '',
  primaryFirstName: '',
  primaryMiddleName: '',
  primaryLastName: '',
  primaryEmail: '',
  primaryEmailId: null, //id holder
  primaryDob: null,
  primaryMobileNumber: '',
  primaryMobileNumberId: null, //id holder
  primaryHomeNumber: '',
  primaryHomeNumberId: null, //id holder

  hasAdditionalContacts: null,
  hearAboutUs: '',

  secondaryId: null, //id holder
  secondaryTitle: '',
  secondaryFirstName: '',
  secondaryMiddleName: '',
  secondaryLastName: '',
  secondaryEmail: '',
  secondaryEmailId: null, //id holder
  secondaryDob: null,
  secondaryMobileNumber: '',
  secondaryMobileNumberId: null, //id holder
  secondaryHomeNumber: '',
  secondaryHomeNumberId: null, //id holder

  contactPreference: '',
};

export const defaultErrorsState = {};

export const defaultTouchedState = {
  primaryTitle: false,
  primaryFirstName: false,
  primaryMiddleName: false,
  primaryLastName: false,
  primaryEmail: false,
  primaryDob: false,
  primaryMobileNumber: false,
  primaryHomeNumber: false,

  hasAdditionalContacts: false,
  hearAboutUs: false,

  secondaryTitle: false,
  secondaryFirstName: false,
  secondaryMiddleName: false,
  secondaryLastName: false,
  secondaryEmail: false,
  secondaryDob: false,
  secondaryMobileNumber: false,
  secondaryHomeNumber: false,

  contactPreference: false,
};

const contactConditions = {
  title: [],
  firstName: [
    {
      condition: isValidNameWithSpaceAndApostrophe,
      errorText: 'yourDetail.errorMessage.validFirstName',
    },
  ],
  middleName: [
    {
      condition: val => !val || isValidNameWithSpaceAndApostrophe(val),
      errorText: 'yourDetail.errorMessage.validMiddleName',
    },
  ],
  lastName: [
    {
      condition: isValidNameWithSpaceAndApostrophe,
      errorText: 'yourDetail.errorMessage.validLastName',
    },
  ],
  mobilePhone: [
    {
      condition: isValidMobilePhoneNumberPrefix,
      errorText: 'yourDetail.errorMessage.validMobilePhoneNumberPrefix',
    },
    {
      condition: isValidMobilePhoneNumberLength,
      errorText: 'yourDetail.errorMessage.validMobilePhoneNumberLength',
    },
  ],
  homePhone: [
    {
      condition: isValidHomePhoneNumberPrefix,
      errorText: 'yourDetail.errorMessage.validHomePhoneNumberPrefix',
    },
    {
      condition: isValidHomePhoneNumberLength,
      errorText: 'yourDetail.errorMessage.validHomePhoneNumberLength',
    },
  ],
  email: [
    {
      condition: isValidEmail,
      errorText: 'yourProperty.errorMessage.validEmail',
    },
  ],
  dob: [],
};

export const fieldValidators = (customConfig, isResident) => ({
  defaultChecker: isNotEmpty,
  fields: {
    primaryTitle: {
      preCondition: '',
      defaultErrorText: 'yourDetail.errorMessage.requiredTitle',
      conditions: contactConditions.title,
    },
    primaryFirstName: {
      preCondition: '',
      defaultErrorText: 'yourDetail.errorMessage.requiredFirstName',
      conditions: contactConditions.firstName,
    },
    primaryMiddleName: {
      preCondition: '',
      isOptional: true,
      defaultErrorText: 'yourDetail.errorMessage.validMiddleName',
      conditions: contactConditions.middleName,
    },
    primaryLastName: {
      preCondition: '',
      defaultErrorText: 'yourDetail.errorMessage.requiredLastName',
      conditions: contactConditions.lastName,
    },
    primaryEmail: {
      preCondition: '',
      defaultErrorText: 'yourDetail.errorMessage.requiredEmail',
      conditions: contactConditions.email,
    },
    primaryDob: {
      preCondition: '',
      defaultErrorText: 'yourDetail.errorMessage.requiredDob',
      conditions: contactConditions.dob,
    },
    primaryMobileNumber: {
      preCondition: '',
      defaultErrorText: 'yourDetail.errorMessage.requiredPhoneNumber',
      conditions: contactConditions.mobilePhone,
    },
    primaryHomeNumber: {
      preCondition: '',
      defaultErrorText: 'yourDetail.errorMessage.requiredPhoneNumber',
      conditions: contactConditions.homePhone,
      isOptional: true,
    },
    contactPreference: {
      preCondition: '',
      defaultErrorText: 'yourDetail.errorMessage.requiredPreferContact',
      conditions: [],
      isOptional: !customConfig.showPreferContact,
    },
    hasAdditionalContacts: {
      preCondition: '',
      isOptional: true,
      defaultErrorText: '',
      conditions: [
        {
          condition: val => {
            return (
              customConfig.disableSecondaryContact ||
              (!customConfig.disableSecondaryContact && val !== null)
            );
          },
          errorText: 'yourDetail.errorMessage.requiredIsSecondaryOption',
        },
      ],
    },
    secondaryTitle: {
      preCondition: 'hasAdditionalContacts',
      defaultErrorText: 'yourDetail.errorMessage.requiredTitle',
      conditions: contactConditions.title,
    },
    secondaryFirstName: {
      preCondition: 'hasAdditionalContacts',
      defaultErrorText: 'yourDetail.errorMessage.requiredFirstName',
      conditions: contactConditions.firstName,
    },
    secondaryLastName: {
      preCondition: 'hasAdditionalContacts',
      defaultErrorText: 'yourDetail.errorMessage.requiredLastName',
      conditions: contactConditions.lastName,
    },
    secondaryMiddleName: {
      preCondition: 'hasAdditionalContacts',
      defaultErrorText: '',
      isOptional: true,
      conditions: contactConditions.middleName,
    },
    secondaryEmail: {
      preCondition: 'hasAdditionalContacts',
      defaultErrorText: 'yourDetail.errorMessage.requiredEmail',
      conditions: contactConditions.email,
    },
    secondaryDob: {
      preCondition: 'hasAdditionalContacts',
      defaultErrorText: 'yourDetail.errorMessage.requiredDob',
      conditions: contactConditions.dob,
    },
    secondaryMobileNumber: {
      preCondition: 'hasAdditionalContacts',
      defaultErrorText: 'yourDetail.errorMessage.requiredPhoneNumber',
      conditions: contactConditions.mobilePhone,
      isOptional: true,
    },
    secondaryHomeNumber: {
      preCondition: 'hasAdditionalContacts',
      defaultErrorText: 'yourDetail.errorMessage.requiredPhoneNumber',
      conditions: contactConditions.homePhone,
      isOptional: true,
    },
    hearAboutUs: {
      preCondition: '',
      defaultErrorText: 'yourDetail.errorMessage.requiredhearAboutUs',
      conditions: [],
      isOptional: !customConfig.showHearAboutUs,
    },
  },
});

// Prepare request to go to API
export const updateYourDetailApiData = fields => {
  const apiData = {
    referralSource: fields.hearAboutUs,
    contacts: [
      {
        contactPreference: fields.contactPreference,
        id: fields.primaryId,
        title: fields.primaryTitle,
        firstName: fields.primaryFirstName,
        middleName: fields.primaryMiddleName,
        lastName: fields.primaryLastName,
        dateOfBirth: fields.primaryDob,
        emailAddress: fields.primaryEmail,
        emailId: fields.primaryEmailId,
        isPrimary: 1,
        phones: [],
      },
    ],
    hasAdditionalContacts: fields.hasAdditionalContacts,
  };

  if (fields.primaryMobileNumber) {
    apiData.contacts[0].phones.push({
      isPrimary: 1,
      type: 'Mobile',
      phoneNumber: fields.primaryMobileNumber,
      id: fields.primaryMobileNumberId || undefined,
    });
  }

  if (fields.primaryHomeNumber) {
    apiData.contacts[0].phones.push({
      isPrimary: fields.primaryMobileNumber ? 0 : 1,
      type: 'Landline',
      phoneNumber: fields.primaryHomeNumber,
      id: fields.primaryHomeNumberId || undefined,
    });
  }

  if (fields.hasAdditionalContacts) {
    apiData.contacts = [
      ...apiData.contacts,
      {
        id: fields.secondaryId,
        title: fields.secondaryTitle,
        firstName: fields.secondaryFirstName,
        middleName: fields.secondaryMiddleName,
        lastName: fields.secondaryLastName,
        dateOfBirth: fields.secondaryDob,
        emailAddress: fields.secondaryEmail,
        emailId: fields.secondaryEmailId,
        isPrimary: 0,
        phones: [],
      },
    ];

    if (fields.secondaryMobileNumber) {
      apiData.contacts[1].phones.push({
        isPrimary: 1,
        type: 'Mobile',
        phoneNumber: fields.secondaryMobileNumber,
        id: fields.secondaryMobileNumberId || undefined,
      });
    }

    if (fields.secondaryHomeNumber) {
      apiData.contacts[1].phones.push({
        isPrimary: fields.secondaryMobileNumber ? 0 : 1,
        type: 'Landline',
        phoneNumber: fields.secondaryHomeNumber,
        id: fields.secondaryHomeNumberId || undefined,
      });
    }
  }

  return apiData;
};
