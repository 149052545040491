import { combineReducers } from 'redux';
import app from './app/reducer';
import pages from './app/pages/reducer';
import comparePlan from './app/pages/ComparePlans/reducer';
import onBoarding from './app/modules/onBoarding/reducer';
import resumeSignup from './app/pages/ResumeSignup/reducer';

const reducer = combineReducers({
  app,
  pages,
  comparePlan,
  onBoarding,
  resumeSignup,
});

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR') {
    state = undefined;
  }
  return reducer(state, action);
};

export default rootReducer;
