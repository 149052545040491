import i18n from 'i18next';
import { RESUME_SIGNUP_VALIDATION_API } from 'App/utils/constants';

export const ACCOUNT_UNLOCKED = 'ACCOUNT_UNLOCKED';
export const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID';

export const validateSignupResumption = data => dispatch => {
  return new Promise((resolve, reject) => {
    window.Api.post(RESUME_SIGNUP_VALIDATION_API, data)
      .then(result => {
        dispatch({
          type: ACCOUNT_UNLOCKED,
          payload: result,
        });
        return resolve(result);
      })
      .catch(error => {
        dispatch({
          type: ACCOUNT_UNLOCKED,
          payload: error,
        });
        return reject({
          message: i18n.t('500.error.message'),
          status: error.status,
        });
      });
  });
};
