import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AgilityFormGroup from '../FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';

const AgilityAutocomplete = props => {
  const { isError, helperText, ...otherProps } = props;
  return (
    <AgilityFormGroup>
      <Autocomplete {...otherProps} iserror={isError} />

      {isError && (
        <FormHelperText className="MuiFormHelperText-contained Mui-error">
          {helperText}
        </FormHelperText>
      )}
    </AgilityFormGroup>
  );
};

export default AgilityAutocomplete;
