import React from 'react';
import i18n from 'i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Element } from 'react-scroll';
import { withSnackbar } from 'notistack';
import {
  PAYMENT_METHOD_TYPE_CARD,
  PAYMENT_METHOD_TYPE_ACCOUNT,
  PAYMENT_METHOD_TYPE_CHEQUE,
  SIGNUP_STEPS as signupSteps,
} from 'App/utils/constants';
import {
  AgilityCard,
  AgilityGrid,
  AgilityButton,
  AgilityRadioGroup,
  AgilityTypography,
} from 'Src/AgilityComponents';
import { getPaymentMethods } from 'App/utils/data';
import { isNullOrEmpty, validateFields } from 'App/utils/validationHelper';
import {
  customMask,
  maskCardDetails,
  dataToMask,
  isEmpty,
  scrollToSection,
} from 'App/utils/helper';
import {
  directDebitIsRequired,
  showDirectDebitStep,
  enableCreditCardPayment,
} from 'App/customConfig';
import CreditCardForm from '../CreditCard/CreditCardForm';
import BankAccountForm from '../BankAccount/BankAccountForm';
import PaymentCard from '../PaymentCard/PaymentCard';
import { GET_CREDITCARD_FRAME_RESPONSE } from 'App/modules/onBoarding/action';
import { bankFormValidators, defaultState } from './utils';
import * as Sentry from '@sentry/react';

class PaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
    this.validators = bankFormValidators;

    this.state.directDebitIsRequired = directDebitIsRequired;
    this.state.showDirectDebitStep = showDirectDebitStep;
  }

  updatePaymentState() {
    const paymentDetails = this.props.paymentDetails;

    if (!paymentDetails) {
      return;
    }

    const {
      paymentMethod: newPaymentMethod,
      bankAccount: newBankAccount,
      creditCard: newCreditCard,
    } = paymentDetails;

    const newState = {
      ...this.state,
      paymentMethod: newPaymentMethod,
      isCardAdded: false,
      isAccountAdded: false,
      creditCardLoading: false,
    };

    if (!isEmpty(newCreditCard)) {
      newState.creditCardDetails = {
        ...newState.creditCardDetails,
        cardholderName: newCreditCard.cardholderName,
        cardName: newCreditCard.cardName,
        cardNumber: newCreditCard.cardNumber,
        cardIcon: newCreditCard.cardIcon ?? `cc-${newCreditCard.cardType}`,
      };
      newState.isCardAdded = true;
    } else {
      newState.creditCardDetails = { ...defaultState.creditCardDetails };
    }

    if (!isEmpty(newBankAccount)) {
      newState.bankAccountFields = {
        ...newState.bankAccountFields,
        id: newBankAccount.id,
        bankName: newBankAccount.bankName,
        bankBranchName: newBankAccount.bankStateBranchNumber,
        bankAccountNumber: newBankAccount.accountNumber,
        bankAccountHolderName: newBankAccount.accountName,
        allowProviderToDirectDebit: newBankAccount.authorization,
        directDebitTNC: newBankAccount.signingAuthority,
      };
      newState.isAccountAdded = true;
    } else {
      newState.bankAccountFields = { ...defaultState.bankAccountFields };
    }

    this.setState(newState);
  }

  handlePaymentMethodChange = async event => {
    const val = event.target.value;

    this.setState({
      paymentMethod: this.state.paymentMethod === val ? '' : val,
      error: '',
    });

    if (this.state.isAccountAdded) {
      this.removeBankDetails();
    }

    // Remove CC details from method switch too :)
    if (this.state.isCardAdded) {
      this.removeCardDetails();
    }
  };

  handleBankAccountMethodChange = (value, name) => {
    const currentFields = { ...this.state.bankAccountFields };
    const currentTouched = { ...this.state.bankAccountFieldsTouched };
    currentFields[name] = value;
    currentTouched[name] = true;
    this.setState(
      {
        bankAccountFields: currentFields,
        bankAccountFieldsTouched: currentTouched,
      },
      () => {
        this.validateBankAccountForm();
      }
    );
  };

  requestCreditCardFrame = async () => {
    try {
      await this.callPaymentGateway();
    } catch (err) {
      Sentry.withScope(function (scope) {
        scope.setLevel(Sentry.Severity.Critical);
        Sentry.captureException(err);
      });
      if (err?.response?.status !== 404) {
        this.props.enqueueSnackbar(i18n.t('creditCardDetails.GET.errorMsg'), {
          variant: 'error',
        });
      }
    }
  };

  setupCreditCardForm = async () => {
    const creditCardFrame =
      this.props.signupAPIResponse?.onBoarding?.creditCardIframe?.result
        ?.vendorUrl;
    const showCreditCard =
      this.state?.paymentMethod === PAYMENT_METHOD_TYPE_CARD &&
      !this.state.isCardAdded;

    if (showCreditCard && !creditCardFrame) {
      await this.requestCreditCardFrame();
    } else {
      this.resetCreditCardGatewayUrl();
    }
  };

  requestCreditCardDetails = async (id, acctId) => {
    this.setState({ loading: true });
    try {
      await this.props.getSignupDetails(id, acctId);
    } catch (err) {
      if (err?.response?.status !== 404) {
        this.props.enqueueSnackbar(i18n.t('creditCardDetails.GET.errorMsg'), {
          variant: 'error',
        });
      }
    }
    this.setState({ loading: false });
    this.updatePaymentState();
  };

  nextForm = e => {
    const isValid = this.validateForm();
    if (!directDebitIsRequired && !this.state.paymentMethod && isValid) {
      const data = { ...this.props.signupAPIResponse };
      data.currentPage = signupSteps.paymentDetails;
      data.paymentDetails = null; // remove payment details
      return this.props.onNavigate('next', data, false);
    }
    if (isValid) {
      if (
        this.state.paymentMethod &&
        (this.state.isAccountAdded || this.state.isCardAdded)
      ) {
        const data = this.getPaymentDetail();
        data.currentPage = signupSteps.paymentDetails;
        this.props.onNavigate('next', data, false);
      }
    } else {
      if (document.getElementById('payment-card')) {
        scrollToSection('payment-card');
      }
    }
  };

  cleanNumbers = input => {
    if (!input) return;

    return input.replace(/-/g, '');
  };

  getPaymentDetail = () => {
    const data = { ...this.props.signupAPIResponse };

    if (!data.paymentDetails) data.paymentDetails = {};
    data.paymentDetails.paymentMethod = this.state.paymentMethod;

    if (
      this.state.paymentMethod === PAYMENT_METHOD_TYPE_ACCOUNT &&
      this.state.isAccountAdded
    ) {
      data.paymentDetails.bankAccount = {
        id: this.state.bankAccountFields.id,
        bankName: this.state.bankAccountFields.bankName,
        accountName: this.state.bankAccountFields.bankAccountHolderName,
        accountNumber: this.cleanNumbers(
          this.state.bankAccountFields.bankAccountNumber
        ),
        bankStateBranchNumber: this.cleanNumbers(
          this.state.bankAccountFields.bankBranchName
        ),
        authorization: this.state.bankAccountFields.allowProviderToDirectDebit,
        acceptedTermsAndConditions: this.state.bankAccountFields.directDebitTNC,
      };
    } else if (this.state.paymentMethod === PAYMENT_METHOD_TYPE_CARD) {
      data.paymentDetails.creditCard = this.props.paymentDetails?.creditCard;
    }
    if (!this.state.isCardAdded) {
      data.paymentDetails.creditCard = null;
    }
    if (!this.state.isAccountAdded) {
      data.paymentDetails.bankAccount = null;
    }
    return data;
  };

  validateForm = () => {
    let formIsValid = true;
    if (!this.state.directDebitIsRequired && !this.state.paymentMethod) {
      return formIsValid;
    }
    if (!this.state.paymentMethod) {
      formIsValid = false;
      this.setState({
        error: i18n.t('paymentMethod.errorMessage.method'),
      });
    } else if (
      this.state.paymentMethod === PAYMENT_METHOD_TYPE_CARD &&
      !this.state.isCardAdded
    ) {
      this.props.enqueueSnackbar(i18n.t('paymentMethod.cardNotAdd.errorMsg'), {
        variant: 'error',
      });
      formIsValid = false;
    } else if (
      this.state.paymentMethod === PAYMENT_METHOD_TYPE_ACCOUNT &&
      !this.state.isAccountAdded
    ) {
      formIsValid = false;
      const currentTouched = { ...this.state.bankAccountFieldsTouched };
      for (const key in currentTouched) {
        currentTouched[key] = true;
      }
      this.setState(
        {
          bankAccountFieldsTouched: currentTouched,
          loading: true,
        },
        async () => {
          formIsValid = await this.validateBankAccountForm();
          if (formIsValid) {
            this.setState({
              loading: false,
              isAccountAdded: true,
            });
          } else {
            this.setState({ loading: false });
          }
        }
      );
    }
    return formIsValid;
  };

  validateBankAccountForm = () => {
    let formIsValid = true;
    const touched = { ...this.state.bankAccountFieldsTouched };
    const fields = { ...this.state.bankAccountFields };
    const validationFormData = validateFields(this.validators, fields, touched);
    if (touched.allowProviderToDirectDebit) {
      validationFormData.errorMessages.allowProviderToDirectDebit = false;
      if (!fields.allowProviderToDirectDebit) {
        formIsValid = false;
        validationFormData.errorMessages.allowProviderToDirectDebit = true;
      }
    }
    if (touched.directDebitTNC) {
      validationFormData.errorMessages.directDebitTNC = false;
      if (!fields.directDebitTNC) {
        formIsValid = false;
        validationFormData.errorMessages.directDebitTNC = true;
      }
    }
    this.setState({
      bankAccountFieldsErrors: validationFormData.errorMessages,
    });
    return formIsValid && !validationFormData.invalidField;
  };

  callPaymentGateway = async () => {
    const currentData = this.getPaymentDetail();
    currentData.currentPage = signupSteps.paymentMethod;
    this.props.onNavigate('progress', currentData, false, false);

    const { id, contacts } = this.props.signupAPIResponse;
    const cardVendor = process.env.REACT_APP_CREDIT_CARD_PROVIDER ?? null;
    const brandCode = process.env.REACT_APP_BRAND_CODE ?? null;
    const primaryContact = contacts?.find(c => c.isPrimary);

    if (!cardVendor || !brandCode) {
      this.props.enqueueSnackbar(i18n.t('paymentMethod.vendor.errorMsg'), {
        variant: 'error',
      });
      return;
    }

    /* DEBUG */
    if (process.env.NODE_ENV !== 'production') {
      window.onbeforeunload = null;
    }

    const data = {
      signUpId: id,
      brand: brandCode,
      redirectURL: null,
      customerDetails: {
        accountNumber: id ?? null,
        lastOrBusinessName: primaryContact?.lastName ?? null,
        firstName: primaryContact?.firstName ?? null,
      },
    };

    try {
      await this.props.getCreditCardFrameUrl(id, cardVendor, data);
    } catch (e) {
      this.props.enqueueSnackbar(
        e?.message ?? i18n.t('paymentMethod.vendor.errorMsg'),
        {
          variant: 'error',
        }
      );
    }
  };

  removeCardDetails = () => {
    this.setState(
      {
        isCardAdded: false,
        creditCardDetails: { ...defaultState.creditCardDetails },
      },
      () => {
        const currentData = this.getPaymentDetail();
        currentData.currentPage = signupSteps.paymentMethod;
        this.props.onNavigate('progress', currentData, false, false);
      }
    );
  };

  removeBankDetails = () => {
    this.setState(
      {
        paymentMethod: '',
        isAccountAdded: false,
        error: '',
        bankAccountFields: {
          ...defaultState.bankAccountFields,
          id: this.state.bankAccountFields?.id ?? null,
        },
        bankAccountFieldsTouched: { ...defaultState.bankAccountFieldsTouched },
        bankAccountFieldsErrors: { ...defaultState.bankAccountFieldsErrors },
      },
      () => {
        const currentData = this.getPaymentDetail();
        currentData.currentPage = signupSteps.paymentMethod;
        this.props.onNavigate('progress', currentData, false, false);
      }
    );
  };

  onBackClick = () => {
    this.resetValidation();
    this.props.onNavigate('back');
  };

  resetValidation = () => {
    const touched = { ...this.state.bankAccountFieldsTouched };
    const errors = touched;
    for (const key in touched) {
      touched[key] = false;
      errors[key] = '';
    }
    this.setState({
      error: '',
      bankAccountFieldsTouched: touched,
      bankAccountFieldsErrors: errors,
    });
  };

  getBankAccountNumber = (bankNumber, mask) => {
    const value = this.cleanNumbers(bankNumber);
    if (isNullOrEmpty(value)) {
      return '';
    }
    const maskArray = customMask(mask);
    return dataToMask(value, maskArray);
  };

  getPaymentList = () => {
    const allPlans = Object.values(this.props.selectedPlans);
    const referencePlans = allPlans.filter(x => x?.directDebitOnly === 0);
    return getPaymentMethods(
      allPlans.length === referencePlans.length,
      this.props.customConfig.enableOneOffPayment,
      enableCreditCardPayment
    );
  };

  resetCreditCardGatewayUrl = () => {
    this.props.dispatch({
      type: GET_CREDITCARD_FRAME_RESPONSE,
      payload: null,
    });
  };

  handleCreditCardSaved = async () => {
    await this.requestCreditCardDetails(
      this.props.signupAPIResponse?.applicationId,
      this.props.signupAPIResponse?.id
    );
  };

  handleCreditCardDeclined = () => {
    this.resetCreditCardGatewayUrl();
    setTimeout(this.requestCreditCardFrame, 100);
  };

  componentDidMount() {
    if (showDirectDebitStep && enableCreditCardPayment) {
      if (this.props.paymentDetails) {
        this.requestCreditCardDetails(
          this.props.signupAPIResponse?.applicationId,
          this.props.signupAPIResponse?.id
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isActive &&
      prevProps.saveProgressTrigger !== this.props.saveProgressTrigger
    ) {
      const data = this.getPaymentDetail();
      data.currentPage = signupSteps.paymentMethod;
      this.props.onNavigate('progress', data, true);
    }

    if (this.props.paymentDetails && !prevProps.paymentDetails) {
      this.updatePaymentState();
    }

    if (
      prevProps.isActive !== this.props.isActive &&
      this.props.isActive === false
    ) {
      this.resetValidation();
    }

    if (prevState.paymentMethod !== this.state.paymentMethod) {
      this.setupCreditCardForm();
    }
  }

  hideNextButtonWhileIFrameIsOpen() {
    if (this.state.paymentMethod !== PAYMENT_METHOD_TYPE_CARD) {
      return true;
    }

    return this.state.isCardAdded;
  }

  render() {
    return (
      <AgilityCard
        className={`steps-wrapper ${this.props.className}`}
        id={this.props.id}
      >
        <Element name="payment-card">
          <AgilityTypography variant="h4" component="h4" className="mb-2">
            {this.state.directDebitIsRequired
              ? i18n.t('paymentMethod.text')
              : i18n.t('paymentMethod.textOpt')}
          </AgilityTypography>
          <form autoComplete="off" noValidate data-test-id="paymentMethodForm">
            <AgilityGrid container>
              <AgilityGrid item xs={12} sm={12}>
                <div className="question-wrapper mt-0">
                  <AgilityTypography
                    variant="body2"
                    component="p"
                    tooltipText={i18n.t('paymentMethod.debitDebit.toolTip')}
                    showInfo={true}
                  >
                    {i18n.t('paymentMethod.debitDebit.text')}
                  </AgilityTypography>
                  <AgilityRadioGroup
                    options={this.getPaymentList()}
                    data-test-id="paymentMethods"
                    row
                    onChange={this.handlePaymentMethodChange}
                    onClick={this.handlePaymentMethodChange}
                    value={this.state.paymentMethod || ''}
                    disabled={!this.props.isActive}
                    isError={this.state.error ? true : false}
                    helperText={this.state.error ? this.state.error : ''}
                    defaultIcon={process.env.REACT_APP_BRAND_CODE === 'GEE'}
                  />
                  {this.state?.paymentMethod === PAYMENT_METHOD_TYPE_CARD &&
                    !this.state.isCardAdded && (
                      <CreditCardForm
                        data-test-id="add-card-form"
                        onError={this.handleCreditCardDeclined}
                        onSuccess={this.handleCreditCardSaved}
                      />
                    )}
                  {this.state.paymentMethod === PAYMENT_METHOD_TYPE_CARD &&
                    this.state.isCardAdded &&
                    this.state.creditCardDetails &&
                    (this.state.creditCardLoading ? (
                      <div className="loader-section credit-card-loader">
                        <CircularProgress
                          color="primary"
                          variant="indeterminate"
                          className="loader"
                          thickness={3}
                          size={30}
                          data-test-id="loading-progress-id"
                        />
                      </div>
                    ) : (
                      <PaymentCard
                        data-test-id="credit-card"
                        onConfirm={this.removeCardDetails}
                        paymentCardInfo={{
                          cardName: i18n.t('paymentMethod.card.title'),
                          cardholderName:
                            this.state.creditCardDetails.cardholderName,
                          cardNumber: maskCardDetails(
                            this.state.creditCardDetails.cardNumber
                          ),
                          cardIcon: this.state.creditCardDetails.cardIcon,
                        }}
                        className="credit-card"
                      />
                    ))}
                  {this.state.paymentMethod === PAYMENT_METHOD_TYPE_ACCOUNT && (
                    <React.Fragment>
                      <AgilityTypography
                        variant="body2"
                        component="p"
                        className="desc"
                      >
                        {i18n.t('bankAccount.supportText.placeholder')}
                      </AgilityTypography>

                      {!this.state.isAccountAdded && (
                        <BankAccountForm
                          data-test-id="direct-debit-form"
                          isActive={this.props.isActive}
                          bankAccountFields={this.state.bankAccountFields}
                          bankAccountFieldsErrors={
                            this.state.bankAccountFieldsErrors
                          }
                          handleBankAccountMethodChange={
                            this.handleBankAccountMethodChange
                          }
                        />
                      )}
                      {this.state.isAccountAdded && (
                        <PaymentCard
                          data-test-id="direct-debit-card"
                          onConfirm={this.removeBankDetails}
                          paymentCardInfo={{
                            cardName: i18n.t('paymentMethod.bank.title'),
                            cardholderName:
                              this.state.bankAccountFields
                                .bankAccountHolderName,
                            cardNumber: this.getBankAccountNumber(
                              this.state.bankAccountFields.bankAccountNumber,
                              process.env.REACT_APP_BANK_ACCOUNT_VALID_FORMAT
                            ),
                            bankIcon: '',
                            cardIcon: ['fas', 'hand-holding-usd'],
                            bankBsb: this.getBankAccountNumber(
                              this.state.bankAccountFields.bankBranchName,
                              process.env.REACT_APP_BSB_VALID_FORMAT
                            ),
                          }}
                          className="bank-account"
                        />
                      )}
                    </React.Fragment>
                  )}
                  {this.state.paymentMethod === PAYMENT_METHOD_TYPE_CHEQUE && (
                    <AgilityTypography
                      variant="subtitle1"
                      component="p"
                      className="desc"
                    >
                      {i18n.t('paymentMethod.manualPayment.description')}
                    </AgilityTypography>
                  )}
                </div>
              </AgilityGrid>
            </AgilityGrid>
            {this.hideNextButtonWhileIFrameIsOpen() && (
              <div className="steps-footer">
                <AgilityButton
                  color="primary"
                  data-test-id="backButton"
                  disabled={!this.props.isActive}
                  onClick={this.onBackClick}
                  label={i18n.t('signup.button.back')}
                  className="push"
                />
                <AgilityButton
                  variant="contained"
                  color="primary"
                  type="primary"
                  disabled={!this.props.isActive}
                  loading={this.state.loading || this.props.nextLoading}
                  label={i18n.t('signup.button.next')}
                  data-test-id="nextButton"
                  onClick={this.nextForm}
                />
              </div>
            )}
          </form>
        </Element>
      </AgilityCard>
    );
  }
}

export { PaymentMethod };

export default withSnackbar(PaymentMethod);
