import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { array, string, bool } from 'prop-types';
import i18n from 'i18next';
import {
  AgilityExpansionPanel,
  AgilityExpansionPanelSummary,
  AgilityExpansionPanelDetails,
  AgilityTypography,
} from 'Src/AgilityComponents';
import RateContent from './RateContent';
import { isEmpty } from 'App/utils/helper';
import { useTheme } from '@material-ui/core/styles';
import dompurify from 'dompurify';
const sanitizer = dompurify.sanitize;

const PlanRates = props => {
  const { rates, feedin, isExpandedRates, onRatesClick, selectedGreenpower } =
    props;
  const theme = useTheme();
  const [expandedRates, setExpandedRates] = useState(isExpandedRates);

  useEffect(() => {
    setExpandedRates(isExpandedRates);
  }, [isExpandedRates]);

  const onExpansionPanelClick = (e, expanded) => {
    typeof onRatesClick === 'function'
      ? onRatesClick(e, expanded)
      : setExpandedRates(!expandedRates);
  };

  return (
    <React.Fragment>
      {!isEmpty(rates) && (
        <div className="rates__section mb-0">
          <AgilityExpansionPanel
            expanded={expandedRates}
            onChange={onExpansionPanelClick}
          >
            <AgilityExpansionPanelSummary
              className="rates__section--summary"
              expandIcon={
                <FontAwesomeIcon
                  icon={theme.typography.icons.accordionExpand}
                />
              }
              data-test-id="rate-header-id"
            >
              {i18n.t('plan.showrate.label', {
                productType: props.productType.toLowerCase(),
              })}
            </AgilityExpansionPanelSummary>
            <AgilityExpansionPanelDetails>
              <div className="w-full">
                <AgilityTypography
                  variant="subtitle1"
                  component="p"
                  className="text-center"
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(
                      i18n.t('planrates.gst.message', {
                        interpolation: { escapeValue: false },
                      })
                    ),
                  }}
                />
                <RateContent
                  rateData={rates}
                  feedinData={feedin}
                  selectedGreenpower={selectedGreenpower}
                  showRefHeading={true}
                  productType={props.productType.toLowerCase()}
                />
              </div>
            </AgilityExpansionPanelDetails>
          </AgilityExpansionPanel>
        </div>
      )}
    </React.Fragment>
  );
};
PlanRates.propTypes = {
  productType: string,
  rates: array,
  feedin: array,
  isExpandedRates: bool,
};
PlanRates.defaultProps = {
  rates: [],
  feedin: [],
  isExpandedRates: false,
};
export default PlanRates;
