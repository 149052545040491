import { REFERRAL_NAME } from './actions';

const initialState = {
  name: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REFERRAL_NAME:
      // Double check that our payload is a string. We don't want to set name to anything funny
      if (typeof action.payload !== 'string') {
        return { ...state };
      }
      return {
        ...state,
        name: action.payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
