import { connect } from 'react-redux';
import YourProperty from './YourProperty';
import { stateList } from 'App/tests/mock_data/listing_data';
import { getListForSelectField } from 'App/utils/helper';

const mapStateToProps = (state, props) => {
  return {
    stateList: getListForSelectField(stateList, true),
    coordinatorsList: state.onBoarding.coordinatorsList,
    signupAPIResponse: state.comparePlan.signupAPIResponse,
    moveInDatesList: state.onBoarding.moveInDatesList,
    productList: state.comparePlan.productList,
    customConfig: state.app.customConfig,
    selectedPlans: state.comparePlan.selectedPlans,
  };
};

export default connect(mapStateToProps, null)(YourProperty);
