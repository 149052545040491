import { ACCOUNT_UNLOCKED } from './actions';
import { APPLICATION_ID, ACCOUNT_ID } from 'App/utils/constants';

const initialState = {
  id: '',
  hash: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_UNLOCKED:
      if (action.payload?.status === 404 || action.payload?.status === 400) {
        return { ...state };
      }

      localStorage.setItem(ACCOUNT_ID, action.payload.id);
      localStorage.setItem(APPLICATION_ID, action.payload.hash);

      return {
        ...state,
        id: action.payload.id,
        hash: action.payload.hash,
        expiredContract: action.payload.expired_contract === '1',
      };
    default:
      return { ...state };
  }
};

export default reducer;
