/* eslint-disable jsx-a11y/no-static-element-interactions */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { t } from 'i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import { useTheme } from '@material-ui/core';
import SaveProgressDialog from 'App/modules/onBoarding/saveProgress';
import { showDebugInfoBar, linkToMainWebsite } from 'App/customConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DebugBar from './DebugBar';
import AgilityDialog from '../Dialog/AgilityDialog';
import AgilityLogo from '../Logo';
import { useLocation } from 'react-router-dom';

const saveProgressTargetIsEmail = false;

const AgilityHeader = ({ triggerHideLeavePopup, login }) => {
  const theme = useTheme();
  const location = useLocation();

  const [hasScrolled, setHasScrolled] = useState(window.scrollY > 0);
  const [showProgressDialog, setShowProgressDialog] = useState(false);
  const [showLinkSendDialog, setShowLinkSendDialog] = useState(false);
  const onSaveProgress = useCallback(() => {
    setShowProgressDialog(false);
    setShowLinkSendDialog(true);
  }, []);
  const onDismiss = useCallback(() => {
    setShowProgressDialog(false);
  }, []);

  const onOkay = useCallback(() => {
    setShowLinkSendDialog(false);
    triggerHideLeavePopup();
  }, [triggerHideLeavePopup]);

  const onLinkDialogDismiss = useCallback(() => {
    setShowLinkSendDialog(false);
  }, []);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 0) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  }, []);

  const isComparePage = useMemo(() => {
    return location.pathname === '/plans' || location.pathname === '/';
  }, [location.pathname]);

  const showHomeIcon = useMemo(() => {
    return (
      location.pathname.startsWith('/user-signup/') && login.isAgentLoggedIn
    );
  }, [location.pathname, login]);

  const backgroundColor = useMemo(() => {
    if (!isComparePage) {
      return theme.custom.AppBar.nonCompare.backgroundColor;
    }

    return hasScrolled && theme.custom.AppBar.scrolledBackgroundColor
      ? theme.custom.AppBar.scrolledBackgroundColor
      : theme.custom.AppBar.backgroundColor;
  }, [
    hasScrolled,
    isComparePage,
    theme.custom.AppBar.backgroundColor,
    theme.custom.AppBar.nonCompare.backgroundColor,
    theme.custom.AppBar.scrolledBackgroundColor,
  ]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <AppBar
      position="fixed"
      color="primary"
      className="header"
      style={{
        backgroundColor,
        boxShadow: theme.custom.AppBar.boxShadow,
        ...(!hasScrolled && isComparePage ? { boxShadow: 'none' } : null),
      }}
    >
      {showDebugInfoBar && <DebugBar />}
      <Toolbar
        style={{
          height: theme.custom.AppBar.height,
        }}
      >
        <Container className="logo__container">
          <Grid container alignItems="center" justifyContent={'space-between'}>
            <Grid item>
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                spacing={4}
              >
                <Grid item>
                  <AgilityLogo
                    logoImage={
                      isComparePage
                        ? theme.custom.AppBar.logoImage
                        : theme.custom.AppBar.nonCompare.logoImage
                    }
                    logoHeight={
                      isComparePage
                        ? theme.custom.AppBar.logoHeight
                        : theme.custom.AppBar.nonCompare.logoHeight
                    }
                  />
                </Grid>
                {showHomeIcon && (
                  <Grid item pl={3}>
                    <a href="/plans">
                      <FontAwesomeIcon icon={['fas', 'home']} size="lg" />
                    </a>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Box
                className="flex-grow"
                css={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                {login.isAgentLoggedIn ? (
                  <Box
                    className="save-btn__container"
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'user-circle']}
                      size="lg"
                      css={{
                        color: theme.palette.primary.main,
                        ...(isComparePage
                          ? theme.custom.AppBar.d2dStyles.compare.iconStyles
                          : theme.custom.AppBar.d2dStyles.nonCompare
                              .iconStyles),
                      }}
                    />
                    <Box
                      css={{
                        marginLeft: '8px',
                        marginTop: '1px',
                        ...(isComparePage
                          ? theme.custom.AppBar.d2dStyles.compare.textStyles
                          : theme.custom.AppBar.d2dStyles.nonCompare
                              .textStyles),
                      }}
                    >
                      {login.agent?.firstName}
                    </Box>
                  </Box>
                ) : (
                  <Typography>
                    {linkToMainWebsite && process.env.REACT_APP_WEBSITE_URL && (
                      <MuiLink
                        data-test-id="logo"
                        href={process.env.REACT_APP_WEBSITE_URL}
                        style={{
                          textTransform: 'uppercase',
                          fontSize: 16,
                          fontWeight: 600,
                          color: theme.custom.Common.textColor,
                        }}
                      >
                        Back to {process.env.REACT_APP_RETAILER_NAME}
                      </MuiLink>
                    )}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
      <SaveProgressDialog
        data-test-id="save-progress-dialog"
        open={showProgressDialog}
        onSaveProgress={onSaveProgress}
        onDismiss={onDismiss}
        isEmail={saveProgressTargetIsEmail}
      />
      <AgilityDialog
        data-test-id="link-sent-dialog"
        className="confirmation-dialog"
        open={showLinkSendDialog}
        okClick={onOkay}
        onDismiss={onLinkDialogDismiss}
        maxWidth="xs"
        title={t('linksent.heading.text')}
        okText={t('linksent.save.button')}
        titleAlign="center"
        contents={[
          {
            body: t('linksent.subheading.text', {
              action: saveProgressTargetIsEmail ? 'emailed' : 'sent via SMS',
            }),
            variant: 'subtitle1',
            element: 'p',
            class: 'mb-1',
          },
        ]}
      />
    </AppBar>
  );
};

export default AgilityHeader;
