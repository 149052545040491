import React from 'react';
import i18n from 'i18next';
import Link from '@material-ui/core/Link';

import {
  AgilityCheckbox,
  AgilityFormGroup,
  AgilityTypography,
} from 'Src/AgilityComponents';
import AgilityDialog from 'App/components/Dialog/AgilityDialog';
import { isEmpty } from 'App/utils/helper';

const CustomCheckbox = ({
  onChange,
  value = false,
  name = '',
  testId = '',
  labelText = '',
  labelTestId = '',
  labelSuffixLink = '',
  labelSuffixLinkText = '',
  hasDialog = false,
  hasError = false,
  dialogTitle = '',
  dialogContents = [],
  dialogTestId = '',
  dialogMaxWidth = 'md',
  dialogOkText = i18n.t('button.accept.text'),
}) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleOpenDialog = React.useCallback(() => setDialogOpen(true), []);
  const handleCloseDialog = React.useCallback(() => setDialogOpen(false), []);

  const handleChange = React.useCallback(
    event => {
      if (hasDialog && !value) {
        handleOpenDialog();
      } else {
        onChange(event?.target?.checked);
      }
    },
    [handleOpenDialog, hasDialog, value, onChange]
  );

  const handleLabelClick = React.useCallback(() => {
    if (hasDialog && !value) {
      handleOpenDialog();
    } else {
      onChange(!value);
    }
  }, [handleOpenDialog, hasDialog, value, onChange]);

  const handleDialogOkClick = React.useCallback(() => {
    handleCloseDialog();
    onChange(true);
  }, [handleCloseDialog, onChange]);

  return (
    <React.Fragment>
      {hasDialog && (
        <AgilityDialog
          data-test-id={dialogTestId}
          className="full-page-popup-sm"
          open={dialogOpen}
          okClick={handleDialogOkClick}
          onDismiss={handleCloseDialog}
          maxWidth={dialogMaxWidth}
          title={dialogTitle}
          okText={dialogOkText}
          contents={dialogContents}
        />
      )}
      <AgilityFormGroup row className="custom-checkbox-row">
        <AgilityCheckbox
          className="custom"
          color="primary"
          data-test-id={testId}
          checked={value}
          onChange={handleChange}
          name={name}
        />
        <AgilityTypography
          onClick={handleLabelClick}
          data-test-id={labelTestId}
          variant="subtitle1"
          component="label"
          align="left"
          className={`desc ${hasError && 'has-error'}`}
          dangerouslySetInnerHTML={{
            __html: labelText,
          }}
        />
        {!isEmpty(labelSuffixLink) && (
          <AgilityTypography
            variant="subtitle1"
            component="label"
            align="left"
            className={'desc'}
          >
            <Link href={labelSuffixLink} rel="noreferrer" target="_blank">
              {labelSuffixLinkText}
            </Link>
          </AgilityTypography>
        )}
      </AgilityFormGroup>
    </React.Fragment>
  );
};

export default CustomCheckbox;
