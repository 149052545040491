import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import UserSignup from './pages/UserSignup';
import AgentLogin from './pages/AgentLogin';
import ComparePlan from './pages/ComparePlans';
import ExpiredContract from './pages/ExpiredContract';
import Refer from './pages/Refer';
import FactSheet from './pages/FactSheet';
import AgilityHeader from './components/Header';
import AgilityFooter from './components/Footer/';
import TangoPartnerFooter from 'Src/BrandComponents/TangoPartnerFooter';
import PacificBluePartnerFooter from 'Src/BrandComponents/PacificBluePartnerFooter';
import NotFound from './pages/NotFound';
import SignUpCompletion from './modules/onBoarding/signUpCompletion';
import LoadingWrapper from 'Src/AgilityComponents/Components/APILoader/LoadingWrapper';
import ResumeSignup from './pages/ResumeSignup';
import ShortLinkRedirect from './pages/ShortLinkRedirect';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import BrandThemeProvider from './themes/BrandThemeProvider';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';
import { factsheetsPage, isDoorToDoor, referAFriend } from './customConfig';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import { GOOGLE_LOCATION_API_URL } from 'App/utils/constants';
import { isEmpty } from 'App/utils/helper';

if (process.env.REACT_APP_GTM_CONTAINER_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
    dataLayer: {
      environment: process.env.NODE_ENV,
      client: process.env.REACT_APP_BRAND_CODE,
    },
  };
  TagManager.initialize(tagManagerArgs);
}

const jss = create({ plugins: [...jssPreset().plugins] });
const allowContentIndex =
  process.env.REACT_APP_ALLOW_INDEX_BY_SEARCH_ENGINES === 'true' &&
  !window.location.hostname.includes('.uconx.com.au');

const selectFooter = () => {
  if (
    process.env.REACT_APP_BRAND_CODE === 'TANGO' &&
    !isEmpty(process.env.REACT_APP_PARTNER_BRAND_CODE)
  ) {
    return TangoPartnerFooter;
  }
  if (
    process.env.REACT_APP_BRAND_CODE === 'PACIFICBLUE' &&
    !isEmpty(process.env.REACT_APP_PARTNER_BRAND_CODE)
  ) {
    return PacificBluePartnerFooter;
  }

  return AgilityFooter;
};

const BrandFooter = selectFooter();

class App extends React.PureComponent {
  render() {
    return (
      <StylesProvider jss={jss}>
        <Helmet>
          <script src={GOOGLE_LOCATION_API_URL} id="google-maps"></script>
        </Helmet>
        <Suspense
          fallback={
            <LoadingWrapper
              isLoading={true}
              className="blank-page full-page-loader"
              style={{
                position: 'fixed',
                width: '100%',
                height: '100%',
                zIndex: '999999',
                top: '0',
                left: '0',
                padding: '0px 20px',
                display: 'flex',
                alignItems: 'center',
                justify: 'center',
              }}
            />
          }
        >
          <BrandThemeProvider>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              TransitionComponent={Slide}
            >
              <Helmet>
                {allowContentIndex || <meta name="robots" content="noindex" />}
              </Helmet>
              <div id="landmark_beforeHeader"></div>
              <AgilityHeader />
              <div id="landmark_afterHeader"></div>
              <React.Fragment>
                <main className={`wrapper`} data-test-id="tally-app">
                  <Switch>
                    <Route exact path="/" component={ComparePlan} />
                    {isDoorToDoor && (
                      <Route exact path="/agent-login" component={AgentLogin} />
                    )}
                    <Route exact path="/plans" component={ComparePlan} />
                    {referAFriend && (
                      <Route exact path="/refer" component={Refer} />
                    )}
                    {factsheetsPage && (
                      <Route exact path="/fact-sheets" component={FactSheet} />
                    )}
                    <Route
                      exact
                      path="/signup-completion"
                      component={SignUpCompletion}
                    />
                    <Route
                      exact
                      path="/resume-signup"
                      component={ResumeSignup}
                    />
                    <Route
                      exact
                      path="/expired-contract"
                      component={ExpiredContract}
                    />
                    <Route
                      exact
                      path="/user-signup/:step"
                      component={UserSignup}
                    />
                    <Route
                      exact
                      path="/s/:link"
                      component={ShortLinkRedirect}
                    />
                    <Route path="*" component={NotFound} />
                  </Switch>
                </main>
                <div id="landmark_beforeFooter"></div>
                <BrandFooter />
                <div id="landmark_afterFooter"></div>
              </React.Fragment>
            </SnackbarProvider>
          </BrandThemeProvider>
        </Suspense>
      </StylesProvider>
    );
  }
}

export default App;
