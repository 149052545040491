import getListApiUrl from 'App/utils/helper';
import { FACTSHEET_API, OFFER_DOCUMENT_API } from 'App/utils/constants';

export const getOfferDocuments = data => dispatch => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(OFFER_DOCUMENT_API, data);
    window.Api.get(url)
      .then(result => {
        result = result.sort((a, b) => {
          return a.distributor > b.distributor
            ? 1
            : b.distributor > a.distributor
            ? -1
            : 0;
        });
        return resolve(result);
      })
      .catch(error => {
        return reject(error);
      });
  });
};

export const getFactSheet = data => dispatch => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(FACTSHEET_API, data);
    window.Api.get(url)
      .then(result => {
        return resolve(result);
      })
      .catch(error => {
        return reject(error);
      });
  });
};
