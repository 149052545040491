import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PaymentMethod from './PaymentMethod';
import {
  getCreditCardFrameUrl,
  validateSignupURL,
} from 'App/modules/onBoarding/action';
import {
  getSignupDetails,
  putSignupDetails,
} from 'App/pages/ComparePlans/action';

const mapStateToProps = (state, props) => {
  return {
    customConfig: state.app.customConfig,
    bankCodeList: state.onBoarding.bankCodeList,
    paymentDetails: state.comparePlan.signupAPIResponse?.paymentDetails,
    selectedPlans: state.comparePlan.selectedPlans,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  putSignupDetails: data => dispatch(putSignupDetails(data)),
  validateSignupURL: token => dispatch(validateSignupURL(token)),
  getSignupDetails: (id, accntId) => dispatch(getSignupDetails(id, accntId)),
  getCreditCardFrameUrl: (signUpId, vendor, data) =>
    dispatch(getCreditCardFrameUrl(signUpId, vendor, data)),
  dispatch,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentMethod)
);
