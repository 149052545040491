import React from 'react';
import { string, object, func, bool } from 'prop-types';
import i18n from 'i18next';
import { AgilityTypography, AgilityInfo } from 'Src/AgilityComponents';
import { AlertTitle } from '@material-ui/lab';
import { Link } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NoIdentifierMessage = props => {
  const { productDetails, enteredSearchLocation } = props;
  return (
    <React.Fragment>
      <AgilityInfo
        severity="warning"
        className="text-left"
        icon={
          <FontAwesomeIcon
            icon={['fas', `${productDetails.icon}`]}
            size="sm"
            className={`${productDetails.icon}`}
          />
        }
      >
        <AlertTitle>
          <AgilityTypography variant="h4" component="h6">
            {i18n.t('siteidentifier.notfound.message1', {
              enteredSearchLocation: enteredSearchLocation,
              productType: (productDetails.display || '').toLowerCase(),
            })}
          </AgilityTypography>
        </AlertTitle>
        <AgilityTypography variant="caption" component="p">
          {i18n.t('siteidentifier.notfound.message2')}

          <React.Fragment>
            {i18n.t('siteidentifier.notfound.message3')}
            <Link
              onClick={() => props.openSearchIdentifierDialog()}
              data-test-id="searchidentifier-link-id"
            >
              {i18n.t('siteidentifier.searchusing.identifier', {
                identifierCode: productDetails.displayAlternative,
              })}
            </Link>
            {i18n.t('siteidentifier.notfound.message4')}
          </React.Fragment>
        </AgilityTypography>
      </AgilityInfo>
    </React.Fragment>
  );
};

NoIdentifierMessage.propTypes = {
  productDetails: object,
  enteredSearchLocation: string,
  openSearchIdentifierDialog: func,
  isEmbeddedNetwork: bool,
};

NoIdentifierMessage.defaultProps = {
  productDetails: {},
  enteredSearchLocation: '',
  isEmbeddedNetwork: false,
};

export default NoIdentifierMessage;
