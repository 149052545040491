/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useState } from 'react';
import { func, object, bool } from 'prop-types';
import i18n from 'i18next';
import {
  AgilityInfoDialog,
  AgilityGrid,
  AgilityButton,
  AgilityTextField,
  AgilityTypography,
} from 'Src/AgilityComponents';
import {
  IDENTIFIER_CODE_MAX_LENGTH,
  IDENTIFIER_CODE_MIN_LENGTH,
} from 'App/utils/fieldLengthHelper';
import { isAlphaNumericOnly } from 'App/utils/validationHelper';
import { isEmpty } from 'App/utils/helper';
import { useTheme } from '@material-ui/core/styles';

const SearchIdentifierDialog = props => {
  const theme = useTheme();
  const { productDetails, open } = props;
  const [identifierCode, setIdentifierCode] = useState('');
  const [errors, setErrors] = useState({
    identifierCode: '',
  });

  const handleChange = val => {
    setIdentifierCode(val);
    validateForm(val);
  };

  const validateForm = val => {
    let formIsValid = true;
    const errors = {};
    if (val !== '' && !isAlphaNumericOnly(val)) {
      formIsValid = false;
      errors['identifierCode'] = i18n.t('siteidentifier.errorMessage.invalid', {
        identifierCode: productDetails.displayAlternative,
      });
    } else if (val !== '' && val.length < IDENTIFIER_CODE_MIN_LENGTH) {
      formIsValid = false;
      errors['identifierCode'] = i18n.t('siteidentifier.errorMessage.length', {
        identifierCode: productDetails.displayAlternative,
      });
    }
    setErrors(errors);
    return formIsValid;
  };

  const onSearchClick = () => {
    props.onSearchClick(identifierCode);
    setIdentifierCode('');
  };
  const resetField = () => {
    setIdentifierCode('');
    setErrors({});
  };
  const onCloseClick = () => {
    resetField();
    props.onDialogCloseClick();
  };
  return (
    <AgilityInfoDialog
      className="full-page-popup-sm search-identifier-dialog"
      data-test-id="search-identifier-dialog"
      fullWidth={true}
      maxWidth="md"
      open={open}
      title={i18n.t('siteidentifier.dialog.heading', {
        identifierCode: productDetails.displayAlternative,
        productType: (productDetails.display || '').toLowerCase(),
      })}
      okClick={() => onCloseClick()}
      closeClick={() => onCloseClick()}
      showOkButton={false}
    >
      <AgilityTypography variant="body2" component="p" containerClass="mb-1">
        {i18n.t(
          productDetails.displayAlternative === 'MIRN'
            ? 'mirn.dialog.message'
            : 'nmi.dialog.message'
        )}
      </AgilityTypography>
      <AgilityTypography variant="body2" component="p">
        {i18n.t('siteidentifier.dialog.message', {
          identifierCode: productDetails.displayAlternative,
        })}
      </AgilityTypography>
      <AgilityGrid container direction="row" className="mt-2">
        <AgilityGrid item xs={12} sm={9}>
          <div className="search-input">
            <AgilityTextField
              id="identifier-code"
              data-test-id="search-identifier-input"
              type="text"
              fullWidth
              placeholder={i18n.t('siteidentifier.dialog.searchplaceholder', {
                identifierCode: productDetails.displayAlternative,
              })}
              value={identifierCode}
              onChange={val => handleChange(val.trim())}
              variant="outlined"
              inputProps={{ maxLength: IDENTIFIER_CODE_MAX_LENGTH }}
              error={errors && errors.identifierCode ? true : false}
              helperText={
                errors && errors.identifierCode ? errors.identifierCode : ''
              }
              css={css`
                &:before {
                  content: '\f002';
                  position: absolute;
                  left: 0;
                  font-family: ${theme.typography.iconFontFamily};
                  font-weight: normal;
                  text-rendering: auto;
                  color: ${theme.palette.grey['500']};
                  font-size: 16px;
                  width: 48px;
                  height: 48px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .MuiInputLabel-formControl {
                  left: 28px;
                  &.MuiInputLabel-shrink {
                    left: 0;
                  }
                }
                .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'],
                .MuiOutlinedInput-input {
                  padding-left: 45px;
                }
              `}
            />
          </div>
        </AgilityGrid>
        <AgilityGrid item xs={12} sm>
          <AgilityButton
            data-test-id="search-identifier-button"
            onClick={() => onSearchClick()}
            label={i18n.t('siteidentifier.dialog.searchbutton')}
            variant="contained"
            color="primary"
            size="large"
            type="primary"
            fullWidth
            disabled={identifierCode === '' || !isEmpty(errors)}
          />
        </AgilityGrid>
      </AgilityGrid>
    </AgilityInfoDialog>
  );
};

SearchIdentifierDialog.propTypes = {
  onDialogCloseClick: func,
  productDetails: object,
  open: bool,
  onSearchClick: func,
};

SearchIdentifierDialog.defaultProps = {
  productDetails: {},
  open: false,
};

export default SearchIdentifierDialog;
