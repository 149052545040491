/* eslint-disable no-underscore-dangle */
import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import thunkMiddleware from 'redux-thunk';
import loggerMiddleware from 'redux-logger';
import './i18n/index';
import apiCall from './app/modules/apiWrapper';
import ErrorPage from './app/pages/ErrorPage';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './app';
import reducer from './reducer';
import { getLocaleDateFormat } from './app/utils/helper';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME_URL,
});

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://1a8fa89acfd5454585332052c4d2d7b8@o60080.ingest.sentry.io/5742278',
    integrations: [
      new Integrations.BrowserTracing({
        // Can also use reactRouterV4Instrumentation
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    environment: process.env.NODE_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  Sentry.setContext('client', {
    name: process.env.REACT_APP_BRAND_CODE,
  });
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

let middleware = [thunkMiddleware];
// Only use logger in dev env
if (
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'staging'
) {
  middleware = [...middleware, loggerMiddleware];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  middleware = composeEnhancers(
    applyMiddleware(...middleware),
    sentryReduxEnhancer
  );
} else {
  middleware = compose(applyMiddleware(...middleware), sentryReduxEnhancer);
}

const persistedState = {};

export const store = createStore(reducer, persistedState, middleware);

const initialEndpoint =
  localStorage.getItem('apiEndpoint') || process.env.REACT_APP_API_ENDPOINT;
const apiObj = apiCall(initialEndpoint);
window.Api = apiObj;

const paymentApiObj = apiCall(process.env.REACT_APP_PAYMENT_API_ENDPOINT);
window.paymentApi = paymentApiObj;

window.dateFormat = getLocaleDateFormat(process.env.REACT_APP_LOCALE);

render(
  <Provider store={store}>
    <Router history={history} basename={process.env.REACT_APP_BASENAME_URL}>
      <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        <App />
      </Sentry.ErrorBoundary>
    </Router>
  </Provider>,
  document.getElementById('root')
);
