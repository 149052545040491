import { connect } from 'react-redux';
import SaveProgressDialog from './SaveProgressDialog';
import { saveProgressTrigger } from '../action';

const mapStateToProps = (state, props) => {
  const [
    contact = {
      emailAddress: '',
      firstName: '',
      phones: [{ type: 'Mobile', phoneNumber: '' }],
      dateOfBirth: '',
    },
  ] = state.comparePlan.signupAPIResponse.contacts || [];
  const { value, name, dateOfBirth } = state.onBoarding.progressData;
  const mobileNumber = contact.phones.find(p => p.type === 'Mobile');
  const progressData = {
    value:
      value ||
      (props.isEmail
        ? contact.emailAddress
        : mobileNumber
        ? mobileNumber.phoneNumber
        : ''),
    name: name || contact.firstName,
    dateOfBirth: dateOfBirth || contact.dateOfBirth,
  };
  return {
    saveProgressLoading: state.onBoarding.saveProgressLoading,
    saveProgressIsSuccess: state.onBoarding.saveProgressIsSuccess,
    progressData,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    saveProgressTrigger: targetValue =>
      dispatch(saveProgressTrigger(targetValue)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveProgressDialog);
