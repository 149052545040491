/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { array } from 'prop-types';
import i18n from 'i18next';
import { AgilityTypography } from 'Src/AgilityComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@material-ui/core/styles';

const PlanFeatures = ({ features = [] }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <div
        css={theme.custom.PlanDetailsCard.PlanFeatures}
        className="plan-features"
      >
        <AgilityTypography
          variant="h6"
          component="h6"
          className="plan-features_header"
        >
          {i18n.t('compareplan.features.heading')}
        </AgilityTypography>
        <ul data-test-id="feature-list-id" className="feature-list">
          {features.length > 0 &&
            features.map((data, index) => (
              <li
                key={`${data.description}_${index}`}
                data-test-id={data.description}
              >
                <div className="flex">
                  <FontAwesomeIcon
                    icon={
                      theme.typography.icons
                        ? theme.typography.icons.planFeatures
                        : ['fas', 'check-circle']
                    }
                    size="sm"
                    className="icon-marker check-icon"
                  />
                  <AgilityTypography variant="subtitle1" component="p">
                    {data.description}
                  </AgilityTypography>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </React.Fragment>
  );
};
PlanFeatures.propTypes = {
  features: array,
};
PlanFeatures.defaultProps = {
  features: [],
};
export default PlanFeatures;
