import React from 'react';
import { AgilityInfoDialog, AgilityTypography } from 'Src/AgilityComponents';
import dompurify from 'dompurify';
import { func, string, bool, array } from 'prop-types';

const sanitizer = dompurify.sanitize;
const AgilityDialog = props => {
  const {
    open,
    title,
    okText,
    maxWidth,
    contents,
    className,
    titleAlign,
    showDeclineButton,
    declineText,
    noClick,
  } = props;

  return (
    <AgilityInfoDialog
      data-test-id="common-dialog-info"
      className={className}
      fullWidth
      maxWidth={maxWidth}
      open={open}
      title={title}
      okText={okText}
      titleAlign={titleAlign}
      okClick={props.okClick}
      closeClick={props.onDismiss}
      noClick={noClick}
      showDeclineButton={showDeclineButton}
      declineText={declineText}
    >
      <React.Fragment>
        {contents.map((item, index) => (
          <AgilityTypography
            key={index}
            data-test-id={item.id}
            variant={item.variant}
            component={item.element}
            className={item.class}
            dangerouslySetInnerHTML={{
              __html: sanitizer(item.body),
            }}
          />
        ))}
      </React.Fragment>
    </AgilityInfoDialog>
  );
};

AgilityDialog.propTypes = {
  okClick: func,
  onDismiss: func,
  open: bool,
  title: string,
  okText: string,
  maxWidth: string,
  contents: array,
  titleAlign: string,
  className: string,
  showDeclineButton: bool,
  declineText: string,
  noClick: func,
};
AgilityDialog.defaultProps = {
  open: false,
  title: '',
  okText: '',
  maxWidth: 'md',
  contents: [],
  titleAlign: 'inherit',
  className: '',
  showDeclineButton: false,
  declineText: '',
};

export default AgilityDialog;
