import CustomError from './CustomError';
import MoveInStartDateError from './MoveInStartDateError';
import * as Sentry from '@sentry/react';

const apiCall = endpoint => {
  const defaultHeader = {
    Accept: 'application/json',
  };
  const realFetch = (
    url,
    method,
    body,
    signal = null,
    headers = defaultHeader
  ) => {
    const options = {
      method,
      headers,
      signal,
    };
    if (body) options.body = JSON.stringify(body);
    return fetch(url, options)
      .then(response => {
        const contentType = response.headers.get('content-type');
        if (response.status === 200) {
          if (contentType && contentType.includes('application/json')) {
            return response.json();
          } else {
            return response;
          }
        } else {
          Sentry.addBreadcrumb({
            category: 'api',
            message: `API call failed: ${method} ${url}`,
            data: {
              response: response,
              method: method,
              url: url,
            },
            level: Sentry.Severity.Debug,
          });
          try {
            if (contentType && contentType.includes('application/json')) {
              return response
                .json()
                .then(data => {
                  const errors = data.errors || [];
                  errors.forEach(error => {
                    if (
                      error.field === 'start_date' &&
                      error.index === 'account.site'
                    ) {
                      throw new MoveInStartDateError();
                    }
                  });
                  throw new CustomError('Error occurred', response.status);
                })
                .catch(err => {
                  throw err;
                });
            } else {
              return response;
            }
          } catch (err) {
            throw err;
          }
        }
      })
      .catch(err => {
        Sentry.addBreadcrumb({
          category: 'api',
          message: `API call failed: ${method} ${url}`,
          data: {
            response: err,
            method: method,
            url: url,
          },
          level: Sentry.Severity.Debug,
        });
        throw err;
      });
  };
  const get = (id, signal = null, stubData = false) => {
    const API_END_POINT = endpoint;
    const url = `${API_END_POINT}${id ? `/${id}` : ''}`;
    if (stubData) {
      return getStub(stubData);
    }
    return realFetch(url, 'GET', false, signal);
  };
  const post = (method, body = false, signal = null, stubData = false) => {
    if (!body) throw new Error('No valid request body provided');
    const API_END_POINT = endpoint;
    const url = `${API_END_POINT}/${method}`;
    if (stubData) {
      return getStub(stubData);
    }
    return realFetch(url, 'POST', body, signal, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  };
  const put = (method, body = false, signal = null, stubData = false) => {
    if (!body) throw new Error('No valid id or body parameter provided');
    const API_END_POINT = endpoint;
    const url = `${API_END_POINT}/${method}`;
    if (stubData) {
      return getStub(stubData);
    }
    return realFetch(url, 'PUT', body, signal, {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  };
  const del = (id = false, signal = null, stubData = false) => {
    if (!id) throw new Error('No valid id parameter provided');
    const API_END_POINT = endpoint;
    const url = `${API_END_POINT}/${id}`;
    if (stubData) {
      return getStub(stubData);
    }
    return realFetch(url, 'DELETE', signal);
  };

  const getStub = async data => {
    return data;
  };
  return {
    get,
    post,
    put,
    del,
  };
};
export default apiCall;
