import React, { useState, useEffect, useRef, useCallback } from 'react';
import i18n from 'i18next';
import 'date-fns';
import {
  AgilityButton,
  AgilityTypography,
  AgilityCard,
  AgilityGrid,
} from 'Src/AgilityComponents';

import { array, func, object, bool } from 'prop-types';
import PropertyCard from './PropertyCard';
import PropertyLayout from './PropertyLayout';
import validate from './validate';
import {
  convertDateFromISO,
  isEmpty,
  getAddressLineOneFromAddress,
  setAllProperties,
} from 'App/utils/helper';
import { isNullOrEmpty } from 'App/utils/validationHelper';
import {
  TRANSFER_TYPE,
  SIGNUP_STEPS as signupSteps,
} from 'App/utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { allowNewConnections } from 'App/customConfig';

const IS_OWNER = 'own';
const IS_RENT = 'rent';
const PRODUCT_TYPE_POWER = 'POWER';
const IS_YES_SELECTED = 'yes';
const IS_NO_SELECTED = 'no';
const NOT_SURE = 'not sure';
const generalValueDict = {
  yes: true,
  no: false,
  'not sure': null,
  own: true,
};

const YourProperty = props => {
  const {
    stateList,
    coordinatorsList,
    moveInDatesList,
    saveProgressTrigger,
    isActive,
    signupAPIResponse,
    onNavigate,
    productList,
    primarySiteIdentifier,
    customConfig,
    selectedPlans,
    openProgressDialog,
    isAgentView,
  } = props;

  const [solarTypeListByOffer, setSolarTypeListByOffer] = useState([]);

  // This method will handle all predefined errors of datepicker with custom errors
  const [moveInError, setMoveInError] = useState('');
  const onMoveInError = error => {
    setMoveInError(error);
    if (error === '') {
      if (fields['transferDate']) {
        if (
          !moveInDatesList.includes(convertDateFromISO(fields['transferDate']))
        ) {
          const today = new Date();
          const selectedDate = new Date(fields['transferDate']);
          if (selectedDate > today) {
            setMoveInError(i18n.t('yourProperty.moveInDate.errorInvalid'));
          } else {
            setMoveInError(
              i18n.t('yourProperty.moveInDate.errorInvalid.selectFutureDate')
            );
          }
        }
      }
    }
  };

  const [fields, setFields] = useState({
    transferType: '',
    transferDate: '',
    solarType: '',
    // Always show postal address for confirmation
    hasDifferentPostalAddress: IS_YES_SELECTED,
    streetAddress: '',
    suburb: '',
    postcode: '',
    state: '',
    propertyOwner: '',
    hasLifeSupportPerson: '',
    hasConnection: '',
    hasObstacles: null,
    obstacles: '',
    hasRisks: null,
    risks: '',
    hasPreferredMeterReader: '',
    preferredMeterReader: '',
    isPowerOptionAvailable: false,
    acceptMoveInTerms: false,
    hasSolarPanels: false,
    vsiAppointmentTime: '',
    vsiAccessMethod: '',
    meterAccess: '',
    isVsiRequirement: false,
  });

  const [errors, setErrors] = useState({
    transferType: '',
    transferDate: '',
    solarType: '',
    hasDifferentPostalAddress: '',
    streetAddress: '',
    suburb: '',
    postcode: '',
    state: '',
    propertyOwner: '',
    hasLifeSupportPerson: '',
    hasConnection: '',
    hasObstacles: '',
    obstacles: '',
    hasRisks: '',
    risks: '',
    hasPreferredMeterReader: '',
    preferredMeterReader: '',
    acceptMoveInTerms: '',
    vsiAppointmentTime: '',
    vsiAccessMethod: '',
    meterAccess: '',
    isVsiRequirement: '',
  });

  const [touched, setTouched] = useState({
    transferType: false,
    transferDate: false,
    solarType: false,
    hasDifferentPostalAddress: false,
    streetAddress: false,
    suburb: false,
    postcode: false,
    state: false,
    propertyOwner: false,
    hasLifeSupportPerson: false,
    hasConnection: false,
    hasObstacles: false,
    obstacles: false,
    hasRisks: false,
    risks: false,
    hasPreferredMeterReader: false,
    preferredMeterReader: false,
    acceptMoveInTerms: false,
    vsiAppointmentTime: false,
    vsiAccessMethod: false,
    meterAccess: false,
    isVsiRequirement: false,
  });

  const [erroredField, setErroredField] = useState({});

  const handleChange = (val, name) => {
    let newTouched = { ...touched, [name]: true };
    let newFields = { ...fields, [name]: val };
    setTouched(newTouched);
    setFields(newFields);
    validateForm(newTouched, newFields);
  };

  const doNewConnection = () => {
    if (!allowNewConnections) {
      return false;
    }
    let hasNewSite = false;
    signupAPIResponse.services.forEach(obj => {
      if (obj.siteIdentifier === null) {
        hasNewSite = true;
      }
    });

    return hasNewSite || signupAPIResponse.address.nmiStatus === 'G'; // G for Greenfield
  };

  const getGeneralDictValue = (field, _default = false) => {
    return typeof generalValueDict[field] !== 'undefined'
      ? generalValueDict[field]
      : _default;
  };

  const fieldMappingModel = useCallback(
    existingValues => {
      const updatedValues = { ...existingValues };
      let primaryAddress = null;
      if (
        typeof props.primarySiteIdentifier === 'object' &&
        !isEmpty(props.primarySiteIdentifier.address)
      ) {
        primaryAddress = props.primarySiteIdentifier.address;
      }
      let {
        services,
        propertyDetail,
        propertyOwner,
        postalAddress,
        hasLifeSupportPerson,
        hasDifferentPostalAddress,
        hasPreferredMeterReader,
        preferredMeterReader,
        transferDetail,
      } = existingValues;

      if (propertyDetail === null) {
        propertyDetail = {
          hasObstacles: null,
          hasRisks: null,
          hasSolarPanels: false,
          obstacles: null,
          propertyAddress: primaryAddress,
          risks: null,
        };
      } else {
        propertyDetail['propertyAddress'] = primaryAddress;
      }

      services.forEach((obj, index) => {
        if (obj.productType === PRODUCT_TYPE_POWER) {
          if (fields.hasConnection !== '') {
            services[index].hasConnection = fields.hasConnection;
          }
          if (!isEmpty(fields.solarType)) {
            services[index].solarType = fields.solarType;
          }
          services[index].vsiAppointmentTime = fields.vsiAppointmentTime;
          services[index].vsiAccessMethod = fields.vsiAccessMethod;
          services[index].meterAccess = fields.meterAccess;
        }
      });

      if (fields.propertyOwner !== '') {
        propertyOwner = fields.propertyOwner === IS_OWNER;
      } else {
        propertyOwner = null;
      }
      transferDetail = {
        transferType: fields.transferType,
        transferDate: fields.transferDate,
      };
      if (fields.transferType === TRANSFER_TYPE.MOVE_IN) {
        transferDetail.acceptMoveInTerms = fields.acceptMoveInTerms;
      }
      if (fields.hasObstacles !== '') {
        propertyDetail.hasObstacles = getGeneralDictValue(fields.hasObstacles);
        propertyDetail.obstacles = propertyDetail.hasObstacles
          ? fields.obstacles
          : null;
      } else {
        propertyDetail.hasObstacles = null;
        propertyDetail.obstacles = null;
      }

      if (fields.hasRisks !== '') {
        propertyDetail.hasRisks = getGeneralDictValue(fields.hasRisks);
        propertyDetail.risks = propertyDetail.hasRisks ? fields.risks : null;
      } else {
        propertyDetail.hasRisks = null;
        propertyDetail.risks = null;
      }

      if (fields.hasLifeSupportPersonPerson !== '') {
        hasLifeSupportPerson = getGeneralDictValue(
          fields.hasLifeSupportPersonPerson
        );
      } else {
        hasLifeSupportPerson = null;
      }

      hasDifferentPostalAddress = getGeneralDictValue(
        fields.hasDifferentPostalAddress
      );
      postalAddress.streetAddress = fields.streetAddress;
      postalAddress.suburb = fields.suburb;
      postalAddress.state = fields.state;
      postalAddress.postcode = fields.postcode;

      if (fields.hasPreferredMeterReader !== '') {
        hasPreferredMeterReader = getGeneralDictValue(
          fields.hasPreferredMeterReader
        );
        if (hasPreferredMeterReader && fields.preferredMeterReader !== '') {
          preferredMeterReader = fields.preferredMeterReader.value || null;
        } else {
          preferredMeterReader = null;
        }
      } else {
        hasPreferredMeterReader = null;
        preferredMeterReader = null;
      }
      updatedValues.currentPage = signupSteps.yourProperty;
      updatedValues.services = services;
      updatedValues.propertyDetail = propertyDetail;
      updatedValues.propertyOwner = propertyOwner;
      updatedValues.postalAddress = postalAddress;
      updatedValues.hasLifeSupportPersonPerson = hasLifeSupportPerson;
      updatedValues.hasDifferentPostalAddress = hasDifferentPostalAddress;
      updatedValues.hasPreferredMeterReader = hasPreferredMeterReader;
      updatedValues.preferredMeterReader = preferredMeterReader;
      updatedValues.transferDetail = transferDetail;
      return updatedValues;
    },
    [
      fields.hasConnection,
      fields.hasDifferentPostalAddress,
      fields.hasLifeSupportPersonPerson,
      fields.hasObstacles,
      fields.hasPreferredMeterReader,
      fields.hasRisks,
      fields.obstacles,
      fields.postcode,
      fields.preferredMeterReader,
      fields.propertyOwner,
      fields.risks,
      fields.state,
      fields.streetAddress,
      fields.suburb,
      fields.transferDate,
      fields.transferType,
      fields.acceptMoveInTerms,
      fields.solarType,
      fields.vsiAppointmentTime,
      fields.vsiAccessMethod,
      fields.meterAccess,
      props.primarySiteIdentifier,
    ]
  );

  const resetValidation = useCallback(() => {
    const updatedTouched = setAllProperties(touched, false);
    setTouched(updatedTouched);

    setErrors({});
  }, []);

  const prevPropRef = useRef();
  useEffect(() => {
    if (
      prevPropRef.current &&
      isActive &&
      prevPropRef.current !== saveProgressTrigger
    ) {
      const data = fieldMappingModel(signupAPIResponse);
      onNavigate('progress', data, true);
    }
    if (isActive === false) {
      resetValidation();
    }
    prevPropRef.current = saveProgressTrigger;
  }, [
    saveProgressTrigger,
    isActive,
    onNavigate,
    signupAPIResponse,
    fieldMappingModel,
    fields,
    resetValidation,
  ]);

  const getPostalAddress = useCallback(primarySiteIdentifier => {
    if (primarySiteIdentifier && primarySiteIdentifier.address) {
      return {
        streetAddress: getAddressLineOneFromAddress(
          primarySiteIdentifier.address
        ),
        suburb: primarySiteIdentifier.address.Suburb,
        state: primarySiteIdentifier.address.State,
        postcode: primarySiteIdentifier.address.PostCode,
      };
    }
  }, []);

  useEffect(() => {
    if (
      customConfig.showSolarType &&
      selectedPlans?.POWER?.feedin &&
      selectedPlans?.POWER?.feedin.length > 0
    ) {
      let filteredSolarTypes = selectedPlans.POWER.feedin.map(solarType => {
        return {
          label: solarType.description,
          value: solarType.id,
        };
      });

      setSolarTypeListByOffer(filteredSolarTypes);
    }
  }, [customConfig.showSolarType, selectedPlans]);

  useEffect(() => {
    const fieldData = {
      transferType: '',
      transferDate: '',
      acceptMoveInTerms: false,
      hasDifferentPostalAddress: '',
      streetAddress: '',
      suburb: '',
      postcode: '',
      state: '',
      propertyOwner: '',
      hasLifeSupportPerson: '',
      hasConnection: '',
      hasObstacles: '',
      obstacles: '',
      hasRisks: '',
      risks: '',
      hasPreferredMeterReader: '',
      preferredMeterReader: '',
      isPowerOptionAvailable: false,
    };

    if (!isEmpty(signupAPIResponse)) {
      const propertyOwner = signupAPIResponse.propertyOwner;
      const obstacles =
        signupAPIResponse.propertyDetail !== null
          ? signupAPIResponse.propertyDetail.obstacles
          : null;
      const risks =
        signupAPIResponse.propertyDetail !== null
          ? signupAPIResponse.propertyDetail.risks
          : null;

      const postalAddress = isNullOrEmpty(signupAPIResponse.postalAddress)
        ? getPostalAddress(primarySiteIdentifier)
        : signupAPIResponse.postalAddress;

      const transferDetail = signupAPIResponse.transferDetail;
      const hasLifeSupportPerson = signupAPIResponse.hasLifeSupportPersonPerson;
      let hasObstacles, hasRisks;
      if (!isEmpty(signupAPIResponse.propertyDetail)) {
        if (signupAPIResponse.propertyDetail.hasObstacles !== undefined) {
          hasObstacles = signupAPIResponse.propertyDetail.hasObstacles;
        }
        if (signupAPIResponse.propertyDetail.hasRisks !== undefined) {
          hasRisks = signupAPIResponse.propertyDetail.hasRisks;
        }
      }
      const hasPreferredMeterReader = signupAPIResponse.hasPreferredMeterReader;
      const preferredMeterReader = signupAPIResponse.preferredMeterReader;
      if (signupAPIResponse.services !== null) {
        const services = signupAPIResponse.services;
        services.forEach(obj => {
          if (obj.productType === PRODUCT_TYPE_POWER) {
            fieldData.hasConnection = obj.hasConnection;
            fieldData.isPowerOptionAvailable = true;
            fieldData.hasSolarPanels = obj.hasSolarPanels;
            fieldData.vsiAppointmentTime = obj.vsiAppointmentTime;
            fieldData.vsiAccessMethod = obj.vsiAccessMethod;
            fieldData.meterAccess = obj.meterAccess;
            if (!isEmpty(obj.solarType)) {
              fieldData.solarType = obj.solarType;
            }
          }
        });
      }

      if (hasLifeSupportPerson !== null) {
        fieldData.hasLifeSupportPersonPerson = hasLifeSupportPerson
          ? IS_YES_SELECTED
          : IS_NO_SELECTED;
      }
      if (!isEmpty(transferDetail)) {
        fieldData.transferType = doNewConnection()
          ? TRANSFER_TYPE.NEW_CONNECTION
          : transferDetail.transferType;
        fieldData.transferDate =
          transferDetail.transferDate !== null
            ? transferDetail.transferDate
            : null;
        if (transferDetail.transferType === TRANSFER_TYPE.MOVE_IN) {
          fieldData.acceptMoveInTerms = transferDetail.acceptMoveInTerms;
        }
      }
      if (propertyOwner !== null) {
        fieldData.propertyOwner = propertyOwner === true ? IS_OWNER : IS_RENT;
      }

      fieldData.hasObstacles =
        typeof hasObstacles === 'undefined' || hasObstacles === null
          ? NOT_SURE
          : hasObstacles
          ? IS_YES_SELECTED
          : IS_NO_SELECTED;

      if (hasObstacles) {
        fieldData.obstacles = obstacles;
      }

      fieldData.hasRisks =
        typeof hasRisks === 'undefined' || hasRisks === null
          ? NOT_SURE
          : hasRisks
          ? IS_YES_SELECTED
          : IS_NO_SELECTED;
      if (hasRisks) {
        fieldData.risks = risks;
      }

      fieldData.hasDifferentPostalAddress = IS_YES_SELECTED;
      fieldData.streetAddress = postalAddress?.streetAddress;
      fieldData.suburb = postalAddress?.suburb;
      fieldData.state = postalAddress?.state;
      fieldData.postcode = postalAddress?.postcode;

      if (hasPreferredMeterReader !== null) {
        fieldData.hasPreferredMeterReader = hasPreferredMeterReader
          ? IS_YES_SELECTED
          : IS_NO_SELECTED;

        if (hasPreferredMeterReader) {
          fieldData.preferredMeterReader = coordinatorsList.find(
            coordinators => coordinators.value === preferredMeterReader
          );
        }
      }

      if (preferredMeterReader !== null) {
        fieldData.preferredMeterReader = coordinatorsList.find(
          coordinators => coordinators.value === preferredMeterReader
        );
      }
    }
    setFields(fieldData);
  }, [
    signupAPIResponse,
    coordinatorsList,
    getPostalAddress,
    primarySiteIdentifier,
  ]);

  const validateForm = (currentTouched, currentFields) => {
    const response = validate(currentTouched, currentFields);
    setErrors(response.errorMessages);
    return response.invalidField;
  };

  const onBackClick = () => {
    resetValidation();
    props.onNavigate('back');
  };

  const nextForm = async e => {
    const secondaryPostalAddresses = [
      'streetAddress',
      'suburb',
      'state',
      'postcode',
    ];
    let newFields = { ...fields };
    let newTouch = { ...touched };
    //Set inputs touch status
    for (const key in touched) {
      if (
        fields['hasDifferentPostalAddress'] !== IS_YES_SELECTED &&
        secondaryPostalAddresses.includes(key)
      ) {
        newFields[key] = '';
        newTouch[key] = false;
      } else if (key === 'hasConnection') {
        newFields[key] = null;
        newTouch[key] = false;
      } else if (
        key === 'transferDate' &&
        fields['transferType'] !== TRANSFER_TYPE.MOVE_IN
      ) {
        newFields[key] = null;
        newTouch[key] = false;
      } else if (
        key === 'acceptMoveInTerms' &&
        (fields['transferType'] !== TRANSFER_TYPE.MOVE_IN ||
          !customConfig.showConnectionTermsAndConditions)
      ) {
        newFields[key] = null;
        newTouch[key] = false;
      } else if (
        fields['transferType'] === TRANSFER_TYPE.MOVE_IN &&
        key === 'hasConnection' &&
        !customConfig.showHasConnection
      ) {
        newFields[key] = null;
        newTouch[key] = false;
      } else if (
        key === 'obstacles' &&
        (fields['hasObstacles'] !== IS_YES_SELECTED ||
          !customConfig.showAnyObstacles)
      ) {
        newFields[key] = '';
        newTouch[key] = false;
      } else if (
        key === 'risks' &&
        (fields['hasRisks'] !== IS_YES_SELECTED || !customConfig.showAnyRisks)
      ) {
        newFields[key] = '';
        newTouch[key] = false;
      } else if (
        !customConfig.showMeterQuestion &&
        key === 'hasPreferredMeterReader'
      ) {
        newFields[key] = null;
        newTouch[key] = false;
      } else if (
        fields['hasPreferredMeterReader'] !== IS_YES_SELECTED &&
        key === 'preferredMeterReader'
      ) {
        newFields[key] = '';
        newTouch[key] = false;
      } else if (
        key === 'hasLifeSupportPerson' &&
        !customConfig.showLifeSupport
      ) {
        newTouch[key] = false;
      } else if (
        key === 'solarType' &&
        (!customConfig.showSolarType || !fields.hasSolarPanels)
      ) {
        newFields[key] = null;
        newTouch[key] = false;
      } else {
        if (fields[key] && typeof fields[key] === 'string') {
          newFields[key] = fields[key].trim();
        }
        newTouch[key] = true;
      }
    }
    setFields(newFields);
    setTouched(newTouch);
    const invalidField = validateForm(newTouch, newFields);

    if (!invalidField && !moveInError) {
      const data = fieldMappingModel(props.signupAPIResponse);
      data.currentPage = signupSteps.yourIdentification;
      props.onNavigate('next', data, false);
    } else {
      setErroredField({ invalid: invalidField });
    }
  };
  return (
    <AgilityCard className={`steps-wrapper ${props.className}`} id={props.id}>
      <AgilityTypography variant="h4" component="h4" className="mb-2">
        {i18n.t('yourProperty.header.text')}
      </AgilityTypography>
      <PropertyCard
        className="mb-0"
        primarySiteIdentifier={primarySiteIdentifier}
        productList={productList}
        services={signupAPIResponse.services}
      />
      <form autoComplete="off" noValidate data-test-id="yourPropertyForm">
        <PropertyLayout
          data-test-id="property-layout"
          stateList={stateList}
          solarTypeList={solarTypeListByOffer || []}
          coordinatorsList={coordinatorsList}
          isActive={isActive}
          errors={errors}
          moveInDatesList={moveInDatesList}
          handleChange={handleChange}
          fields={fields}
          onMoveInError={onMoveInError}
          moveInError={moveInError}
          erroredField={erroredField}
          customConfig={customConfig}
          signupAPIResponse={signupAPIResponse}
        />
        <AgilityGrid container spacing={2} justifyContent="flex-end">
          <AgilityGrid item xs={12} sm={12}>
            <div className="steps-footer">
              {isAgentView && (
                <AgilityButton
                  disabled={!isActive}
                  color="primary"
                  onClick={openProgressDialog}
                  label={i18n.t('saveprogress.sms.button')}
                  data-test-id="smsButton"
                  endIcon={<FontAwesomeIcon icon={['fas', 'envelope']} />}
                />
              )}
              <AgilityButton
                disabled={!isActive}
                color="primary"
                data-test-id="backButton"
                onClick={() => {
                  onBackClick();
                }}
                className="push"
                label={i18n.t('signup.button.back')}
              />
              <AgilityButton
                disabled={!isActive}
                variant="contained"
                color="primary"
                type="primary"
                label={i18n.t('signup.button.next')}
                data-test-id="nextButton"
                onClick={e => {
                  nextForm(e);
                }}
                loading={props.nextLoading}
              />
            </div>
          </AgilityGrid>
        </AgilityGrid>
      </form>
    </AgilityCard>
  );
};

YourProperty.propTypes = {
  propertyFields: object,
  stateList: array,
  coordinatorsList: array,
  signupAPIResponse: object,
  visible: bool,
  fetchMoveInDatesList: func,
  moveInDatesList: array,
  customConfig: object,
};
YourProperty.defaultProps = {
  propertyFields: {},
  stateList: [],
  coordinatorsList: [],
  signupAPIResponse: {},
  visible: true,
  moveInDatesList: [],
  customConfig: {},
};

export default YourProperty;
