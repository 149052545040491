import { connect } from 'react-redux';
import Identification from './Identification';

const mapStateToProps = (state, props) => {
  return {
    idTypeList: state.onBoarding.identificationTypeList,
  };
};

export default connect(mapStateToProps, null)(Identification);
