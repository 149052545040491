import { connect } from 'react-redux';
import businessNameLookup from './BusinessNameLookup';
import {
  searchBusinessName,
  setBusinessName,
} from 'App/modules/onBoarding/action';

const mapStateToProps = (state, props) => {
  return {
    businessName: state.onBoarding.businessName,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    searchBusinessName: term => searchBusinessName(term),
    setBusinessName: name => setBusinessName(name),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(businessNameLookup);
