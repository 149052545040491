import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ResumeSignup from './ResumeSignup';
import { getSignupDetails } from 'App/pages/ComparePlans/action';
import { validateSignupURL } from 'App/modules/onBoarding/action';
import { validateSignupResumption } from './actions';

const mapStateToProps = (state, props) => {
  return {
    ...props,
    account: state.resumeSignup,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    validateSignupURL: token => dispatch(validateSignupURL(token)),
    validateSignupResumption: data => dispatch(validateSignupResumption(data)),
    getSignupDetails: (hash, accountId, dateOfBirth) =>
      dispatch(getSignupDetails(hash, accountId, dateOfBirth)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResumeSignup)
);
