/**@jsx jsx */
import { css, jsx } from '@emotion/react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { AgilityTypography } from 'Src/AgilityComponents';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import i18n from 'i18next';
import dompurify from 'isomorphic-dompurify';
const sanitizer = dompurify.sanitize;

const PacificBluePartnerFooter = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      className="footer pacificblue-partner-footer"
      id="footer"
      data-test-id="footer-id"
      css={css`
        background: ${theme.palette.common.white};
        box-shadow: inset 0 1px 0 ${theme.palette.grey['100']};
        a {
          text-decoration: underline !important;
        }
      `}
    >
      <Container>
        <Grid container className="mb-1">
          <Grid item xs={12}>
            {i18n.exists('pacificblue.footer.headline') && (
              <AgilityTypography
                variant="h3"
                css={{
                  fontWeight: 700,
                  color: theme.palette.miscellaneous.brandGreen,
                }}
              >
                {i18n.t('pacificblue.footer.headline')}
              </AgilityTypography>
            )}
            <AgilityTypography
              variant="body2"
              css={{
                fontWeight: 700,
                color: theme.palette.miscellaneous.brandGreen,
              }}
              dangerouslySetInnerHTML={{
                __html: sanitizer(i18n.t('pacificblue.footer.tagline')),
              }}
            ></AgilityTypography>
            {i18n.exists('pacificblue.footer.footertext') && (
              <AgilityTypography
                variant="subtitle2"
                css={{
                  color: theme.palette.common.black,
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(i18n.t('pacificblue.footer.footertext')),
                }}
              ></AgilityTypography>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          {/* {![
            'CUSTOMEROFFERS',
            'ADDGAS',
            'ADDELEC',
            'SPECIALOFFERS',
            'OFFERS',
            'BUSINESS',
            'BUSINESSOFFERS',
          ].includes(process.env.REACT_APP_PARTNER_BRAND_CODE) && (
            <Grid item>
              <AgilityTypography
                css={{
                  fontSize: '40px',
                  fontWeight: 700,
                  color: theme.palette.miscellaneous.brandGreen,
                }}
              >
                133 669
              </AgilityTypography>
            </Grid>
          )} */}
          <Grid item>
            <img
              src={theme.images.product}
              width={150}
              alt="Pacific Blue Logo"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PacificBluePartnerFooter;
