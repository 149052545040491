import { getCurrentTimestamp } from 'App/utils/helper';
export const testApi = () => dispatch => {
  const url = '/api/product-offerings/123456';
  window.Api.get(url)
    .then(result => {
      return dispatch({
        type: 'TEST',
        payload: result,
      });
    })
    .catch(error => {});
};

export const resetCurrentStep = () => dispatch => {
  return dispatch({
    type: 'RESET_CURRENT_STEP',
  });
};

export const toggleSteps =
  (actionParam, steps, setStepCompleted = false) =>
  (dispatch, getState) => {
    let page = getState().pages.userSignup.currentPage;

    if (actionParam === 'next') page++;
    else page--;

    const stepParent = getStepParent(page, steps);
    const requestData = { page: page, currentStep: stepParent };
    if (setStepCompleted) {
      requestData.completedStep = page;
    }
    return dispatch({
      type: 'SWITCH_PAGE',
      payload: requestData,
    });
  };

export const togglePopup = (status, content) => dispatch => {
  return dispatch({
    type: 'TOGGLE_POPUP',
    payload: { status: status, content: content },
  });
};

export const setCompletedStep = id => dispatch => {
  return dispatch({
    type: 'SET_COMPLETED_STEP',
    payload: id,
  });
};

const getStepParent = (page, steps) => {
  const data = steps.find(({ nested }) =>
    nested.some(({ key }) => key === page)
  );
  if (data) {
    const childObj = data.nested.find((obj, index) => {
      return obj.key === page;
    });
    return childObj;
  }
};

export const triggerHideLeavePopup = () => dispatch => {
  return dispatch({
    type: 'TRIGGER_HIDE_LEAVE_POPUP',
    payload: getCurrentTimestamp(),
  });
};
