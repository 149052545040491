import React, { useRef, useEffect } from 'react';

import { AgilityGrid, AgilityTextField } from 'Src/AgilityComponents';

const SingleInput = ({
  checkedCode,
  validCode,
  handleChange,
  focus,
  value,
}) => {
  const inputRef = useRef(null);
  const fieldHandleChange = val => {
    let inputVal = val;
    if (val.length > 1) {
      // user input multi digits in one box
      inputVal = parseInt(val.replace(value, '')); // just get last digit
    }
    if (!isNaN(inputVal)) {
      handleChange(inputVal);
    }
  };

  useEffect(() => {
    focus ? inputRef.current.focus() : inputRef.current.blur();
  }, [focus]);

  return (
    <AgilityGrid item>
      <AgilityTextField
        inputRef={inputRef}
        onChange={fieldHandleChange}
        variant="outlined"
        // autoComplete="one-time-code"
        inputProps={{
          type: 'text',
          pattern: '\\d*',
          style: {
            textAlign: 'center',
            caretColor: 'transparent',
          },
        }}
        className={checkedCode && validCode ? 'green' : ''}
        style={{ width: 40, height: 40 }}
        value={value || ''}
        error={checkedCode && !validCode}
        size="small"
      />
    </AgilityGrid>
  );
};

export default SingleInput;
