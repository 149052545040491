// import StaticJsonBackend from './StaticJsonBackend';
import Common from './translations/common';
import Covau from './translations/covau';
import Demo from './translations/demo';
import Gee from './translations/gee';
import Seg from './translations/seg';
import Tango from './translations/tango';
import Addgas from './translations/tango-addgas';
import Addelec from './translations/tango-addelec';
import Offers from './translations/tango-offers';
import SpecialOffers from './translations/tango-specialoffers';
import Customeroffers from './translations/tango-customeroffers';
import TangoStaff from './translations/tango-staff';
import PacificBlueStaff from './translations/pacificblue-staff';
import PacificBlueBusiness from './translations/pacificblue-business';
import TangoGreatSouthWest from './translations/tango-greatsouthwest';
import TangoBusiness from './translations/tango-business';
import TangoBusinessOffers from './translations/tango-businessoffers';
import First from './translations/first';
import Accredited from './translations/tango-accredited';
import Emerald from './translations/tango-emerald';
import Solarrun from './translations/tango-solarrun';
import MyConnect from './translations/tango-myconnect';
import United from './translations/tango-unitedsolar';
import BSolar from './translations/tango-bsolar';
import Nbe from './translations/nbe';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// @TODO Fix this, as it'll blow the bundle size out
let resources;
const brand = process.env.REACT_APP_BRAND_CODE;
const partner = process.env.REACT_APP_PARTNER_BRAND_CODE;
if (brand === 'SEG') {
  resources = {
    en: Seg,
  };
} else if (brand === 'GEE') {
  resources = {
    en: Gee,
  };
} else if (brand === 'DEMO') {
  resources = {
    en: Demo,
  };
} else if (brand === 'COVAU') {
  resources = {
    en: Covau,
  };
} else if (brand === 'FIRST') {
  resources = {
    en: First,
  };
} else if (brand === 'SOLARRUN') {
  resources = {
    en: Solarrun,
  };
} else if (brand === 'NBE') {
  resources = {
    en: Nbe,
  };
} else if (brand === 'PACIFICBLUE') {
  if (partner === 'STAFF') {
    resources = {
      en: PacificBlueStaff,
    };
  }
  if (partner === 'BUSINESS') {
    resources = {
      en: PacificBlueBusiness,
    };
  }
} else if (brand === 'TANGO') {
  if (partner === 'DIRECT') {
    resources = {
      en: Tango,
    };
  }
  if (partner === 'ADDGAS') {
    resources = {
      en: Addgas,
    };
  }
  if (partner === 'ADDELEC') {
    resources = {
      en: Addelec,
    };
  }
  if (partner === 'SPECIALOFFERS') {
    resources = {
      en: SpecialOffers,
    };
  }
  if (partner === 'OFFERS') {
    resources = {
      en: Offers,
    };
  }
  if (partner === 'STAFF') {
    resources = {
      en: TangoStaff,
    };
  }
  if (partner === 'GREATSOUTHWEST') {
    resources = {
      en: TangoGreatSouthWest,
    };
  }
  if (partner === 'EMERALD') {
    resources = {
      en: Emerald,
    };
  }

  if (partner === 'MYCONNECT') {
    resources = {
      en: MyConnect,
    };
  }
  if (partner === 'ACCREDITED') {
    resources = {
      en: Accredited,
    };
  }
  if (partner === 'UNITEDSOLAR') {
    resources = {
      en: United,
    };
  }
  if (partner === 'BSOLAR') {
    resources = {
      en: BSolar,
    };
  }
  if (partner === 'CUSTOMEROFFERS') {
    resources = {
      en: Customeroffers,
    };
  }
  if (partner === 'BUSINESS') {
    resources = {
      en: TangoBusiness,
    };
  }
  if (partner === 'BUSINESSOFFERS') {
    resources = {
      en: TangoBusinessOffers,
    };
  }
} else {
  resources = {
    en: Demo,
  };
}

resources.common = Common;

// .use(StaticJsonBackend)
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'common',
    debug: process.env.NODE_ENV !== 'production',
    lng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
      // useSuspense: true,
    },
  });

export default i18n;
