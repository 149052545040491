import {
  isNotEmpty,
  isNotNullOrUndefined,
  isValidConcessionsCardNumber,
  isValidNameWithSpaceAndApostrophe,
} from 'App/utils/validationHelper';

import { yesNoOptionConverter } from 'App/utils/helper';
import { unselectHasConcessionCard } from 'App/customConfig';

export const changeDate = 'change date';

export const maxDob = new Date(
  new Date().getFullYear() - 18,
  new Date().getMonth(),
  new Date().getDate()
);

export const concessionCardArrayField = [
  'cardNumber',
  'cardType',
  'cardExpiry',
  'cardName',
  'cardLastName',
  'cardStartDate',
  'hasConsented',
];

let initialFieldsState = {
  state: undefined,
  lifeSupportPersonDetail: null,
  hasLifeSupportPerson: false,
  lifeSupportType: '',
  fuelType: '',
  hasConcessionCard: false,

  cardId: null, //id holder
  cardName: '',
  cardLastName: '',
  cardNumber: '',
  cardType: '',
  cardExpiry: '',
  cardStartDate: '',

  hasCoolingConcession: false,
  hasConsented: false,
};

if (unselectHasConcessionCard) {
  initialFieldsState.hasConcessionCard = null;
}

export const defaultFieldsState = { ...initialFieldsState };

export const defaultErrorsState = {};

export const defaultTouchedState = {
  lifeSupportPersonDetail: false,
  hasLifeSupportPerson: false,
  lifeSupportType: false,
  hasConcessionCard: false,

  cardName: false,
  cardLastName: false,
  cardNumber: false,
  cardType: false,
  cardExpiry: false,
  cardStartDate: false,
  hasConsented: false,

  hasCoolingConcession: false,
};

export const fieldValidators = (customConfig, isResident) => ({
  defaultChecker: isNotEmpty,
  fields: {
    hasLifeSupportPerson: {
      preCondition: '',
      defaultErrorText: 'lifeSupportConcession.errorMessage.lifeSupport',
      conditions: [],
    },
    hasConcessionCard: {
      defaultChecker: isNotNullOrUndefined,
      preCondition: '',
      isOptional: false,
      defaultErrorText: 'lifeSupportConcession.errorMessage.hasConcessionCard',
      conditions: [
        {
          condition: val => {
            return !isResident || (isResident && val !== null);
          },
          errorText:
            'lifeSupportConcession.errorMessage.requiredIsConcessionCard',
        },
      ],
    },
    cardName: {
      preCondition: 'hasConcessionCard',
      defaultErrorText:
        'lifeSupportConcession.errorMessage.requiredCardHolderName',
      conditions: [
        {
          condition: isValidNameWithSpaceAndApostrophe,
          errorText: 'lifeSupportConcession.errorMessage.validCardHolderName',
        },
      ],
    },

    cardLastName: {
      preCondition: 'hasConcessionCard',
      defaultErrorText:
        'lifeSupportConcession.errorMessage.requiredCardHolderLastName',
      conditions: [
        {
          condition: isValidNameWithSpaceAndApostrophe,
          errorText:
            'lifeSupportConcession.errorMessage.validCardHolderLastName',
        },
      ],
    },
    cardNumber: {
      preCondition: 'hasConcessionCard',
      defaultErrorText: 'lifeSupportConcession.errorMessage.requiredCardNumber',
      conditions: [
        {
          condition: isValidConcessionsCardNumber,
          errorText: 'lifeSupportConcession.errorMessage.validCardNumber',
        },
      ],
    },
    cardType: {
      preCondition: 'hasConcessionCard',
      defaultErrorText: 'lifeSupportConcession.errorMessage.requiredCardType',
      conditions: [],
    },
    cardStartDate: {
      preCondition: 'hasConcessionCard',
      defaultErrorText: 'lifeSupportConcession.errorMessage.startDate',
      conditions: [],
    },
    cardExpiry: {
      preCondition: 'hasConcessionCard',
      defaultErrorText: 'lifeSupportConcession.errorMessage.expiryDate',
      conditions: [],
    },
    hasConsented: {
      preCondition: 'hasConcessionCard',
      defaultErrorText:
        'lifeSupportConcession.errorMessage.requiredHasConsented',
      conditions: [],
    },
    hasCoolingConcession: {
      preCondition: 'hasConcessionCard',
      isOptional: false,
      defaultErrorText:
        'lifeSupportConcession.errorMessage.hasCoolingConcession',
      conditions: [],
    },
  },
});

// Prepare request to go to API
export const updateApiData = fields => {
  const apiData = {
    lifeSupportPersonDetail: fields.lifeSupportPersonDetail,
    hasLifeSupportPerson: fields.hasLifeSupportPerson,
    lifeSupportType: fields.lifeSupportType,
    fuelType: fields.fuelType,
    hasConcessionCard: yesNoOptionConverter(fields.hasConcessionCard),
    hasCoolingConcession: yesNoOptionConverter(fields.hasCoolingConcession),
  };

  if (fields.hasConcessionCard) {
    apiData.concessionCardDetail = {
      id: fields.cardId,
      cardTypeCode: fields.cardType,
      cardNumber: fields.cardNumber,
      firstName: fields.cardName,
      lastName: fields.cardLastName,
      startDate: fields.cardStartDate,
      expiryDate: fields.cardExpiry,
      hasConsented: fields.hasConsented,
    };
  }

  return apiData;
};
