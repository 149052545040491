import React from 'react';
import PlanDetailsCard from 'App/pages/ComparePlans/Components/ProductPlans/PlanDetailsCard';
import { AgilityInfoDialog } from 'Src/AgilityComponents';

const PlanInfoDialog = props => {
  const product = props.content.productType;
  const { open, isEmbeddedNetwork, site, isPopup } = props;
  return (
    <AgilityInfoDialog
      data-test-id="plan-details-dialog"
      open={open}
      fullWidth
      maxWidth="xs"
      okClick={props.onDismiss}
      closeClick={props.onDismiss}
      className="full-page-popup-sm plan-details-popup"
      showOkButton={false}
      showModalTitle={false}
    >
      <div className="service-list">
        <PlanDetailsCard
          data-test-id="plan-details-card"
          planDetails={props.content}
          productDetails={product}
          selection={false}
          isPopup={isPopup}
          isEmbeddedNetwork={isEmbeddedNetwork}
          site={site}
        />
      </div>
    </AgilityInfoDialog>
  );
};

export default PlanInfoDialog;
