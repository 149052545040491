import React from 'react';
import i18n from 'i18next';
import {
  AgilityButton,
  AgilityGrid,
  AgilityTextField,
  AgilityTypography,
} from 'Src/AgilityComponents';
import { PROMO_CODE_MAX_LENGTH } from 'App/utils/fieldLengthHelper';
import { func, bool, number, string } from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { validatePromoCode } from 'App/pages/ComparePlans/action';
import { Link } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cookie from 'js-cookie';
import { AgilityInfoDialog } from 'Src/AgilityComponents';
import { useTheme } from '@material-ui/core/styles';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const PromoCode = props => {
  const theme = useTheme();
  const { search } = useLocation();
  const [promoCode, setPromoCode] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [validCode, setValidCode] = React.useState();
  const [formIsValid, setFormIsValid] = React.useState(true);
  const [showPopup, setShowPopup] = React.useState(false);

  React.useEffect(() => {
    const updatePromoFromCache = async () => {
      const queryParams = queryString.parse(search);
      const promoCode =
        queryParams.promo !== undefined
          ? queryParams.promo
          : cookie.get('promoCode') || '';
      if (promoCode) {
        setLoading(true);
        let data = {
          code: promoCode,
        };
        const response = await validatePromoCode(data).catch(error => {});

        if (response.valid) {
          setPromoCode(promoCode);
          setValidCode(true);
          updatePromoCodeInProps(true, promoCode, true);
        }
        setLoading(false);
      }
    };

    updatePromoFromCache().catch(error => {});
  }, []);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const handlePromoCodeChange = value => {
    setPromoCode(value.trim());
    validateForm();

    if (props.onPromoCodeChange) {
      props.onPromoCodeChange(value.trim());
    }
  };
  const validateForm = () => {
    let formIsValid = true;
    const errors = {};
    setErrors(errors);
    setFormIsValid(formIsValid);
    return formIsValid;
  };
  const onPromoCodeClick = async () => {
    validateForm();
    if (validCode) {
      setValidCode(false);
      setPromoCode('');
      cookie.remove('promoCode');
      updatePromoCodeInProps(false, '', false);
      props.onPromoCodeRemove();
    } else {
      setLoading(true);
      const data = {
        code: promoCode,
      };
      const response = await validatePromoCode(data).catch(error => {});
      setLoading(false);
      if (response.valid) {
        setValidCode(true);
        cookie.set('promoCode', promoCode, { expires: 5 });
        updatePromoCodeInProps(true, promoCode, true);
        if (showPopup) {
          setTimeout(function () {
            setShowPopup(false);
          }, 1000);
        }
      } else {
        const errors = {
          promoCode: response.message,
        };
        setValidCode(false);
        updatePromoCodeInProps(false, '', false);
        setErrors(errors);
      }
    }
  };
  const updatePromoCodeInProps = (isPromoApplied, promoCode, isValidCode) => {
    const promoCodeObj = {
      isPromoApplied,
      code: promoCode,
      isValidCode,
    };
    props.setOfferFilterPromoCode(promoCodeObj);
  };
  const clearPromoCode = () => {
    if (loading) return;
    setPromoCode('');
    handlePromoCodeChange('');
  };
  const getLoadingMessage = () => {
    if (validCode) {
      return i18n.t('button.remove.label');
    }
    return loading
      ? i18n.t('button.applying.label')
      : i18n.t('button.apply.label');
  };
  const getPromoCodeEndIcon = () => {
    if (validCode) {
      return (
        <FontAwesomeIcon
          data-test-id="applied-promocode"
          icon={['fas', 'check']}
        />
      );
    }
    return promoCode !== '' ? (
      <FontAwesomeIcon
        data-test-id="clear-promocode"
        onClick={clearPromoCode}
        icon={['fas', 'times']}
        className="close"
      />
    ) : (
      ''
    );
  };
  return (
    <React.Fragment>
      <Link
        onClick={togglePopup}
        css={theme.custom.PromoPopup.PromoPopupLinkCss}
        className={props.promoCode.isPromoApplied ? 'promo-applied' : ''}
      >
        {props.promoCode.isPromoApplied ? (
          <span>
            <span className="promo-applied__code">{props.promoCode.code}</span>
            {i18n.t('promocode.header.promoAddedText')}
            <FontAwesomeIcon icon={['fas', 'pen']} />
          </span>
        ) : (
          i18n.t('promocode.header.text')
        )}
      </Link>
      <AgilityInfoDialog
        open={showPopup}
        fullWidth
        maxWidth="sm"
        closeClick={togglePopup}
        className="full-page-popup-sm promocode-popup"
        showOkButton={false}
        showModalTitle={false}
      >
        <AgilityTypography variant="h4" component="label">
          {i18n.t('promocode.popup.text')}
        </AgilityTypography>
        <AgilityGrid container direction="row" className="promocode">
          <AgilityGrid item lg={9} md={9} sm={8} xs={6}>
            <AgilityTypography
              className="promocode-label-wrapper"
              variant="subtitle2"
              component="label"
              align="left"
              css={theme.custom.PlanFilter.searchField.helperTextCss}
            >
              <span css={theme.custom.PromoPopup.PromoPopupTextCss}>
                {i18n.t('promocode.textfield.label')}
              </span>
            </AgilityTypography>
            <AgilityTextField
              disabled={validCode || !props.isActive}
              onChange={handlePromoCodeChange}
              value={promoCode}
              data-test-id="txt-promo-code"
              fullWidth
              variant="outlined"
              error={errors && errors.promoCode ? true : false}
              helperText={errors && errors.promoCode ? errors.promoCode : ''}
              InputProps={{
                placeholder: i18n.t('promocode.textfield.placeholder'),
                endAdornment: (
                  <InputAdornment position="end">
                    {getPromoCodeEndIcon()}
                  </InputAdornment>
                ),
                maxLength: PROMO_CODE_MAX_LENGTH,
              }}
            />
          </AgilityGrid>
          <AgilityGrid item lg={3} md={3} sm={4} xs={6}>
            <AgilityButton
              loading={loading}
              data-test-id="btn-promo-code"
              label={getLoadingMessage()}
              variant="contained"
              color="primary"
              type="primary"
              disabled={promoCode === '' || !formIsValid || !props.isActive}
              onClick={onPromoCodeClick}
              buttonCss={{
                ...theme.custom.PlanFilter.searchField.buttonCss,
                ...theme.custom.PromoPopup.buttonCss,
              }}
            />
          </AgilityGrid>
          {validCode && process.env.REACT_APP_PROMO_BENIFIT_URL && (
            <AgilityTypography variant="subtitle1" component="p" align="left">
              <Link
                className="promocode-link"
                data-test-id="promo-code-link"
                href={
                  props.isActive ? process.env.REACT_APP_PROMO_BENIFIT_URL : ''
                }
                target="_blank"
              >
                {i18n.t('promocode.url.label')}
              </Link>
            </AgilityTypography>
          )}
        </AgilityGrid>
      </AgilityInfoDialog>
    </React.Fragment>
  );
};
PromoCode.propTypes = {
  setOfferFilterPromoCode: func,
  isRequiredValidationNeeded: bool,
  onPromoCodeChange: func,
  updateTimestamp: number,
  onPromoCodeRemove: func,
  isActive: bool,
};
PromoCode.defaultProps = {
  isRequiredValidationNeeded: false,
  updateTimestamp: 0,
  onPromoCodeRemove: () => {},
  isActive: true,
};
export default PromoCode;
