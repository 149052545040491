import i18n from 'i18next';

export const noYesOptions = [
  { label: i18n.t('signup.no'), value: 'no' },
  { label: i18n.t('signup.yes'), value: 'yes' },
];
export const yesNoOptions = [
  { label: i18n.t('signup.yes'), value: 'yes' },
  { label: i18n.t('signup.no'), value: 'no' },
];

export const preferContact = [
  { label: i18n.t('yourDetail.preferContactEmail.text'), value: '0' },
  { label: i18n.t('yourDetail.preferContactPhone.text'), value: '1' },
];

export const cardType = [
  { label: i18n.t('yourDetail.cardTypeDebit.text'), value: 'debitCard' },
  { label: i18n.t('yourDetail.cardTypeCredit.text'), value: 'Credit Card' },
];

export const propertyPlanOption = [
  {
    label:
      process.env.REACT_APP_ON_SWITCH_SHOW_RETAILER_NAME === 'true'
        ? i18n.t('yourProperty.propertyPlanOptionSwitch.text', {
            companyType: process.env.REACT_APP_COPYRIGHT_COMPANY,
          })
        : i18n.t('yourProperty.propertyPlanOptionSwitchPrimary.text'),
    value: 'Transfer',
  },
  {
    label: i18n.t('yourProperty.propertyPlanOptionMoveIn.text'),
    value: 'MoveIn',
  },
];

export const ownerType = [
  { label: i18n.t('yourProperty.ownerTypeRent.text'), value: 'rent' },
  { label: i18n.t('yourProperty.ownerTypeOwn.text'), value: 'own' },
];

export const yesNoNotSureOptions = [
  { label: i18n.t('yourProperty.yesNoNotSureOptionsNo.text'), value: 'no' },
  { label: i18n.t('yourProperty.yesNoNotSureOptionsYes.text'), value: 'yes' },
  {
    label: i18n.t('yourProperty.yesNoNotSureOptionsNotSure.text'),
    value: 'not sure',
  },
];

export const paymentCardInfo = {
  cardNumber: '●●●● ●●●● ●●●● ●●●●',
  cardIcon: 'credit-card',
};

export const getPaymentMethods = (
  isReference,
  isManualPayment,
  isCreditCardPayment
) => {
  return [
    ...(isCreditCardPayment
      ? [
          {
            label: i18n.t('paymentMethod.paymentMethodCard.text'),
            value: 'CRDCARD',
          },
        ]
      : []),
    {
      label: i18n.t('paymentMethod.paymentMethodAccount.text'),
      value: 'DIRECT',
    },
    // ...isReference || isManualPayment ? [{ label: i18n.t('paymentMethod.manualPayment.text'), value: 'CHEQUE' }] : []
  ];
};

let receiveBillTypeList = [
  {
    label: i18n.t('paymentdetails.receiveBillTypeEmail.text'),
    value: 'Email',
  },
];
if (process.env.REACT_APP_DISABLE_RECEIVE_BILL_BY_POST !== 'true') {
  receiveBillTypeList.push({
    label: i18n.t('paymentdetails.receiveBillTypePost.text'),
    value: 'Post',
  });
}
export const receiveBillType = receiveBillTypeList;

export const sameDeliveryMethodType = [
  { label: i18n.t('paymentdetails.sameDeliveryMethod.yes'), value: 'yes' },
  { label: i18n.t('paymentdetails.sameDeliveryMethod.no'), value: 'no' },
];

export const yesNoNotSureOptionsProperty = [
  { label: i18n.t('yourProperty.yesNoNotSureOptionsNo.text'), value: 'false' },
  { label: i18n.t('yourProperty.yesNoNotSureOptionsYes.text'), value: 'true' },
  {
    label: i18n.t('yourProperty.yesNoNotSureOptionsNotSure.text'),
    value: 'unknown',
  },
];

export const yesNoOptionsWithBooleanValue = [
  { label: i18n.t('signup.no'), value: false },
  { label: i18n.t('signup.yes'), value: true },
];

export const identifierDialogContent = [
  {
    header: i18n.t('siteIdentifier.tooltip.heading'),
    body: i18n.t('siteIdentifier.tooltip.text', {
      interpolation: { escapeValue: false },
    }),
  },
];

export const billingFrequencyList = [
  {
    display: i18n.t('paymentdetails.paybill.monthly'),
    code: 'MONTHLY',
  },
  {
    display: i18n.t('paymentdetails.paybill.quarterly'),
    code: 'QUARTERLY',
  },
];

export const vsiAppointmentTime = [
  { label: '9am - 2pm', value: '09:00' },
  { label: '10am - 3pm', value: '10:00' },
  { label: '11am - 4pm', value: '11:00' },
  { label: '12pm - 5pm', value: '12:00' },
  { label: '1pm - 6pm', value: '13:00' },
];

export const vsiAccessMethod = [
  { label: 'Yes, I’ll be home', value: 'customer_on_site' },
  {
    label: 'I’ll leave keys in the letter box',
    value: 'keys_letter_box',
  },
  {
    label: 'I’ll leave keys in the meter box',
    value: 'keys_meter_box',
  },
];
