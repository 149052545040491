/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { array, string } from 'prop-types';
import i18n from 'i18next';
import {
  AgilitySelectField,
  AgilityInfoDialog,
  AgilityTypography,
} from '../../../../../AgilityComponents';
import {
  isEmpty,
  roundToDecimal,
  roundToDecimalCents,
} from '../../../../utils/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import greenPowerLogo from '../../../../../assets/images/greenpower-logo.png';
import { useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { selectFirstGreenpowerOption } from 'App/customConfig';

const GreenpowerOptions = props => {
  const { options } = props;
  const theme = useTheme();
  const [greenpowerList, setGreenpowerList] = useState([]);
  const [selectedGreenpower, setSelectedGreenpower] = useState(0);
  const [openedDialog, setOpenedDialog] = useState(false);

  useEffect(() => {
    let greenpowerOptions = [
      {
        value: 0,
        label: 'Please select GreenPower',
      },
    ];
    options.sort((a, b) => {
      return a.percentage_green - b.percentage_green;
    });
    greenpowerOptions = greenpowerOptions.concat(
      options
        .filter(opt => opt.id && opt.percentage_green)
        .map(opt => {
          return {
            value: opt.id,
            label: percentageGreenpower(opt.percentage_green),
          };
        })
    );
    setGreenpowerList(greenpowerOptions);
    if (selectFirstGreenpowerOption || greenpowerOptions.length === 2) {
      setSelectedGreenpower(greenpowerOptions[1].value);
      if (greenpowerOptions[1].value > 0) {
        props.onSelectGreenpower(
          options.filter(opt => opt.id === greenpowerOptions[1].value)[0]
        );
      }
    }
  }, [options]);

  const percentageGreenpower = value => {
    return value ? `${parseInt(value)}% Greenpower` : '';
  };

  const priceGreenpower = ({
    charge_type_id: chargeTypeId,
    charge_amount: chargeAmount,
    charge_percentage: chargePercentage,
    is_gst_exempt: isGstExempt,
  }) => {
    switch (chargeTypeId) {
      case 1: // CHARGE_TYPE_DAILY_AMOUNT
        return `$${roundToDecimal(chargeAmount, false, isGstExempt)} per day`;
      case 2: // CHARGE_TYPE_WEEKLY_AMOUNT
        return `$${roundToDecimal(chargeAmount, false, isGstExempt)} per week`;
      case 3: // CHARGE_TYPE_MONTHLY_AMOUNT
        return `$${roundToDecimal(chargeAmount, false, isGstExempt)} per month`;
      case 4: // CHARGE_TYPE_PER_CONSUMPTION_AMOUNT
        return `${roundToDecimalCents(
          chargeAmount,
          true,
          isGstExempt
        )} cents per kWh`;
      case 5: // CHARGE_TYPE_CONSUMPTION_PERCENTAGE
        return `${chargePercentage}% per kWh`;
      default:
        // CHARGE_TYPE_BILL_PERCENTAGE
        return `${chargePercentage}% of Bill`;
    }
  };

  const handleGreenpowerChange = event => {
    setSelectedGreenpower(event.target.value);
    if (event.target.value > 0) {
      props.onSelectGreenpower(
        options.filter(opt => opt.id === event.target.value)[0]
      );
    }
  };

  const onGreenpowerClick = event => {
    setOpenedDialog(true);
  };

  const closeDialog = () => {
    setOpenedDialog(false);
  };

  return (
    <React.Fragment>
      {!isEmpty(greenpowerList) && greenpowerList.length > 1 && (
        <div className="mt-2 mb-2">
          <AgilitySelectField
            data-test-id="greenpower-options-id"
            options={greenpowerList}
            variant="outlined"
            fullWidth
            className={`greenpower-options mb-0 ${
              selectedGreenpower === 0 ? 'placeholder-item' : ''
            }`}
            value={selectedGreenpower}
            onChange={handleGreenpowerChange}
            showlabel={true}
            placeholder={i18n.t('compareplan.greenpower.placeholder')}
            MenuProps={{ classes: { paper: 'greenpower-menu-item' } }}
            isError={props.errors}
            helperText={
              props.errors && i18n.t('compareplan.errorMessage.greenpower')
            }
          />
          <FontAwesomeIcon
            icon={['fas', `info-circle`]}
            data-test-id="tooltip-icon-id"
            onClick={onGreenpowerClick}
            css={css`
              font-size: 25px;
              color: ${theme.palette.grey['500']};
              margin-left: 10px;
              margin-top: 8px;
            `}
          />
        </div>
      )}

      <AgilityInfoDialog
        data-test-id="greenpower-dialog"
        className="full-page-popup-sm"
        fullWidth
        maxWidth="md"
        open={openedDialog}
        title={
          <img
            data-test-id="feature-list-greenpower"
            src={greenPowerLogo}
            alt="GreenPower™ Logo"
            css={css`
              max-width: 30%;
            `}
          />
        }
        okText={i18n.t('greenpower.dialog.okText')}
        okClick={closeDialog}
        closeClick={closeDialog}
      >
        <AgilityTypography variant="body2" component="p">
          {i18n.t('greenpower.dialog.content', {
            retailer:
              process.env.REACT_APP_BRAND_CODE === 'TANGO'
                ? 'Tango Energy'
                : process.env.REACT_APP_RETAILER_NAME,
          })}
        </AgilityTypography>
        <TableContainer className="greenpower-dialog-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '25%' }}>
                  {i18n.t('greenpower.dialog.optionText')}
                </TableCell>
                <TableCell>
                  {i18n.t('greenpower.dialog.descriptionText')}
                </TableCell>
                <TableCell style={{ width: '25%' }}>
                  {i18n.t('greenpower.dialog.priceText')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {options.map(opt => (
                <TableRow key={opt.id}>
                  <TableCell>
                    {percentageGreenpower(opt.percentage_green)}
                  </TableCell>
                  <TableCell>{opt.description}</TableCell>
                  <TableCell>{priceGreenpower(opt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AgilityInfoDialog>
    </React.Fragment>
  );
};
GreenpowerOptions.propTypes = {
  productType: string,
  options: array,
};
GreenpowerOptions.defaultProps = {
  options: [],
};
export default GreenpowerOptions;
