/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { Box, Typography, Grid, useTheme, Button } from '@material-ui/core';
import { t } from 'i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SolarCheckbox = ({ value: hasSolar, onChange, disabled }) => {
  const theme = useTheme();

  const handleOnChange = React.useCallback(() => {
    if (!disabled) {
      onChange(!hasSolar);
    }
  }, [hasSolar, onChange, disabled]);

  const { menuButtonCss, textCss, iconCss } = theme.custom.PlanFilter.filters;

  const iconMarginTop = React.useMemo(() => {
    return (
      (menuButtonCss.borderWidth > 0 ? menuButtonCss.borderWidth * 2 : 0) + 1
    );
  }, [menuButtonCss.borderWidth]);

  return (
    <Button
      fullWidth
      css={{
        alignItems: 'center',
        ...menuButtonCss,
        ...textCss,
      }}
      direction="row"
      disabled={disabled || false}
      onClick={handleOnChange}
    >
      <Grid
        container
        direction="row"
        alignContent={'center'}
        css={{
          height: 44 - menuButtonCss.borderWidth * 2,
          alignItems: 'center',
        }}
      >
        <Grid item css={{ width: 36, textAlign: 'center', paddingRight: 12 }}>
          <FontAwesomeIcon
            icon={['fas', 'sun']}
            css={{
              marginTop: iconMarginTop,
              ...iconCss,
            }}
          />
        </Grid>
        <Grid item css={{ flexGrow: 1 }}>
          <Typography css={textCss}>
            {t('compareplan.havesolar.text')}
          </Typography>
        </Grid>
        <Grid
          item
          css={{
            width: 24,
            textAlign: 'center',
            alignSelf: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={hasSolar ? ['fas', 'check'] : ['fas', 'times']}
            css={{
              marginTop: iconMarginTop,
              ...iconCss,
            }}
          />
        </Grid>
      </Grid>
    </Button>
  );
};

export default SolarCheckbox;
