import React from 'react';
import Popover from '@material-ui/core/Popover';
import './popover.scss';
import AgilityTypography from '../Typography';
import { string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AgilityInfoPopover = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <div
        className="info-icon"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <FontAwesomeIcon icon={['fas', `info-circle`]} />
      </div>
      <Popover
        id="mouse-over-popover"
        classes={{
          root: 'popover',
          paper: 'paper',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <AgilityTypography>{props.popoverText}</AgilityTypography>
      </Popover>
    </React.Fragment>
  );
};
AgilityInfoPopover.propTypes = {
  popoverText: string,
};
AgilityInfoPopover.defaultProps = {
  popoverText: '',
};
export default AgilityInfoPopover;
