import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from '@material-ui/core';
import i18n from 'i18next';
import IframeResizer from 'iframe-resizer-react';

import { AgilityTypography } from 'Src/AgilityComponents';

import Cards from 'Images/cards.png';
import { Alert } from '@material-ui/lab';

const CreditCardForm = ({ onError, onSuccess }) => {
  const frameUrl = useSelector(
    state => state?.onBoarding?.creditCardIframe?.result?.vendorUrl
  );
  const [hasError, setHasError] = React.useState(false);

  const handleFrameMessage = React.useCallback(
    event => {
      const { data, origin } = event;

      if (
        origin !== process.env.REACT_APP_PAYMENT_API_ENDPOINT ||
        data?.event_id !== 'payment_callback'
      ) {
        return;
      }

      if (data.response.isSuccess) {
        setHasError(false);
        onSuccess();
      } else {
        setHasError(true);
        onError();
      }
    },
    [onError, onSuccess]
  );

  React.useEffect(() => {
    window.addEventListener('message', handleFrameMessage, false);

    return () => {
      document.removeEventListener('message', handleFrameMessage);
    };
  });

  return (
    <React.Fragment>
      <div className="mb-2">
        <AgilityTypography
          variant="subtitle1"
          component="p"
          className="desc"
          display="inline"
        >
          {i18n.t('paymentByCard.text.acceptedCard')}
        </AgilityTypography>
        <img src={Cards} alt="cards" className="ml-1" />
      </div>
      <AgilityTypography
        variant="subtitle1"
        component="p"
        className="desc mb-2"
      >
        {i18n.t('paymentByCard.text.acceptedCardInfo')}
      </AgilityTypography>
      <Divider light className="mt-2 mb-2" />

      {hasError && (
        <Alert severity="error">
          We were unable to process your card. Please check your card details
          and try again
        </Alert>
      )}

      {frameUrl && (
        <IframeResizer
          inPageLinks
          src={frameUrl}
          checkOrigin={[process.env.REACT_APP_PAYMENT_API_ENDPOINT]}
          style={{
            width: '1px',
            minWidth: '100%',
            height: '400px',
            border: 0,
          }}
          width="100%"
        />
      )}
      {!frameUrl && <AgilityTypography>Loading..</AgilityTypography>}
    </React.Fragment>
  );
};

export default CreditCardForm;
