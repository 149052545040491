/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { bool, oneOf } from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import './button.scss';
// import { useTheme } from '@material-ui/core/styles';

const AgilityButton = props => {
  // const theme = useTheme();
  // const invertedPrimary = css`
  //   background: ${theme.palette.primary.main};
  //   color: ${theme.palette.primary.contrastText} !important;
  //   border-color: ${theme.palette.primary.contrastText} !important;
  //   &.Mui-disabled {
  //     opacity: 0.5;
  //   }
  // `;

  return (
    <div className={`btn-wrapper ${props.className ?? ''}`}>
      <Button
        disabled={props.disabled || props.loading}
        href={props.href}
        fullWidth={props.fullWidth}
        onClick={props.onClick}
        color={props.color}
        variant={props.variant}
        classes={{ root: `btn ${props.type} ${props.buttonClasses}` }}
        disableElevation
        size={props.size}
        type={props.htmltype}
        // css={invertedPrimary}
        endIcon={props.endIcon}
        css={props.buttonCss ?? null}
        target={props.target}
      >
        {props.label}
      </Button>
      {props.loading && <CircularProgress size={24} />}
    </div>
  );
};
AgilityButton.propTypes = {
  loading: bool,
  size: oneOf(['small', 'medium', 'large']),
  htmltype: oneOf(['button', 'submit']),
};
AgilityButton.defaultProps = {
  loading: false,
  size: 'medium',
  htmltype: 'button',
};
export default AgilityButton;
