/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { bool, array, string } from 'prop-types';
import { AgilityTypography } from 'Src/AgilityComponents';
import { useTheme } from '@material-ui/core/styles';
import {
  isEmpty,
  roundToDecimal,
  roundToDecimalCents,
  roundToDecimalGSTCents,
} from '../../../../utils/helper';

const RateContent = props => {
  const theme = useTheme();
  const { rateData, feedinData, header, productType, selectedGreenpower } =
    props;

  const rateDesc = css`
    color: ${theme.palette.grey['500']};
  `;

  const generateRateItems = (rates, applyGst) => {
    return rates.map((_rate, key) => (
      <div
        key={`rate-item__${key}`}
        className="rates__container rates__items w-full"
      >
        <div className="rate__item-container rate__item-container__desc">
          {productType === 'electricity' && (
            <AgilityTypography
              data-test-id={_rate.range}
              variant="subtitle2"
              component="p"
              className="rate__item rate__item-units-start"
            >
              {_rate.range}
            </AgilityTypography>
          )}
          {productType === 'gas' && (
            <AgilityTypography
              data-test-id={_rate.formattedRangeFull || _rate.range}
              variant="subtitle2"
              component="p"
              className="rate__item rate__item-units-start"
            >
              {_rate.formattedRangeFull || _rate.range}
            </AgilityTypography>
          )}
        </div>
        <div className="rate__item-container rate__item-container__value">
          <AgilityTypography
            data-test-id={_rate.rate}
            variant="subtitle2"
            component="p"
            className="rate__item rate__item-value"
          >
            {applyGst
              ? `${roundToDecimalGSTCents(_rate.rate)} \u00A2/${
                  _rate.unit
                }${demandPeriod(_rate)}`
              : `${roundToDecimalCents(_rate.rate)} \u00A2/${
                  _rate.unit
                }${demandPeriod(_rate)}`}
          </AgilityTypography>
        </div>
      </div>
    ));
  };

  /**
   * Append the demand measurement period, if the
   * rate has a demand type unit (kW and kVA
   * are only used in demand contexts)
   */
  const demandPeriod = rate => {
    if (rate.unit !== 'kW' && rate.unit !== 'kVA') {
      return '';
    }

    switch (rate.measurement_period) {
      case 'Daily':
        return '/day';
      case 'Monthly':
        return '/month';
      case '2 Monthly':
        return '/2 months';
      case 'Quarterly':
        return '/quarter';
      case '6 Monthly':
        return '/6 months';
      case 'Annually':
        return '/year';
      default:
        return '/day';
    }
  };

  const priceGreenpower = ({
    charge_type_id: chargeTypeId,
    charge_amount: chargeAmount,
    charge_percentage: chargePercentage,
    is_gst_exempt: isGstExempt,
  }) => {
    switch (chargeTypeId) {
      case 1: // CHARGE_TYPE_DAILY_AMOUNT
        return `${roundToDecimal(chargeAmount, false, isGstExempt)} $/day`;
      case 2: // CHARGE_TYPE_WEEKLY_AMOUNT
        return `${roundToDecimal(chargeAmount, false, isGstExempt)} $/week`;
      case 3: // CHARGE_TYPE_MONTHLY_AMOUNT
        return `${roundToDecimal(chargeAmount, false, isGstExempt)} $/month`;
      case 4: // CHARGE_TYPE_PER_CONSUMPTION_AMOUNT
        return `${roundToDecimalCents(chargeAmount, true, isGstExempt)} ¢/kWh`; // chargeAmount is in $/kWh in Uconx
      case 5: // CHARGE_TYPE_CONSUMPTION_PERCENTAGE
        return `${chargePercentage}% /kWh`;
      default:
        // CHARGE_TYPE_BILL_PERCENTAGE
        return `${chargePercentage}% of Bill`;
    }
  };

  const generateRateSection = (
    data,
    dataType = 'fuel-rate',
    applyGst = true
  ) => {
    if (data.length < 1) {
      return;
    }
    return data.map((_rate, key) => (
      <div key={`${dataType}-${key}`} data-test-id={header}>
        <div
          className="rates__container header w-full"
          data-test-id={_rate.description}
        >
          <div className="rate__item-container">
            <AgilityTypography
              data-test-id={_rate.description}
              variant="subtitle2"
              component="p"
              className="text1 rate__price-class"
              css={rateDesc}
              tooltipText={_rate.period}
              showInfo={_rate.period ? true : false}
            >
              {_rate.description}
            </AgilityTypography>
          </div>
        </div>
        {generateRateItems(_rate.rates, applyGst)}
      </div>
    ));
  };

  const generateFeedinSection = () => {
    if (feedinData.length < 1) {
      return;
    }

    return (
      <React.Fragment>
        <AgilityTypography
          variant="h4"
          component="h4"
          className="text-center"
          css={css`
            color: ${theme.palette.primary.main};
            margin-top: 20px;
          `}
        >
          Solar Feed In
        </AgilityTypography>
        <AgilityTypography
          variant="subtitle1"
          component="p"
          className="text-center"
        >
          All solar feed-in rates are exclusive of GST
        </AgilityTypography>

        {generateRateSection(feedinData, 'feedin', false)}
      </React.Fragment>
    );
  };

  const generateGreenpowerSection = greenpower => {
    if (isEmpty(greenpower)) {
      return;
    }

    return (
      <div data-test-id={header}>
        <div className="rates__container header w-full">
          <div className="rate__item-container">
            <AgilityTypography
              variant="subtitle2"
              component="p"
              className="text1 rate__price-class"
            ></AgilityTypography>
          </div>
        </div>
        <div
          key={`rate-item__greenpower`}
          className="rates__container rates__items w-full"
        >
          <div className="rate__item-container rate__item-container__desc">
            <AgilityTypography
              data-test-id={greenpower.name}
              variant="subtitle2"
              component="p"
              className="rate__item rate__item-units-start"
            >
              {greenpower.name}
            </AgilityTypography>
          </div>
          <div className="rate__item-container rate__item-container__value">
            <AgilityTypography
              data-test-id={priceGreenpower(greenpower)}
              variant="subtitle2"
              component="p"
              className="rate__item rate__item-value"
            >
              {priceGreenpower(greenpower)}
            </AgilityTypography>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="rates__card rates__section">
      <div className="rates">
        {generateRateSection(rateData)}
        {generateGreenpowerSection(selectedGreenpower)}
        {generateFeedinSection()}
      </div>
    </div>
  );
};
RateContent.propTypes = {
  rateData: array,
  feedinData: array,
  showDetail: bool,
  showRefHeading: bool,
  header: string,
  productType: string,
};
RateContent.defaultProps = {
  rateData: [],
  feedinData: [],
  showDetail: false,
  showRefHeading: false,
  header: '',
  productType: 'electricity',
};

export default RateContent;
