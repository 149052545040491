import { connect } from 'react-redux';
import abnNumberLookup from './AbnNumberLookup';
import {
  searchAbnNumber,
  setGstRegisteredAt,
  setAbnNumber,
  setAcnNumber,
  setOrganisationTypeId,
  setOrganisationTypeDescription,
  setOrganisationIsTrust,
} from 'App/modules/onBoarding/action';

const mapStateToProps = (state, props) => {
  return {
    abnNumber: state.onBoarding.abnNumber,
    gstRegisteredAt: state.onBoarding.gstRegisteredAt,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    searchAbnNumber: term => searchAbnNumber(term),
    setGstRegisteredAt: gstRegisteredAt =>
      dispatch(setGstRegisteredAt(gstRegisteredAt)),
    setAbnNumber: abn => dispatch(setAbnNumber(abn)),
    setAcnNumber: acn => dispatch(setAcnNumber(acn)),
    setOrganisationTypeId: id => dispatch(setOrganisationTypeId(id)),
    setOrganisationTypeDescription: description =>
      dispatch(setOrganisationTypeDescription(description)),
    setOrganisationIsTrust: isTrust =>
      dispatch(setOrganisationIsTrust(isTrust)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(abnNumberLookup);
