import { connect } from 'react-redux';
import companyDetail from './CompanyDetail';
import {
  searchBusinessName,
  searchAbnNumber,
  setBusinessName,
  setAbnNumber,
  setAcnNumber,
  setGstRegisteredAt,
  setTradingName,
  setOrganisationTypeId,
  setOrganisationTypeDescription,
  setOrganisationIsTrust,
} from 'App/modules/onBoarding/action';

const mapStateToProps = (state, props) => {
  return {
    businessName: state.onBoarding.businessName,
    abnNumber: state.onBoarding.abnNumber,
    acnNumber: state.onBoarding.acnNumber,
    gstRegisteredAt: state.onBoarding.gstRegisteredAt,
    tradingName: state.onBoarding.tradingName,
    organisationTypeId: state.onBoarding.organisationTypeId,
    organisationTypeDescription: state.onBoarding.organisationTypeDescription,
    organisationIsTrust: state.onBoarding.organisationIsTrust,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    searchBusinessName: term => searchBusinessName(term),
    searchAbnNumber: term => searchAbnNumber(term),
    setBusinessName: name => dispatch(setBusinessName(name)),
    setAbnNumber: abn => dispatch(setAbnNumber(abn)),
    setAcnNumber: acn => dispatch(setAcnNumber(acn)),
    setTradingName: tradingName => dispatch(setTradingName(tradingName)),
    setGstRegisteredAt: gstRegisteredAt =>
      dispatch(setGstRegisteredAt(gstRegisteredAt)),
    setOrganisationTypeId: organisationTypeId =>
      dispatch(setOrganisationTypeId(organisationTypeId)),
    setOrganisationTypeDescription: description =>
      dispatch(setOrganisationTypeDescription(description)),
    setOrganisationIsTrust: isTrust =>
      dispatch(setOrganisationIsTrust(isTrust)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(companyDetail);
