import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getReferName } from './actions';
import Refer from 'App/pages/Refer/Refer';

const mapStateToProps = (state, props) => {
  return {
    ...props,
    name: state.pages.refer.name,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getReferName: data => dispatch(getReferName(data)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Refer));
