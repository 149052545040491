import {
  isNumericOnly,
  validateFields,
  isNotEmpty,
} from 'App/utils/validationHelper';

import { disableRentOwnQuestion } from 'App/customConfig';

const TRANSFER_TYPE_MOVE_IN = 'MoveIn';
const validators = {
  defaultChecker: isNotEmpty,
  fields: {
    transferType: {
      preCondition: '',
      defaultErrorText: 'yourProperty.errorMessage.plan',
      conditions: [],
    },
    transferDate: {
      preCondition: '',
      defaultErrorText: 'yourProperty.errorMessage.moveInDate',
      conditions: [],
    },
    hasDifferentPostalAddress: {
      preCondition: '',
      defaultErrorText: 'yourProperty.errorMessage.postalAddress',
      conditions: [],
    },
    streetAddress: {
      preCondition: '',
      defaultErrorText: 'yourProperty.errorMessage.streetAddress',
      conditions: [],
    },
    suburb: {
      preCondition: '',
      defaultErrorText: 'yourProperty.errorMessage.suburb',
      conditions: [],
    },
    state: {
      preCondition: '',
      defaultErrorText: 'yourProperty.errorMessage.state',
      conditions: [],
    },
    postcode: {
      preCondition: '',
      defaultErrorText: 'yourProperty.errorMessage.postcode',
      conditions: [
        {
          condition: isNumericOnly,
          errorText: 'yourProperty.invalid.postcode',
        },
      ],
    },
    propertyOwner: {
      preCondition: '',
      isOptional: disableRentOwnQuestion,
      defaultErrorText: 'yourProperty.errorMessage.ownerType',
      conditions: [],
    },
    isPowerOptionAvailable: {
      preCondition: '',
      defaultErrorText: 'yourProperty.errorMessage.isPowerOn',
      conditions: [],
    },
    hasConnection: {
      preCondition: 'isPowerOptionAvailable',
      defaultErrorText: 'yourProperty.errorMessage.isPowerOn',
      conditions: [],
    },
    hasObstacles: {
      preCondition: '',
      defaultErrorText: 'yourProperty.errorMessage.meterObstacles',
      conditions: [],
    },
    obstacles: {
      preCondition: '',
      defaultErrorText: 'yourProperty.errorMessage.meterObstaclesDescription',
      conditions: [],
    },
    risks: {
      preCondition: '',
      defaultErrorText: 'yourProperty.errorMessage.meterAccessRisksDescription',
      conditions: [],
    },
    hasPreferredMeterReader: {
      preCondition: '',
      defaultErrorText: 'yourProperty.errorMessage.meterCoordinatorOption',
      conditions: [],
    },
    preferredMeterReader: {
      preCondition: '',
      defaultErrorText: 'yourProperty.errorMessage.meterCoordinator',
      conditions: [],
    },
    acceptMoveInTerms: {
      preCondition: inputForm =>
        inputForm['transferType'] === TRANSFER_TYPE_MOVE_IN,
      defaultErrorText: 'yourProperty.errorMessage.acceptTerms',
      conditions: [],
    },
    vsiAppointmentTime: {
      preCondition: inputForm => inputForm['isVsiRequirement'] === true,
      defaultErrorText: 'yourProperty.errorMessage.vsiAppointmentTime',
      conditions: [],
    },
    vsiAccessMethod: {
      preCondition: inputForm => inputForm['isVsiRequirement'] === true,
      defaultErrorText: 'yourProperty.errorMessage.vsiAccessMethod',
      conditions: [],
    },
    solarType: {
      defaultErrorText: 'yourProperty.errorMessage.solarType',
      conditions: [],
    },
  },
};

const validate = (touched, fields) => {
  const validationFormData = validateFields(validators, fields, touched);
  return {
    ...validationFormData,
  };
};
export default validate;
