import React from 'react';
import { AgilityTypography } from 'Src/AgilityComponents';
import { array } from 'prop-types';

const PropertyAddress = props => {
  const { displayAddresses } = props;
  return (
    <React.Fragment>
      {displayAddresses.map((data, index) => {
        const headerType = index > 0 ? 'h4' : 'h3';
        return (
          <AgilityTypography
            key={index}
            variant={headerType}
            component={headerType}
            data-test-id={`address-line-${index}`}
          >
            {data}
          </AgilityTypography>
        );
      })}
    </React.Fragment>
  );
};
PropertyAddress.propTypes = {
  displayAddresses: array,
};
PropertyAddress.defaultProps = {
  displayAddresses: [],
};

export default PropertyAddress;
