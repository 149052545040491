/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useState, useEffect } from 'react';
import i18n from 'i18next';
import { showTranslations } from 'translation-check';
import apiCall from 'App/modules/apiWrapper';
import { useTheme } from '@material-ui/core/styles';

const DebugBar = props => {
  const theme = useTheme();
  const defaultEndpoint = process.env.REACT_APP_API_ENDPOINT;
  const initialEndpoint =
    localStorage.getItem('apiEndpoint') || defaultEndpoint;

  const [showEndpointOptions, setShowEndpointOptions] = useState(false);
  const [endpoint, setEndpoint] = useState(initialEndpoint);
  const [endpointList, setEndpointList] = useState([defaultEndpoint]);

  // overwrite the Api object on the window.
  const setNewEndpoint = endpoint => {
    // If we're using the default endpoint, remove it from localStorage
    if (endpoint === defaultEndpoint) {
      localStorage.removeItem('apiEndpoint');
    } else {
      localStorage.setItem('apiEndpoint', endpoint);
    }
    setEndpoint(endpoint);
    const apiObj = apiCall(endpoint);
    window.Api = apiObj;
  };

  // Get the feature-testing URLs
  useEffect(() => {
    fetch('https://github.testing.uconx.com.au/list/json.php')
      .then(response => response.json())
      .then(data => {
        let featureUrls = data.map(endpoint => {
          // remove trailing slash
          return endpoint.url.slice(0, -1);
        });
        featureUrls.push(defaultEndpoint);
        setEndpointList(featureUrls);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [defaultEndpoint]);

  // overwrite the default paddings for the debug bar
  const pagecss = `
    @media (max-width: ${theme.breakpoints.values.md - 1}px) {
       .page-wrapper { padding-top: 3px; }
       .page-wrapper .wrapper { padding-top: 0; }
      }
    }
    @media (min-width: 600px) and (max-width: 960px) {
       .page-wrapper { padding-top: 70px; }
       .page-wrapper .wrapper { padding-top: 0; }
      }
    }

  `;

  return (
    <div
      css={css`
        height: 35px;
        background: red;
        color: white;
        padding: 8px 14px;
        display: flex;
        justify-content: space-between;
        @media (max-width: 959px) {
          height: 3px;
          font-size: 1px;
          padding: 0;
          color: red;
        }
      `}
    >
      <style>{pagecss}</style>
      <div>
        Build Environment:{' '}
        <strong>{process.env.REACT_APP_PLATFORM_ENV.toUpperCase()}</strong>.{' '}
        <span
          css={css`
            text-decoration: underline;
            cursor: pointer;
          `}
          onClick={e => {
            showTranslations(i18n);
          }}
        >
          View translations
        </span>
      </div>
      {!showEndpointOptions && (
        <div
          className="text-right"
          onClick={e => {
            setShowEndpointOptions(!showEndpointOptions);
          }}
        >
          API Endpoint:{' '}
          <strong>
            <u>{endpoint}</u>
          </strong>
        </div>
      )}
      {showEndpointOptions && (
        <div>
          <span>Set new endpoint: </span>
          <select onChange={e => setEndpoint(e.target.value)} value={endpoint}>
            {endpointList.map(e => (
              <option key={e} value={e}>
                {e}
              </option>
            ))}
          </select>

          <button
            onClick={e => {
              setShowEndpointOptions(!showEndpointOptions);
              setNewEndpoint(endpoint);
            }}
          >
            Set
          </button>
          <button
            onClick={e => {
              setShowEndpointOptions(!showEndpointOptions);
              setNewEndpoint(defaultEndpoint);
            }}
          >
            Reset to Default
          </button>
        </div>
      )}
    </div>
  );
};

export default DebugBar;
