import customConfig from './customConfig';
import { isEmpty } from 'App/utils/helper';

const brandCode = process.env.REACT_APP_BRAND_CODE ?? 'DEMO';
const partnerCode = process.env.REACT_APP_PARTNER_BRAND_CODE ?? false;

const initialState = {
  brand: {
    code: brandCode,
    isPartner: !isEmpty(partnerCode),
    isCovau: brandCode === 'COVAU',
    isNBE: brandCode === 'NBE',
    isGEE: brandCode === 'GEE',
    isSEG: brandCode === 'SEG',
    isTango: brandCode === 'TANGO',
    isFirstEnergy: brandCode === 'FIRST',
    isDemo: brandCode === 'DEMO',
    partner: {
      code: partnerCode,
      isMyConnect: partnerCode === 'MYCONNECT',
      isEmerald: partnerCode === 'EMERALD',
      isUnitedSolar: partnerCode === 'UNITEDSOLAR',
      isAccreditedPower: partnerCode === 'ACCREDITED',
      isBSolar: partnerCode === 'BSOLAR',
    },
  },
  hideNotificationSignup: sessionStorage.getItem('hideNotification') || false,
  customConfig,
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case 'HIDE_NOTIFICATION':
      return {
        ...state,
        hideNotificationSignup: true,
      };
    default:
      return { ...state };
  }
};

export default app;
