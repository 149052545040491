import i18n from 'i18next';
import { REFERRAL_NAME_API } from 'App/utils/constants';
import getListApiUrl from 'App/utils/helper';

export const REFERRAL_NAME = 'REFERRAL_NAME';

export const getReferName = data => dispatch => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(REFERRAL_NAME_API, { ref: data });
    window.Api.get(url)
      .then(result => {
        dispatch({
          type: REFERRAL_NAME,
          payload: result,
        });
        return resolve(result);
      })
      .catch(error => {
        // Dispatch the error first for logging purposes, then return an empty string as a fallback
        let result = '';
        dispatch({
          type: REFERRAL_NAME,
          payload: error,
        });
        dispatch({
          type: REFERRAL_NAME,
          payload: result,
        });
        return resolve(result);
      });
  });
};
