import { connect } from 'react-redux';
import AgentLogin from './AgentLogin';
import { validateAgentLogin } from './action';

const mapStateToProps = (state, props) => {
  return {
    ...props,
    agent: state.pages.login.agent,
    isAgentLoggedIn: state.pages.login.isAgentLoggedIn,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    validateAgentLogin: data => dispatch(validateAgentLogin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentLogin);
