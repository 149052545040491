/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useState } from 'react';
import { func, object, bool } from 'prop-types';
import i18n from 'i18next';
import {
  AgilityInfoDialog,
  AgilityGrid,
  AgilityTypography,
  AgilityButton,
} from 'Src/AgilityComponents';
import { isEmpty } from 'App/utils/helper';
import SearchLocation from 'App/components/Location';
import { useTheme } from '@material-ui/core/styles';

const SearchAddressDialog = props => {
  const theme = useTheme();
  const { open } = props;
  const [selectedLocation, setSelectedLocation] = useState({});

  const onSearchClick = () => {
    if (!isEmpty(selectedLocation)) {
      props.onSearchClick(selectedLocation);
    }
  };

  const onCloseClick = () => {
    props.onDialogCloseClick();
  };

  const onLocationSelected = (location, textFieldSearch) => {
    setSelectedLocation({
      location,
      textFieldSearch,
    });
  };

  return (
    <AgilityInfoDialog
      className="full-page-popup-sm search-address-dialog"
      data-test-id="search-address-dialog"
      fullWidth={true}
      maxWidth="sm"
      open={open}
      okClick={() => onCloseClick()}
      closeClick={() => onCloseClick()}
      showOkButton={false}
    >
      <AgilityGrid
        css={css`
          text-align: center;
        `}
      >
        <img
          css={{ maxHeight: '100px', maxWidth: '100%', height: 'auto' }}
          height="100%"
          src={theme.images.payBills}
          alt="Finding address"
        />
        <AgilityTypography variant="h4" component="h4" className="mt-2">
          {i18n.t('compareplan.searchAddress.heading')}
        </AgilityTypography>
      </AgilityGrid>

      <AgilityGrid
        container
        alignItems={'flex-end'}
        direction={'row'}
        spacing={2}
        css={css`
          margin-top: 20px;
        `}
      >
        <AgilityGrid item xs={12} sm={8}>
          <SearchLocation
            data-test-id="you-location-id"
            showTitle={false}
            onLocationSelected={onLocationSelected}
          />
        </AgilityGrid>
        <AgilityGrid
          item
          xs={12}
          sm={4}
          css={css`
            margin-bottom: 20px;
          `}
        >
          <AgilityButton
            data-test-id="filter-search-button"
            onClick={() => onSearchClick()}
            label={i18n.t('button.addressSearch.label.small')}
            variant="contained"
            color="primary"
            fullWidth={true}
            size="large"
            type="primary"
          />
        </AgilityGrid>
      </AgilityGrid>
    </AgilityInfoDialog>
  );
};

SearchAddressDialog.propTypes = {
  onDialogCloseClick: func,
  productDetails: object,
  open: bool,
  onSearchClick: func,
  planFilterData: object,
  setPlanFilters: func,
};

SearchAddressDialog.defaultProps = {
  productDetails: {},
  open: false,
  planFilterData: {},
};

export default SearchAddressDialog;
