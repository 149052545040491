import {
  STATE_LIST_API,
  METER_COORDINATORS_LIST_API,
  IDENTIFICATION_TYPE_API,
  SEARCH_BUSINESS_NAME_API,
  SEARCH_ABN_NUMBER_API,
  SALUTATION_LIST_API,
  CARD_LIST_API,
  PREFER_HEARING_API,
  CONCESSION_CARD_VALIDATE_API,
  VALIDATE_BANK_ACCOUNT_NUMBER,
  FETCH_MOVE_IN_DATES_API,
  SIGNUP_SUBMIT_API,
  GET_BILLING_FREQUENCY_API,
  VALIDATE_SIGNUP_URL_API,
  GENERATE_PAYMENT_REQUEST_API,
  VALIDATE_PIN_CODE,
  REQUEST_PIN_CODE_EMAIL,
  PHONE_VALIDATION_API,
  EMAIL_VALIDATION_API,
  SOLAR_TYPE_LIST_API,
  REQUEST_MOBILE_AUTHENTICATION,
  CHECK_MOBILE_CODE,
  CUSTOMER_LOG_ACTIVITY,
} from 'App/utils/constants';
import getListApiUrl, {
  getListForSelectField,
  getListForSelectFieldByKeys,
  getCurrentTimestamp,
  convertDateFromISOArray,
} from 'App/utils/helper';
import i18n from 'i18next';
import { SIGNUP_GET_API_ACTION } from 'App/pages/ComparePlans/action';
import {
  lookupMap,
  requestSiteDetails,
  referrerMap,
  summaryMap,
} from 'App/utils/data_adapter';
import { stateList } from 'App/tests/mock_data/listing_data';
import { billingFrequencyList } from 'App/utils/data';
import { allowsSameDayFuelInjections } from 'App/customConfig';
import { logSaleAction } from 'App/utils/geolocation';

export const FETCH_IDENTIFICATION_TYPE_LIST = 'FETCH_IDENTIFICATION_TYPE_LIST';
export const GET_CARD_LIST = 'GET_CARD_LIST';
export const GET_CREDITCARD_FRAME_RESPONSE = 'GET_CREDITCARD_FRAME_RESPONSE';
export const GET_INDUSTRY_LIST = 'GET_INDUSTRY_LIST';
export const GET_PAYMENT_DETAILS_ACTION = 'GET_PAYMENT_DETAILS';
export const GET_PAYMENT_FREQUENCY_LIST = 'GET_PAYMENT_FREQUENCY_LIST';
export const GET_PREFER_HEARING_LIST = 'GET_PREFER_HEARING_LIST';
export const GET_SALUTATION_LIST = 'GET_SALUTATION_LIST';
export const RESET_USER_DATA_ACTION = 'CLEAR';
export const SAVE_PROGRESS_IS_SUCCESS_ACTION =
  'SAVE_PROGRESS_IS_SUCCESS_ACTION';
export const SAVE_PROGRESS_LOADING_ACTION = 'SAVE_PROGRESS_LOADING_ACTION';
export const SAVE_PROGRESS_TRIGGER_ACTION = 'SAVE_PROGRESS_TRIGGER_ACTION';
export const SET_ABN_NUMBER_ACTION = 'SET_ABN_NUMBER_ACTION';
export const SET_ACN_NUMBER_ACTION = 'SET_ACN_NUMBER_ACTION';
export const SET_BUSINESS_NAME_ACTION = 'SET_BUSINESS_NAME_ACTION';
export const SET_GST_REGISTERED_AT_ACTION = 'SET_GST_REGISTERED_AT_ACTION';
export const SET_MOVE_IN_DATES_ACTION = 'SET_MOVE_IN_DATES_ACTION';
export const SET_ORGANISATION_IS_TRUST_ACTION =
  'SET_ORGANISATION_IS_TRUST_ACTION';
export const SET_ORGANISATION_TYPE_DESCRIPTION_ACTION =
  'SET_ORGANISATION_TYPE_DESCRIPTION_ACTION';
export const SET_ORGANISATION_TYPE_ID_ACTION =
  'SET_ORGANISATION_TYPE_ID_ACTION';
export const SET_PRIMARY_SITE_IDENTIFIER = 'SET_PRIMARY_SITE_IDENTIFIER';
export const SET_TEMPORAL_DATA = 'SET_TEMPORAL_DATA';
export const SET_TRADING_NAME_ACTION = 'SET_TRADING_NAME_ACTION';
export const TRIGGER_EMAIL_SETTER = 'TRIGGER_EMAIL_SETTER';
export const YOUR_PROPERTY_COORDINATORS_LIST_ACTION =
  'YOUR_PROPERTY_COORDINATORS_LIST_ACTION';
export const YOUR_PROPERTY_SOLAR_TYPE_LIST_ACTION =
  'YOUR_PROPERTY_SOLAR_TYPE_LIST_ACTION';
export const YOUR_PROPERTY_STATE_LIST_ACTION =
  'YOUR_PROPERTY_STATE_LIST_ACTION';

export function resetOnBoardingUserData() {
  return {
    type: RESET_USER_DATA_ACTION,
    payload: {},
  };
}

export const fetchSolarTypeList = () => dispatch => {
  return new Promise((resolve, reject) => {
    window.Api.get(SOLAR_TYPE_LIST_API)
      .then(result => {
        dispatch({
          type: YOUR_PROPERTY_SOLAR_TYPE_LIST_ACTION,
          payload: result,
        });
        resolve();
      })
      .catch(error => {
        reject({ message: i18n.t('yourProperty.solarType.error') });
      });
  });
};

export const fetchStateList = () => dispatch => {
  return new Promise((resolve, reject) => {
    window.Api.get(STATE_LIST_API)
      .then(result => {
        dispatch({
          type: YOUR_PROPERTY_STATE_LIST_ACTION,
          payload: getListForSelectField(stateList, true),
        });
        resolve();
      })
      .catch(error => {
        reject({ message: i18n.t('yourProperty.state.error') });
      });
  });
};

export const fetchCoordinatorsList = () => dispatch => {
  return new Promise((resolve, reject) => {
    window.Api.get(METER_COORDINATORS_LIST_API)
      .then(result => {
        dispatch({
          type: YOUR_PROPERTY_COORDINATORS_LIST_ACTION,
          payload: getListForSelectField(result, true),
        });
        return resolve();
      })
      .catch(error => {
        return reject({ message: i18n.t('yourProperty.coordinator.error') });
      });
  });
};

export const fetchIdentificationTypeList = () => dispatch => {
  return new Promise((resolve, reject) => {
    window.Api.get(IDENTIFICATION_TYPE_API)
      .then(result => {
        result = lookupMap(result);
        dispatch({
          type: FETCH_IDENTIFICATION_TYPE_LIST,
          payload: getListForSelectField(result, true),
        });
        return resolve();
      })
      .catch(error => {
        return reject({ message: i18n.t('identification.typelist.error') });
      });
  });
};

export const setOrganisationTypeId = name => dispatch => {
  return dispatch({
    type: SET_ORGANISATION_TYPE_ID_ACTION,
    payload: name,
  });
};

export const setTradingName = name => dispatch => {
  return dispatch({
    type: SET_TRADING_NAME_ACTION,
    payload: name,
  });
};

export const setOrganisationTypeDescription = name => dispatch => {
  return dispatch({
    type: SET_ORGANISATION_TYPE_DESCRIPTION_ACTION,
    payload: name,
  });
};

export const setOrganisationIsTrust = name => dispatch => {
  return dispatch({
    type: SET_ORGANISATION_IS_TRUST_ACTION,
    payload: name,
  });
};

export const setBusinessName = name => dispatch => {
  return dispatch({
    type: SET_BUSINESS_NAME_ACTION,
    payload: name,
  });
};

export const setAbnNumber = abn => dispatch => {
  return dispatch({
    type: SET_ABN_NUMBER_ACTION,
    payload: abn,
  });
};

export const setAcnNumber = acn => dispatch => {
  return dispatch({
    type: SET_ACN_NUMBER_ACTION,
    payload: acn,
  });
};

export const setGstRegisteredAt = gstRegisteredAt => dispatch => {
  return dispatch({
    type: SET_GST_REGISTERED_AT_ACTION,
    payload: gstRegisteredAt,
  });
};

export const searchBusinessName = term => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(SEARCH_BUSINESS_NAME_API, { term: term });
    window.Api.get(url)
      .then(result => {
        return resolve(result);
      })
      .catch(error => {
        return reject(error);
      });
  });
};

export const searchAbnNumber = term => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(SEARCH_ABN_NUMBER_API, { term: term });
    window.Api.get(url)
      .then(result => {
        return resolve(result);
      })
      .catch(error => {
        return reject(error);
      });
  });
};

export const fetchSalutationList = () => dispatch => {
  return new Promise((resolve, reject) => {
    window.Api.get(SALUTATION_LIST_API)
      .then(result => {
        result = lookupMap(result);
        dispatch({
          type: GET_SALUTATION_LIST,
          payload: getListForSelectField(result, true),
        });
        return resolve();
      })
      .catch(err =>
        reject({ message: i18n.t('companyDetail.salutation.error') })
      );
  });
};

export const fetchCardList = params => dispatch => {
  const url = getListApiUrl(CARD_LIST_API, params);
  return window.Api.get(url).then(result => {
    result = lookupMap(result);
    dispatch({
      type: GET_CARD_LIST,
      payload: getListForSelectField(result, true),
    });
  });
};

export const fetchPreferHearingList = () => dispatch => {
  return new Promise((resolve, reject) => {
    window.Api.get(PREFER_HEARING_API)
      .then(result => {
        dispatch({
          type: GET_PREFER_HEARING_LIST,
          payload: getListForSelectField(referrerMap(result), true),
        });
        return resolve();
      })
      .catch(error => {
        return reject({ message: i18n.t('yourDetail.preferHearing.error') });
      });
  });
};

export const validateCardDetails = cardData => {
  return window.Api.post(CONCESSION_CARD_VALIDATE_API, cardData);
};

export const validateBankAccountNumber = params => {
  return window.Api.post(VALIDATE_BANK_ACCOUNT_NUMBER, params);
};

export const fetchMoveInDatesList = accountId => dispatch => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(FETCH_MOVE_IN_DATES_API, {
      accountId: accountId,
      allow_sdfi: allowsSameDayFuelInjections,
      pad_cut_off_time: allowsSameDayFuelInjections, // if we're doing SDFIs, then pad the cutoff
    });
    window.Api.get(url)
      .then(result => {
        dispatch({
          type: SET_MOVE_IN_DATES_ACTION,
          payload: convertDateFromISOArray(result),
        });
        return resolve();
      })
      .catch(error => {
        return reject({ message: i18n.t('yourProperty.moveInDates.error') });
      });
  });
};

export const submitSignupDetails =
  ({ hash, id: account_id }) =>
  dispatch => {
    return new Promise((resolve, reject) => {
      const url = getListApiUrl(SIGNUP_SUBMIT_API, {
        hash,
        account_id,
      });
      window.Api.post(url, {})
        .then(result => {
          logSaleAction(result, 'COMPLETE_SIGNUP', true);
          return resolve(summaryMap(result));
        })
        .catch(error => {
          return reject({ message: i18n.t('signUpCompletion.submit.error') });
        });
    });
  };

export const saveProgressTrigger = progressData => {
  return {
    type: SAVE_PROGRESS_TRIGGER_ACTION,
    payload: getCurrentTimestamp(),
    progressData,
  };
};

export const showSaveProgressLoading = loading => {
  return {
    type: SAVE_PROGRESS_LOADING_ACTION,
    payload: loading,
  };
};

export const saveProgressIsSuccess = isSuccess => {
  return {
    type: SAVE_PROGRESS_IS_SUCCESS_ACTION,
    payload: isSuccess,
  };
};

export const fetchSiteIdentifierDetailByID = data => dispatch => {
  dispatch({
    type: SET_PRIMARY_SITE_IDENTIFIER,
    payload: data,
  });
};

export const fetchBillingFrequencyList = params => dispatch => {
  const url = getListApiUrl(
    GET_BILLING_FREQUENCY_API,
    requestSiteDetails(params)
  );
  return new Promise((resolve, reject) => {
    window.Api.get(url, null, billingFrequencyList)
      .then(result => {
        dispatch({
          type: GET_PAYMENT_FREQUENCY_LIST,
          payload: getListForSelectField(result, true),
        });

        return resolve();
      })
      .catch(err => reject(err, i18n.t('paymentDetails.frequency.error')));
  });
};

export const getCreditCardFrameUrl = (signUpId, vendor, data) => dispatch => {
  return new Promise((resolve, reject) => {
    const url = GENERATE_PAYMENT_REQUEST_API.replace(
      '{signUpId}',
      signUpId
    ).replace('{vendor}', vendor);
    window.paymentApi
      .post(url, data)
      .then(result => {
        dispatch({
          type: GET_CREDITCARD_FRAME_RESPONSE,
          payload: result,
        });
        return resolve(result);
      })
      .catch(error => {
        return reject(error);
      });
  });
};

export const validateSignupURL = token => dispatch => {
  return new Promise((resolve, reject) => {
    window.Api.put(VALIDATE_SIGNUP_URL_API, { token }, null)
      .then(result => {
        if (result.applicationId) {
          dispatch({
            type: SIGNUP_GET_API_ACTION,
            payload: result,
          });
          return resolve(result);
        } else {
          return reject('no-application-Id');
        }
      })
      .catch(error => {
        return reject(error);
      });
  });
};

export const validatePinCodes = (id, pinCodes, signal) => {
  return new Promise((resolve, reject) => {
    if (!id || !pinCodes) {
      return reject(i18n.t('500.error.message'));
    }
    const url = VALIDATE_PIN_CODE.replace('{applicationId}', id);
    window.Api.put(
      url,
      {
        code: pinCodes,
      },
      signal
    )
      .then(resp => {
        if (resp.isSuccess) {
          return resolve(resp);
        } else {
          return reject(
            resp.messages.length > 0
              ? resp.messages[0].message
              : i18n.t('500.error.message')
          );
        }
      })
      .catch(err => {
        return reject(err);
      });
  });
};

export const requestValidationEmail = id => {
  return new Promise((resolve, reject) => {
    if (!id) {
      return reject({
        message: i18n.t('completesummary.validationEmail.request.error'),
      });
    }
    const url = REQUEST_PIN_CODE_EMAIL.replace('{applicationId}', id);
    window.Api.post(url, {})
      .then(resp => {
        if (resp.isSuccess) {
          return resolve(resp);
        } else {
          const message =
            resp.messages.length > 0
              ? resp.messages[0].message
              : i18n.t('completesummary.validationEmail.request.error');
          return reject({ message });
        }
      })
      .catch(err => {
        return reject(err);
      });
  });
};

export const triggerEmailSetter = isEmail => (dispatch, getState) => {
  const state = getState();
  if (!state.onBoarding.progressData) {
    dispatch({
      type: TRIGGER_EMAIL_SETTER,
      payload: {
        targetType: isEmail ? 'email' : 'phone',
      },
    });
  }
};

export const setProgressData = progressData => {
  return {
    type: SET_TEMPORAL_DATA,
    progressData,
  };
};

export const validatePhone = phone => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(PHONE_VALIDATION_API, { phone });
    window.Api.get(url)
      .then(resp => {
        return resp === true
          ? resolve({ isSuccess: true })
          : resolve({
              isSuccess: false,
              message: i18n.t('yourDetail.errorMessage.validMobilePhoneNumber'),
            });
      })
      .catch(err => {
        return reject(err);
      });
  });
};

export const validateEmail = email => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(EMAIL_VALIDATION_API, { email });
    window.Api.get(url)
      .then(resp => {
        return resp === true
          ? resolve({ isSuccess: true })
          : resolve({
              isSuccess: false,
              message: i18n.t('yourDetail.errorMessage.validEmail'),
            });
      })
      .catch(err => {
        return reject(err);
      });
  });
};

export const requestMobileAuthentication = ({ hash, id }) => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(REQUEST_MOBILE_AUTHENTICATION, {
      hash,
      id,
    });
    window.Api.post(url, {})
      .then(result => {
        return resolve(result);
      })
      .catch(err => {
        return reject(err);
      });
  });
};

export const checkMobileCode = ({ hash, id, mobile_code }) => {
  return new Promise((resolve, reject) => {
    const url = getListApiUrl(CHECK_MOBILE_CODE, {
      hash,
      id,
    });
    window.Api.post(url, { mobile_code })
      .then(result => {
        return resolve(result);
      })
      .catch(err => {
        return reject(err);
      });
  });
};

export const sendCustomerLog = ({
  applicationId: hash,
  id,
  action,
  context,
}) => {
  return new Promise((resolve, reject) => {
    window.Api.post(CUSTOMER_LOG_ACTIVITY, {
      hash,
      id,
      action,
      context,
    })
      .then(result => {
        return resolve(result);
      })
      .catch(err => {
        return reject(err);
      });
  });
};
