import { connect } from 'react-redux';
import Wizard from './Wizard';
import { toggleSteps } from 'App/pages/UserSignup/actions';
import {
  fetchStateList,
  fetchSolarTypeList,
  fetchIdentificationTypeList,
  fetchCoordinatorsList,
  fetchSalutationList,
  fetchPreferHearingList,
} from 'App/modules/onBoarding/action';
import { validateAgentLogin } from 'App/pages/AgentLogin/action';
import { fetchProductList } from 'App/pages/ComparePlans/action';
const mapStateToProps = (state, props) => {
  return {
    ...props,
    selectedPlans: state.comparePlan.selectedPlans,
    productList: state.comparePlan.productList,
    customConfig: state.app.customConfig,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleClick: (buttonAction, steps) =>
      dispatch(toggleSteps(buttonAction, steps)),
    fetchStateList: () => dispatch(fetchStateList()),
    fetchSolarTypeList: () => dispatch(fetchSolarTypeList()),
    fetchIdentificationTypeList: () => dispatch(fetchIdentificationTypeList()),
    fetchCoordinatorsList: () => dispatch(fetchCoordinatorsList()),
    fetchProductList: () => dispatch(fetchProductList()),
    fetchSalutationList: () => dispatch(fetchSalutationList()),
    fetchPreferHearingList: () => dispatch(fetchPreferHearingList()),
    validateAgentLogin: data => dispatch(validateAgentLogin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
