import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FactSheet from 'App/pages/FactSheet/FactSheet';
import { getFactSheet, getOfferDocuments } from 'App/pages/FactSheet/actions';
import {
  fetchDistributors,
  fetchOfferList,
  fetchProductList,
  fetchPropertyList,
} from 'App/pages/ComparePlans/action';

const mapStateToProps = (state, props) => {
  return {
    ...props,
    allPlans: state.comparePlan.offers,
    productList: state.comparePlan.productList,
    propertyList: state.comparePlan.propertyList,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchOfferList: (siteIdentifier, params, productType) =>
      dispatch(fetchOfferList(siteIdentifier, params, productType)),
    fetchDistributors: postcode => fetchDistributors(postcode),
    getOfferDocuments: data => dispatch(getOfferDocuments(data)),
    getFactSheet: data => dispatch(getFactSheet(data)),
    fetchProductList: () => dispatch(fetchProductList()),
    fetchPropertyList: () => dispatch(fetchPropertyList()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FactSheet)
);
