import React from 'react';
import { AgilityCard, AgilityTypography } from 'Src/AgilityComponents';
import CircularProgress from '@material-ui/core/CircularProgress';
import i18n from 'i18next';
import { array, object, bool } from 'prop-types';
import BasicPriceDetails from './BasicPriceDetails';
import { isEmpty } from 'App/utils/helper';
import { useTheme } from '@material-ui/core/styles';

const BasicPriceDetailsWrapper = props => {
  const {
    displayProductHeading,
    showImage,
    isEmbeddedNetwork,
    showDesc,
    showIcon,
    productList,
    displayProduct,
    signupAPIResponse,
    selectedPlans,
    inSidebar,
    inCompleteSummary,
  } = props;

  const theme = useTheme();

  let services = [];
  if (signupAPIResponse.services) {
    services = [...signupAPIResponse.services];
  }

  return (
    <React.Fragment>
      {services &&
        Object.keys(services).map((key, index) => {
          const plan = selectedPlans[services[key].productType];

          let product;
          if (plan) {
            product = plan.productType;
          }
          return (
            <React.Fragment key={`step-${index}`}>
              {displayProductHeading && product && product.display && (
                <AgilityTypography component="h4" variant="h4" className="mb-2">
                  {`${product.display} ${i18n.t('Plan.title.text')}`}
                </AgilityTypography>
              )}
              <AgilityCard
                className={`service-card basic-details-wrapper ${
                  !showImage ? 'justify-center' : 'has-image'
                }`}
                elevation={2}
              >
                {plan && !isEmpty(props.productList) ? (
                  <React.Fragment>
                    {showImage && (
                      <div
                        className={`image-container ${
                          props.displayProduct ? 'text-center' : ''
                        }`}
                        data-test-id="plan-image"
                      >
                        <img
                          src={
                            plan.imageReference
                              ? plan.imageReference
                              : theme.images.product
                          }
                          alt="Logo"
                          className="basic-price-details-wrapper--image_height"
                        />
                      </div>
                    )}

                    <BasicPriceDetails
                      data-test-id="plan-detail-id"
                      planDetails={plan}
                      productList={productList}
                      showDesc={showDesc}
                      showIcon={showIcon}
                      displayProduct={displayProduct}
                      productDetails={product}
                      isEmbeddedNetwork={isEmbeddedNetwork}
                      site={signupAPIResponse.address}
                      selectedGreenpower={services[key].selectedGreenpower}
                      inSidebar={inSidebar}
                      inPlanDetailsCard={false}
                      inCompleteSummary={inCompleteSummary}
                    />
                  </React.Fragment>
                ) : (
                  <div className="loader-section">
                    <CircularProgress
                      color="primary"
                      variant="indeterminate"
                      className="loader"
                      thickness={3}
                      size={30}
                      data-test-id="loading-progress-id"
                    />
                  </div>
                )}
              </AgilityCard>
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
};
BasicPriceDetailsWrapper.propTypes = {
  productList: array,
  signupAPIResponse: object,
  selectedPlans: object,
  displayProductHeading: bool,
  showImage: bool,
  showDesc: bool,
  showIcon: bool,
  displayProduct: bool,
  isEmbeddedNetwork: bool,
  inSidebar: bool,
  inCompleteSummary: bool,
};

BasicPriceDetailsWrapper.defaultProps = {
  productList: [],
  signupAPIResponse: {},
  selectedPlans: {},
  displayProductHeading: false,
  showImage: true,
  showDesc: true,
  showIcon: false,
  displayProduct: false,
  isEmbeddedNetwork: false,
  inSidebar: false,
  inCompleteSummary: false,
};
export default BasicPriceDetailsWrapper;
