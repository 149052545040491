import React from 'react';
import { func, object, bool, array } from 'prop-types';
import i18n from 'i18next';
import { AgilityInfoDialog, AgilityTypography } from 'Src/AgilityComponents';
import SiteIdentifier from '../SiteIdentifier/Siteidentifier';
import { Link } from '@material-ui/core';

const ListIdentifierDialog = props => {
  const { productDetails, open, siteIdentifierList, selectedSiteIdentifier } =
    props;

  return (
    <AgilityInfoDialog
      className="full-page-popup-sm list-identifier-dialog"
      data-test-id="list-identifier-dialog"
      open={open}
      fullWidth={true}
      maxWidth="sm"
      title={i18n.t('listidentifier.dialog.heading', {
        identifierCode: productDetails.displayAlternative,
        productType: (productDetails.display || '').toLowerCase(),
      })}
      okClick={props.onDialogCloseClick()}
      closeClick={props.onDialogCloseClick()}
      showOkButton={false}
    >
      <AgilityTypography variant="body2" component="p">
        {i18n.t('listidentifier.dialog.message1')}&nbsp;
        <Link
          onClick={() => props.onSearchIdentifierClick()}
          data-test-id="search-ident-link"
        >
          {i18n.t('siteidentifier.searchusing.identifier', {
            identifierCode: productDetails.displayAlternative,
          })}
        </Link>
        &nbsp;
        {i18n.t('listidentifier.dialog.message2', {
          productType: (productDetails.display || '').toLowerCase(),
        })}
      </AgilityTypography>
      <SiteIdentifier
        data-test-id={`site-identifier-${productDetails.code}`}
        selectedSiteIdentifier={selectedSiteIdentifier}
        siteIdentifierList={siteIdentifierList}
        onSiteIdentifierSelected={props.onSiteIdentifierSelected}
        productDetails={productDetails}
      />
    </AgilityInfoDialog>
  );
};

ListIdentifierDialog.propTypes = {
  onDialogCloseClick: func,
  open: bool,
  onSiteIdentifierSelected: func,
  productDetails: object,
  siteIdentifierList: array,
  selectedSiteIdentifier: object,
  onSearchIdentifierClick: func,
};

ListIdentifierDialog.defaultProps = {
  open: false,
  productDetails: {},
  siteIdentifierList: [],
  selectedSiteIdentifier: {},
};

export default ListIdentifierDialog;
