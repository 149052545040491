import { connect } from 'react-redux';
import PromoCode from './PromoCode';
import {
  setOfferFilterPromoCode,
  setPlanFilters,
} from 'App/pages/ComparePlans/action';

const mapStateToProps = (state, props) => {
  return {
    promoCode: state.comparePlan.planFilter.promoCode,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setOfferFilterPromoCode: data => dispatch(setOfferFilterPromoCode(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCode);
