import { connect } from 'react-redux';
import yourDetail from './YourDetail';
import {
  fetchSalutationList,
  fetchPreferHearingList,
  setProgressData,
  validatePhone,
  validateEmail,
} from 'App/modules/onBoarding/action';

const mapStateToProps = (state, props) => {
  return {
    salutationList: state.onBoarding.salutationList,
    preferHearingList: state.onBoarding.preferHearingList,
    customConfig: state.app.customConfig,
    triggerTempProgressSave: state.onBoarding.triggerTempProgressSave,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchSalutationList: () => dispatch(fetchSalutationList()),
    fetchPreferHearingList: () => dispatch(fetchPreferHearingList()),
    setProgressData: data => dispatch(setProgressData(data)),
    validatePhone: phone => validatePhone(phone),
    validateEmail: email => validateEmail(email),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(yourDetail);
