const initialState = {
  registerResonse: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REGISTER_POST_DATA':
      return {
        ...state,
        registerResonse: action.response,
      };
    default:
      return { ...state };
  }
};

export default reducer;
