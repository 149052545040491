import { connect } from 'react-redux';
import Siteidentifier from './Siteidentifier';

const mapStateToProps = (state, props) => {
  const { [props.productDetails.code]: siteIdentifierList = [] } =
    state.comparePlan.siteIdentifierList;
  return {
    siteIdentifierList,
  };
};

export default connect(mapStateToProps, null)(Siteidentifier);
