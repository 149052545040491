/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useState, useEffect, useCallback } from 'react';
import i18n from 'i18next';
import {
  AgilityButton,
  AgilityCard,
  AgilityTypography,
  AgilityGrid,
} from 'Src/AgilityComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SingleInput from './Components/SingleInput';
import AgilityDialog from 'App/components/Dialog/AgilityDialog';
import { SIGNUP_STEPS as signupSteps } from 'App/utils/constants';
import { withSnackbar } from 'notistack';
import { isEmpty } from 'Root/src/app/utils/helper';

const MobileAuthentication = ({
  id,
  className,
  isActive,
  signupAPIResponse,
  onNavigate,
  nextLoading,
  checkMobileCode,
  requestMobileAuthentication,
  enqueueSnackbar,
}) => {
  const numInputs = 6;
  const defaultCode = '      ';
  const [code, setCode] = useState(defaultCode);
  const [loading, setLoading] = useState(false);
  const [validCode, setValidCode] = useState(false);
  const [checkedCode, setCheckedCode] = useState(false);
  const [showResendDialog, setShowResendDialog] = useState(false);
  const [activeInput, setActiveInput] = useState(null);
  const [countFailedAttempts, setCountFailedAttempts] = useState(0);

  const replaceAt = (idx, val) => {
    let chars = code.split('');
    chars[idx] = val;
    return chars.join('');
  };

  const inputHandleChange = (idx, val) => {
    if (val >= 0 && val <= 9) {
      setCode(replaceAt(idx, val));
      setActiveInput(idx + 1);
    }
  };

  const renderInputs = () => {
    const inputs = [];
    for (let i = 0; i < numInputs; i++) {
      inputs.push(
        <SingleInput
          index={i}
          key={i}
          focus={activeInput === i}
          handleChange={val => inputHandleChange(i, val)}
          checkedCode={checkedCode}
          validCode={validCode}
          value={code[i]}
        />
      );
    }

    return inputs;
  };

  const getPrimaryMobileNumber = useCallback(() => {
    const contactData = signupAPIResponse.contacts[0];
    if (isEmpty(contactData) || isEmpty(contactData.phones)) {
      return '';
    }

    const primaryPhone = contactData.phones.find(p => p.type === 'Mobile');
    if (isEmpty(primaryPhone)) {
      return '';
    }

    return primaryPhone.phoneNumber || '';
  }, [signupAPIResponse]);

  useEffect(() => {
    if (code.indexOf(' ') < 0 && code.length === numInputs) {
      // enough 6 digits
      setLoading(true);
      checkMobileCode({
        hash: signupAPIResponse.applicationId,
        id: signupAPIResponse.id,
        mobile_code: code,
      })
        .then(result => {
          setCheckedCode(true);
          setValidCode(result.validated);
          setCountFailedAttempts(result.validation_attempts);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          enqueueSnackbar(i18n.t('mobileAuthentication.service.invalid'), {
            variant: 'error',
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const resetValidation = () => {
    setCode(defaultCode);
    setValidCode(false);
    setCheckedCode(false);
  };

  const onBackClick = useCallback(() => {
    resetValidation();
    onNavigate('back');
  }, [onNavigate]);

  const resendMobileCode = () => {
    setShowResendDialog(true);
    requestMobileAuthentication({
      id: signupAPIResponse.id,
      hash: signupAPIResponse.applicationId,
    });
    resetValidation();
  };

  const onOkay = () => {
    setShowResendDialog(false);
  };

  const onResendDialogDismiss = () => {
    setShowResendDialog(false);
  };

  const validateCurrentForm = () => {
    if (checkedCode && validCode) {
      const data = signupAPIResponse;
      data.currentPage = signupSteps.yourProperty;
      onNavigate('next', data, false);
    }
  };

  return (
    <AgilityCard className={`steps-wrapper ${className}`} id={id}>
      <AgilityTypography variant="h4" component="h4" className="mb-2">
        {i18n.t('mobileAuthentication.header.text')}
      </AgilityTypography>
      <form autoComplete="off" noValidate data-test-id="yourDetailForm">
        <AgilityGrid className="mb-2">
          <AgilityTypography variant="body2" component="p">
            {i18n.t('mobileAuthentication.body.message', {
              mobileNumber: getPrimaryMobileNumber(),
            })}
          </AgilityTypography>
          <AgilityTypography variant="body2" component="p">
            {i18n.t('mobileAuthentication.code.text')}
          </AgilityTypography>
        </AgilityGrid>
        <AgilityGrid container spacing={2}>
          {renderInputs()}
          {loading && (
            <FontAwesomeIcon
              icon={['fas', 'spinner']}
              spin
              className="mobile-code-loading"
            />
          )}
        </AgilityGrid>
        {checkedCode && !validCode && (
          <AgilityGrid item xs={12} sm={12}>
            <AgilityTypography
              variant="caption"
              className="ml-0 MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
            >
              {countFailedAttempts > 2
                ? i18n.t('mobileAuthentication.invalidCode2.message')
                : i18n.t('mobileAuthentication.invalidCode.message')}
            </AgilityTypography>
          </AgilityGrid>
        )}
        <AgilityGrid container spacing={2} justifyContent="flex-end">
          <AgilityGrid item xs={12} sm={12}>
            <div className="steps-footer">
              <AgilityButton
                disabled={!isActive}
                color="primary"
                onClick={resendMobileCode}
                label={i18n.t('mobileAuthentication.resend.text')}
              />
              <AgilityButton
                color="primary"
                onClick={onBackClick}
                disabled={!isActive}
                label={i18n.t('signup.button.back')}
                data-test-id="backButton"
                className="push"
              />
              <AgilityButton
                variant="contained"
                color="primary"
                type="primary"
                label={i18n.t('signup.button.next')}
                disabled={!isActive}
                data-test-id="nextButton"
                onClick={validateCurrentForm}
                loading={nextLoading}
              />
            </div>
          </AgilityGrid>
        </AgilityGrid>
      </form>
      <AgilityDialog
        className="resend-confirmation-dialog"
        open={showResendDialog}
        okClick={onOkay}
        onDismiss={onResendDialogDismiss}
        maxWidth="xs"
        title={i18n.t('resendMobileCodeDialog.heading.text')}
        okText={i18n.t('resendMobileCodeDialog.save.button')}
        titleAlign="center"
        contents={[
          {
            body: i18n.t('resendMobileCodeDialog.subheading.text'),
            variant: 'subtitle1',
            element: 'p',
            class: 'mb-1',
          },
        ]}
      />
    </AgilityCard>
  );
};

export default withSnackbar(MobileAuthentication);
