import {
  isNotEmpty,
  isValidBankAccountName,
  isValidBankAccountNumber,
  isValidBankBSBNumber,
} from 'App/utils/validationHelper';

export const defaultState = {
  paymentMethod: '',
  error: '',
  isCardAdded: false,
  isAccountAdded: false,
  bankAccountFields: {
    bankName: '',
    bankAccountHolderName: '',
    bankBranchName: '',
    bankAccountNumber: '',
    allowProviderToDirectDebit: false,
    directDebitTNC: false,
  },
  bankAccountFieldsTouched: {
    bankName: false,
    bankAccountHolderName: false,
    bankBranchName: false,
    bankAccountNumber: false,
    allowProviderToDirectDebit: false,
    directDebitTNC: false,
  },
  bankAccountFieldsErrors: {
    bankName: '',
    bankAccountHolderName: '',
    bankBranchName: '',
    bankAccountNumber: '',
    allowProviderToDirectDebit: '',
    directDebitTNC: '',
  },
  creditCardDetails: {
    cardName: '',
    cardholderName: '',
    cardNumber: '',
    cardType: '',
    cardIcon: '',
  },
  loading: false,
  cardVendor: process.env.REACT_APP_CREDIT_CARD_PROVIDER ?? null,
  creditCardLoading: true,
  directDebitIsRequired: false,
  showDirectDebitStep: false,
};

export const bankFormValidators = {
  defaultChecker: isNotEmpty,
  fields: {
    bankAccountHolderName: {
      preCondition: '',
      defaultErrorText: 'bankAccount.errorMessage.bankAccountName',
      conditions: [
        {
          condition: isValidBankAccountName,
          errorText: 'bankAccount.errorMessage.validbankAccountName',
        },
      ],
    },
    bankBranchName: {
      preCondition: '',
      defaultErrorText: 'bankAccount.errorMessage.BranchNumber',
      conditions: [
        {
          condition: isValidBankBSBNumber,
          errorText: 'bankAccount.errorMessage.validBSBNumber',
        },
      ],
    },
    bankAccountNumber: {
      preCondition: '',
      defaultErrorText: 'bankAccount.errorMessage.AccountNumber',
      conditions: [
        {
          condition: isValidBankAccountNumber,
          errorText: 'bankAccount.errorMessage.validAccountNumber',
        },
      ],
    },
  },
};
