import React from 'react';
import './tooltip.scss';
import Tooltip from '@material-ui/core/Tooltip';
import { string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AgilityTooltip = props => {
  return (
    <div className="icon">
      <Tooltip
        data-test-id="tooltip-id"
        enterTouchDelay={0}
        title={props.tooltipText}
        arrow={false}
      >
        <div className="tooltip__container">
          {props.tooltipLabel && (
            <span className="tooltip__label">{props.tooltipLabel}</span>
          )}
          <span className="tooltip__icon">
            <FontAwesomeIcon
              icon={['fas', `info-circle`]}
              data-test-id="tooltip-icon-id"
            />
          </span>
        </div>
      </Tooltip>
    </div>
  );
};
AgilityTooltip.propTypes = {
  tooltipText: string,
  tooltipLabel: string,
};
AgilityTooltip.defaultProps = {
  tooltipText: '',
  tooltipLabel: '',
};
export default AgilityTooltip;
