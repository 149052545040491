import React, { useEffect, useState } from 'react';
import { func, object, bool } from 'prop-types';
import i18n from 'i18next';
import {
  AgilityGrid,
  AgilitySelectField,
  AgilityTypography,
  AgilityTextField,
  AgilityInfo,
} from 'Src/AgilityComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { vsiAccessMethod, vsiAppointmentTime } from 'App/utils/data';
import { Element } from 'react-scroll';
import { isEmpty } from 'Root/src/app/utils/helper';

const PRODUCT_TYPE_POWER = 'POWER';
const METER_ACCESS_MAX_LENGTH = 240;

const VsiFields = props => {
  const { isActive, errors, handleChange, fields, signupAPIResponse } = props;

  const [meterAccessCount, setMeterAccessCount] = useState(0);
  const meterAccessChange = value => {
    if (value.length > METER_ACCESS_MAX_LENGTH) {
      return;
    }
    setMeterAccessCount(value.length);
    handleChange(value, 'meterAccess');
  };

  useEffect(() => {
    if (!isEmpty(signupAPIResponse) && signupAPIResponse.services !== null) {
      const services = signupAPIResponse.services;
      services.forEach(obj => {
        if (
          obj.productType === PRODUCT_TYPE_POWER &&
          !isEmpty(obj.meterAccess)
        ) {
          setMeterAccessCount(obj.meterAccess.length);
        }
      });
    }
  }, [signupAPIResponse]);

  return (
    <React.Fragment>
      <AgilityGrid item xs={12} sm={9}>
        <div className="question-wrapper">
          <AgilityTypography variant="body2" component="p">
            {i18n.t('yourProperty.vsiAppointmentTime.title')}
          </AgilityTypography>
        </div>
        <Element name="vsi-appointment-time">
          <AgilitySelectField
            disabled={!isActive}
            data-test-id="vsi-appointment-time"
            options={vsiAppointmentTime}
            variant="outlined"
            value={fields.vsiAppointmentTime || ''}
            showlabel={true}
            required={true}
            placeholder={`${i18n.t(
              'yourProperty.vsiAppointmentTime.placeholder'
            )} *`}
            onChange={event =>
              handleChange(event.target.value, 'vsiAppointmentTime')
            }
            isError={errors && errors.vsiAppointmentTime ? true : false}
            helperText={
              errors && errors.vsiAppointmentTime
                ? errors.vsiAppointmentTime
                : ''
            }
            MenuProps={{
              PaperProps: { square: true },
            }}
          />
        </Element>
      </AgilityGrid>
      <AgilityGrid item xs={12} sm={12}>
        <AgilityInfo
          severity="warning"
          className="mt-0 info"
          icon={<FontAwesomeIcon icon={['fas', 'info-circle']} />}
        >
          <AgilityTypography
            variant="subtitle2"
            component="p"
            className="mt-0"
            dangerouslySetInnerHTML={{
              __html: i18n.t('yourProperty.vsiAppointmentTime.info'),
            }}
          />
        </AgilityInfo>
      </AgilityGrid>
      <AgilityGrid item xs={12} sm={12}>
        <div className="question-wrapper">
          <AgilityTypography variant="body2" component="p">
            {i18n.t('yourProperty.vsiAccessMethod.title')}
          </AgilityTypography>
        </div>
      </AgilityGrid>
      <AgilityGrid item xs={12} sm={9}>
        <Element name="vsi-access-method">
          <AgilitySelectField
            disabled={!isActive}
            data-test-id="vsi-access-method"
            options={vsiAccessMethod}
            variant="outlined"
            value={fields.vsiAccessMethod || ''}
            showlabel={true}
            required={true}
            placeholder={`${i18n.t(
              'yourProperty.vsiAccessMethod.placeholder'
            )} *`}
            onChange={event =>
              handleChange(event.target.value, 'vsiAccessMethod')
            }
            isError={errors && errors.vsiAccessMethod ? true : false}
            helperText={
              errors && errors.vsiAccessMethod ? errors.vsiAccessMethod : ''
            }
            MenuProps={{
              PaperProps: { square: true },
            }}
          />
        </Element>
      </AgilityGrid>
      <AgilityGrid item xs={12} sm={12}>
        <AgilityInfo
          severity="warning"
          className="mt-0 info"
          icon={<FontAwesomeIcon icon={['fas', 'info-circle']} />}
        >
          <AgilityTypography
            variant="subtitle2"
            component="p"
            className="mt-0"
            dangerouslySetInnerHTML={{
              __html: i18n.t('yourProperty.vsiAccessMethod.info'),
            }}
          ></AgilityTypography>
        </AgilityInfo>
      </AgilityGrid>
      <AgilityGrid item xs={12} sm={12}>
        <div className="question-wrapper">
          <AgilityTypography variant="body2" component="p">
            {i18n.t('yourProperty.vsiMeterAccess.title')}
          </AgilityTypography>
        </div>
      </AgilityGrid>
      <AgilityGrid item xs={12} sm={12}>
        <Element name="vsi-meter-access">
          <AgilityTextField
            disabled={!isActive}
            data-test-id="vsi-meter-access"
            variant="outlined"
            multiline
            rows={3}
            value={fields.meterAccess || ''}
            showlabel={true}
            placeholder={`${i18n.t('yourProperty.vsiMeterAccess.placeholder')}`}
            onChange={val => meterAccessChange(val)}
            helperText={meterAccessCount + '/' + METER_ACCESS_MAX_LENGTH}
          />
        </Element>
      </AgilityGrid>
      <AgilityGrid item xs={12} sm={12}>
        <AgilityInfo
          severity="warning"
          className="mt-0 info"
          icon={<FontAwesomeIcon icon={['fas', 'info-circle']} />}
        >
          <AgilityTypography
            variant="subtitle2"
            component="p"
            className="mt-0"
            dangerouslySetInnerHTML={{
              __html: i18n.t('yourProperty.vsiMeterAccess.info'),
            }}
          ></AgilityTypography>
        </AgilityInfo>
      </AgilityGrid>
    </React.Fragment>
  );
};
VsiFields.propTypes = {
  isActive: bool,
  errors: object,
  handleChange: func,
  fields: object,
  signupAPIResponse: object,
};
VsiFields.defaultProps = {
  isActive: false,
  errors: {},
  fields: {},
};

export default VsiFields;
