import React from 'react';
import i18n from 'i18next';
import { noYesOptions } from 'App/utils/data';
import {
  AgilityGrid,
  AgilityTypography,
  AgilityRadioGroup,
  AgilityInfo,
} from 'Src/AgilityComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Element } from 'react-scroll';
import { disableSignupsForLifeSupportCustomers } from 'App/customConfig';

const IS_YES_SELECTED = 'yes';

const fuelOptions = [
  { label: 'Electricity', value: 'E' },
  { label: 'Gas', value: 'G' },
  { label: 'Both', value: 'D' },
];

const LifeSupportCard = ({
  fields,
  errors,
  isActive,
  handleChange,
  isTangoPartner,
  isCovau,
  isFirstEnergy,
}) => {
  const hasError = React.useCallback(
    field => errors && errors.hasOwnProperty(field) && errors[field] !== '',
    [errors]
  );

  const handleLifeSupportPersonDetailChange = React.useCallback(
    event => {
      const value = event.target.value;
      let changed = {};
      changed['hasLifeSupportPerson'] = value;

      if (value === 'no') {
        changed['lifeSupportPersonDetail'] = 'no';
      }
      handleChange(changed);
    },
    [handleChange]
  );

  React.useEffect(() => {
    if (
      (fields.fuelType === 'E' || fields.fuelType === 'G') &&
      fields.hasLifeSupportPerson === 'yes' &&
      fields.lifeSupportType === ''
    ) {
      handleChange({ lifeSupportType: fields.fuelType });
    }
  }, [
    fields.fuelType,
    fields.hasLifeSupportPerson,
    fields.lifeSupportType,
    handleChange,
  ]);

  return (
    <AgilityGrid container>
      <AgilityGrid item xs={12} sm={12}>
        <div className="question-wrapper">
          <AgilityTypography
            variant="body2"
            component="p"
            tooltipText={i18n.t('lifeSupportConcession.lifeSupport.info', {
              interpolation: { escapeValue: false },
            })}
            showInfo={true}
          >
            {i18n.t('lifeSupportConcession.lifeSupport.placeholder')}
          </AgilityTypography>
          <Element name="hasLifeSupportPerson">
            <AgilityRadioGroup
              disabled={!isActive}
              options={noYesOptions}
              onChange={handleLifeSupportPersonDetailChange}
              value={fields.hasLifeSupportPerson}
              data-test-id="hasLifeSupportPerson"
              row
              isError={hasError('hasLifeSupportPerson')}
              helperText={
                hasError('hasLifeSupportPerson')
                  ? errors.hasLifeSupportPerson
                  : ''
              }
            />
          </Element>
          {fields.hasLifeSupportPerson === IS_YES_SELECTED &&
            !isCovau &&
            !isFirstEnergy &&
            fields.fuelType === 'D' && (
              <>
                <AgilityTypography variant="body2" component="p">
                  {i18n.t(
                    'lifeSupportConcession.header.lifeSupportPersonDetail'
                  )}
                </AgilityTypography>
                <Element name="lifeSupportType">
                  <AgilityRadioGroup
                    disabled={!isActive}
                    options={fuelOptions}
                    onChange={event => {
                      handleChange({ lifeSupportType: event.target.value });
                    }}
                    value={fields.lifeSupportType || ''}
                    data-test-id="lifeSupportType"
                    row
                    isError={hasError('lifeSupportType')}
                    helperText={
                      hasError('lifeSupportType') ? errors.lifeSupportType : ''
                    }
                  />
                </Element>
              </>
            )}

          {fields.hasLifeSupportPerson === IS_YES_SELECTED &&
            disableSignupsForLifeSupportCustomers && (
              <AgilityGrid item xs={12} sm={12}>
                <AgilityInfo
                  severity="warning"
                  color="warning"
                  className="alert-wrapper"
                  icon={
                    <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
                  }
                >
                  <AgilityTypography
                    variant="body2"
                    component="p"
                    className="mt-0"
                    dangerouslySetInnerHTML={{
                      __html: i18n.t(
                        'lifeSupportConcession.lifeSupportPersonDetail.warning.description',
                        {
                          phoneNumber: process.env.REACT_APP_COMPANY_PHONE,
                        }
                      ),
                    }}
                  ></AgilityTypography>
                </AgilityInfo>
              </AgilityGrid>
            )}

          {fields.hasLifeSupportPerson === IS_YES_SELECTED &&
            fields.lifeSupportType === 'D' &&
            isTangoPartner && (
              <>
                <AgilityTypography
                  variant="subtitle1"
                  component="p"
                  color="textPrimary"
                >
                  {i18n.t(
                    'lifeSupportConcession.lifeSupportPersonDetail.welcomepack'
                  )}
                </AgilityTypography>
              </>
            )}

          {fields.hasLifeSupportPerson === IS_YES_SELECTED &&
            fields.lifeSupportType === 'E' &&
            isTangoPartner && (
              <AgilityTypography
                variant="subtitle1"
                component="p"
                color="textPrimary"
              >
                {i18n.t(
                  'lifeSupportConcession.lifeSupportPersonDetail.welcomepack'
                )}
              </AgilityTypography>
            )}

          {!disableSignupsForLifeSupportCustomers &&
            fields.fuelType === 'E' && (
              <AgilityTypography
                variant="subtitle1"
                component="p"
                color="textPrimary"
              >
                {i18n.t(
                  'lifeSupportConcession.lifeSupportPersonDetail.description.electricity'
                )}
              </AgilityTypography>
            )}

          {fields.hasLifeSupportPerson === IS_YES_SELECTED &&
            fields.lifeSupportType === 'G' &&
            isTangoPartner && (
              <AgilityTypography
                variant="subtitle1"
                component="p"
                color="textPrimary"
              >
                {i18n.t(
                  'lifeSupportConcession.lifeSupportPersonDetail.welcomepack'
                )}
              </AgilityTypography>
            )}

          {fields.fuelType === 'G' && (
            <AgilityTypography
              variant="subtitle1"
              component="p"
              color="textPrimary"
            >
              {i18n.t(
                'lifeSupportConcession.lifeSupportPersonDetail.description.gas'
              )}
            </AgilityTypography>
          )}
        </div>
      </AgilityGrid>
    </AgilityGrid>
  );
};

export default LifeSupportCard;
