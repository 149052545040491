import { connect } from 'react-redux';
import AgilityRouteLeavingDialog from './AgilityRouteLeavingDialog';
import { withRouter } from 'react-router-dom';
import { resetOnBoardingUserData } from 'App/modules/onBoarding/action';
import { resetComparePlanUserData } from 'App/pages/ComparePlans/action';

const mapDispatchToProps = (dispatch, props) => {
  return {
    resetOnBoardingUserData: () => dispatch(resetOnBoardingUserData()),
    resetComparePlanUserData: () => dispatch(resetComparePlanUserData()),
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(AgilityRouteLeavingDialog)
);
