import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CompleteSummary from './CompleteSummary';
import {
  // getCreditCardDetails,
  validatePinCodes,
  requestValidationEmail,
  fetchMoveInDatesList,
  sendCustomerLog,
} from 'App/modules/onBoarding/action';
import { putSignupDetails } from 'App/pages/ComparePlans/action';

const mapStateToProps = (state, props) => {
  return {
    brandConfig: state.app.brand,
    stateList: state.onBoarding.stateList,
    idTypeList: state.onBoarding.identificationTypeList,
    selectedPlans: state.comparePlan.selectedPlans,
    paymentFrequencyList: state.onBoarding.paymentFrequencyList,
    concessionCardList: state.onBoarding.cardList,
    creditCardDetails:
      state.comparePlan.signupAPIResponse?.paymentDetails?.creditCard,
  };
};
const mapDispatchToProps = (dispatch, props) => ({
  validatePinCodes,
  requestValidationEmail,
  sendCustomerLog,
  putSignupDetails: data => dispatch(putSignupDetails(data, false)),
  fetchMoveInDatesList: accountId => dispatch(fetchMoveInDatesList(accountId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompleteSummary)
);
