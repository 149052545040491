import React from 'react';
import { Link } from 'react-router-dom';
import { AgilityButton, AgilityTypography } from 'Src/AgilityComponents';
import { Helmet } from 'react-helmet';

const NotFound = props => {
  return (
    <div className="page-container page-not-found">
      <Helmet>
        <title>{`410 Expired Link | ${process.env.REACT_APP_RETAILER_NAME}`}</title>
      </Helmet>
      <AgilityTypography variant="h2" component="h2" align="center">
        Sorry, that link has expired.
      </AgilityTypography>
      <AgilityTypography variant="h6" component="h6" align="center">
        We're going to redirect you to see what offers are available
      </AgilityTypography>
      <Link to="/plans" className="mt-3">
        <AgilityButton
          variant="outlined"
          color="primary"
          size="large"
          label=" Back to Home"
        />
      </Link>
    </div>
  );
};

export default NotFound;
