import { connect } from 'react-redux';
import PaymentDetails from './PaymentDetails';
import { setPlanFilters } from 'App/pages/ComparePlans/action';

const mapStateToProps = (state, props) => {
  return {
    promoCode: state.comparePlan.planFilter.promoCode,
    paymentFrequencyList: state.onBoarding.paymentFrequencyList,
    signupAPIResponse: state.comparePlan.signupAPIResponse,
    showBillingFrequency: state.app.customConfig.showBillingFrequency,
    showSameDeliveryMethod: state.app.customConfig.showSameDeliveryMethod,
    disableReceiveBillByPost: state.app.customConfig.disableReceiveBillByPost,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setPlanFilters: data => dispatch(setPlanFilters(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
