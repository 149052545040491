/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useState, useEffect, useRef } from 'react';
import i18n from 'i18next';
import 'date-fns';
import {
  AgilityButton,
  AgilityGrid,
  AgilityRadioGroup,
  AgilityCard,
  AgilityTextField,
  AgilityTypography,
} from 'Src/AgilityComponents';
import {
  receiveBillType,
  sameDeliveryMethodType,
  yesNoOptions,
} from 'App/utils/data';
import { object, func, array, number, bool, string } from 'prop-types';
import {
  getCurrentTimestamp,
  formatCurrency,
  getTangoBillFrequency,
  setAllProperties,
} from 'App/utils/helper';
import { SIGNUP_STEPS as signupSteps } from 'App/utils/constants';
import { useTheme } from '@material-ui/core/styles';
import {
  PRODUCT,
  RECEIVE_BILL,
  SAME_DELIVERY_METHOD,
  USE_ESTIMATED_READ_FOR_BILLING,
  BILL_CYCLE_CODES,
} from 'App/utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  showMonthlyEstimateForBasicMeters,
  showEstimatedBillingFrequency,
} from 'App/customConfig';
import InputAdornment from '@material-ui/core/InputAdornment';

const PaymentDetails = props => {
  const {
    promoCode,
    saveProgressTrigger,
    isActive,
    signupAPIResponse,
    onNavigate,
    primarySiteIdentifier,
    paymentFrequencyList,
    nextLoading,
    className,
    id,
    showBillingFrequency,
    showSameDeliveryMethod,
    disableReceiveBillByPost,
    openProgressDialog,
    isAgentView,
  } = props;

  const theme = useTheme();
  const [fields, setFields] = useState({
    billingFrequency: '',
    estimatedBillingFrequency: '',
    powerBillingFrequency: '',
    gasBillingFrequency: '',
    receiveBill: RECEIVE_BILL.EMAIL,
    postAddress: '',
    customerEmail: '',
    sameDeliveryMethod: SAME_DELIVERY_METHOD.YES,
    hasPowerPlan: false,
    hasGasPlan: false,
    hasBasicMeter: false,
    estimatedMonthlyBill: '',
  });

  const [errors, setErrors] = useState({
    billingFrequency: '',
    estimatedBillingFrequency: '',
    receiveBill: '',
    sameDeliveryMethod: '',
    estimatedMonthlyBill: '',
  });

  const [touched, setTouched] = useState({
    promoCode: false,
    billingFrequency: false,
    estimatedBillingFrequency: false,
    receiveBill: false,
    sameDeliveryMethod: false,
    estimatedMonthlyBill: false,
  });

  const [formId, setFormId] = useState(0);
  const estimatedMonthlyBillMin = 50;
  const estimatedMonthlyBillMax = 10000;

  // populate the form, based on a GET request to the API
  useEffect(() => {
    if (
      Array.isArray(signupAPIResponse.contacts) &&
      signupAPIResponse.contacts.length
    ) {
      setFields(prev => ({
        ...prev,
        customerEmail: signupAPIResponse.contacts[0].emailAddress,
      }));
    }

    if (
      Array.isArray(signupAPIResponse.services) &&
      signupAPIResponse.services.length
    ) {
      const powerService = signupAPIResponse.services.find(
        service => service.productType === PRODUCT.ELECTRICITY
      );
      if (powerService) {
        setFields(prev => ({
          ...prev,
          billingFrequency: powerService.billingFrequency,
          estimatedMonthlyBill: powerService.estimatedMonthlyBill,
          hasBasicMeter: powerService.hasBasicMeter,
          hasPowerPlan: true,
        }));
      }
      const gasService = signupAPIResponse.services.find(
        service => service.productType === PRODUCT.GAS
      );
      if (gasService) {
        setFields(prev => ({
          ...prev,
          billingFrequency: gasService.billingFrequency,
          hasGasPlan: true,
        }));
      }

      // UC-2578
      if (showEstimatedBillingFrequency) {
        if (powerService?.billingFrequency && !gasService) {
          setFields(prev => ({
            ...prev,
            estimatedBillingFrequency:
              powerService.billingFrequency ===
              getTangoBillFrequency(
                PRODUCT.ELECTRICITY,
                BILL_CYCLE_CODES.MONTHLY
              )
                ? USE_ESTIMATED_READ_FOR_BILLING.YES
                : USE_ESTIMATED_READ_FOR_BILLING.NO,
          }));
        }
        if (gasService?.billingFrequency && !powerService) {
          setFields(prev => ({
            ...prev,
            estimatedBillingFrequency:
              gasService.billingFrequency ===
              getTangoBillFrequency(PRODUCT.GAS, BILL_CYCLE_CODES.MONTHLY)
                ? USE_ESTIMATED_READ_FOR_BILLING.YES
                : USE_ESTIMATED_READ_FOR_BILLING.NO,
          }));
        }

        if (powerService?.billingFrequency && gasService?.billingFrequency) {
          if (
            powerService.billingFrequency ===
              getTangoBillFrequency(
                PRODUCT.ELECTRICITY,
                BILL_CYCLE_CODES.MONTHLY
              ) &&
            gasService.billingFrequency ===
              getTangoBillFrequency(PRODUCT.GAS, BILL_CYCLE_CODES.MONTHLY)
          ) {
            setFields(prev => ({
              ...prev,
              estimatedBillingFrequency: USE_ESTIMATED_READ_FOR_BILLING.YES,
            }));
          } else {
            if (
              powerService.hasBasicMeter &&
              gasService.billingFrequency ===
                getTangoBillFrequency(
                  PRODUCT.ELECTRICITY,
                  BILL_CYCLE_CODES.MONTHLY
                )
            ) {
              setFields(prev => ({
                ...prev,
                estimatedBillingFrequency: USE_ESTIMATED_READ_FOR_BILLING.YES,
              }));
            } else {
              setFields(prev => ({
                ...prev,
                estimatedBillingFrequency: USE_ESTIMATED_READ_FOR_BILLING.NO,
              }));
            }
          }
        }
      }
    }
    setFormId(getCurrentTimestamp());
  }, [signupAPIResponse]);

  useEffect(() => {
    let address;
    const {
      postalAddress: { postcode, streetAddress, state, suburb } = {
        postCode: '',
        streetAddress: '',
        state: '',
        suburb: '',
      },
      hasDifferentPostalAddress,
      paymentDetails,
    } = signupAPIResponse;
    const { address: siteAddressObj, multilineAddress = [] } =
      primarySiteIdentifier;
    if (siteAddressObj) {
      address = hasDifferentPostalAddress
        ? `${streetAddress}, ${suburb}, ${postcode} ${state}`
        : multilineAddress.join(', ');
    }
    let newFields = { ...fields, postAddress: address };

    if (paymentDetails) {
      if (paymentDetails && Object.keys(paymentDetails).length) {
        if (signupAPIResponse['correspondenceDelivery'] !== '') {
          newFields['receiveBill'] =
            signupAPIResponse['correspondenceDelivery'];
        }
        if (signupAPIResponse['sameDeliveryMethod'] !== '') {
          newFields['sameDeliveryMethod'] =
            signupAPIResponse['sameDeliveryMethod'];
          if (fields.receiveBill === RECEIVE_BILL.POST) {
            if (process.env.REACT_APP_BRAND_CODE === 'SEG') {
              newFields['sameDeliveryMethod'] = SAME_DELIVERY_METHOD.YES;
            } else {
              newFields['sameDeliveryMethod'] =
                fields.sameDeliveryMethod === SAME_DELIVERY_METHOD.YES
                  ? SAME_DELIVERY_METHOD.NO
                  : SAME_DELIVERY_METHOD.YES;
            }
          }
        }
        setFields(newFields);
        setFormId(getCurrentTimestamp());
      }
    }
  }, [
    fields.receiveBill,
    fields.sameDeliveryMethod,
    signupAPIResponse,
    primarySiteIdentifier,
  ]);

  const resetValidation = React.useCallback(() => {
    const updatedTouched = setAllProperties(touched, false);
    setTouched(updatedTouched);
    setErrors({});
  }, []);

  const prevPropRef = useRef();
  useEffect(() => {
    if (
      prevPropRef.current &&
      isActive &&
      prevPropRef.current !== saveProgressTrigger
    ) {
      const data = { ...signupAPIResponse };
      data.correspondenceDelivery = fields.receiveBill;
      data.sameDeliveryMethod = fields.sameDeliveryMethod;
      if (fields.receiveBill === RECEIVE_BILL.POST) {
        if (process.env.REACT_APP_BRAND_CODE === 'SEG') {
          data.sameDeliveryMethod = SAME_DELIVERY_METHOD.YES;
        } else {
          data.sameDeliveryMethod =
            data.sameDeliveryMethod === SAME_DELIVERY_METHOD.YES
              ? SAME_DELIVERY_METHOD.NO
              : SAME_DELIVERY_METHOD.YES;
        }
      }
      data.paymentDetails = data.paymentDetails || {};
      data.paymentDetails['billingFrequency'] = fields['billingFrequency'];
      data.paymentDetails['estimatedMonthlyBill'] =
        fields['estimatedMonthlyBill'];
      data.paymentDetails['promotionReferralCode'] = promoCode.promoCode
        ? 'yes'
        : 'no';
      data.currentPage = signupSteps.paymentDetails;
      onNavigate('progress', data, true);
    }
    prevPropRef.current = saveProgressTrigger;
    if (isActive === false) {
      resetValidation();
    }
  }, [
    saveProgressTrigger,
    isActive,
    onNavigate,
    signupAPIResponse,
    fields,
    promoCode,
    resetValidation,
  ]);

  const handleChange = (val, name) => {
    setTouched(prev => ({
      ...prev,
      [name]: true,
    }));

    setFields(prev => ({
      ...prev,
      [name]: val,
    }));
    validateForm();
  };

  const validateForm = () => {
    const errorMessages = {};
    let formIsValid = true;

    if (fields.hasPowerPlan) {
      if (touched['billingFrequency']) {
        if (!fields['billingFrequency'] && showBillingFrequency) {
          formIsValid = false;
          errorMessages['billingFrequency'] = i18n.t(
            'paymentdetails.errorMessage.paybill'
          );
        }
      }

      if (
        showMonthlyEstimateForBasicMeters &&
        fields.hasBasicMeter &&
        touched['estimatedMonthlyBill']
      ) {
        if (
          fields['estimatedMonthlyBill'] < estimatedMonthlyBillMin ||
          fields['estimatedMonthlyBill'] > estimatedMonthlyBillMax
        ) {
          formIsValid = false;
          errorMessages[
            'estimatedMonthlyBill'
          ] = `Monthly electricity bill estimate must be between ${formatCurrency(
            estimatedMonthlyBillMin
          )} and ${formatCurrency(estimatedMonthlyBillMax)}`;
        }
      }
    }
    // UC-2578
    if (showEstimatedBillingFrequency) {
      if (touched['estimatedBillingFrequency']) {
        if (!fields['estimatedBillingFrequency'] && showBillingFrequency) {
          formIsValid = false;
          errorMessages['estimatedBillingFrequency'] = i18n.t(
            'paymentdetails.errorMessage.estimatedBillingFrequency'
          );
        }
      }
    }

    if (touched['receiveBill']) {
      if (!fields['receiveBill']) {
        formIsValid = false;
        errorMessages['receiveBill'] = i18n.t(
          'paymentdetails.errorMessage.receivebill'
        );
      }
    }
    setErrors(errorMessages);
    return formIsValid;
  };

  const nextForm = e => {
    const updatedTouched = setAllProperties(touched, true);
    setTouched(updatedTouched);
    const isFormValid = validateForm();
    if (isFormValid) {
      const data = { ...signupAPIResponse };
      const powerService = data.services.find(
        service => service.productType === PRODUCT.ELECTRICITY
      );
      if (powerService) {
        powerService.billingFrequency = fields.billingFrequency;
        powerService.estimatedMonthlyBill = fields.estimatedMonthlyBill;
      }
      const gasService = data.services.find(
        service => service.productType === PRODUCT.GAS
      );
      data.correspondenceDelivery = fields.receiveBill;
      data.sameDeliveryMethod = fields.sameDeliveryMethod;
      if (fields.receiveBill === RECEIVE_BILL.POST) {
        if (process.env.REACT_APP_BRAND_CODE === 'SEG') {
          data.sameDeliveryMethod = SAME_DELIVERY_METHOD.YES;
        } else {
          data.sameDeliveryMethod =
            data.sameDeliveryMethod === SAME_DELIVERY_METHOD.YES
              ? SAME_DELIVERY_METHOD.NO
              : SAME_DELIVERY_METHOD.YES;
        }
      }
      data.currentPage = signupSteps.lifeSupportConcession;
      data.paymentDetails = data.paymentDetails || {};

      // UC-2578
      if (showEstimatedBillingFrequency) {
        if (powerService && gasService) {
          if (
            fields.estimatedBillingFrequency ===
            USE_ESTIMATED_READ_FOR_BILLING.YES
          ) {
            powerService.billingFrequency = getTangoBillFrequency(
              PRODUCT.ELECTRICITY,
              BILL_CYCLE_CODES.MONTHLY
            );
            gasService.billingFrequency = getTangoBillFrequency(
              PRODUCT.GAS,
              BILL_CYCLE_CODES.MONTHLY
            );
          } else {
            powerService.billingFrequency = getTangoBillFrequency(
              PRODUCT.ELECTRICITY,
              BILL_CYCLE_CODES.QUARTERLY
            );
            gasService.billingFrequency = getTangoBillFrequency(
              PRODUCT.GAS,
              BILL_CYCLE_CODES.QUARTERLY
            );
          }
        } else if (powerService && !gasService) {
          powerService.billingFrequency = getTangoBillFrequency(
            PRODUCT.ELECTRICITY,
            fields.estimatedBillingFrequency ===
              USE_ESTIMATED_READ_FOR_BILLING.YES
              ? BILL_CYCLE_CODES.MONTHLY
              : BILL_CYCLE_CODES.QUARTERLY
          );
        } else if (!powerService && gasService) {
          gasService.billingFrequency = getTangoBillFrequency(
            PRODUCT.GAS,
            fields.estimatedBillingFrequency ===
              USE_ESTIMATED_READ_FOR_BILLING.YES
              ? BILL_CYCLE_CODES.MONTHLY
              : BILL_CYCLE_CODES.QUARTERLY
          );
        }
      }

      data.paymentDetails['billingFrequency'] = fields['billingFrequency'];
      data.paymentDetails['estimatedMonthlyBill'] =
        fields['estimatedMonthlyBill'];
      data.paymentDetails['promotionReferralCode'] = promoCode.promoCode
        ? 'yes'
        : 'no';
      props.onNavigate('next', data, false);
    }
  };

  const onBackClick = () => {
    resetValidation();
    props.onNavigate('back');
  };

  const deliveryElement = (receiveBill, sameDeliveryMethod) => {
    const emailElement = (
      <React.Fragment>
        <AgilityTextField
          disabled
          type="text"
          fullWidth
          placeholder={i18n.t('paymentdetails.email.placeholder')}
          value={fields.customerEmail}
          variant="outlined"
        />
        <p
          className="text__note"
          css={css`
            color: ${theme.palette.grey['500']};
          `}
        >
          {i18n.t('paymentdetails.sameDeliveryMethod.emailNote')}
        </p>
      </React.Fragment>
    );
    const postalAddressElement = (
      <React.Fragment>
        <AgilityTextField
          disabled
          type="text"
          fullWidth
          placeholder={i18n.t('paymentdetails.postaladdress.placeholder')}
          value={fields.postAddress || ''}
          variant="outlined"
        />
        <p
          className="text__note"
          css={css`
            color: ${theme.palette.grey['500']};
          `}
        >
          {i18n.t('paymentdetails.sameDeliveryMethod.postalAddressNote')}
        </p>
      </React.Fragment>
    );

    if (receiveBill === RECEIVE_BILL.EMAIL) {
      return sameDeliveryMethod === SAME_DELIVERY_METHOD.YES
        ? emailElement
        : postalAddressElement;
    }

    if (receiveBill === RECEIVE_BILL.POST) {
      if (process.env.REACT_APP_BRAND_CODE === 'SEG') {
        return emailElement;
      } else {
        return sameDeliveryMethod === SAME_DELIVERY_METHOD.YES
          ? postalAddressElement
          : emailElement;
      }
    }

    return <></>;
  };

  const estimatedBillingFrequencyLabel = React.useMemo(() => {
    let labelPath = 'paymentdetails.paybill.question';

    const powerService = signupAPIResponse.services.find(
      service => service.productType === PRODUCT.ELECTRICITY
    );
    const gasService = signupAPIResponse.services.find(
      service => service.productType === PRODUCT.GAS
    );

    // Gas only
    if (gasService && !powerService) {
      labelPath += '.gas';
    }
    // Elec only
    if (powerService && !gasService) {
      labelPath += powerService.hasBasicMeter ? '.elec.dumb' : '.elec.smart';
    }

    // dual fuel
    if (powerService && gasService) {
      labelPath += powerService.hasBasicMeter ? '.dual.dumb' : '.dual.smart';
    }

    return i18n.t(labelPath);
  }, [signupAPIResponse]);

  return (
    <AgilityCard className={`steps-wrapper ${className}`} id={id}>
      <AgilityTypography variant="h4" component="h4">
        {i18n.t('paymentdetails.header.text')}
      </AgilityTypography>

      <form
        autoComplete="off"
        noValidate
        data-test-id="paymentDetailsForm"
        id={formId}
      >
        {showMonthlyEstimateForBasicMeters &&
          fields.hasPowerPlan &&
          fields.hasBasicMeter && (
            <AgilityGrid container>
              <AgilityGrid
                item
                xs={12}
                sm={12}
                data-test-id="pay-bill-question"
              >
                <div className="question-wrapper">
                  <AgilityTypography variant="body2" component="p">
                    {i18n.t('paymentdetails.estimatedMonthlyBill.label')}
                  </AgilityTypography>
                  <AgilityTextField
                    disabled={!isActive}
                    type="number"
                    fullWidth
                    value={fields.estimatedMonthlyBill}
                    variant="outlined"
                    onChange={val => {
                      handleChange(val, 'estimatedMonthlyBill');
                    }}
                    InputProps={{
                      inputProps: {
                        min: estimatedMonthlyBillMin,
                        max: estimatedMonthlyBillMax,
                        placeholder: i18n.t(
                          'paymentdetails.estimatedMonthlyBill.placeholder'
                        ),
                      },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    error={errors && errors.estimatedMonthlyBill ? true : false}
                    helperText={
                      errors && errors.estimatedMonthlyBill
                        ? errors.estimatedMonthlyBill
                        : ''
                    }
                  />
                </div>
              </AgilityGrid>
            </AgilityGrid>
          )}
        {showEstimatedBillingFrequency && (
          <AgilityGrid container>
            <AgilityGrid item xs={12} sm={12} data-test-id="pay-bill-question">
              <div className="question-wrapper">
                <React.Fragment>
                  <AgilityTypography variant="body2" component="p">
                    {estimatedBillingFrequencyLabel}
                  </AgilityTypography>
                  {paymentFrequencyList.length > 0 && (
                    <AgilityRadioGroup
                      disabled={!isActive}
                      options={yesNoOptions}
                      onChange={event => {
                        handleChange(
                          event.target.value,
                          'estimatedBillingFrequency'
                        );
                      }}
                      value={fields.estimatedBillingFrequency || ''}
                      data-test-id="estimatedBillingFrequency"
                      row
                      isError={
                        errors && errors.estimatedBillingFrequency
                          ? true
                          : false
                      }
                      helperText={
                        errors && errors.estimatedBillingFrequency
                          ? errors.estimatedBillingFrequency
                          : ''
                      }
                    />
                  )}
                </React.Fragment>
              </div>
            </AgilityGrid>
          </AgilityGrid>
        )}
        {showBillingFrequency && (
          <AgilityGrid container>
            <AgilityGrid item xs={12} sm={12} data-test-id="pay-bill-question">
              <div className="question-wrapper">
                {fields.hasPowerPlan && (
                  <React.Fragment>
                    <AgilityTypography variant="body2" component="p">
                      {i18n.t('paymentdetails.paybill.question')}
                    </AgilityTypography>
                    {paymentFrequencyList.length > 0 && (
                      <AgilityRadioGroup
                        disabled={!isActive}
                        options={paymentFrequencyList}
                        onChange={event => {
                          handleChange(event.target.value, 'billingFrequency');
                        }}
                        value={fields.billingFrequency || ''}
                        data-test-id="billingFrequency"
                        row
                        isError={
                          errors && errors.billingFrequency ? true : false
                        }
                        helperText={
                          errors && errors.billingFrequency
                            ? errors.billingFrequency
                            : ''
                        }
                      />
                    )}
                  </React.Fragment>
                )}
                {fields.hasPowerPlan && fields.hasGasPlan && (
                  <AgilityTypography variant="body2" component="p">
                    {i18n.t('paymentdetails.paybill.elecNoteForDualPlan')}
                  </AgilityTypography>
                )}
                {fields.hasPowerPlan && !fields.hasGasPlan && (
                  <AgilityTypography variant="body2" component="p">
                    {i18n.t('paymentdetails.paybill.elecNote')}
                  </AgilityTypography>
                )}
                {fields.hasGasPlan && (
                  <AgilityTypography variant="body2" component="p">
                    {i18n.t('paymentdetails.paybill.gasNote')}
                  </AgilityTypography>
                )}
              </div>
            </AgilityGrid>
          </AgilityGrid>
        )}

        <AgilityGrid container>
          <AgilityGrid
            item
            xs={12}
            sm={12}
            data-test-id="receive-bill-question"
          >
            <div className="question-wrapper">
              <AgilityTypography variant="body2" component="p">
                {i18n.t('paymentdetails.receiveBill.text')}
              </AgilityTypography>
              <AgilityTypography variant="body2" component="p">
                {i18n.t('paymentdetails.receiveBill.subText')}
              </AgilityTypography>
              <AgilityRadioGroup
                disabled={!isActive}
                options={receiveBillType}
                onChange={event => {
                  handleChange(event.target.value, 'receiveBill');
                }}
                value={fields.receiveBill || ''}
                data-test-id="receiveBill"
                row
                isError={errors && errors.receiveBill ? true : false}
                helperText={
                  errors && errors.receiveBill ? errors.receiveBill : ''
                }
              />
              {disableReceiveBillByPost && (
                <AgilityTypography
                  variant="body2"
                  component="p"
                  css={css`
                    font-size: 12px;
                  `}
                >
                  {i18n.t('paymentdetails.receiveBill.note')}
                </AgilityTypography>
              )}
              {fields.receiveBill === RECEIVE_BILL.EMAIL &&
                process.env.REACT_APP_BRAND_CODE === 'NBE' && (
                  <AgilityGrid item xs={12}>
                    {deliveryElement(
                      fields.receiveBill,
                      fields.sameDeliveryMethod
                    )}
                  </AgilityGrid>
                )}
            </div>
          </AgilityGrid>
        </AgilityGrid>

        {showSameDeliveryMethod && (
          <AgilityGrid container>
            <AgilityGrid item xs={12} sm={12}>
              <div className="question-wrapper">
                <AgilityTypography variant="body2" component="p">
                  {fields.receiveBill === RECEIVE_BILL.EMAIL
                    ? i18n.t('paymentdetails.sameDeliveryMethod.emailQuestion')
                    : i18n.t(
                        'paymentdetails.sameDeliveryMethod.postalAddressQuestion'
                      )}
                </AgilityTypography>
                {(process.env.REACT_APP_BRAND_CODE !== 'SEG' ||
                  (process.env.REACT_APP_BRAND_CODE === 'SEG' &&
                    fields.receiveBill === RECEIVE_BILL.EMAIL)) && (
                  <AgilityRadioGroup
                    disabled={!isActive}
                    options={sameDeliveryMethodType}
                    onChange={event => {
                      handleChange(event.target.value, 'sameDeliveryMethod');
                    }}
                    value={fields.sameDeliveryMethod}
                    row
                    isError={errors && errors.sameDeliveryMethod ? true : false}
                    helperText={
                      errors && errors.sameDeliveryMethod
                        ? errors.sameDeliveryMethod
                        : ''
                    }
                  />
                )}
              </div>
            </AgilityGrid>
            <AgilityGrid item xs={12}>
              {deliveryElement(fields.receiveBill, fields.sameDeliveryMethod)}
            </AgilityGrid>
          </AgilityGrid>
        )}

        <div className="steps-footer">
          {isAgentView && (
            <AgilityButton
              color="primary"
              onClick={openProgressDialog}
              label={i18n.t('saveprogress.sms.button')}
              data-test-id="smsButton"
              endIcon={<FontAwesomeIcon icon={['fas', 'envelope']} />}
            />
          )}
          <AgilityButton
            disabled={!isActive}
            color="primary"
            data-test-id="backButton"
            onClick={() => {
              onBackClick();
            }}
            label={i18n.t('signup.button.back')}
            className="push"
          />
          <AgilityButton
            disabled={!isActive}
            variant="contained"
            color="primary"
            type="primary"
            label={i18n.t('signup.button.next')}
            loading={nextLoading}
            data-test-id="nextButton"
            onClick={e => {
              nextForm(e);
            }}
          />
        </div>
      </form>
    </AgilityCard>
  );
};

PaymentDetails.propTypes = {
  promoCode: object,
  setPlanFilters: func,
  signupAPIResponse: object,
  saveProgressTrigger: number,
  isActive: bool,
  nextLoading: bool,
  paymentFrequencyList: array,
  className: string,
  id: string,
  primarySiteIdentifier: object,
  showBillingFrequency: bool,
};

PaymentDetails.defaultProps = {
  promoCode: {},
  signupAPIResponse: {},
  saveProgressTrigger: 0,
  isActive: false,
  nextLoading: false,
  paymentFrequencyList: [],
  className: '',
  id: '',
  primarySiteIdentifier: {},
  showBillingFrequency: true,
};

export default PaymentDetails;
