import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ShortLinkRedirect from './ShortLinkRedirect';
import { getShortLinkRedirectUrl } from './action';

const mapStateToProps = (state, props) => {
  return {
    ...props,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getShortLinkRedirectUrl: shortcode =>
      dispatch(getShortLinkRedirectUrl(shortcode)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShortLinkRedirect)
);
