import React from 'react';
import AgilityMap from 'App/components/Map/AgilityMap';
import PropertyAddress from './PropertyAddress';
// import SiteIdentifiers from './SiteIdentifiers';
// import { getProductTypeByProductCode, isEmpty } from 'App/utils/helper';
import { isEmpty } from 'App/utils/helper';
import i18n from 'i18next';
import { AgilityCard } from 'Src/AgilityComponents';
import AgilitySiteIdentifierDialog from 'App/components/Dialog/AgilitySiteIdentifierDialog';
import { object, array, bool } from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { identifierDialogContent } from 'App/utils/data';

const PropertyCard = props => {
  const [showSearchInfo, setShowSearchInfo] = React.useState(false);
  const [mapText, setMapText] = React.useState('');

  const notFoundHandler = status => {
    if (status !== 'OK') {
      setMapText(i18n.t('yourProperty.errorMessage.locationNotFound'));
    }
  };
  const { primarySiteIdentifier, productList, services, showInfo } = props;

  const onInfoDialogClick = () => {
    setShowSearchInfo(true);
  };
  const infoDialogOkClick = () => {
    setShowSearchInfo(false);
  };

  return (
    <AgilityCard className={`service-card ${props.className}`} elevation={2}>
      {!isEmpty(primarySiteIdentifier) &&
      !isEmpty(productList) &&
      !isEmpty(services) ? (
        <React.Fragment>
          <div className="image-container">
            <AgilityMap
              id="myMap"
              address={primarySiteIdentifier.formattedAddress}
              zoom={18}
              notFoundHandler={notFoundHandler}
              data-test-id="tally-map"
            >
              {mapText}
            </AgilityMap>
          </div>

          <div className="basic-details">
            <address className="mb-1">
              <PropertyAddress
                displayAddresses={primarySiteIdentifier.multilineAddress}
              />
            </address>

            {/* {services != null &&
              services.map((item, index) => {
                const productObj = getProductTypeByProductCode(
                  productList,
                  item.productType
                );
                return (
                  <SiteIdentifiers
                    key={index}
                    type={(productObj && productObj.displayAlternative) || ''}
                    siteIdentifier={item.siteIdentifier || ''}
                    data-test-id="side-identifiers"
                  />
                );
              })} */}
            {showInfo && (
              <div
                className="icon-info"
                data-test-id="info-icon"
                onClick={onInfoDialogClick}
              >
                <FontAwesomeIcon icon={['fas', `info-circle`]} />
              </div>
            )}
            <AgilitySiteIdentifierDialog
              open={showSearchInfo}
              onDismiss={infoDialogOkClick}
              data-test-id="site-identifier-dialog"
              data={identifierDialogContent}
            />
          </div>
        </React.Fragment>
      ) : (
        <div className="loader-section">
          <CircularProgress
            color="primary"
            variant="indeterminate"
            className="loader"
            thickness={3}
            size={30}
            data-test-id="loading-progress-id"
          />
        </div>
      )}
    </AgilityCard>
  );
};

PropertyCard.propTypes = {
  primarySiteIdentifier: object,
  productList: array,
  services: array,
  showInfo: bool,
};
PropertyCard.defaultProps = {
  primarySiteIdentifier: {},
  productList: [],
  services: [],
  showInfo: false,
};

export default PropertyCard;
