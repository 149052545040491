/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { Grid, Typography, useTheme, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ThemedBox = ({ icon = ['fas', 'alert'], label = '' }) => {
  const theme = useTheme();
  const { menuButtonCss, textCss, iconCss } = theme.custom.PlanFilter.filters;

  const iconMarginTop = React.useMemo(() => {
    return (
      (menuButtonCss.borderWidth > 0 ? menuButtonCss.borderWidth * 2 : 0) + 1
    );
  }, [menuButtonCss.borderWidth]);

  return (
    <Button
      fullWidth
      disabled
      css={{
        ...menuButtonCss,
        cursor: 'default',
        opacity: 0.65,
      }}
    >
      <Grid
        container
        alignItems="center"
        alignContent={'center'}
        css={{
          height: 44 - menuButtonCss.borderWidth * 2,
          flexDirection: 'row',
        }}
      >
        <Grid item css={{ width: 36, textAlign: 'center', paddingRight: 12 }}>
          <FontAwesomeIcon
            icon={icon}
            css={{
              marginTop: iconMarginTop,
              ...iconCss,
            }}
          />
        </Grid>
        <Grid item>
          <Typography variant="body2" css={textCss}>
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

export default ThemedBox;
