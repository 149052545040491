import React, { useState, useEffect, useRef, useCallback } from 'react';
import i18n from 'i18next';
import {
  AgilityButton,
  AgilityCard,
  AgilityTextField,
  AgilityTypography,
  AgilityGrid,
  AgilityTableCell,
} from 'Src/AgilityComponents';
import {
  isValidAlphanumericOfLength,
  isValidNameWithSpaceAndSymbol,
  isNotEmpty,
  isNullOrEmpty,
  validateFields,
} from 'App/utils/validationHelper';
import {
  TRADING_NAME_MAX_LENGTH,
  ABN_VALID_LENGTH,
  ACN_VALID_LENGTH,
} from 'App/utils/fieldLengthHelper';
import { SIGNUP_STEPS as signupSteps } from 'App/utils/constants';
import { Element, scroller } from 'react-scroll';
import BusinessNameLookup from './Components/BusinessNameLookup';
import AbnNumberLookup from './Components/AbnNumberLookup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputAdornment from '@material-ui/core/InputAdornment';
import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';
import { freeTextAcn } from 'App/customConfig';

const CompanyDetail = props => {
  const {
    abnNumber,
    acnNumber,
    businessName,
    gstRegisteredAt,
    isActive,
    onNavigate,
    organisationIsTrust,
    organisationTypeDescription,
    organisationTypeId,
    saveProgressTrigger,
    searchAbnNumber,
    searchBusinessName,
    setAbnNumber,
    setAcnNumber,
    setBusinessName,
    setGstRegisteredAt,
    setOrganisationIsTrust,
    setOrganisationTypeDescription,
    setOrganisationTypeId,
    setTradingName,
    signupAPIResponse,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [fields, setFields] = useState({
    businessName: '',
    tradingName: '',
    abnNumber: '',
    acnNumber: '',
    trusteeName: '',
    abnStatus: '',
  });
  // Note that errors for abnNumber could come from here, or they could be set in redux via AbnNumberLookup
  const [errors, setErrors] = useState({
    businessName: '',
    tradingName: '',
    abnNumber: '',
    acnNumber: '',
    trusteeName: '',
  });
  const [touched, setTouched] = useState({
    businessName: false,
    tradingName: false,
    abnNumber: false,
    acnNumber: '',
    trusteeName: false,
  });
  const [selected, setSelected] = useState({
    businessName: false,
    abnNumber: false,
  });
  const [loading, setLoading] = useState({
    businessName: false,
    abnNumber: false,
  });
  const [abnStatus, setAbnStatus] = useState({
    status: '',
  });
  const [doFreeTextAcn, setDoFreeTextAcn] = useState(false);

  // An array of business names options for the ABN
  const [businessNames, setBusinessNames] = useState([]);
  const [abnBusinessNames, setAbnBusinessNames] = useState([]);

  const validators = {
    defaultChecker: isNotEmpty,
    fields: {
      businessName: {
        preCondition: '',
        defaultErrorText: 'companyDetail.errorMessage.requiredBusinessName',
        conditions: [
          {
            condition: isValidNameWithSpaceAndSymbol,
            errorText: 'companyDetail.errorMessage.validBusinessName',
          },
        ],
      },
      tradingName: {
        preCondition: '',
        defaultErrorText: 'companyDetail.errorMessage.requiredTradingName',
        conditions: [
          {
            condition: isValidNameWithSpaceAndSymbol,
            errorText: 'companyDetail.errorMessage.validTradingName',
          },
        ],
      },
      abnNumber: {
        preCondition: '',
        isOptional: false,
        defaultErrorText: 'companyDetail.errorMessage.requiredABN',
        conditions: [
          {
            condition: value =>
              isNullOrEmpty(value) ||
              isValidAlphanumericOfLength(value, ABN_VALID_LENGTH),
            errorText: 'companyDetail.errorMessage.validABN',
          },
        ],
      },
      acnNumber: {
        preCondition: () => doFreeTextAcn,
        isOptional: false,
        defaultErrorText: 'companyDetail.errorMessage.requiredACN',
        conditions: [
          {
            condition: value =>
              isNullOrEmpty(value) ||
              isValidAlphanumericOfLength(value, ACN_VALID_LENGTH),
            errorText: 'companyDetail.errorMessage.validACN',
          },
        ],
      },
    },
  };

  // this method validate all the form fields
  const validateForm = (currentTouched, currentFields) => {
    const formValidationData = validateFields(
      validators,
      currentFields,
      currentTouched
    );

    if (currentTouched['trusteeName'] && organisationIsTrust) {
      if (organisationIsTrust) {
        // TrusteeName is required
        if (isNullOrEmpty(fields.trusteeName)) {
          formValidationData.errorMessages['trusteeName'] = i18n.t(
            'companyDetail.errorMessage.requiredTrusteeName'
          );
          formValidationData.invalidField = 'trusteeName';
        }
      }
    }
    if (
      !currentFields.businessName &&
      currentTouched.businessName &&
      !loading.businessName &&
      !selected.businessName
    ) {
      formValidationData.errorMessages['businessName'] = i18n.t(
        'companyDetail.errorMessage.selectBusinessName'
      );

      formValidationData.invalidField = 'businessName';
    }

    if (abnStatus.status !== 'Active' && abnStatus.status !== '') {
      formValidationData.errorMessages['abnNumber'] = i18n.t(
        'companyDetail.errorMessage.activeABN'
      );

      formValidationData.invalidField = 'abnNumber';
    }
    setErrors(formValidationData.errorMessages);
    return formValidationData.invalidField;
  };

  // Updates local state variables from UCONX, as well as the redux store via setters that were added as props
  useEffect(() => {
    const organisation = signupAPIResponse['organisation'];
    if (organisation) {
      const fieldData = {
        businessName: organisation['name'] || '',
        tradingName: organisation['trading_name'] || '',
        trusteeName: organisation['trustee_name'] || '',
        abnNumber: organisation['abn'] || '',
        acnNumber: organisation['acn'] || '',
      };
      setFields(fieldData);
      setBusinessName(fieldData.businessName);
      setAbnNumber(fieldData.abnNumber);
      setAcnNumber(fieldData.acnNumber);
      setGstRegisteredAt(organisation['gst_registered_at'] || '');
      setOrganisationTypeId(organisation['organisation_type_id'] || '');
      setOrganisationTypeDescription(
        organisation['organisationType']['description'] || ''
      );
      setDoFreeTextAcn(
        freeTextAcn &&
          organisation['abr_verified_acn'] === 0 &&
          organisation['organisationType']['requires_acn'] === 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupAPIResponse]);

  /**
   * Updates the data which will be sent to UCONX.
   * Note that the redux store is not updated here, and needs to be updated in the on change function, alongside
   * handleChange(), which only updates the local state for fields and touched.
   * Some redux values may also be updated in handleAbnNumberChange(), directly from the response returned by the lookup.
   */
  function updateResponse() {
    const apiData = {
      organisation: {
        name: fields.businessName,
        trading_name: fields.tradingName,
        trustee_name: fields.trusteeName,
        organisation_type_id: organisationTypeId,
        abn: fields.abnNumber,
        acn: fields.acnNumber,
        gst_registered_at: gstRegisteredAt,
        abr_verified_abn: 1,
        abr_verified_acn: (acnNumber ? !doFreeTextAcn : false) ? 1 : 0, // API wants an int
      },
    };
    return { ...signupAPIResponse, ...apiData };
  }

  const resetValidation = useCallback(() => {
    setTouched({
      businessName: false,
      tradingName: false,
      abnNumber: false,
      acnNumber: false,
      trusteeName: false,
    });

    setErrors({});
  }, []);

  const prevPropRef = useRef();
  useEffect(() => {
    if (
      prevPropRef.current &&
      isActive &&
      prevPropRef.current !== saveProgressTrigger
    ) {
      const data = updateResponse();
      data.currentPage = signupSteps.yourCompanyDetails;
      onNavigate('progress', data, true);
    }
    prevPropRef.current = saveProgressTrigger;
    if (isActive === false) {
      resetValidation();
    }
  }, [saveProgressTrigger, isActive, onNavigate, resetValidation]);

  const handleChange = (val, name) => {
    let newTouched = { ...touched, [name]: true };
    let newFields = { ...fields, [name]: val };
    setTouched(newTouched);
    setFields(newFields);
    validateForm(newTouched, newFields);
  };

  const nextForm = e => {
    const updatedTouched = {
      businessName: true,
      tradingName: true,
      abnNumber: true,
      acnNumber: true,
      trusteeName: true,
    };
    setTouched(updatedTouched);

    const invalidField = validateForm(updatedTouched, fields);

    if (invalidField) {
      setTimeout(() => {
        scroller.scrollTo(invalidField, {
          duration: 1000,
          delay: 10,
          smooth: 'easeInOutQuart',
          isDynamic: true,
          top: 0,
          offset: -220,
        });
      }, 300);
      return; // Don't proceed if there are errors
    }

    const data = updateResponse();
    data.currentPage = signupSteps.yourDetails;
    props.onNavigate('next', data, false);
  };

  const onBackClick = () => {
    resetValidation();
    props.onNavigate('back');
  };

  const onClearClick = () => {
    handleChange('', 'tradingName');
    handleChange('', 'trusteeName');
    setAbnNumber('');
    setAcnNumber('');
    setBusinessName('');
    setTradingName('');
    setGstRegisteredAt('');
    setOrganisationTypeId('');
    setOrganisationTypeDescription('');
    setDoFreeTextAcn(false);
    setFields({
      businessName: '',
      tradingName: '',
      abnNumber: '',
      acnNumber: '',
      trusteeName: '',
      abnStatus: '',
    });
    setAbnBusinessNames([]);

    // handles `touched`, `errors`
    resetValidation();

    setSelected({
      businessName: false,
      abnNumber: false,
    });

    setLoading({
      businessName: false,
      abnNumber: false,
    });
    setAbnStatus({
      status: '',
    });

    setBusinessNames([]);
  };

  const getEndIcon = field => {
    // A name lookup will also run when you select another item from the list,
    // so make sure we've not already selected something (and therefore the list of names will already be populated)
    if (loading[field] && !selected[field]) {
      return (
        <CircularProgress
          color="primary"
          variant="indeterminate"
          className="loader"
          thickness={3}
          size={15}
          style={{
            left: '90%',
            top: '30px',
          }}
        />
      );
    }
    if (selected[field] && !errors[field]) {
      return (
        <FontAwesomeIcon
          icon={['fas', 'check']}
          style={{
            marginRight: '-15px',
          }}
        />
      );
    }
  };

  const searchBusinessNameCallback = useCallback(
    (val, callback) => {
      searchBusinessName(val)
        .then(response => {
          setLoading(prev => ({ ...prev, businessName: false }));
          callback(response);
        })
        .catch(err => {
          enqueueSnackbar(i18n.t('500.error.message'), { variant: 'error' });
          setLoading(prev => ({ ...prev, businessName: false }));
        });
    },
    [enqueueSnackbar, searchBusinessName]
  );

  const searchAbnNumberCallback = useCallback(
    (val, callback) => {
      searchAbnNumber(val)
        .then(response => {
          setLoading(prev => ({ ...prev, abnNumber: false }));
          callback(response);
        })
        .catch(err => {
          setErrors({
            abnNumber: i18n.t('companyDetail.errorMessage.validABN'),
          });
          setLoading(prev => ({ ...prev, abnNumber: false }));
        });
    },
    [searchAbnNumber]
  );

  const handleBusinessNameChange = val => {
    if (val) {
      if (val.Abn) {
        // will be from selecting a dropdown item
        setSelected(prev => ({ ...prev, businessName: true }));
        handleAbnNumberChange(val.Abn);
      } else {
        // Just typing in the name
        setSelected(prev => ({ ...prev, businessName: false }));
        setLoading(prev => ({ ...prev, businessName: true }));
        setErrors(prev => ({ ...prev, businessName: '' }));

        searchBusinessNameCallback(val.Name, response => {
          setBusinessNames(response || []);
          setLoading(prev => ({ ...prev, businessName: false }));
        });
      }
      setBusinessName(val.Name);
      handleChange(val.Name, 'businessName');
    } else {
      setLoading(prev => ({ ...prev, businessName: false }));
      setBusinessName('');
      handleChange('', 'businessName');
      setAbnNumber('');
      handleChange('', 'abnNumber');
    }
  };

  const debouncedHandleBusinessNameChange = debounce(
    handleBusinessNameChange,
    500
  );

  const handleAbnNumberChange = val => {
    setLoading(prev => ({ ...prev, abnNumber: true }));
    setErrors(prev => ({ ...prev, abnNumber: '', tradingName: '' })); // TODO doesn't actually clear the errors???

    searchAbnNumberCallback(val, response => {
      if (response) {
        if (response.AbnStatus === 'Active') {
          let newFields = { ...fields };
          if (Array.isArray(response.BusinessName)) {
            if (response.BusinessName.length !== 0) {
              let names = response.BusinessName;
              names.push(response.EntityName);
              setAbnBusinessNames(names);
              // Set the Business Name to the root entity, and the trading name to the searched name.
              setBusinessName(response.EntityName);
              setTradingName(businessName);
              newFields['businessName'] = response.EntityName;
              newFields['tradingName'] = businessName;
            } else {
              setTradingName(response.EntityName);
              newFields['tradingName'] = response.EntityName;
            }
          }
          if (selected.businessName !== true) {
            setSelected(prev => ({ ...prev, abnNumber: true }));
          } else {
            setSelected({ businessName: true, abnNumber: true });
            setAbnNumber(response.Abn);
          }
          setAbnStatus({
            status: '' + response.AbnStatus,
          });
          if (response.Acn) {
            setAcnNumber(response.Acn);
            newFields['acnNumber'] = response.Acn;
            setDoFreeTextAcn(false);
          } else if (freeTextAcn && response.RequiresAcn) {
            setDoFreeTextAcn(true);
          }
          setGstRegisteredAt(response.Gst);
          setOrganisationTypeId(response.OrganisationTypeId);
          setOrganisationTypeDescription(response.OrganisationTypeDescription);
          setOrganisationIsTrust(response.IsTrust);

          // Because this happens in a callback, we need to make sure we don't overwrite the ABN number in Fields
          // with an empty string from the previous state
          newFields['abnNumber'] = val;
          let newTouched = {
            ...touched,
            abnNumber: true,
            acnNumber: true,
            businessName: true,
            tradingName: true,
          };
          setFields(newFields);
          validateForm(newTouched, newFields);
        } else {
          setErrors({
            abnNumber: i18n.t('companyDetail.errorMessage.activeABN'),
          });
        }
      }
      setLoading(prev => ({ ...prev, abnNumber: false }));
    });

    // This happens before the callback above
    handleChange(val, 'abnNumber'); // sets fields / touched array
    setAbnNumber(val);
  };

  const debouncedHandleAbnNumberChange = debounce(handleAbnNumberChange, 500);

  const handleAbnNumberSelectedChange = val => {
    if (abnBusinessNames.length === 0) {
      handleChange(val, 'businessName');
      setBusinessName(val);
    } else {
      handleChange(val, 'tradingName');
      setTradingName(val);
    }
  };

  return (
    <React.StrictMode>
      <AgilityCard className={`steps-wrapper ${props.className}`} id={props.id}>
        <AgilityTypography variant="h4" component="h4" className="mb-2">
          {i18n.t('companyDetail.header.text')}
        </AgilityTypography>
        <form autoComplete="off" noValidate data-test-id="companyDetailForm">
          <p>{i18n.t('companyDetail.header.ABN')}</p>
          <AgilityGrid container spacing={2}>
            <AgilityGrid item xs={12} sm={6}>
              <BusinessNameLookup
                data-test-id="businessName"
                required={true}
                onChange={debouncedHandleBusinessNameChange}
                businessNamesOptions={businessNames}
                value={businessName}
                touched={touched.businessName ? true : false}
                loading={loading.businessName ? true : false}
                error={errors && errors.businessName ? true : false}
                helperText={
                  errors && errors.businessName ? errors.businessName : ''
                }
                endIcon={
                  <InputAdornment position="end">
                    <>{getEndIcon('businessName')}</>
                  </InputAdornment>
                }
              />
            </AgilityGrid>

            <AgilityGrid item xs={12} sm={6}>
              <AbnNumberLookup
                data-test-id="abnNumber"
                required={false}
                onSelectedChange={handleAbnNumberSelectedChange}
                placeholder={i18n.t('companyDetail.placeholder.requiredABN')}
                abnNumberOptions={abnBusinessNames}
                onChange={debouncedHandleAbnNumberChange}
                value={abnNumber}
                touched={touched.abnNumber ? true : false}
                loading={loading.abnNumber ? true : false}
                error={errors && errors.abnNumber ? true : false}
                helperText={errors && errors.abnNumber ? errors.abnNumber : ''}
                endIcon={
                  <InputAdornment position="end">
                    <>{getEndIcon('abnNumber')}</>
                  </InputAdornment>
                }
              />
            </AgilityGrid>
          </AgilityGrid>

          {organisationIsTrust && (
            <AgilityGrid container spacing={2}>
              <AgilityGrid item xs={12} sm={12}>
                <Element name="trusteeName">
                  <AgilityTextField
                    id="trustee-name"
                    type="text"
                    fullWidth
                    placeholder={i18n.t(
                      'companyDetail.placeholder.trusteeName'
                    )}
                    value={fields.trusteeName || ''}
                    disabled={!isActive}
                    onChange={val => handleChange(val, 'trusteeName')}
                    variant="outlined"
                    error={errors && errors.trusteeName ? true : false}
                    helperText={
                      errors && errors.trusteeName ? errors.trusteeName : ''
                    }
                  />
                </Element>
              </AgilityGrid>
            </AgilityGrid>
          )}

          <AgilityGrid container>
            <AgilityGrid item xs={12}>
              <Element name="tradingName">
                <AgilityTextField
                  id="tradingName"
                  data-test-id="tradingName"
                  type="text"
                  fullWidth
                  placeholder={i18n.t('companyDetail.placeholder.tradingName')}
                  value={fields.tradingName || ''}
                  disabled={!isActive}
                  onChange={val => handleChange(val, 'tradingName')}
                  variant="outlined"
                  required={true}
                  inputProps={{ maxLength: TRADING_NAME_MAX_LENGTH }}
                  error={errors && errors.tradingName ? true : false}
                  helperText={
                    errors && errors.tradingName ? errors.tradingName : ''
                  }
                />
              </Element>
            </AgilityGrid>
          </AgilityGrid>

          {doFreeTextAcn && (
            <AgilityGrid item xs={12} sm={6}>
              <Element name="trusteeName">
                <AgilityTextField
                  id="acn"
                  type="text"
                  fullWidth
                  placeholder={i18n.t('companyDetail.placeholder.ACN')}
                  value={fields.acnNumber || ''}
                  disabled={!isActive}
                  onChange={val => {
                    setAcnNumber(val);
                    handleChange(val, 'acnNumber');
                  }}
                  variant="outlined"
                  error={errors && errors.acnNumber ? true : false}
                  helperText={
                    errors && errors.acnNumber ? errors.acnNumber : ''
                  }
                />
              </Element>
            </AgilityGrid>
          )}

          {abnNumber &&
            !errors.abnNumber &&
            (loading.abnNumber ? (
              <div
                className="loader-section"
                style={{
                  position: 'relative',
                  marginTop: '20px',
                }}
              >
                <CircularProgress
                  color="primary"
                  variant="indeterminate"
                  className="loader"
                  thickness={3}
                  size={30}
                />
              </div>
            ) : (
              <div>
                <p>{i18n.t('companyDetail.details.header')}</p>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <AgilityTableCell>
                          <strong>Business Type</strong>
                        </AgilityTableCell>
                        <AgilityTableCell>
                          {organisationTypeDescription}
                        </AgilityTableCell>
                      </TableRow>
                      {!doFreeTextAcn && (
                        <TableRow>
                          <AgilityTableCell>
                            <strong>ACN</strong>
                          </AgilityTableCell>
                          <AgilityTableCell>
                            {acnNumber || 'N/A'}
                          </AgilityTableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <AgilityTableCell>
                          <strong>GST Registered</strong>
                        </AgilityTableCell>
                        <AgilityTableCell>
                          {gstRegisteredAt ? 'Yes' : 'No'}
                        </AgilityTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ))}

          <AgilityGrid container spacing={2} justifyContent="space-between">
            <div className="steps-footer">
              <AgilityButton
                variant="contained"
                disabled={!isActive}
                color="primary"
                type="primary"
                label={i18n.t('signup.button.reset')}
                data-test-id="resetButton"
                onClick={() => {
                  onClearClick();
                }}
              />
            </div>
            <div className="steps-footer">
              <AgilityButton
                color="primary"
                disabled={!isActive}
                onClick={() => {
                  onBackClick();
                }}
                label={i18n.t('signup.button.back')}
                data-test-id="backButton"
              />
              <AgilityButton
                variant="contained"
                disabled={!isActive}
                color="primary"
                type="primary"
                label={i18n.t('signup.button.next')}
                data-test-id="nextButton"
                onClick={e => nextForm(e)}
                loading={props.nextLoading}
              />
            </div>
          </AgilityGrid>
        </form>
      </AgilityCard>
    </React.StrictMode>
  );
};

export default CompanyDetail;
