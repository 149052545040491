import i18n from 'i18next';
class MoveInStartDateError extends Error {
  constructor(
    message = i18n.t('yourProperty.moveInDates.sdfi', {
      phoneNumber: process.env.REACT_APP_COMPANY_PHONE,
    }),
    status = 500,
    ...params
  ) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MoveInStartDateError);
    }

    this.message = message;
    this.status = status;
  }
}
export default MoveInStartDateError;
