/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import './sociallink.scss';
import Link from '@material-ui/core/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SocialLinks from 'Src/i18n/translations/SocialLinks';
import { useTheme } from '@material-ui/core/styles';

const AgilitySocialFollow = () => {
  const theme = useTheme();

  return (
    <div className="social-icon" data-test-id="social-icon">
      {SocialLinks[process.env.REACT_APP_BRAND_CODE.toLowerCase()].length &&
        SocialLinks[process.env.REACT_APP_BRAND_CODE.toLowerCase()].map(
          (item, index) => (
            <Link
              key={index}
              href={item.route}
              title={item.title}
              rel="noreferrer"
              target="_blank"
              color="inherit"
              css={css`
                color: ${theme.palette.grey['400']};
                &:hover {
                  color: ${theme.palette.primary.main};
                }
              `}
            >
              <FontAwesomeIcon icon={['fab', `${item.icon}`]} />
            </Link>
          )
        )}
    </div>
  );
};

export default AgilitySocialFollow;
