import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { AgilityConfirmationDialog } from 'Src/AgilityComponents';
import i18n from 'i18next';

class AgilityRouteLeavingDialog extends Component {
  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
  };
  showModal = location =>
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });
  closeModal = callback =>
    this.setState(
      {
        modalVisible: false,
      },
      callback
    );
  handleBlockedNavigation = nextLocation => {
    const { confirmedNavigation } = this.state;
    const { shouldBlockNavigation } = this.props;
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  };
  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { navigate } = this.props;
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true,
          },
          () => {
            // Navigate to the previous blocked location with your navigate function
            //clean up
            this.props.resetOnBoardingUserData();
            localStorage.clear();
            window.onbeforeunload = () => undefined;
            navigate(lastLocation.pathname);
          }
        );
      }
    });
  render() {
    const { when } = this.props;
    const { modalVisible } = this.state;
    return (
      <React.Fragment>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        <AgilityConfirmationDialog
          data-test-id="routeLeavingconfirmationDialog"
          textAlign="center"
          fullWidth
          maxWidth="xs"
          titleAlign="center"
          className="card-confirmation-dialog"
          open={modalVisible}
          title={i18n.t('routeLeaving.dialog.title')}
          description={i18n.t('routeLeaving.dialog.text')}
          okText={i18n.t('button.label.yes')}
          cancelText={i18n.t('cardConfirmationDialog.button.cancel')}
          closeClick={this.closeModal}
          cancelClick={this.closeModal}
          okClick={this.handleConfirmNavigationClick}
          okColor="primary"
        />
      </React.Fragment>
    );
  }
}
export default AgilityRouteLeavingDialog;
