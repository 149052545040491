import React from 'react';
import './plan-filer.scss';
import { func, object, array, bool } from 'prop-types';
import i18n from 'i18next';
import SearchLocation from 'App/components/Location';
import PromoCode from 'App/components/PromoCode';
import {
  AgilityCard,
  AgilityButton,
  AgilityGrid,
  AgilityTypography,
  AgilityBanner,
} from 'Src/AgilityComponents';
import { Box } from '@material-ui/core';
import {
  getListForSelectField,
  getReferFromCookie,
  isEmpty,
} from 'App/utils/helper';
import SearchOffMarket from 'App/components/Location/SearchOffMarket';
import Skeleton from '@material-ui/lab/Skeleton';
import Hidden from '@material-ui/core/Hidden';
import { PRODUCT } from 'App/utils/constants';
import {
  isDoorToDoor,
  hasGasSupply,
  showPromoCodeInput,
  showDebugInfoBar,
  isXSellGas,
} from 'App/customConfig';
import { withTheme } from '@material-ui/styles';

import { withSnackbar } from 'notistack';
import SolarCheckbox from './components/SolarCheckbox';
import PropertySelect from './components/PropertySelect';
import ProductSelect from './components/ProductSelect';
import DoorToDoorCheckbox from './components/DoorToDoorCheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

class PlanFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAPILoading: true,
      isPreload: false,
      hasElectricity: true,
      selectProductListValue: isXSellGas
        ? PRODUCT.GAS
        : hasGasSupply
        ? PRODUCT.BOTH
        : PRODUCT.ELECTRICITY,
      filterTopPadding: 0,
    };
  }

  componentDidMount() {
    Promise.all([this.props.fetchProductList(), this.props.fetchPropertyList()])
      .then(async () => {
        // Preload the data after lookups
        this.loadSavedData();
        this.setState({
          isAPILoading: false,
        });
      })
      .catch(err => {
        this.props.enqueueSnackbar(i18n.t('500.error.message'), {
          variant: 'error',
        });
      });

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  UNSAFE_componentWillReceiveProps(nextState) {
    if (
      this.props.planFilterData &&
      this.props.planFilterData.selectedProducts !==
        nextState.planFilterData.selectedProducts
    ) {
      this.setState({
        hasElectricity:
          nextState.planFilterData.selectedProducts !== PRODUCT.GAS,
      });
    }

    if (
      this.props.planFilterData.enteredSearchLocation !==
      nextState.planFilterData.enteredSearchLocation
    ) {
      this.setState({
        isPreload: true,
      });
    }
  }

  handleResize = () => {
    const filterTopPadding =
      this.props.isBSolar || this.props.isFirstEnergy
        ? 0
        : this.props.theme.custom.AppBar.height +
          this.props.theme.custom.PlanFilter.extraPaddingTop +
          (showDebugInfoBar
            ? window.innerWidth < this.props.theme.breakpoints.values.md
              ? 0
              : 35
            : 0);

    this.setState({ filterTopPadding });
  };

  loadSavedData = () => {
    if (!isEmpty(this.props.signupAPIResponse)) {
      const {
        services,
        address: { street_address, nmi_id, ...address } = {
          street_address: '',
          siteIdentifier: '',
        },
        propertyType,
        promotionCode = '',
      } = this.props.signupAPIResponse;
      if (services) {
        const selectedIdentifier = {};
        for (const obj of services) {
          const productType = (
            this.props.productList.find(
              _product => _product.code === obj.productType
            ) || {}
          ).code;
          if (productType) {
            selectedIdentifier[productType] = {
              multilineAddress: street_address.split(', '),
              formattedAddress: street_address,
              productType: productType,
              siteIdentifier: nmi_id,
            };
          }
        }
        const products = Object.keys(selectedIdentifier);
        const filterData = {
          enteredSearchLocation: street_address,
          // d2dApproval: false,
          hasSolar: false,
          selectedProducts: products,
          selectedProperty: propertyType || 'RESIDENT',
          selectedSearchLocation: { unstructuredAddress: nmi_id },
          promoCode: {
            promoCode: promotionCode,
            isPromoApplied: !!promotionCode,
          },
        };
        if (!isEmpty(address)) {
          filterData.selectedSearchLocation = {
            PostCode: address.PostCode,
            State: address.State,
            StreetName: address.StreetName,
            StreetNumber: address.StreetNumber,
            StreetSuffix:
              address.StreetSuffix === null ? '' : address.StreetSuffix,
            StreetType: address.StreetType,
            Suburb: address.Suburb,
          };
        }
        this.props.setPlanFilters(filterData);
        this.props.loadSavedData(selectedIdentifier, filterData);
        this.setState({ isPreload: true });
      } else {
        this.props.enqueueSnackbar(i18n.t('siteidentifier.loading.error'), {
          variant: 'error',
        });
      }
    }
  };

  handlePropertyChange = value => {
    this.setFilterToProps('selectedProperty', value);
  };

  handleD2DChange = value => {
    this.setFilterToProps('d2dApproval', value);
  };

  requiresD2dApproval = () => {
    return isDoorToDoor && this.props.agent?.isAgentLoggedIn
      ? !this.props.planFilterData.d2dApproval
      : false;
  };

  onProductSelected = value => {
    let products = [];
    if (value === PRODUCT.ELECTRICITY || value === PRODUCT.BOTH) {
      products.push(PRODUCT.ELECTRICITY);
    }
    if (value === PRODUCT.GAS || value === PRODUCT.BOTH) {
      products.push(PRODUCT.GAS);
    }
    this.setFilterToProps('selectedProducts', products);
    this.setState({
      selectProductListValue: value,
    });
  };

  setFilterToProps(key, value) {
    const planFilterData = { ...this.props.planFilterData };
    planFilterData[key] = value;
    this.props.setPlanFilters(planFilterData);
  }

  onSolarPlanChange = state => {
    this.setState({
      solarPlanChecked: state,
    });
    this.setFilterToProps('hasSolar', state);
  };

  onLocationSelected = (_location, textFieldSearch) => {
    const planFilterData = { ...this.props.planFilterData };
    const { productList } = this.props;
    if (this.props.isEmbeddedNetwork && typeof _location === 'object') {
      // _location is object when embedded network
      planFilterData['enteredSearchLocation'] = _location.address;
      planFilterData['code'] = _location.code;
      // Since productList type will be filtered by either frontend or backend based on embedded network status
    } else {
      // _location is string on google search
      planFilterData['selectedSearchLocation'] = _location;
      planFilterData['enteredSearchLocation'] = textFieldSearch;
    }
    // So if its embedded network or there's only one product type available, then select that item from the list automatically
    if (
      productList.length === 1 &&
      planFilterData['selectedProducts'].length <= 0
    ) {
      planFilterData['selectedProducts'] = [productList[0].code];
    }
    if (!isEmpty(this.props.siteIdentifierList)) {
      this.props.onClearLowerSection();
    }
    this.props.setPlanFilters(planFilterData);
    this.props.onLocationSelected();
  };

  render() {
    const productList = getListForSelectField(this.props.productList, true);

    // https://stackoverflow.com/questions/3007480/determine-if-user-navigated-from-mobile-safari
    const iphoneSafari =
      /iPhone/i.test(window.navigator.userAgent) &&
      /WebKit/i.test(window.navigator.userAgent) &&
      !/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent);

    // UC-1043. When select box is openning, user can still scroll down on iphone safari. While other browsers can not.
    const disableTouchMove = () => {
      if (iphoneSafari)
        document.getElementsByTagName('BODY')[0].style.touchAction = 'none';
    };

    const enableTouchMove = () => {
      if (iphoneSafari)
        document.getElementsByTagName('BODY')[0].style.touchAction = 'auto';
    };

    const {
      selectedProperty,
      enteredSearchLocation = '',
      hasSolar = false,
      d2dApproval = false,
      selectedProducts,
    } = this.props.planFilterData;

    return (
      <AgilityCard
        className="plan-filter"
        style={{
          paddingTop: this.state.filterTopPadding,
          marginBottom: 0,
          background: this.props.theme.custom.PlanFilter.background,
        }}
      >
        {!(this.props.isCovau || this.props.isSEG || this.props.isDemo) && (
          <AgilityBanner data-test-id="tally-banner-id" type="inner-banner" />
        )}
        <Box
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: 880,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <AgilityGrid container direction="row">
            <AgilityGrid item xs={12} className={'container-margin mb-2 mt-3'}>
              <AgilityGrid container direction="column">
                <AgilityGrid
                  container
                  direction={'row'}
                  spacing={6}
                  className="mb-2"
                >
                  <AgilityGrid item xs={12} md={4}>
                    {this.state.isAPILoading ? (
                      <Skeleton animation="wave" height={48} />
                    ) : (
                      <ProductSelect
                        options={productList}
                        value={this.state.selectProductListValue}
                        onChange={this.onProductSelected}
                        disableTouchMove={disableTouchMove}
                        enableTouchMove={enableTouchMove}
                      />
                    )}
                  </AgilityGrid>

                  <AgilityGrid item xs={12} md={4}>
                    <Box mb={{ xs: 0 }}>
                      {this.state.isAPILoading ? (
                        <Skeleton animation="wave" height={44} />
                      ) : (
                        <PropertySelect
                          options={this.props.propertyList}
                          value={selectedProperty}
                          onChange={this.handlePropertyChange}
                          disableTouchMove={disableTouchMove}
                          enableTouchMove={enableTouchMove}
                        />
                      )}
                    </Box>
                  </AgilityGrid>

                  <AgilityGrid item xs={12} md={4}>
                    <SolarCheckbox
                      onChange={this.onSolarPlanChange}
                      disabled={
                        this.state.selectProductListValue === PRODUCT.GAS
                      }
                      value={
                        this.state.selectProductListValue === PRODUCT.GAS
                          ? false
                          : hasSolar
                      }
                    />
                  </AgilityGrid>
                </AgilityGrid>

                <AgilityGrid
                  container
                  alignItems={'flex-end'}
                  direction={'row'}
                  spacing={6}
                >
                  <AgilityGrid
                    item
                    xs={12}
                    sm={8}
                    className="searchLocation-wrapper"
                  >
                    {this.props.isEmbeddedNetwork ? (
                      <SearchOffMarket
                        data-test-id="embedded-search"
                        searchKey="address"
                        subDataKey="name"
                        heading={i18n.t('embeddedNetwork.heading.label')}
                        placeholder={i18n.t('embeddedNetwork.placeholder.text')}
                        searchAPI={this.props.searchEmbeddedNetwork}
                        onLocationSelected={this.onLocationSelected}
                        showInfo={this.props.showSiteInfo}
                      />
                    ) : (
                      <SearchLocation
                        data-test-id="you-location-id"
                        searchValue={enteredSearchLocation}
                        onLocationSelected={this.onLocationSelected}
                        isPreload={this.state.isPreload}
                      />
                    )}
                  </AgilityGrid>

                  <AgilityGrid item xs={12} sm>
                    <Hidden only="sm">
                      <AgilityButton
                        data-test-id="filter-search-button"
                        buttonCss={
                          this.props.theme.custom.PlanFilter.searchField
                            .buttonCss
                        }
                        loading={this.props.searchLoading}
                        onClick={() => this.props.onSearchClick()}
                        label={i18n.t('button.search.label')}
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        size="large"
                        type="primary"
                        disabled={
                          isEmpty(enteredSearchLocation) ||
                          this.requiresD2dApproval() ||
                          (!this.props.isEmbeddedNetwork &&
                            isEmpty(selectedProducts))
                        }
                      />
                    </Hidden>
                    <Hidden only={['xs', 'md', 'lg', 'xl']}>
                      <AgilityButton
                        data-test-id="filter-search-button"
                        buttonCss={
                          this.props.theme.custom.PlanFilter.searchField
                            .buttonCss
                        }
                        loading={this.props.searchLoading}
                        onClick={() => this.props.onSearchClick()}
                        label={i18n.t('button.search.label.small')}
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        size="large"
                        type="primary"
                        disabled={
                          isEmpty(enteredSearchLocation) ||
                          this.requiresD2dApproval() ||
                          (!this.props.isEmbeddedNetwork &&
                            isEmpty(selectedProducts))
                        }
                      />
                    </Hidden>
                  </AgilityGrid>
                </AgilityGrid>

                {isDoorToDoor && this.props.agent?.isAgentLoggedIn && (
                  <AgilityGrid
                    container
                    direction={'row'}
                    spacing={6}
                    className="mt-2"
                  >
                    <AgilityGrid item xs={12} sm={8}>
                      <DoorToDoorCheckbox
                        onChange={this.handleD2DChange}
                        value={d2dApproval}
                      />
                    </AgilityGrid>
                  </AgilityGrid>
                )}

                {getReferFromCookie() === 'RAF' ? (
                  <AgilityGrid
                    container
                    direction={'row'}
                    spacing={6}
                    justifyContent="center"
                    className="mt-2 rafMessage"
                    css={
                      this.props.theme.custom.PlanDetailsCard
                        .PromoMessageTextCss
                    }
                  >
                    {i18n.t('signUpCompletion.referFriend.applied')}
                    <FontAwesomeIcon
                      icon={['fas', 'check-circle']}
                      css={css`
                        margin-left: 6px;
                      `}
                    />
                  </AgilityGrid>
                ) : (
                  <React.Fragment>
                    {showPromoCodeInput && (
                      <AgilityGrid
                        container
                        direction={'row'}
                        spacing={6}
                        justifyContent="center"
                        className="mt-2"
                      >
                        <AgilityGrid
                          item
                          xs={12}
                          sm={6}
                          className="text-center"
                        >
                          <PromoCode data-test-id="promo-code-id" />
                        </AgilityGrid>
                      </AgilityGrid>
                    )}
                  </React.Fragment>
                )}
              </AgilityGrid>
            </AgilityGrid>
          </AgilityGrid>
        </Box>
      </AgilityCard>
    );
  }
}
PlanFilter.propTypes = {
  setPlanFilters: func,
  planFilterData: object,
  fetchProductList: func,
  productList: array,
  onSearchClick: func,
  propertyList: array,
  fetchPropertyList: func,
  siteIdentifierList: object,
  onClearLowerSection: func,
  searchLoading: bool,
  isEmbeddedNetwork: bool,
  showSiteInfo: bool,
  agent: object,
};
PlanFilter.defaultProps = {
  planFilterData: {},
  productList: [],
  propertyList: [],
  siteIdentifierList: {},
  searchLoading: false,
  isEmbeddedNetwork: false,
  showSiteInfo: false,
  agent: {},
};

export { PlanFilter };

export default withSnackbar(withTheme(PlanFilter));
