import React, { PureComponent } from 'react';
import { GOOGLE_LOCATION_API_URL } from 'App/utils/constants';
import { string, number, bool } from 'prop-types';

// function titleCase(s) {
//   return s && s[0].toUpperCase() + s.toLowerCase().slice(1);
// }

// const brand = titleCase(process.env.REACT_APP_BRAND_CODE);
// let parter = '';
// if (process.env.REACT_APP_PARTNER_BRAND_CODE) {
//   parter = titleCase(process.env.REACT_APP_PARTNER_BRAND_CODE);
// }

// const path = `./../../theme/${brand}${parter}/images/marker.svg`;
// // eslint-disable-next-line
// const markerImg = require(path);

// import markerImg from '.Images/marker.svg';

// const markerImg = require(`Images/${process.env.REACT_APP_BRAND_CODE}/marker.svg`);
class AgilityMap extends PureComponent {
  _isMounted = false;

  state = {
    map: null,
    geocoder: null,
    marker: null,
    isMapLoaded: false,
  };

  checkStatus = status => {
    this.props.notFoundHandler(status);
  };

  mapCallBack = (results, status) => {
    if (this._isMounted) {
      this.setState({
        isMapLoaded: true,
      });
      this.checkStatus(status);
      if (status === 'OK') {
        this.state.map.setCenter(results[0].geometry.location);
        const marker = new window.google.maps.Marker({
          map: this.state.map,
          position: results[0].geometry.location,
          animation: window.google.maps.Animation.DROP,
          // icon: {
          //   // url: markerImg.default,
          //   scaledSize: new window.google.maps.Size(64, 64),
          // },
        });
        if (this._isMounted) {
          this.setState({
            marker: marker,
          });
        }
        marker.setMap(this.state.map);
      }
    }
  };

  onScriptLoad = () => {
    const { id, address, zoom, enableZoom } = this.props;
    if (address) {
      const mapOptions = {
        zoom: zoom,
        center: { lat: 26.93973, lng: 75.762 },
        disableDefaultUI: true,
        gestureHandling: 'none',
        zoomControl: enableZoom,
        clickableIcons: false,
      };
      const map = new window.google.maps.Map(
        document.getElementById(id),
        mapOptions
      );
      const geocoder = new window.google.maps.Geocoder();
      if (this._isMounted) {
        this.setState({
          map: map,
          geocoder: geocoder,
        });
      }
      geocoder.geocode({ address: address }, this.mapCallBack);
    }
  };

  loadMap = () => {
    if (!window.google) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = GOOGLE_LOCATION_API_URL;
      script.id = 'google-maps';
      script.async = '';
      script.defer = '';
      document.body.appendChild(script);
      script.addEventListener('load', e => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadMap();
  }

  componentDidUpdate(prevProps) {
    if (this.props.address !== prevProps.address) {
      this.loadMap();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="map-canvas"
          data-test-id="google-map"
          id={this.props.id}
        >
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}

AgilityMap.propTypes = {
  id: string,
  address: string,
  zoom: number,
  enableZoom: bool,
};
AgilityMap.defaultProps = {
  id: '',
  address: '',
  zoom: 13,
  enableZoom: false,
};

export default AgilityMap;
