import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import './dialog.scss';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AgilityButton from '../Button';
import { func, string, bool, oneOfType, object } from 'prop-types';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import AgilityTypography from '../Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Scrollbars } from 'react-custom-scrollbars';

const DialogTitle = props => {
  const { onClose, children, hastitleicon, titleicon, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      {hastitleicon && (
        <div className="title-icon">
          <FontAwesomeIcon icon={['fas', titleicon]} />
        </div>
      )}
      <AgilityTypography variant="h6">{children}</AgilityTypography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className="closeButton"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={['fas', `times`]} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const AgilityConfirmationDialog = props => {
  return (
    <Dialog
      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth}
      className={props.className}
      // disableBackdropClick={!props.closeOnOutsideClick}
      open={props.open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          if (props.closeOnOutsideClick) {
            props.closeClick();
          }

          return;
        }

        props.closeClick();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        test-data-id={props.title}
        id="alert-dialog-title"
        onClose={() => props.closeClick()}
        hastitleicon={props.hastitleicon}
        titleicon={props.titleicon}
      >
        <AgilityTypography
          data-test-id="dialog-header-id"
          variant="h3"
          component="div"
          align={props.titleAlign}
        >
          {props.title}
        </AgilityTypography>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          overflow: 'auto',
        }}
      >
        <Scrollbars
          autoHeight
          autoHeightMin={30}
          autoHeightMax="100%"
          style={{ width: '100%' }}
          className="dialog-scroll"
        >
          <div className="dialog-scroll-content">
            <AgilityTypography
              data-test-id="dialog-description-id"
              variant="body2"
              component="p"
              align={props.textAlign}
            >
              {props.description}
            </AgilityTypography>
          </div>
        </Scrollbars>
      </DialogContent>
      <DialogActions id="dialog-action-id">
        <AgilityButton
          loading={props.okLoading}
          data-test-id="dialog-button-ok"
          onClick={() => props.okClick()}
          variant="contained"
          color={props.okColor ? props.okColor : 'default'}
          label={props.okText}
        />
        {props.showCancel && (
          <AgilityButton
            loading={props.cancelLoading}
            data-test-id="dialog-button-cancel"
            onClick={() => props.cancelClick()}
            variant="contained"
            color="default"
            autoFocus
            label={props.cancelText}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

AgilityConfirmationDialog.propTypes = {
  open: bool,
  title: string,
  description: oneOfType([string, object]),
  okText: string,
  cancelText: string,
  okClick: func,
  cancelClick: func,
  closeClick: func,
  closeOnOutsideClick: bool,
  className: string,
  showCancel: bool,
  hastitleicon: bool,
  titleicon: string,
  okLoading: bool,
  cancelLoading: bool,
};
AgilityConfirmationDialog.defaultProps = {
  open: false,
  title: '',
  description: '',
  okText: '',
  cancelText: '',
  closeOnOutsideClick: true,
  fullWidth: false,
  maxWidth: 'sm',
  className: '',
  showCancel: true,
  hastitleicon: false,
  titleicon: 'string',
  okLoading: false,
  cancelLoading: false,
};

export default AgilityConfirmationDialog;
