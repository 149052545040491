/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useState } from 'react';
import { object, func, bool, string } from 'prop-types';
import i18n from 'i18next';
import './plan-details.scss';
import BasicPriceDetails from './BasicPriceDetails';
import PlanFeatures from './PlanFeatures';
import PlanRates from './PlanRates';
import GreenpowerOptions from './GreenpowerOptions';
import { AgilityButton } from 'Src/AgilityComponents';
import { isEmpty, pfsDocumentName } from 'App/utils/helper';
import Link from '@material-ui/core/Link';
import { showPlanImage } from 'App/customConfig';
import { isNullOrEmpty } from 'Root/src/app/utils/validationHelper';
import { useTheme } from '@material-ui/core/styles';
import { AgilityTypography } from 'Src/AgilityComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dompurify from 'isomorphic-dompurify';
const sanitizer = dompurify.sanitize;

const PlanDetailsCard = props => {
  const {
    planDetails,
    selectedPlan,
    productDetails,
    isExpandedRates,
    isPopup,
    isEmbeddedNetwork,
    onRatesClick,
  } = props;

  const [selectedGreenpower, setSelectedGreenpower] = useState(null);
  const [greenpowerError, setGreenpowerError] = useState(false);
  const theme = useTheme();

  const onSelectPlanClick = (newSelected, oldSelected) => {
    // deselect the plan.
    if (!isEmpty(oldSelected) && newSelected.id === oldSelected.id) {
      props.onPlanUnSelected();
    } else {
      if (Array.isArray(newSelected.greenpowerOptions)) {
        newSelected.selectedGreenpower = selectedGreenpower;
        if (newSelected.greenpowerOptions.length === 1) {
          newSelected.selectedGreenpower = newSelected.greenpowerOptions[0];
        }
      }

      if (
        Array.isArray(newSelected.greenpowerOptions) &&
        newSelected.greenpowerOptions.length &&
        isNullOrEmpty(newSelected.selectedGreenpower)
      ) {
        setGreenpowerError(true);
      } else {
        props.onPlanSelected(newSelected);
      }
    }
  };

  const onSelectGreenpower = greenpower => {
    setSelectedGreenpower(greenpower);
    setGreenpowerError(false);
  };

  return (
    <div
      className={`service-card ${planDetails.promocode ? 'has-promocode' : ''}`}
      css={theme.custom.PlanDetailsCard.PlanDetailsCardCss}
    >
      <div
        className="service-card__top"
        css={theme.custom.PlanDetailsCard.ServiceCardTopCss}
      >
        {/* {process.env.REACT_APP_BRAND_CODE === 'SOLARRUN' && (
          <div
            className="image-container text-center"
            css={css`
              width: 50%;
              margin: 0 auto;
              margin-bottom: 40px;
              margin-top: 20px;
            `}
            data-test-id="plan-image"
          >
            {planDetails.epfsId.substring(0, 3) === '1ST' && (
              <img src={theme.images.first} className="img-fluid" />
            )}
            {planDetails.epfsId.substring(0, 3) === 'TAN' && (
              <img src={theme.images.tango} className="img-fluid" />
            )}
            {planDetails.epfsId.substring(0, 3) === 'COV' && (
              <img src={theme.images.covau} className="img-fluid" />
            )}
          </div>
        )} */}
        <BasicPriceDetails
          data-test-id="plan-detail-id"
          planDetails={planDetails}
          productDetails={planDetails.productType}
          isPopup={isPopup}
          isEmbeddedNetwork={isEmbeddedNetwork}
          inPlanDetailsCard={true}
          showLogo={isPopup && showPlanImage}
          site={props.site}
          serviceListPosition={props.serviceListPosition}
        />
      </div>
      {!isEmpty(planDetails.features) && (
        <PlanFeatures
          data-test-id="plan-feature-id"
          features={planDetails.features}
        />
      )}
      <div
        className="service-card__bottom"
        css={theme.custom.PlanDetailsCard.ServiceCardBottomCss}
      >
        {!isEmpty(planDetails.rates) && (
          <PlanRates
            data-test-id="plan-rates-id"
            productType={productDetails.display}
            rates={planDetails.rates}
            feedin={planDetails.feedin}
            selectedGreenpower={selectedGreenpower}
            isExpandedRates={isExpandedRates}
            onRatesClick={onRatesClick}
          />
        )}
        {!isEmpty(planDetails.greenpowerOptions) && (
          <GreenpowerOptions
            data-test-id="greenpower-options-id"
            productType={productDetails.display}
            options={planDetails.greenpowerOptions}
            onSelectGreenpower={onSelectGreenpower}
            errors={greenpowerError}
          />
        )}
        <div className="footer-btn">
          {props.selection && (
            <AgilityButton
              className="select__button"
              data-test-id="plan-select-btn-id"
              variant="contained"
              color="primary"
              type="primary"
              fullWidth
              label={i18n.t('compareplan.planbutton.text', {
                plan: planDetails.name,
              })}
              onClick={() => onSelectPlanClick(planDetails, selectedPlan)}
            />
          )}

          {planDetails.epfsLink && (
            <AgilityTypography
              // data-test-id="plan-price-id"
              variant="subtitle1"
              component="p"
              containerClass="text-center mt-2"
            >
              <Link
                href={planDetails.epfsLink}
                target="_blank"
                rel="noreferrer"
                className="efs__button"
              >
                {pfsDocumentName(
                  props.site.State || props.site.state || props.state
                )}
                &ensp;
                <FontAwesomeIcon icon={['fas', 'external-link-square-alt']} />
              </Link>
            </AgilityTypography>
          )}

          {i18n.exists('compareplan.promo.terms') &&
            props.isPopup &&
            process.env.REACT_APP_BRAND_CODE !== 'COVAU' &&
            planDetails.features !== undefined && (
              <AgilityTypography
                component="p"
                containerClass="mt-2 mb-2"
                variant="subtitle1"
                dangerouslySetInnerHTML={{
                  __html: sanitizer(i18n.t('compareplan.promo.terms')),
                }}
              >
                {/*
                Promocodes no longer use this translation, but it's being hijacked for other things,
                in this case the feature disclaimer
              */}
              </AgilityTypography>
            )}

          {planDetails.promocode && props.isPopup && (
            <AgilityTypography
              component="p"
              containerClass="mt-2 mb-2"
              variant="subtitle1"
            >
              *{i18n.t('promocode.terms.label')}: {planDetails.promocode.terms}
            </AgilityTypography>
          )}
        </div>
      </div>
    </div>
  );
};
PlanDetailsCard.propTypes = {
  planDetails: object,
  onPlanSelected: func,
  selectedPlan: object,
  onPlanUnSelected: func,
  productDetails: object,
  selection: bool,
  isExpandedRates: bool,
  isPopup: bool,
  isEmbeddedNetwork: bool,
  site: object,
  onRatesClick: func,
  serviceListPosition: Number,
  state: string,
};
PlanDetailsCard.defaultProps = {
  planDetails: {},
  selectedPlan: {},
  productDetails: {},
  selection: true,
  isExpandedRates: false,
  isPopup: false,
  isEmbeddedNetwork: false,
  site: {},
  serviceListPosition: 0,
  state: '',
};
export default PlanDetailsCard;
