import i18n from 'i18next';
export const isAlphaNumericOnly = value => {
  return value !== '' && /^([a-zA-Z0-9]+)$/.test(value);
};

export const isValidPassword = value => {
  return (
    value !== '' &&
    /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z 0-9]).*$/.test(
      value
    )
  );
};

export const isValidAlphanumericOfLength = (value, length = 10) => {
  const regex = new RegExp(`^[a-zA-Z0-9]{${length},}$`);
  return value !== '' && regex.test(value);
};

export const isValidEmail = value => {
  return (
    value !== '' &&
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(
      value
    )
  );
};
export const isValidNameWithSpaceAndSymbol = value => {
  return value !== '' && /(^[\w\S]+( [\w\S]+)*$)/.test(value);
};

export const isValidNameWithSpace = value => {
  return value !== '' && /^([a-zA-Z0-9]+[\s|\\-])*[a-zA-Z0-9]+$/.test(value);
};

export const isValidNameWithSpaceAndApostrophe = value => {
  return value !== '' && /\b([A-Za-z][-,A-za-z. ']+[ ]*)+$/.test(value);
};

export const isAlphabaticOnly = value => {
  return value !== '' && /^([a-zA-Z]+)$/.test(value);
};

export const isValidMobilePhoneNumberPrefix = value => {
  return value !== '' && /^04{1}.{0,}$/.test(value);
};

export const isValidMobilePhoneNumberLength = value => {
  return value !== '' && /^[0-9]{10}$/.test(value);
};

export const isValidMobilePhoneNumber = value => {
  return (
    isValidMobilePhoneNumberPrefix(value) &&
    isValidMobilePhoneNumberLength(value)
  );
};

export const isValidHomePhoneNumberPrefix = value => {
  return value !== '' && /^0(2|3|7|8){1}.{0,}$/.test(value);
};

export const isValidHomePhoneNumberLength = value => {
  return value !== '' && /^[0-9]{10}$/.test(value);
};

export const isValidHomePhoneNumber = value => {
  return (
    isValidHomePhoneNumberPrefix(value) && isValidHomePhoneNumberLength(value)
  );
};

export const isValidConcessionsCardNumber = value => {
  return value !== '' && /^[0-9a-zA-Z]*$/.test(value) && value.length <= 10;
};

export const isValidBankAccountNumber = value => {
  var matcher = new RegExp(process.env.REACT_APP_BANK_ACCOUNT_REGEX);
  return value !== '' && matcher.test(value);
};

export const isValidBankBSBNumber = value => {
  var matcher = new RegExp(process.env.REACT_APP_BSB_REGEX);
  return value !== '' && matcher.test(value);
};

export const isValidBankAccountName = value => {
  return value !== '' && /^([a-zA-Z]+[\s|\\']?)*\s*$/.test(value);
};

export const isValidNameWithoutSpace = value => {
  return value !== '' && /^[a-zA-Z]*$/.test(value);
};

export const isNumericOnly = value => {
  return value !== '' && /^([0-9]+)$/.test(value);
};

// according to https://www.aemo.com.au/-/media/Files/Electricity/NEM/Retail_and_Metering/Metering-Procedures/NMI-Allocation-List.pdf
// Etc. NCCC0048158
// NMI length ranges from 10 to 11. mixing numbers and characters.
export const isSiteIdentifier = value => {
  return /^[A-Za-z0-9]{10,11}$/.test(value);
};

export const isPostcode = value => {
  return value && value !== '' && /^[0-9]{4}$/.test(value);
};

export const isNotEmpty = value => {
  return !value;
};

export const isNotNullOrUndefined = value => {
  if (value === null || value === '' || value === undefined) {
    return true;
  }
  return false;
};

export const isNullOrEmpty = value => {
  if (value === null || value === '' || value === undefined) {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true;
  }
  return false;
};

/**
 * Validates form fields based on provided validators.
 *
 * @param {Object} validators - An object containing validation rules for each field.
 * @param {Object} formInputs - An object containing the current values of the form fields.
 * @param {Object} touched - An object indicating which form fields have been touched.
 * @returns {Object} An object containing any error messages and the first invalid field encountered.
 *
 * @example
 * const validators = {
 *   fields: {
 *     name: {
 *       isOptional: false,
 *       defaultErrorText: 'Name is required',
 *       conditions: [],
 *     },
 *   },
 *   defaultChecker: value => !value,
 * };
 * const formInputs = { name: '' };
 * const touched = { name: true };
 * const result = validateFields(validators, formInputs, touched);
 * // result: { errorMessages: { name: 'Name is required' }, invalidField: 'name' }
 */
export const validateFields = (validators, formInputs, touched) => {
  let invalidField = '';
  const errorMessages = {};
  for (const obj of Object.entries(validators.fields)) {
    const key = obj[0];
    const data = obj[1];
    const fieldValue = formInputs[key];
    if (touched[key]) {
      if (data.preCondition) {
        switch (typeof data.preCondition) {
          case 'function':
            if (!data.preCondition(formInputs)) {
              continue;
            }
            break;
          case 'string':
            if (!formInputs[data.preCondition]) {
              continue;
            }
            break;
          default:
        }
      }
      const defaultChecker = data.defaultChecker || validators.defaultChecker;
      if (!data.isOptional && defaultChecker(fieldValue)) {
        errorMessages[key] = i18n.t(data.defaultErrorText);
        invalidField = invalidField || key;
        continue;
      }
      if (data.conditions.length > 0) {
        for (const _condObj of data.conditions) {
          if (
            typeof fieldValue !== 'undefined' &&
            fieldValue !== '' &&
            !_condObj.condition(fieldValue)
          ) {
            invalidField = invalidField || key;
            errorMessages[key] = i18n.t(
              _condObj.errorText || data.defaultErrorText
            );
            break;
          }
        }
      }
      if (data.isOptional && isNullOrEmpty(fieldValue)) {
        delete errorMessages[key];
      }
    }
  }
  return {
    errorMessages,
    invalidField,
  };
};
