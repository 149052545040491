import { connect } from 'react-redux';
import AgilityHeader from './AgilityHeader';
import { withRouter } from 'react-router-dom';
import { triggerHideLeavePopup } from 'App/pages/UserSignup/actions';
import {
  resetOnBoardingUserData,
  triggerEmailSetter,
} from 'App/modules/onBoarding/action';

const mapStatesToProps = (state, props) => {
  const {
    comparePlan: { offers },
    pages: { login },
  } = state;
  return {
    offers,
    login,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    triggerHideLeavePopup: () => dispatch(triggerHideLeavePopup()),
    resetOnBoardingUserData: () => dispatch(resetOnBoardingUserData()),
    triggerEmailSetter: () => dispatch(triggerEmailSetter()),
  };
};

export default withRouter(
  connect(mapStatesToProps, mapDispatchToProps)(AgilityHeader)
);
