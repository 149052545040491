/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { array, string, bool, object } from 'prop-types';
import i18n from 'i18next';
import { useTheme } from '@material-ui/core/styles';
import dompurify from 'dompurify';
import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

const sanitizer = dompurify.sanitize;

const PromoMessage = props => {
  const { promo, fuel, name, description } = props;
  const theme = useTheme();

  return (
    <React.Fragment>
      <Alert
        className="promo-message"
        severity="info"
        color="success"
        variant="filled"
        icon={false}
        css={theme.custom.PlanDetailsCard.PromoMessageCss}
      >
        <Typography variant="h5" component="h5">
          {i18n.t('promocode.promoMessage.title')}
        </Typography>
        <Typography
          variant="h3"
          component="h4"
          css={theme.custom.PlanDetailsCard.PromoMessageTextCss}
        >
          {name} <span>Applied</span>
        </Typography>

        <Typography variant="h6" component="h6">
          <span className="promo-message__description">{description}</span>
          <span className="promo-message__terms">
            {i18n.t('promocode.terms.label')} apply
            {i18n
              .t('promocode.terms.asterisk')
              .repeat(props.serviceListPosition + 1)}
          </span>
        </Typography>
      </Alert>
    </React.Fragment>
  );
};
PromoMessage.propTypes = {
  promo: object,
  fuel: string,
  serviceListPosition: Number,
};
PromoMessage.defaultProps = {
  rates: [],
  fuel: 'Electricity',
  serviceListPosition: 0,
};
export default PromoMessage;
