import React from 'react';
import i18n from 'i18next';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  AgilitySelectField,
  AgilityTextField,
  AgilityGrid,
} from 'Src/AgilityComponents';
import { InputAdornment, CircularProgress } from '@material-ui/core';
import {
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
  MOBILE_NUMBER_MAX_LENGTH,
  HOME_NUMBER_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  MIDDLE_NAME_MAX_LENGTH,
} from 'App/utils/fieldLengthHelper';
import { convertToDateOnly } from 'App/utils/helper';
import { changeDate, maxDob } from '../utils';
import { Element } from 'react-scroll';
import { enableExperianEmailValidation } from 'App/customConfig';

const YourDetailPrimaryContact = ({
  isActive,
  salutationList,
  dobError,
  onDobError,
  handleChange,
  fields,
  errors,
  loadingFields,
}) => {
  const hasError = React.useCallback(
    field => errors && errors.hasOwnProperty(field) && errors[field] !== '',
    [errors]
  );

  return (
    <AgilityGrid container data-test-id="primary-contact-fields">
      <AgilityGrid container spacing={2}>
        <AgilityGrid item xs={6} sm={4}>
          <Element name="primaryTitle">
            <AgilitySelectField
              data-test-id="primaryTitle"
              options={salutationList || []}
              variant="outlined"
              labelId="demo-simple-select-filled-label"
              value={fields.primaryTitle || ''}
              disabled={!isActive}
              showlabel
              placeholder={i18n.t('yourDetail.placeholder.title')}
              onChange={event =>
                handleChange(event.target.value, 'primaryTitle')
              }
              isError={hasError('primaryTitle')}
              helperText={hasError('primaryTitle') ? errors.primaryTitle : ''}
            />
          </Element>
        </AgilityGrid>
        <AgilityGrid item xs={6} sm={6}>
          <Element name="primaryFirstName">
            <AgilityTextField
              id="primaryFirstName"
              data-test-id="primaryFirstName"
              type="text"
              fullWidth
              placeholder={i18n.t('yourDetail.placeholder.firstName')}
              value={fields.primaryFirstName || ''}
              disabled={!isActive}
              onChange={val => handleChange(val, 'primaryFirstName')}
              variant="outlined"
              required
              inputProps={{ maxLength: FIRST_NAME_MAX_LENGTH }}
              error={hasError('primaryFirstName')}
              helperText={
                hasError('primaryFirstName') ? errors.primaryFirstName : ''
              }
            />
          </Element>
        </AgilityGrid>
      </AgilityGrid>
      <AgilityGrid container spacing={2}>
        <AgilityGrid item xs={6} sm={6}>
          <Element name="primaryMiddleName">
            <AgilityTextField
              id="primaryMiddleName"
              data-test-id="primaryMiddleName"
              type="text"
              fullWidth
              placeholder={i18n.t('yourDetail.placeholder.middleName')}
              value={fields.primaryMiddleName || ''}
              disabled={!isActive}
              onChange={val => handleChange(val, 'primaryMiddleName')}
              variant="outlined"
              inputProps={{ maxLength: MIDDLE_NAME_MAX_LENGTH }}
              error={hasError('primaryMiddleName')}
              helperText={
                hasError('primaryMiddleName') ? errors.primaryMiddleName : ''
              }
            />
          </Element>
        </AgilityGrid>
        <AgilityGrid item xs={6} sm={6}>
          <Element name="primaryLastName">
            <AgilityTextField
              id="primaryLastName"
              data-test-id="primaryLastName"
              type="text"
              fullWidth
              placeholder={i18n.t('yourDetail.placeholder.lastName')}
              value={fields.primaryLastName || ''}
              disabled={!isActive}
              onChange={val => handleChange(val, 'primaryLastName')}
              variant="outlined"
              required
              inputProps={{ maxLength: LAST_NAME_MAX_LENGTH }}
              error={hasError('primaryLastName')}
              helperText={
                hasError('primaryLastName') ? errors.primaryLastName : ''
              }
            />
          </Element>
        </AgilityGrid>
      </AgilityGrid>

      <AgilityGrid container spacing={2}>
        <AgilityGrid item xs={12} sm={6}>
          <Element name="primaryMobileNumber">
            <AgilityTextField
              id="primaryMobileNumber"
              data-test-id="primaryMobileNumber"
              type="text"
              fullWidth
              placeholder={i18n.t('yourDetail.placeholder.mobilePhone')}
              value={fields.primaryMobileNumber || ''}
              disabled={!isActive}
              onChange={val => handleChange(val, 'primaryMobileNumber')}
              variant="outlined"
              required
              InputProps={{
                endAdornment: loadingFields['primaryMobileNumber'] && (
                  <InputAdornment classes={{ root: 'loading' }} position="end">
                    <CircularProgress size={30} />
                  </InputAdornment>
                ),
              }}
              error={hasError('primaryMobileNumber')}
              helperText={
                hasError('primaryMobileNumber')
                  ? errors.primaryMobileNumber
                  : ''
              }
              inputProps={{
                maxLength: MOBILE_NUMBER_MAX_LENGTH,
              }}
            />
          </Element>
        </AgilityGrid>

        <AgilityGrid item xs={12} sm={6}>
          <Element name="primaryHomeNumber">
            <AgilityTextField
              id="primaryHomeNumber"
              data-test-id="primaryHomeNumber"
              type="text"
              fullWidth
              placeholder={i18n.t('yourDetail.placeholder.homePhone')}
              value={fields.primaryHomeNumber || ''}
              disabled={!isActive}
              onChange={val => handleChange(val, 'primaryHomeNumber')}
              variant="outlined"
              error={hasError('primaryHomeNumber')}
              helperText={
                hasError('primaryHomeNumber') ? errors.primaryHomeNumber : ''
              }
              inputProps={{
                maxLength: HOME_NUMBER_MAX_LENGTH,
              }}
            />
          </Element>
        </AgilityGrid>
      </AgilityGrid>
      <AgilityGrid container spacing={2}>
        <AgilityGrid item xs={12} sm={6}>
          <div className="text-field-wrapper">
            <Element name="primaryDob">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  format={window.dateFormat}
                  id="primaryDob"
                  label={i18n.t('yourDetail.placeholder.dob', {
                    dateFormat: window.dateFormat.toUpperCase(),
                  })}
                  value={fields.primaryDob || null}
                  disabled={!isActive}
                  data-test-id="primaryDob"
                  onChange={val =>
                    handleChange(convertToDateOnly(val), 'primaryDob')
                  }
                  KeyboardButtonProps={{
                    'aria-label': changeDate,
                  }}
                  autoOk
                  maxDate={maxDob}
                  maxDateMessage={i18n.t('yourDetail.errorMessage.maxDateDob')}
                  onError={onDobError}
                  required
                  helperText={
                    hasError('primaryDob') || Boolean(dobError)
                      ? errors.primaryDob || dobError
                      : ''
                  }
                  error={hasError('primaryDob') || Boolean(dobError)}
                />
              </MuiPickersUtilsProvider>
            </Element>
          </div>
        </AgilityGrid>
      </AgilityGrid>
      <AgilityGrid container spacing={2}>
        <AgilityGrid item xs={12} sm={12}>
          <Element name="primaryEmail">
            <AgilityTextField
              id="primaryEmail"
              data-test-id="primaryEmail"
              type="email"
              fullWidth
              placeholder={i18n.t('yourDetail.placeholder.email')}
              value={fields.primaryEmail || ''}
              disabled={!isActive}
              onChange={val => handleChange(val, 'primaryEmail')}
              variant="outlined"
              required
              error={hasError('primaryEmail')}
              InputProps={{
                endAdornment: loadingFields['primaryEmail'] && (
                  <InputAdornment classes={{ root: 'loading' }} position="end">
                    <CircularProgress size={30} />
                  </InputAdornment>
                ),
              }}
              helperText={
                hasError('primaryEmail')
                  ? errors.primaryEmail
                  : !enableExperianEmailValidation
                  ? i18n.t('yourDetail.placeholder.emailHelperText')
                  : null
              }
              inputProps={{ maxLength: EMAIL_MAX_LENGTH }}
            />
          </Element>
        </AgilityGrid>
      </AgilityGrid>
    </AgilityGrid>
  );
};

export default YourDetailPrimaryContact;
