import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import {
  AgilityCheckbox,
  AgilityGrid,
  AgilityFormGroup,
  AgilityTextField,
  AgilityTypography,
} from 'Src/AgilityComponents';

import { BANK_ACCOUNT_NAME_MAX_LENGTH } from 'App/utils/fieldLengthHelper';
import AgilityMaskInput from 'App/components/MaskInput';
import MaskInput from 'App/components/MaskInput/MaskInput';
import { func, object, array } from 'prop-types';
import AgilityDialog from 'App/components/Dialog/AgilityDialog';
import dompurify from 'dompurify';
import { isNullOrEmpty } from 'App/utils/validationHelper';

const sanitizer = dompurify.sanitize;
const BankAccountForm = props => {
  const { bankAccountFields, bankAccountFieldsErrors, isActive } = props;

  const [directDebitDialog, setDirectDebitDialog] = useState(false);
  const [authorizeBank, setAuthorizeBank] = useState(false);
  const [directDebit, setDirectDebit] = useState(false);

  const authorizeBankChangeHandler = status => {
    setAuthorizeBank(!status);
    props.handleBankAccountMethodChange(!status, 'allowProviderToDirectDebit');
  };
  const directDebitChangeHandler = checked => {
    setDirectDebit(false);
    props.handleBankAccountMethodChange(false, 'directDebitTNC');
    if (checked) {
      setDirectDebitDialog(true);
    }
  };

  const checkDirectDebitBank = () => {
    setDirectDebit(true);
    closeDirectDebitDialog();
    props.handleBankAccountMethodChange(true, 'directDebitTNC');
  };

  const closeDirectDebitDialog = () => {
    setDirectDebitDialog(false);
  };

  useEffect(() => {
    setAuthorizeBank(bankAccountFields.allowProviderToDirectDebit);
    setDirectDebit(bankAccountFields.directDebitTNC);
  }, [bankAccountFields]);

  return (
    <React.Fragment>
      <AgilityGrid container>
        {process.env.REACT_APP_CREDIT_CARD_PROVIDER === 'NAB' && (
          <AgilityGrid item xs={12} sm={12}>
            <AgilityTextField
              disabled={!isActive}
              id="bank-name"
              data-test-id="bank-name"
              type="text"
              fullWidth
              placeholder={i18n.t('bankAccount.BankName.placeholder')}
              variant="outlined"
              required={true}
              value={bankAccountFields.bankName || ''}
              inputProps={{ maxLength: BANK_ACCOUNT_NAME_MAX_LENGTH }}
              onChange={value => {
                props.handleBankAccountMethodChange(value, 'bankName');
              }}
              error={
                bankAccountFieldsErrors && bankAccountFieldsErrors.bankName
                  ? true
                  : false
              }
              helperText={
                bankAccountFieldsErrors && bankAccountFieldsErrors.bankName
                  ? bankAccountFieldsErrors.bankName
                  : ''
              }
            />
          </AgilityGrid>
        )}
        <AgilityGrid item xs={12} sm={12}>
          <AgilityTextField
            disabled={!isActive}
            id="bank-account-name"
            data-test-id="bank-account-name"
            type="text"
            fullWidth
            placeholder={i18n.t('bankAccount.AccountName.placeholder')}
            variant="outlined"
            required={true}
            value={bankAccountFields.bankAccountHolderName || ''}
            inputProps={{ maxLength: BANK_ACCOUNT_NAME_MAX_LENGTH }}
            onChange={value => {
              props.handleBankAccountMethodChange(
                value,
                'bankAccountHolderName'
              );
            }}
            error={
              bankAccountFieldsErrors &&
              bankAccountFieldsErrors.bankAccountHolderName
                ? true
                : false
            }
            helperText={
              bankAccountFieldsErrors &&
              bankAccountFieldsErrors.bankAccountHolderName
                ? bankAccountFieldsErrors.bankAccountHolderName
                : ''
            }
          />
        </AgilityGrid>
        <AgilityGrid item xs={12} sm={12}>
          <AgilityMaskInput
            disabled={!isActive}
            id="branch-name"
            data-test-id="branch-name"
            type="text"
            fullWidth
            placeholder={i18n.t('bankAccount.BranchNumber.placeholder')}
            InputLabelProps={{
              shrink: !isNullOrEmpty(bankAccountFields.bankBranchName),
            }}
            InputProps={{
              inputComponent: MaskInput,
              inputProps: {
                mask: process.env.REACT_APP_BSB_VALID_FORMAT,
              },
            }}
            variant="outlined"
            required={true}
            value={bankAccountFields.bankBranchName || ''}
            onChange={value => {
              props.handleBankAccountMethodChange(value, 'bankBranchName');
            }}
            error={
              bankAccountFieldsErrors && bankAccountFieldsErrors.bankBranchName
                ? true
                : false
            }
            helperText={
              bankAccountFieldsErrors && bankAccountFieldsErrors.bankBranchName
                ? bankAccountFieldsErrors.bankBranchName
                : ''
            }
          />
        </AgilityGrid>
        <AgilityGrid item xs={12} sm={12}>
          <AgilityMaskInput
            disabled={!isActive}
            id="account-number"
            data-test-id="account-number"
            type="text"
            fullWidth
            placeholder={i18n.t('bankAccount.AccountNumber.placeholder')}
            variant="outlined"
            required={true}
            value={bankAccountFields.bankAccountNumber || ''}
            onChange={value => {
              props.handleBankAccountMethodChange(value, 'bankAccountNumber');
            }}
            InputLabelProps={{
              shrink: !isNullOrEmpty(bankAccountFields.bankAccountNumber),
            }}
            InputProps={{
              inputComponent: MaskInput,
              inputProps: {
                mask: process.env.REACT_APP_BANK_ACCOUNT_VALID_FORMAT,
              },
            }}
            error={
              bankAccountFieldsErrors &&
              bankAccountFieldsErrors.bankAccountNumber
                ? true
                : false
            }
            helperText={
              bankAccountFieldsErrors &&
              bankAccountFieldsErrors.bankAccountNumber
                ? bankAccountFieldsErrors.bankAccountNumber
                : ''
            }
          />
        </AgilityGrid>
        <AgilityGrid item xs={12} sm={12}>
          <AgilityFormGroup row className="custom-checkbox-row">
            <AgilityCheckbox
              disabled={!isActive}
              className="custom"
              data-test-id="authorize-bank"
              color="primary"
              value={bankAccountFields.allowProviderToDirectDebit}
              checked={authorizeBank}
              onChange={() => authorizeBankChangeHandler(authorizeBank)}
            />
            <AgilityTypography
              onClick={() => {
                authorizeBankChangeHandler(authorizeBank);
              }}
              data-test-id="direct-debit-text"
              variant="subtitle1"
              component="label"
              align="left"
              className={`desc ${
                bankAccountFieldsErrors.allowProviderToDirectDebit &&
                'has-error'
              }`}
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  i18n.t('bankAccount.authorizeBank.text', {
                    PROVIDER_NAME: process.env.REACT_APP_COPYRIGHT_COMPANY,
                    interpolation: { escapeValue: false },
                  })
                ),
              }}
            />
          </AgilityFormGroup>
        </AgilityGrid>
        <AgilityGrid item xs={12} sm={12}>
          <AgilityFormGroup row className="custom-checkbox-row">
            <AgilityCheckbox
              disabled={!isActive}
              className="custom"
              data-test-id="allow-direct-debit"
              color="primary"
              value={bankAccountFields.directDebitTNC}
              checked={directDebit}
              onChange={event => directDebitChangeHandler(event.target.checked)}
            />
            <AgilityTypography
              onClick={event => {
                directDebitChangeHandler(!directDebit);
              }}
              data-test-id="direct-debit-tnc-text"
              variant="subtitle1"
              component="label"
              align="left"
              className={`desc ${
                bankAccountFieldsErrors.directDebitTNC && 'has-error'
              }`}
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  i18n.t('bankAccount.debitDebit.text', {
                    interpolation: { escapeValue: false },
                  })
                ),
              }}
            />
            <AgilityDialog
              data-test-id="acceptence-direct-debit"
              className="full-page-popup-sm terms-condition-dialog"
              open={directDebitDialog}
              okClick={checkDirectDebitBank}
              onDismiss={closeDirectDebitDialog}
              maxWidth="md"
              title={i18n.t('bankAccount.authorizeDirectDebitTnC.Title')}
              okText={i18n.t('button.accept.text')}
              contents={[
                {
                  id: 'dialog-authorize-bank-text',
                  body: i18n.t(
                    'bankAccount.authorizeDirectDebitTnC.Description',
                    {
                      interpolation: { escapeValue: false },
                    }
                  ),
                  variant: 'subtitle1',
                  element: 'label',
                },
              ]}
            />
          </AgilityFormGroup>
        </AgilityGrid>
      </AgilityGrid>
    </React.Fragment>
  );
};

BankAccountForm.propTypes = {
  bankAccountFields: object,
  bankAccountFieldsErrors: object,
  handleBankAccountMethodChange: func,
  bankCodeList: array,
};
BankAccountForm.defaultProps = {
  bankAccountFields: {},
  bankAccountFieldsErrors: {},
  bankCodeList: [],
};

export default BankAccountForm;
