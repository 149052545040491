import { connect } from 'react-redux';
import {
  toggleSteps,
  togglePopup,
  resetCurrentStep,
  setCompletedStep,
} from './actions';
import { withRouter } from 'react-router-dom';
import UserSignup from './UserSignup';
import {
  putSignupDetails,
  postSignupDetails,
  getSignupDetails,
  getPlanDetailsAPI,
  setPropertyType,
} from '../ComparePlans/action';
import {
  fetchSiteIdentifierDetailByID,
  fetchMoveInDatesList,
  fetchCardList,
  fetchBillingFrequencyList,
  requestMobileAuthentication,
} from 'App/modules/onBoarding/action';

const mapStateToProps = (state, props) => {
  return {
    ...props,
    page: state.pages.userSignup.currentPage,
    currentStep: state.pages.userSignup.currentStep,
    selectedPlans: state.comparePlan.selectedPlans,
    selectedProperty: state.comparePlan.planFilter.selectedProperty,
    productList: state.comparePlan.productList,
    popupState: state.pages.userSignup.popupState,
    popupContent: state.pages.userSignup.popupContent,
    signupAPIResponse: state.comparePlan.signupAPIResponse,
    saveProgressTrigger: state.onBoarding.saveProgressTrigger,
    progressData: state.onBoarding.progressData,
    primarySiteIdentifier: state.onBoarding.primarySiteIdentifier,
    completedStep: state.pages.userSignup.completedStep,
    triggerHideLeavePopup: state.pages.userSignup.triggerHideLeavePopup,
    isEmbeddedNetwork: state.app.customConfig.isEmbeddedNetwork,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleClick: (buttonAction, steps, setStepCompleted) => {
      dispatch(toggleSteps(buttonAction, steps, setStepCompleted));
    },
    togglePopup: (status, content) => {
      dispatch(togglePopup(status, content));
    },
    resetCurrentStep: () => dispatch(resetCurrentStep()),
    setCurrentStep: currentStep => {
      dispatch({
        type: 'SWITCH_PAGE',
        payload: { page: currentStep.key, currentStep: currentStep },
      });
    },
    putSignupDetails: data => dispatch(putSignupDetails(data)),
    postSignupDetails: (data, saveProgressTrigger) =>
      dispatch(postSignupDetails(data, saveProgressTrigger)),
    getSignupDetails: (id, accntId) => dispatch(getSignupDetails(id, accntId)),
    fetchSiteIdentifierDetailByID: data =>
      dispatch(fetchSiteIdentifierDetailByID(data)),
    getPlanDetailsAPI: (siteIdentifier, productType, appId, id) =>
      dispatch(getPlanDetailsAPI(siteIdentifier, productType, appId, id)),
    fetchMoveInDatesList: accountId =>
      dispatch(fetchMoveInDatesList(accountId)),
    setCompleted: id => dispatch(setCompletedStep(id)),
    fetchCardList: state => dispatch(fetchCardList(state)),
    setPropertyType: propertyType => dispatch(setPropertyType(propertyType)),
    fetchBillingFrequencyList: (districtCode, customerType) =>
      dispatch(fetchBillingFrequencyList(districtCode, customerType)),
    requestMobileAuthentication: data => requestMobileAuthentication(data),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserSignup)
);
