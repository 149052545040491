import { combineReducers } from 'redux';
import login from './AgentLogin/reducer';
import register from './Register/reducer';
import userSignup from './UserSignup/reducer';
import refer from './Refer/reducer';
const reducer = combineReducers({
  login,
  register,
  userSignup,
  refer,
});

export default reducer;
