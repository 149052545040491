import React from 'react';
import TextField from '@material-ui/core/TextField';
import './input.scss';
class AgilityTextField extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }
  onChange = e => {
    const value = e.target.value;
    if (this.props.inputProps && this.props.inputProps.maxLength) {
      if (value.length > this.props.inputProps.maxLength) {
        return;
      }
    }
    if (this.props.maxLength) {
      if (value.length > this.props.maxLength) {
        return;
      }
    }
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  render() {
    return (
      <div
        className={`text-field-wrapper ${this.props.className}`}
        style={this.props.style}
      >
        <TextField
          {...this.props}
          ref={this.inputRef}
          onChange={event => this.onChange(event)}
          label={this.props.label || this.props.placeholder}
          placeholder={this.props.placeholder || ''}
          id={this.props.id}
          type={this.props.type}
          rowsMax={this.props.rowsMax}
          disabled={this.props.disabled}
          variant={this.props.variant}
          name={this.props.name}
          helperText={this.props.helperText}
          fullWidth={this.props.fullWidth}
          size={this.props.size}
        />
      </div>
    );
  }
}

export default AgilityTextField;
