import React from 'react';
import { AgilityTypography, AgilityInfoDialog } from 'Src/AgilityComponents';
import i18n from 'i18next';

const AgilitySiteIdentifierDialog = props => {
  const { open, data } = props;
  return (
    <AgilityInfoDialog
      data-test-id="infodialog"
      className="full-page-popup-sm"
      fullWidth
      maxWidth="md"
      open={open}
      title={i18n.t('searchlocation.info.header')}
      okText={i18n.t('searchlocation.info.oktext')}
      okClick={props.onDismiss}
      closeClick={props.onDismiss}
    >
      {data.map(({ header, body, quote }, index) => (
        <div key={index}>
          {header && (
            <AgilityTypography variant="h5" component="h5">
              {header}
            </AgilityTypography>
          )}
          {body && (
            <AgilityTypography variant="body2" component="p">
              {body}
            </AgilityTypography>
          )}
          {quote && (
            <blockquote>
              <AgilityTypography variant="body2" component="p">
                {quote}
              </AgilityTypography>
            </blockquote>
          )}
        </div>
      ))}
    </AgilityInfoDialog>
  );
};

export default AgilitySiteIdentifierDialog;
