import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import i18n from 'i18next';
import 'date-fns';
import { isAfter, isBefore, parseISO } from 'date-fns';

import { AgilityButton, AgilityCard, AgilityGrid } from 'Src/AgilityComponents';

import { validateFields } from 'App/utils/validationHelper';
import { SIGNUP_STEPS as signupSteps } from 'App/utils/constants';
import {
  scrollToSection,
  yesNoOptionConverter,
  getFuelType,
} from 'App/utils/helper';
import {
  defaultFieldsState,
  defaultTouchedState,
  fieldValidators,
  updateApiData,
  changeDate,
  concessionCardArrayField,
  defaultErrorsState,
} from './utils';
import ConcessionCard from './Components/ConcessionCard';
import LifeSupportCard from './Components/LifeSupportCard';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ACCOUNT_ID } from 'App/utils/constants';
import { logSaleAction } from 'App/utils/geolocation';

const LifeSupportConcession = ({
  id,
  className,
  isActive,
  signupAPIResponse,
  onNavigate,
  propertyType,
  customConfig = {},
  cardList,
  nextLoading,
  openProgressDialog,
  isAgentView,
  saveProgressTrigger,
}) => {
  const { isEmbeddedNetwork = false } = customConfig;

  const brandConfig = useSelector(state => state.app.brand);
  const isTangoPartner = brandConfig.isTango && brandConfig.isPartner;

  const isCovau = brandConfig.isCovau;
  const isFirstEnergy = brandConfig.isFirstEnergy;

  const [fields, setFields] = useState(defaultFieldsState);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState(defaultTouchedState);
  const [disableNextBtn, setDisableNextBtn] = useState(false);

  const [startDateError, setStartDateError] = useState('');
  const [expiryDateError, setExpiryDateError] = useState('');
  const [minCardExpiry, setMinCardExpiry] = useState(new Date());

  const isResident = useMemo(() => propertyType === 'RESIDENT', [propertyType]);
  const validators = fieldValidators(customConfig, isResident);

  const updateResponse = useCallback(
    () => ({ ...signupAPIResponse, ...updateApiData(fields) }),
    [fields, signupAPIResponse]
  );

  const validateForm = useCallback(
    (currentTouched, currentFields) => {
      if (
        currentFields.hasConcessionCard &&
        isTangoPartner &&
        ['QLD', 'SA'].indexOf(currentFields.state) > -1
      ) {
        validators.fields.cardName.isOptional = true;
        validators.fields.cardType.isOptional = true;
        validators.fields.cardNumber.isOptional = true;
        validators.fields.cardLastName.isOptional = true;
        validators.fields.cardStartDate.isOptional = true;
        validators.fields.cardExpiry.isOptional = true;
        validators.fields.hasConsented.isOptional = true;
        validators.fields.hasCoolingConcession.isOptional = true;
      }
      // Not relevant for SME customers
      if (
        (currentFields.hasConcessionCard === null ||
          currentFields.hasConcessionCard === undefined) &&
        !isResident
      ) {
        validators.fields.hasConcessionCard.isOptional = true;
      }

      const formValidationData = validateFields(
        validators,
        currentFields,
        currentTouched
      );
      const errorMessages = formValidationData.errorMessages;
      let invalidField = formValidationData.invalidField;

      if (
        currentFields.lifeSupportType === 'D' &&
        currentFields.hasLifeSupportPerson === 'yes' &&
        currentFields.lifeSupportPersonDetail === ''
      ) {
        errorMessages['lifeSupportPersonDetail'] = i18n.t(
          'lifeSupportConcession.errorMessage.lifeSupportDetail'
        );

        invalidField = 'lifeSupportPersonDetail';
      }

      if (
        currentFields.hasLifeSupportPerson === 'yes' &&
        customConfig.disableSignupsForLifeSupportCustomers
      ) {
        invalidField = 'hasLifeSupportPerson';
      }

      if (
        currentFields.hasCoolingConcession === 'yes' &&
        customConfig.disableSignupsForMedicalCoolingCustomers
      ) {
        invalidField = 'hasCoolingConcession';
      }

      setErrors(errorMessages);

      return invalidField;
    },
    [
      isTangoPartner,
      validators,
      customConfig.disableSignupsForLifeSupportCustomers,
      customConfig.disableSignupsForMedicalCoolingCustomers,
    ]
  );

  useEffect(() => {
    if (
      fields.hasLifeSupportPerson === 'yes' &&
      customConfig.disableSignupsForLifeSupportCustomers
    ) {
      setDisableNextBtn(true);
    } else if (
      fields.hasCoolingConcession === 'yes' &&
      customConfig.disableSignupsForMedicalCoolingCustomers
    ) {
      setDisableNextBtn(true);
    } else {
      setDisableNextBtn(false);
    }
  }, [
    customConfig.disableSignupsForLifeSupportCustomers,
    customConfig.disableSignupsForMedicalCoolingCustomers,
    fields.hasLifeSupportPerson,
    fields.hasCoolingConcession,
  ]);

  const handleChange = useCallback(
    changed => {
      const resetTouched = { ...touched };
      const resetFields = { ...fields };
      for (const name in changed) {
        let val = changed[name];
        const hasLifeSupportPersonChange =
          val === 'yes' &&
          name === 'hasLifeSupportPerson' &&
          fields['hasLifeSupportPerson'] === 'no';
        if (name === 'hasConcessionCard') {
          val = val === 'true';
          if (!val) {
            setStartDateError('');
            setExpiryDateError('');
          }
        }

        resetTouched[name] = true;
        resetFields[name] = val;
        if (name === 'cardStartDate') {
          resetFields['cardExpiry'] = '';
          resetTouched['cardExpiry'] = false;
          setErrors(prev => ({ ...prev, cardExpiry: '' }));
          // if the start date is before today, set expiry max date to today
          if (isBefore(parseISO(val), new Date())) {
            setMinCardExpiry(new Date());
          }
          // if start date is after the current min, set the max date to that day.
          if (isAfter(parseISO(val), minCardExpiry)) {
            setMinCardExpiry(val);
          }
        }
        if (hasLifeSupportPersonChange) {
          logSaleAction(
            { accountId: localStorage.getItem(ACCOUNT_ID) },
            'CHECK_LIFE_SUPPORT',
            true
          );
        }
      }

      setFields(resetFields);
      setTouched(resetTouched);
      validateForm(resetTouched, resetFields);
    },
    [errors, fields, touched, validateForm, minCardExpiry]
  );

  const prevPropRef = useRef();
  useEffect(() => {
    if (
      prevPropRef.current &&
      isActive &&
      prevPropRef.current !== saveProgressTrigger
    ) {
      const data = updateResponse();
      onNavigate('progress', data, true);
    }
    prevPropRef.current = saveProgressTrigger;
  }, [
    isActive,
    onNavigate,
    saveProgressTrigger,
    signupAPIResponse,
    updateResponse,
  ]);

  const callNextStep = React.useCallback(() => {
    const data = updateResponse();
    data.currentPage = signupSteps.completeSummary;
    onNavigate('next', data, false);
  }, [onNavigate, updateResponse]);

  const validateCurrentForm = useCallback(
    e => {
      let newFields = { ...fields };
      let newTouch = { ...touched };
      if (isEmbeddedNetwork) {
        newFields['hasConcessionCard'] = false;
      }

      for (const key in touched) {
        if (
          fields['hasConcessionCard'] !== true &&
          concessionCardArrayField.includes(key)
        ) {
          newTouch[key] = false;
          newFields[key] = '';
        } else {
          if (fields[key] && typeof fields[key] === 'string') {
            newFields[key] = fields[key].trim();
          }
          newTouch[key] = true;
        }
      }
      setFields(newFields);
      setTouched(newTouch);

      const invalidField = validateForm(newTouch, newFields);

      if (!invalidField && !startDateError && !expiryDateError) {
        callNextStep();
      } else {
        scrollToSection(invalidField);
      }
    },
    [
      isEmbeddedNetwork,
      touched,
      fields,
      validateForm,
      startDateError,
      expiryDateError,
      callNextStep,
    ]
  );

  const resetValidation = useCallback(() => {
    setErrors(defaultErrorsState);
    setTouched(defaultTouchedState);
  }, []);

  const onBackClick = useCallback(() => {
    resetValidation();
    onNavigate('back');
  }, [onNavigate, resetValidation]);

  // Hydrate Form with fresh data from API
  useEffect(() => {
    if (!signupAPIResponse) {
      return;
    }

    const {
      hasLifeSupportPerson,
      lifeSupportPersonDetail,
      lifeSupportType,
      concessionCardDetail,
      hasConcessionCard,
      hasCoolingConcession,
      contacts,
      address,
      services,
    } = signupAPIResponse;

    const primaryContact = contacts.find(c => c.isPrimary === 1);

    let fieldData = {
      hasLifeSupportPerson: hasLifeSupportPerson,
      lifeSupportPersonDetail: lifeSupportPersonDetail || null,
      lifeSupportType: lifeSupportType,
      hasConcessionCard: hasConcessionCard,
      hasCoolingConcession: yesNoOptionConverter(hasCoolingConcession),
      fuelType: getFuelType(services),
    };

    if (address) {
      fieldData['state'] = address.State;
    }

    if (!concessionCardDetail && primaryContact) {
      fieldData['cardName'] = primaryContact.firstName;
      fieldData['cardLastName'] = primaryContact.lastName;
    }

    if (concessionCardDetail && Object.keys(concessionCardDetail).length) {
      fieldData['cardId'] = concessionCardDetail['id'];
      fieldData['cardType'] = concessionCardDetail['cardTypeCode'];
      fieldData['cardNumber'] = concessionCardDetail['cardNumber'];
      fieldData['cardName'] = concessionCardDetail['cardName'];
      fieldData['cardLastName'] = concessionCardDetail['cardLastName'];
      fieldData['cardStartDate'] = concessionCardDetail['startDate'];
      fieldData['cardExpiry'] = concessionCardDetail['expiryDate'];
      fieldData['hasConsented'] = concessionCardDetail['hasConsented'];
    }

    setFields(fieldData);
  }, [signupAPIResponse]);

  return (
    <AgilityCard className={`steps-wrapper ${className}`} id={id}>
      <form
        autoComplete="off"
        noValidate
        data-test-id="lifeSupportConcessionForm"
      >
        <AgilityGrid container spacing={2}>
          <LifeSupportCard
            {...{
              fields,
              errors,
              isActive,
              handleChange,
              isTangoPartner,
              isCovau,
              isFirstEnergy,
            }}
          />

          <ConcessionCard
            {...{
              fields,
              errors,
              cardList,
              isActive,
              handleChange,
              changeDate,
              onStartDateError: setStartDateError,
              startDateError,
              minCardExpiry,
              onExpiryDateError: setExpiryDateError,
              expiryDateError,
              isResident,
              isEmbeddedNetwork,
              setFields,
              isTangoPartner,
              isAgentView,
            }}
          />
        </AgilityGrid>
        <AgilityGrid container spacing={2} justifyContent="flex-end">
          <AgilityGrid item xs={12} sm={12}>
            <div className="steps-footer">
              <AgilityButton
                color="primary"
                onClick={onBackClick}
                disabled={!isActive}
                label={i18n.t('signup.button.back')}
                data-test-id="backButton"
                className="push"
              />
              {isAgentView && (
                <AgilityButton
                  variant="contained"
                  color="primary"
                  type="primary"
                  onClick={openProgressDialog}
                  disabled={!isActive}
                  label={i18n.t('saveprogress.sms.button')}
                  data-test-id="smsButton"
                  endIcon={<FontAwesomeIcon icon={['fas', 'envelope']} />}
                />
              )}
              {!isAgentView && (
                <AgilityButton
                  variant="contained"
                  color="primary"
                  type="primary"
                  label={i18n.t('signup.button.next')}
                  disabled={!isActive || disableNextBtn}
                  data-test-id="nextButton"
                  onClick={validateCurrentForm}
                  loading={nextLoading}
                />
              )}
            </div>
          </AgilityGrid>
        </AgilityGrid>
      </form>
    </AgilityCard>
  );
};

export default LifeSupportConcession;
